import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SucursalModel } from 'src/app/models/branch-office/sucursal.model';
import { AuthService } from 'src/app/services/auth.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  token: string = sessionStorage.getItem('token');
  username: string = sessionStorage.getItem('username');
  nombrePersonaUsuario: string;
  nombreSucursal: string = sessionStorage.getItem('nombreSucursal');

  constructor(private authService: AuthService, private usuarioService: UsuarioService, private router: Router) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('nombrePersonaUsuario') == null) {
      this.getCliente();
    } else {
      this.nombrePersonaUsuario = sessionStorage.getItem('nombrePersonaUsuario');
    }
  }

  cambiarSucursal() {
    this.usuarioService.obtenerDatosUsuario(this.token, this.username)
    .subscribe(resp => {
      let sucursales: SucursalModel[] = [];
      sucursales = resp.resultado.sucursales;

      //Se evalua si el usuario tiene asignado mas de una sucursal para mostrar pantalla y seleccionar sucursal
      if (sucursales.length > 1) {
        let sucursalesSelect = new Map<number, string>();
  
        sucursales.forEach(sucursal => {
          sucursalesSelect.set(sucursal.idSucursal, sucursal.nombre);
        });
        
        Swal.fire({
          title: 'Selecciona la sucursal',
          input: 'select',
          inputOptions: sucursalesSelect,
          inputPlaceholder: 'Seleccionar sucursal',
          showCancelButton: true,
          heightAuto: false,
          allowOutsideClick: false,
          confirmButtonText: 'Seleccionar',
          cancelButtonText: 'Cancelar',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value != '') {
                resolve(undefined)
              } else {
                resolve('Selecciona la sucursal')
              }
            })
          }
        }).then(result => {
          if (result.isConfirmed) {
            let idSucursalSelect = result.value;

            if (idSucursalSelect == sessionStorage.getItem('idSucursal')) {
              return;
            }
            
            sessionStorage.setItem('idSucursal', idSucursalSelect);
            sessionStorage.setItem('nombreSucursal', sucursalesSelect.get(+idSucursalSelect));
            if (this.router && this.router.url == '/home') {
              window.location.reload();
            } else {
              this.router.navigate(['/home']);
            }
          }
        });
      }
    }, (err) => {
      if (err.status == 401) {
        this.authService.refrescarToken().subscribe((resp: any) => {
          this.token = resp.access_token;
          this.authService.guardarToken(resp.access_token, resp.refresh_token);
          this.cambiarSucursal();
        }, err => {
          Swal.fire({
            icon: 'info',
            title: 'Ha pasado demasiado tiempo',
            text: 'Por seguridad es necesario que vuelva a iniciar sesión',
            heightAuto: false
          });
          this.authService.logout();
          return;
        });
      } else {
        this._validarEstatusError(err);
      }
    });
    Swal.close();
  }

  logout() {
    Swal.fire({
      title: '¿Estás seguro de salir?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8973D9',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Salir',
      heightAuto: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.authService.logout();
      }
    });
  }

  getCliente() {
    this.usuarioService.obtenerDatosUsuario(this.token, sessionStorage.getItem('username'))
      .subscribe(resp => {
        this.nombrePersonaUsuario = resp.resultado.persona.nombre + ' ' + resp.resultado.persona.apellidoPaterno;

        sessionStorage.setItem('nombrePersonaUsuario', this.nombrePersonaUsuario);
        sessionStorage.setItem('idUsuario', resp.resultado.idUsuario);
      }, (err) => {
        if (err.status == 401) {
          this.authService.refrescarToken().subscribe((resp: any) => {
            this.token = resp.access_token;
            this.authService.guardarToken(resp.access_token, resp.refresh_token);
            this.getCliente();
          }, err => {
            Swal.fire({
              icon: 'info',
              title: 'Ha pasado demasiado tiempo',
              text: 'Por seguridad es necesario que vuelva a iniciar sesión',
              heightAuto: false
            });
            this.authService.logout();
            return;
          });
        } else {
          this._validarEstatusError(err);
        }
      });
  }

  private _validarEstatusError(err) {
    if (err.status == 400) {
      this._lanzarError('Error de comunicación', err.error.detalles[0].mensaje.split(".", 1));
    } else if (err.status == 403) {
      this._lanzarError('Acceso denegado', err.error.detalles[0].mensaje);
    } else if(err.status == 404) {
      this.nombrePersonaUsuario = '';
    } else if (err.status == 500 || err.status == 503) {
      this._lanzarError('Error de comunicación', err.error.detalles[0].mensaje);
    } else {
      this._lanzarError('Error desconocido', 'Lo sentimos, ocurrio un error al intentar procesar la petición, si el problema persiste reportalo a soporte@grupofragy.com');
    }
  }

  private _lanzarError(titulo: string, texto: string) {
    Swal.fire({
      icon: 'error',
      title: titulo,
      text: texto,
      heightAuto: false
    });
  }

}
