import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ArchivoDigitalizacion, ArchivoSolicitudModel, EliminarArchivoModel, VerArchivoModel } from 'src/app/models/files/archivo-solicitud.model';
import { ExpedienteModel, ReglasClasificacionArchivoModel, ReglasExpedienteModel, ReglasTiposArchivosModel, TiposArchivosModel } from 'src/app/models/files/expediente.model';
import { AuthService } from 'src/app/services/auth.service';
import UtilsRest from 'src/utils/UtilsRest';
import UtilsSwal from 'src/utils/UtilsSwal';
import { Constants } from '../../../classes/constants.class';
import { DigitalizacionService } from '../../../services/digitalizacion.service';
import { notNullUndefined } from '../../../../utils/nonNullUndefined';
import Swal from 'sweetalert2';
import { EstatusSolicitudRequest } from 'src/app/models/credits-application/estatus-solicitud.model';
import { SolicitudesService } from 'src/app/services/solicitudes.service';

@Component({
  selector: 'app-expediente-form',
  templateUrl: './expediente-form.component.html',
  styleUrls: ['./expediente-form.component.scss']
})
export class ExpedienteFormComponent implements OnInit, OnChanges, OnDestroy {

  private token: string = sessionStorage.getItem('token');
  private idUsuario: string = sessionStorage.getItem('idUsuario');

  // PARAMETROS RECIBIDOS
  @Input() numSolicitud: string;
  @Input() claveTipoSolicitud: string;

  // PARAMETROS LOCALES
  claveTipoExpediente: string;
  archivos: ArchivoDigitalizacion[] = [];
  verArchivos: VerArchivoModel[];

  // PARAMETROS DINAMICOS HTML (REGLAS)
  clasificacionArchivoSelect: ReglasClasificacionArchivoModel;
  tipoArchivoSelect: ReglasTiposArchivosModel;
  
  // PARAMETROS SERVICIOS EXTERNOS
  reglasExpediente: ReglasExpedienteModel;
  expediente: ExpedienteModel;

  // CONSTANTES
  claveValidacionDocumentos = Constants.CLAVE_RECEPCION_VALIDACION_DOCUMENTOS;
  tituloActualizarEstatus = "Confirmar expediente completo";
  tipo = Constants.TIPO_DIGITALIZACION_IMAGEN;

  constructor(
    private router: Router,
    private authService: AuthService,
    private solicitudesService: SolicitudesService,
    private digitalizacionService: DigitalizacionService,
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.claveTipoSolicitud.currentValue === Constants.CLAVE_TIPO_SOLICITUD_NUEVA 
        || changes.claveTipoSolicitud.currentValue === Constants.CLAVE_TIPO_SOLICITUD_NUEVA_PAG_WEB
    ) {
      this.claveTipoExpediente = Constants.CLAVE_TIPO_EXPEDIENTE_NUEVO;
    } else {
      this.claveTipoExpediente = Constants.CLAVE_TIPO_EXPEDIENTE_PREMIUM;
    }
    this.buscarReglasExpediente();
  }

  ngOnDestroy(): void {
    this.reglasExpediente = null;
    this.clasificacionArchivoSelect = null;
    this.tipoArchivoSelect = null;
  }

  // METODOS HTML 
  regresar(){
    this.router.navigate(['/solicitudes']);
  }

  setClasificacionArchivoSelect(
    clasificacionArchivo: ReglasClasificacionArchivoModel
  ) {
    this.clasificacionArchivoSelect = clasificacionArchivo;
    this.tipoArchivoSelect = null;
  }

  setTipoArchivoSelect(event) { 
    this.obtenerDatosTipoArchivo(event.target.value);
  }

  addFile(nuevoArchivo: ArchivoDigitalizacion) {

    const indexArchivoAnterior = this.archivos
      .findIndex((archivo) => archivo.numArchivo === nuevoArchivo.numArchivo);

    // Si el archivo contiene información
    if(nuevoArchivo.base64) {

      // Si ya existe el archivo en la lista, se actualiza
      if(indexArchivoAnterior >= 0) {
        this.archivos[indexArchivoAnterior] = nuevoArchivo;
      } else {
        // Si no existe el archivo, se agrega a la lista
        this.archivos.push(nuevoArchivo);
      }
    
    // Si el archivo esta vacio y existen la lista, se borra 
    } else if(!nuevoArchivo.base64 && indexArchivoAnterior >= 0) {
      this.archivos.splice(indexArchivoAnterior, 1);
    }
  }

  guardarArchivos() {
    const archivos: ArchivoSolicitudModel = {
      idDinamico: this.numSolicitud,
      claveTipoExpediente: this.claveTipoExpediente,
      claveClasificacionArchivo: this.clasificacionArchivoSelect.clave,
      usuarioUltMod: this.idUsuario,
      tiposArchivos: [{
        clave: this.tipoArchivoSelect.clave,
        archivos: this.archivos
      }]
    };

    UtilsSwal.enviarInformacion(
      "¿Estás seguro que deseas guardar estos archivos en el servidor?",
      "Guardando archivo(s)",
      "Guardar",
      this.digitalizacionService.guardarArchivo(this.token, archivos),
      false
    )
    .then(() => this.reloadPage());
  }

  borrarArchivos() {

    Swal.fire({
      title: '¿Estás seguro que deseas eliminar estos achivos?',
      text: 'Esta acción no se podrá revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8973D9',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      heightAuto: false

    }).then((result) => {
      if (result.isConfirmed) {

        // Se eliminan todos los archivos por tipo de archivo
        this.verArchivos.forEach((archivo, key, arr) => {

          const eliminarArchivo: EliminarArchivoModel = {
            idDinamico: this.numSolicitud,
            claveTipoArchivo: this.tipoArchivoSelect.clave,
            numArchivo: archivo.numArchivo,
            usuarioUltMod: this.idUsuario
          }

          Swal.fire({
            allowOutsideClick: false,
            icon: 'info',
            text: `Eliminando archivo (${archivo.numArchivo})`
          });
          Swal.showLoading();

          this.digitalizacionService.eliminarArchivo(
            this.token,
            eliminarArchivo
          )
          .subscribe(() => {})
          .add(() => {
            Swal.close();
            // Si es el ultimo item, se recarga la pagina
            if (Object.is(arr.length - 1, key)) {
              this.reloadPage();
            }
          });
        })
      }
    });
  }

  // SERVICIOS EXTERNOS
  buscarReglasExpediente() {
    this.digitalizacionService.obtenerReglasExpediente(
      this.token, 
      this.claveTipoExpediente, 
    ).subscribe(resp => {
      this.reglasExpediente = resp.resultado;
      this.buscarExpediente();
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarReglasExpediente();
      }
    });
  }

  buscarExpediente() {
    this.digitalizacionService.obtenerExpediente(
      this.token, 
      this.numSolicitud,
      this.claveTipoExpediente, 
    ).subscribe(resp => {
      this.expediente = resp.resultado;
      this.validarClasificacionExpediene();
    }, (err) => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarExpediente();
      }
    });
  }

  // METODOS PRIVADOS
  reloadPage() {
    window.location.reload();
  }

  obtenerDatosTipoArchivo(claveTipoArchivo: string) {

    this.tipoArchivoSelect = this.clasificacionArchivoSelect.tiposArchivos
      .find((tipoArchivo) => tipoArchivo.clave === claveTipoArchivo);

    const tipoArchivoExpediente = this.buscarTipoArchivoExpediente();

    if(tipoArchivoExpediente.archivos) {
      this.verArchivos = tipoArchivoExpediente.archivos
      .filter(notNullUndefined)
      .map((archivo) => ({
        idDinamico: this.numSolicitud,
        numArchivo: archivo.numArchivo,
        tipoArchivo: tipoArchivoExpediente.clave
      }));
    } else {
      this.verArchivos = null;
    }
  }

  validarClasificacionExpediene() {

    // Se actualiza estatus de la clasificacion de los archivos
    this.expediente.clasificacionesArchivos.forEach((expediente) => {
      this.reglasExpediente.clasificacionesArchivos.forEach((regla) => {

        // Se valida la clave de la clasificación del archivo
        if(expediente.clave === regla.clave &&
          // Se valida que tenga el minimo y maximo de tipos de archivos de esa clasificación
          expediente.tiposArchivos.length >= regla.numMinTipoArchivo && 
          expediente.tiposArchivos.length <= regla.numMaxTipoArchivo
        ) {

          // Se valida que cada tipo de archivo cumpla con las reglas
          expediente.tiposArchivos.forEach((expedienteTipoArchivo) => {

            // Se obtienen las reglas del tipo de archivo
            const reglaTipoArchivoExpediente = regla.tiposArchivos
              .find((reglaTipoArchivo) => expedienteTipoArchivo.clave === reglaTipoArchivo.clave);

            // Se valida la regla
            if(expedienteTipoArchivo.archivos.length >= reglaTipoArchivoExpediente.numMinArchivos && 
              expedienteTipoArchivo.archivos.length <= reglaTipoArchivoExpediente.numMaxArchivos
            ) {
              // Se actualiza el estatus de la clasifiación del archivo
              regla.complete = true;
            }
          });
        }
      });
    });
  }

  buscarTipoArchivoExpediente(): TiposArchivosModel {

    // Se llena la infomación genral del tipo de archivo
    let tipoArchivoExpediente: TiposArchivosModel = {
      clave: this.tipoArchivoSelect.clave,
      descripcion: this.tipoArchivoSelect.descripcion,
      archivos: null
    };

    if(!this.expediente) return tipoArchivoExpediente;

    // Se busca la clasificación del archivo
    this.expediente.clasificacionesArchivos.forEach((clasfArchivoExpediente) => {
      if(clasfArchivoExpediente.clave === this.clasificacionArchivoSelect.clave) {

        // Se busca que el expediente tenga el mismo tipo de archivo
        clasfArchivoExpediente.tiposArchivos.forEach((tipoArchivo) => {
          if(tipoArchivo.clave === this.tipoArchivoSelect.clave) {
            tipoArchivoExpediente = tipoArchivo;
          }
        });

      }
    });
    return tipoArchivoExpediente;
  }

  validarAutorizarExpedienteCompleto() {

    if(!this.reglasExpediente) return false;

    return this.reglasExpediente.clasificacionesArchivos
      .filter((regla) => regla.obligatorio)
      .map((regla) => regla.complete)
      .every((complete) => complete);
  }

  eliminarSolicitud() {
    Swal.fire({
      title: 'Confirmación de cancelación',
      html: `<textarea id="motivo" placeholder="Inserta un motivo de cancelación" cols="50"></textarea>`,
      confirmButtonText: 'Confirmar',
      focusConfirm: false,
      preConfirm: () => {
        const motivo = (<HTMLInputElement> Swal.getPopup().querySelector('#motivo')).value;

        if (!motivo) {
          Swal.showValidationMessage(`Por favor inserta un motivo para continuar`)
        }
        return { motivo: motivo }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        
        const estatusSolicitud: EstatusSolicitudRequest = {
          numSolicitud: this.numSolicitud,
          usuarioUltMod: this.idUsuario,
          motivo: result.value.motivo.trim(),
          claveEstatusSolicitud: Constants.CLAVE_TIPO_ESTATUS_CANCELACION
        }

        this._cancelarSolicitud(estatusSolicitud);
      }
    });
  }

  // METODOS HTML - VALIDACIONES
  mostrarBotonEliminar() {
    if(!this.authService.hasRole(Constants.ROLE_ADMIN)) return false;
    return true;
  }

  // SERVICIOS TERCEROS
  private _cancelarSolicitud(estatusSolicitud: EstatusSolicitudRequest) {

    UtilsSwal.enviarInformacion(
      "¿Estás seguro de cancelar esta solicitud?",
      "Cancelando solicitud",
      "Cancelar",
      this.solicitudesService.cancelarSolicitud(this.token, estatusSolicitud),
      false
    )
    .then(() => this.router.navigate(['/solicitudes']));
  }

}
