<app-sidebar></app-sidebar>
<div class="horizontal-menu horizontal-app-menu dashboard">
    <app-header></app-header>
    <div class="page-container pl-5" *ngIf="permisoUsuario">
        <div class="page-content-wrapper ">
            <div class="content sm-gutter">
                <div class="container sm-padding-10 p-t-20 p-l-0 p-r-0">
                    <div class="row">
                        <div class="col-lg-12 flex-column p-b-10">
                            <app-estatus-solicitudes [solicitudesCreadas]="solicitudesCreadas" [solicitudesDatosTemporales]="solicitudesDatosTemporales" [solicitudesDocumentos]="solicitudesDocumentos" [solicitudesDatos]="solicitudesDatos" [solicitudesPreautorizadas]="solicitudesPreautorizadas"
                                [solicitudesFirmaContrato]="solicitudesFirmaContrato" [solicitudesAutorizadas]="solicitudesAutorizadas">
                            </app-estatus-solicitudes>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>