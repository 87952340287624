<div *ngIf="tiposArchivo && tiposArchivo.length > 0">
    <div class="card social-card share full-width">
        <div class="row">
            <div class="col-lg-4">
                <div class="card-description">
                    <h4 class="text-primary">Expediente digital </h4>
                    <div *ngFor="let tipoArchivo of tiposArchivo">
                        <p class="select-image cursor" *ngFor="let archivo of tipoArchivo.archivos" (click)="selectImage(archivo, tipoArchivo.clave)" [class.image-selected]="archivoSelected && archivo.idArchivo == archivoSelected.idArchivo">
                            {{tipoArchivo.descripcion}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="card-header full-width">
                    <img alt="document" [src]="archivoSelected | noimage | domseguro">
                </div>
            </div>
        </div>
    </div>
</div>