<app-sidebar></app-sidebar>
<div class="horizontal-menu horizontal-app-menu dashboard">
    <app-header></app-header>
    <div class="page-container pl-5" *ngIf="permisoUsuario">
        <div class="page-content-wrapper ">
            <div class="content sm-gutter">
                <div class="container sm-padding-10 p-t-20 p-l-0 p-r-0" *ngIf="solicitud">
                    <div class="row">
                        <div class="col-lg-12">
                            <app-preautorizar [solicitud]="solicitud" *ngIf="permisoAdmin"></app-preautorizar>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <app-datos-generales-solicitud [solicitud]="solicitud"></app-datos-generales-solicitud>
                        </div>
                        <div class="col-lg-6">
                            <app-tracking-solicitud [tracking]="tracking"></app-tracking-solicitud>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>