import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ArchivoSolicitudModel, EliminarArchivoModel } from '../models/files/archivo-solicitud.model';

@Injectable({
  providedIn: 'root'
})
export class DigitalizacionService {

  constructor(private http: HttpClient) { }

  // GET
  obtenerExpediente(accessToken: string, idDinamico: string, claveTipoExpediente: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/digitalizacion/v1/expediente?idDinamico=${idDinamico}&claveTipoExpediente=${claveTipoExpediente}`, 
      {headers: headers}
    );
  }

  obtenerReglasExpediente(
    accessToken: string, 
    claveTipoExpediente: string
  ): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/digitalizacion/v1/expediente/reglas?claveTipoExpediente=${claveTipoExpediente}`, 
      {headers: headers}
    );
  }

  obtenerArchivo(accessToken: string, idDinamico: string, claveTipoArchivo: string, numArchivo: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/digitalizacion/v1/archivos?idDinamico=${idDinamico}&claveTipoArchivo=${claveTipoArchivo}&numArchivo=${numArchivo}`, 
      {headers: headers}
    );
  }

  // POST
  guardarArchivo(accessToken: string, archivoSolicitud: ArchivoSolicitudModel): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/digitalizacion/v1/archivos/solicitud`,
      JSON.stringify(archivoSolicitud),
      {headers: headers}
    );
  }

  // DELETE
  eliminarArchivo(
    accessToken: string, 
    eliminarArchivoSolicitud: EliminarArchivoModel
  ): Observable<any> {

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }), 
      body: JSON.stringify(eliminarArchivoSolicitud)
    }

    return this.http.delete(
      `${environment.url}/digitalizacion/v1/archivos`,
      headers
    );
  }
}
