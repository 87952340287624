<!-- START card -->
<div class="card card-default" *ngIf="persona">
    <div class=" card-body">
        <h4 class="text-primary">Datos de la persona</h4>
        <form autocomplete="off" [formGroup]="formPersona" (ngSubmit)="guardarInformacion()">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Nombre(s)</label>
                        <input type="text" class="form-control" placeholder="Nombre(s)" formControlName="nombre" [class.is-invalid]="nombreNoValido" id="nombrePersona">
                        <small *ngIf="nombreNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Apellido Paterno</label>
                        <input type="text" class="form-control" placeholder="Apellido Paterno" formControlName="apellidoPaterno" [class.is-invalid]="apellidoPaternoNoValido" id="apellidoPaterno">
                        <small *ngIf="apellidoPaternoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Apellido Materno</label>
                        <input type="text" class="form-control" placeholder="Apellido Materno" formControlName="apellidoMaterno" id="apellidoMaterno">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Nacionalidad</label>
                        <input type="text" class="form-control" placeholder="Nacionalidad" formControlName="nacionalidad" [class.is-invalid]="nacionalidadNoValido">
                        <small *ngIf="nacionalidadNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Lugar de Nacimiento</label>
                        <input type="text" class="form-control" placeholder="Lugar de Nacimiento" formControlName="lugarNacimiento" [class.is-invalid]="lugarNacimientoNoValido">
                        <small *ngIf="lugarNacimientoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>País de Nacimiento</label>
                        <input type="text" class="form-control" placeholder="País de Nacimiento" formControlName="paisNacimiento" [class.is-invalid]="paisNacimientoNoValido">
                        <small *ngIf="paisNacimientoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <label>Fecha de Nacimiento</label>
                    <div class="input-group date">
                        <ngx-combo-datepicker class="birtday-group" formControlName="fechaNacimiento" months="Enero,Febrero,Marzo,Abril,Mayo,Junio,Julio,Agosto,Septiembre,Octubre,Noviembre,Diciembre">
                        </ngx-combo-datepicker>
                        <small *ngIf="fechaNacimientoNoValido" class="text-danger">El campo no es requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>CURP</label>
                        <input type="text" class="form-control" placeholder="CURP" formControlName="curp" [class.is-invalid]="curpNoValido" id="curp">
                        <small *ngIf="curpNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>RFC</label>
                        <input type="text" class="form-control" placeholder="RFC" formControlName="rfc">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Num Identificación</label>
                        <input type="text" class="form-control" placeholder="Num Identificación" formControlName="numIdentificacion" [class.is-invalid]="numIdentificacionNoValido">
                        <small *ngIf="numIdentificacionNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group form-select">
                        <label>Estado Civil</label>
                        <select class="selectpicker" formControlName="estadoCivil" [class.is-invalid]="estadoCivilNoValido">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let estadoCivil of catEstadoCivil" [ngValue]="estadoCivil.clave">{{estadoCivil.descripcion}}</option>
                        </select>
                        <small *ngIf="estadoCivilNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Genero</label>
                        <div class="form-group ellipsis">
                            <div class="form-check-inline form-radio-buttons">
                                <input class="form-check-input" type="radio" value="M" formControlName="genero">
                                <label class="form-check-label">Masculino</label> &nbsp;
                                <input class="form-check-input" type="radio" value="F" formControlName="genero">
                                <label class="form-check-label">Femenino</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <app-viviendas #appViviendas [vivienda]="vivienda" [catTipoVivienda]="catTipoVivienda"></app-viviendas>

            <div class="row">
                <h5 class="text-primary">Contacto</h5>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Correo Electrónico</label>
                        <input type="text" class="form-control" placeholder="Correo Electrónico" formControlName="correo" [class.is-invalid]="correoNoValido">
                        <small *ngIf="correoNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
            </div>

            <app-telefonos #appTelefonos [telefonos]="telefonos"></app-telefonos>

            <div class="col-lg-12 m-t-20">
                <button type="submit" class="btn btn-primary float-right" *ngIf="persona.idPersona">Actualizar</button>
                <button type="submit" class="btn btn-primary float-right" *ngIf="numSolicitud && !persona.idPersona">Guardar</button>
            </div>
        </form>
    </div>
</div>
<!-- END card -->