<!-- START CONTAINER FLUID -->
<div class="container container-fixed-lg footer pl-5">
    <!-- START COPYRIGHT -->
    <div class="copyright sm-text-center">
        <p class="small-text no-margin pull-left sm-pull-reset">
            &copy; {{ anio }} Point Financiera. All Rights Reserved. <br> <a href="https://www.grupofragy.com" target="_blank">Powered by Grupo Fragy</a>
        </p>
        <p class="small no-margin pull-right sm-pull-reset">
            <span class="hint-text m-l-15">v3.1.0-beta</span>
        </p>
        <div class="clearfix"></div>
    </div>
    <!-- END COPYRIGHT -->
</div>