<!-- CARD -->
<div class="card social-card share m-b-10 no-border full-width" data-social="item">
    <div class="card-header clearfix">
        <h5 class="pull-left fs-12">CLIENTES</h5>
    </div>
    <div class="card-description">
        <h5 class='no-margin text-primary'>Información general del cliente</h5>
    </div>
    <div class="card-body">
        <label>Buscar cliente</label>
        <ng-autocomplete [data]="autocompleteClientes" [searchKeyword]="keyword" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" placeholder="Buscar cliente" (selected)='selectEvent($event)' (inputCleared)='clearedEvent()'>
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.nombre"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
            <div>No existen registros</div>
        </ng-template>

        <form [formGroup]="form" (ngSubmit)="enviarInfoCliente()">
            <label *ngIf="clienteSelect" class="form-control-label p-t-20" for="input-negocio" style="margin-top: 8px">Selecciona negocio</label>
            <select *ngIf="clienteSelect" id="input-negocio" class="selectpicker" style="width: 100%;" formControlName="negocio" [(ngModel)]='negocioSelect' [class.is-invalid]="negocioInvalido">
                         <option *ngFor="let negocio of negocios; let i = index" [ngValue]="negocio">
                            {{negocio.nombre}}
                        </option>
                    </select>
            <small *ngIf="negocioInvalido" class="text-danger">Selecciona un negocio</small>
            <button [disabled]="!clienteSelect || !negocioSelect" type="submit" class="btn btn-primary float-right m-t-40" *ngIf="negocioSelect">
                    Buscar
                </button>
        </form>
    </div>
</div>