<!--PAGOS -->
<div *ngIf="pagoModel">
    <div class="card social-card share full-width m-b-10 no-border" data-social="item">
        <div class="card-header clearfix">
            <div class="row">
                <div class="col-10-flex">
                    <h5 class="text-primary">Pagos</h5>
                </div>
                <div *ngIf="!historico">
                    <div class="col-2 btn-align-right" *ngIf="pagoModel.estatusVisita === claveCuotaPagada">
                        <button type="button" (click)="realizarPago()" data-tooltip="Registrar complemento de pago" class="btn btn-primary btn-sm btn-add">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-random" *ngIf="!pagoModel || !pagoModel.pagos || pagoModel.pagos.length === 0">
            <div class="card-body">
                <p> Esta cuota no ha recibido pagos </p>
            </div>
        </div>
    </div>
    <div class="card share full-width m-b-10 no-border no-background" data-social="item" *ngIf="pagoModel.pagos && pagoModel.pagos.length > 0">
        <div class="timeline-container top-circle">
            <section class="timeline">
                <div class="timeline-block" *ngFor="let pago of pagoModel.pagos">
                    <div class="timeline-point" [ngClass]="pago.tipoPago.clave === clavePagoCancelado ? 'danger' : 'primary'">
                        <h5 class="text-white">{{pago.numPago}}</h5>
                    </div>
                    <!-- timeline-point -->
                    <div class="timeline-content">
                        <div class="card social-card share full-width">
                            <div class="circle" data-toggle="tooltip" title="Label" data-container="body">
                            </div>
                            <div class="card-header clearfix">
                                <div class="row">
                                    <div class="col-5-flex" *ngIf="pago.referencia">
                                        <h5 [ngClass]="pago.tipoPago.clave === clavePagoCancelado ? 'text-danger' : 'text-primary'">
                                            {{pago.tipoPago.descripcion | uppercase}}
                                        </h5>
                                        <h6 class="d-flex align-items-center" *ngIf="pago.cobrador && pago.cobrador.persona">
                                            {{pago.cobrador.persona.nombre}} {{pago.cobrador.persona.apellidoPaterno}}
                                        </h6>
                                    </div>
                                    <div class="col-2 btn-align-right" *ngIf="validaCancelacion(pago)">
                                        <button type="button" (click)="cancelarPago(pago.idPagoCredito)" data-tooltip="Cancelar pago" class="btn btn-danger btn-sm btn-delete">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-description">
                                <p>
                                    <span class="title-labels">Monto Cobrado: </span> {{pago.montoCobrado | currency}}
                                </p>
                                <p>
                                    <span class="title-labels">Monto a Capital: </span> {{pago.montoACapital | currency}}
                                </p>
                                <p>
                                    <span class="title-labels">Monto a Intereses: </span> {{pago.montoAIntereses | currency}}
                                </p>
                                <p>
                                    <span class="title-labels">Referencia: </span> {{pago.referencia}}
                                </p>
                                <!-- TRANSFERENCIA -->
                                <p *ngIf="pago.formaPago.clave == claveTransferencia">
                                    <span class="title-labels">No. Transferencia: </span> {{pago.numTransferencia}}
                                </p>
                                <!-- CANCELACION -->
                                <p *ngIf="pago.tipoPago.clave == clavePagoCancelado">
                                    <span class="title-labels">Motivo: </span> {{pago.motivoCancelacion}}
                                </p>
                                <div class="m-t-20">
                                    <small class="fs-12 hint-text">{{pago.fechaPago}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>