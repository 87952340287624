import { AuthService } from 'src/app/services/auth.service';
import UtilsSwal from './UtilsSwal';

export type ApiResponse = { 
    codigo: number; 
    accessToken: string | null; 
    refreshToken: string | null; 
  };

export default class UtilsRest {

    // TODO: Generar metodo con promesa generica para validar respuesta de cualquier API

    static validaApiError(err, notFound: boolean, authService: AuthService): ApiResponse {

        if (err.status == 401) {
            authService.refrescarToken().subscribe((resp: any) => {
                authService.guardarToken(resp.access_token, resp.refresh_token);
                return {
                    codigo: 401,
                    accessToken: resp.access_token,
                    refreshToken: resp.refresh_token
                };
            }, err => {
                UtilsSwal.sesionExpirada();
                authService.logout();
            });

        } else if (!notFound){
            return this.validarEstatusErrorSin404(err);
        } else {
            return this.validarEstatusErrorCon404(err);
        }
    }
    
    static validarEstatusErrorSin404(err): ApiResponse {
        if (err.status == 400) {
            UtilsSwal.lanzarError('Petición no valida', err.error.detalles[0].mensaje.split(".", 1));
        } else if (err.status == 403) {
            UtilsSwal.lanzarError('Acceso denegado', err.error.detalles[0].mensaje);
        } else if (err.status == 404) {
            return {
                codigo: 404,
                accessToken: null,
                refreshToken: null
            };
        } else if (err.status == 500 || err.status == 503) {
            UtilsSwal.lanzarError('Error de comunicación', err.error.detalles[0].mensaje);
        } else {
            UtilsSwal.lanzarError('Error desconocido', 'Lo sentimos, ocurrio un error al intentar procesar la petición, si el problema persiste reportalo a soporte@grupofragy.com');
        }

        return {
            codigo: 0,
            accessToken: null,
            refreshToken: null
        };
    }

    static validarEstatusErrorCon404(err): ApiResponse {
        if (err.status == 400) {
            UtilsSwal.lanzarError('Petición no valida', err.error.detalles[0].mensaje);
        } else if (err.status == 403) {
            UtilsSwal.lanzarError('Acceso denegado', err.error.detalles[0].mensaje);
        } else if (err.status == 404) {
            UtilsSwal.lanzarError('Información no encontrada', err.error.detalles[0].mensaje);
        } else if (err.status == 500 || err.status == 503) {
            UtilsSwal.lanzarError('Error de comunicación', err.error.detalles[0].mensaje);
        } else {
            UtilsSwal.lanzarError('Error desconocido', 'Lo sentimos, ocurrio un error al intentar procesar la petición, si el problema persiste reportalo a soporte@grupofragy.com');
        }

        return {
            codigo: 0,
            accessToken: null,
            refreshToken: null
        };
    }
}