<!-- START card -->
<div class="card card-default">
    <div class="card-body">
        <h4 class="text-primary">Datos de la referencia</h4>
        <form autocomplete="off" [formGroup]="formPersona" (ngSubmit)="guardarReferencia()">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Nombre(s)</label>
                        <input type="text" class="form-control" placeholder="Nombre(s)" formControlName="nombre" [class.is-invalid]="nombreNoValido" id="nombrePersona">
                        <small *ngIf="nombreNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Apellido Paterno</label>
                        <input type="text" class="form-control" placeholder="Apellido Paterno" formControlName="apellidoPaterno" [class.is-invalid]="apellidoPaternoNoValido" id="apellidoPaterno">
                        <small *ngIf="apellidoPaternoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Apellido Materno</label>
                        <input type="text" class="form-control" placeholder="Apellido Materno" formControlName="apellidoMaterno" id="apellidoMaterno">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <label>Fecha de Nacimiento</label>
                    <div class="input-group date">
                        <input type="text" class="form-control" id="datepicker-component">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Lugar de Nacimiento</label>
                        <input type="text" class="form-control" placeholder="Lugar de Nacimiento" formControlName="lugarNacimiento" [class.is-invalid]="lugarNacimientoNoValido">
                        <small *ngIf="lugarNacimientoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>País de Nacimiento</label>
                        <input type="text" class="form-control" placeholder="País de Nacimiento" formControlName="paisNacimiento" [class.is-invalid]="paisNacimientoNoValido">
                        <small *ngIf="paisNacimientoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Nacionalidad</label>
                        <input type="text" class="form-control" placeholder="Nacionalidad" formControlName="nacionalidad" [class.is-invalid]="nacionalidadNoValido">
                        <small *ngIf="nacionalidadNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>RFC</label>
                        <input type="text" class="form-control" placeholder="RFC" formControlName="rfc">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>CURP</label>
                        <input type="text" class="form-control" placeholder="CURP" formControlName="curp" [class.is-invalid]="curpNoValido" id="curp">
                        <small *ngIf="curpNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Genero</label>
                        <div class="form-group">
                            <div class="form-check-inline">
                                <input class="form-check-input" type="radio" value="M" formControlName="genero">
                                <label class="form-check-label">Masculino</label> &nbsp;
                                <input class="form-check-input" type="radio" value="F" formControlName="genero">
                                <label class="form-check-label">Femenino</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <label>Estado Civil</label>
                    <select class="selectpicker" (change)="cambiarEstadoCivil($event)" formControlName="estadoCivil" [class.is-invalid]="estadoCivilNoValido">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let estadoCivil of catEstadoCivil" [ngValue]="estadoCivil.clave">{{estadoCivil.descripcion}}</option>
                         </select>
                    <small *ngIf="estadoCivilNoValido" class="text-danger">Campo requerido</small>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label>Num Identificación</label>
                        <input type="text" class="form-control" placeholder="Num Identificación" formControlName="numIdentificacion" [class.is-invalid]="numIdentificacionNoValido">
                        <small *ngIf="numIdentificacionNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Calle</label>
                        <input type="text" class="form-control" placeholder="Calle" formControlName="calle" [class.is-invalid]="calleNoValido">
                        <small *ngIf="calleNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>Num Ext</label>
                        <input type="text" class="form-control" placeholder="Num Ext" formControlName="numExt" [class.is-invalid]="numExtNoValido">
                        <small *ngIf="numExtNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>Num Int</label>
                        <input type="text" class="form-control" placeholder="Num Int" formControlName="numInt">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Colonia</label>
                        <input type="text" class="form-control" placeholder="Colonia" formControlName="colonia" [class.is-invalid]="coloniaNoValido">
                        <small *ngIf="coloniaNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Delegación o Municipio</label>
                        <input type="text" class="form-control" placeholder="Delegación o Municipio" formControlName="municipio" [class.is-invalid]="municipioNoValido">
                        <small *ngIf="municipioNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Estado</label>
                        <input type="text" class="form-control" placeholder="Estado" formControlName="estado" [class.is-invalid]="estadoNoValido">
                        <small *ngIf="estadoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>País</label>
                        <input type="text" class="form-control" placeholder="País" formControlName="pais" [class.is-invalid]="paisNoValido">
                        <small *ngIf="paisNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>Código Postal</label>
                        <input type="text" class="form-control" placeholder="Código Postal" formControlName="cp" [class.is-invalid]="cpNoValido">
                        <small *ngIf="cpNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <label>Tipo Vivienda</label>
                    <select class="selectpicker" (change)="cambiarTipoVivienda($event)" formControlName="tipoVivienda" [class.is-invalid]="tipoViviendaNoValido">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let tipoVivienda of catTipoVivienda" [ngValue]="tipoVivienda.clave">{{tipoVivienda.descripcion}}</option>
                         </select>
                    <small *ngIf="tipoViviendaNoValido" class="text-danger">Campo requerido</small>
                </div>

                <div class="col-md-3" *ngIf="showEscrituras">
                    <div class="form-group">
                        <label>Tiene escrituras</label>
                        <div class="form-group">
                            <div class="form-check-inline">
                                <input class="form-check-input" type="radio" value="S" formControlName="escrituras">
                                <label class="form-check-label">Si</label> &nbsp;
                                <input class="form-check-input" type="radio" value="N" formControlName="escrituras">
                                <label class="form-check-label">No</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group">
                        <label>Correo Electrónico</label>
                        <input type="text" class="form-control" placeholder="Correo Electrónico" formControlName="correo" [class.is-invalid]="correoNoValido">
                        <small *ngIf="correoNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12 card-title">
                    <b class="mr-2">Teléfonos</b>
                    <button type="button" (click)="agregarTelefono()" class="btn btn-primary btn-sm">
                            Agregar Teléfono
                        </button>
                </div>
            </div>

            <div formArrayName="telefonos">
                <div *ngFor="let telefonosForm of telefonos.controls; let i = index">
                    <div class="row" [formGroup]="telefonosForm">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Teléfono</label>
                                <input type="tel" placeholder="Teléfono" class="form-control" formControlName="telefono" [class.is-invalid]="telefonosForm.controls['telefono']?.touched && !telefonosForm.controls['telefono']?.valid">
                                <small *ngIf="telefonosForm.controls['telefono']?.touched && !telefonosForm.controls['telefono']?.valid" class="text-danger">El campo no es válido</small>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label>Tipo Teléfono</label>
                                <div class="form-group">
                                    <div class="form-check-inline">
                                        <input class="form-check-input" type="radio" [value]="claveTel" formControlName="tipoTelefono">
                                        <label class="form-check-label">Teléfono</label> &nbsp;
                                        <input class="form-check-input" type="radio" [value]="claveCel" formControlName="tipoTelefono">
                                        <label class="form-check-label">Celular</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3" *ngIf="i != 0">
                            <div class="mt-4">
                                <button type="button" (click)="eliminarTelefono(i)" class="btn btn-danger btn-sm">
                                        Eliminar
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <button type="submit" class="btn btn-primary float-right">Guardar Referencia</button>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- END card -->