import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { LoadjsService } from 'src/app/services/loadjs.service';
import { ReporteService } from 'src/app/services/reporte.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-reporte-cobranza',
  templateUrl: './reporte-cobranza.component.html',
  styleUrls: ['./reporte-cobranza.component.scss']
})
export class ReporteCobranzaComponent implements OnInit {

  form: UntypedFormGroup;
  private token: string = sessionStorage.getItem('token');
  private idSucursal: string = sessionStorage.getItem('idSucursal');

  constructor(private fb: UntypedFormBuilder, 
              private authService: AuthService,
              private loadjsService: LoadjsService,
              private reporteService: ReporteService) {
   }

  ngOnInit(): void {
    this.loadjsService.loadScript();
  }

  generarReporteCobranza() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Generando Reporte...',
      heightAuto: false
    });
    Swal.showLoading();

    this.reporteService.generarReporteCobranza(this.token, $('#datepicker-cobranza').val(), this.idSucursal).subscribe(resp => {
      Swal.close();

      var downloadURL = window.URL.createObjectURL(resp.body);
      var link = document.createElement('a');

      link.href = downloadURL;
      link.setAttribute('target', '_blank');
      link.click();
    }, (err) => {
      Swal.close();
      if (err.status == 401) {
        this.authService.refrescarToken().subscribe((resp: any) => {
          this.token = resp.access_token;
          this.authService.guardarToken(resp.access_token, resp.refresh_token);
          this.generarReporteCobranza();
        }, err => {
          Swal.fire({
            icon: 'info',
            title: 'Ha pasado demasiado tiempo',
            text: 'Por seguridad es necesario que vuelva a iniciar sesión',
            heightAuto: false
          });
          this.authService.logout();
          return;
        });
      } else {
        this._validarEstatusError(err);
      }
    });
  }

  private _validarEstatusError(err) {
    if (err.status == 400) {
      this._lanzarError('Error de comunicación', 'Lo sentimos, ocurrió un error al generar el reporte');
    } else if (err.status == 403) {
      this._lanzarError('Acceso denegado', '¡UPS!, No tienes permisos para visualizar este reporte');
    } else if (err.status == 404) {
      this._lanzarError('Error de usuario', 'Lo sentimos, no encontramos información para generar el reporte con los datos proporcionados');
    } else if (err.status == 500 || err.status == 503) {
      this._lanzarError('Error de comunicación', err.error.detalles[0].mensaje);
    } else {
      this._lanzarError('Error desconocido', 'Lo sentimos, ocurrio un error al intentar procesar la petición, si el problema persiste reportalo a soporte@grupofragy.com');
    }
  }

  private _lanzarError(titulo: string, texto: string) {
    Swal.fire({
      icon: 'error',
      title: titulo,
      text: texto,
      heightAuto: false
    });
  }

}
