<app-sidebar></app-sidebar>
<div class="horizontal-menu horizontal-app-menu">
    <app-header></app-header>
    <div class="page-container pl-5">
        <div class="page-content-wrapper">
            <div class="content">
                <div class="social-wrapper">
                    <div class="social">
                        <div class="jumbotron">
                            <div class="cover-photo">
                                <img alt="Cover photo" src="assets/img/home_cover.jpeg" />
                            </div>
                            <div class="container container-fixed-lg sm-p-l-0 sm-p-r-0">
                                <div class="inner">
                                    <div class="pull-bottom bottom-left m-b-40 sm-p-l-15">
                                        <h5 class="no-margin text-primary">Bienvenido al sistema de administración</h5>
                                        <h1 class="no-margin text-primary"><span class="semi-bold">Point</span> Financiera</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>