import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadjsService {

  constructor() { }

  public loadScript() {
    let node = document.getElementById("loadjs");
   
    if (node) {
      node.remove();
    }
    let newNode = document.createElement("script");
    newNode.src = "assets/js/script.js";
    newNode.type = "text/javascript";
    newNode.async = false;
    newNode.charset = "utf-8";
    newNode.id = "loadjs";
    document.getElementsByTagName("body")[0].appendChild(newNode);
  }
}
