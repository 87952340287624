<!-- START card -->
<div class="card card-default">
    <div class=" card-body">
        <form autocomplete="off" [formGroup]="formNegocio" (ngSubmit)="guardarInformacion()">
            <div class="card card-default">
                <div class="row">
                    <div class="col-md-5">
                        <h4 class="text-primary">Datos del negocio</h4>
                    </div>
                </div>
                <div class="row m-b-10">
                    <div class="form-check">
                        <input type="checkbox" [checked]="sameDir" (change)="onCheckboxSelect($event)" id="check-dir">
                        <label for="check-dir"> <b>Misma dirección que la persona</b></label>
                    </div>
                    <div class="form-check m-l-20">
                        <input type="checkbox" [checked]="sameTel" (change)="onCheckboxSelect($event)" id="check-tel">
                        <label for="check-tel"> <b>Mismos teléfonos que la persona</b></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Nombre Negocio</label>
                            <input type="text" class="form-control" placeholder="Nombre Negocio" formControlName="nombreNegocio" [class.is-invalid]="nombreNegocioNoValido">
                            <small *ngIf="nombreNegocioNoValido" class="text-danger">Campo requerido</small>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Actividad Económica</label>
                            <input type="text" class="form-control" placeholder="Actividad Económica" formControlName="actividadEconomica">
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Antigüedad (meses)</label>
                            <input type="number" class="form-control" placeholder="Antigüedad" formControlName="antiguedad" [class.is-invalid]="antiguedadNoValido">
                            <small *ngIf="antiguedadNoValido" class="text-danger">El campo no es válido</small>
                        </div>
                    </div>
                </div>

                <app-direcciones #appDirecciones [direccion]="direccion"></app-direcciones>
                <app-telefonos #appTelefonos [telefonos]="telefonos"></app-telefonos>

            </div>

            <!-- PERFIL TRANSACCIONAL -->
            <h4 class="text-primary">Perfil transaccional</h4>
            <div class="card-title">
                <b>Ingresos Mensuales</b>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Ingresos Mensuales</label>
                        <input type="number" class="form-control" placeholder="Ingresos Mensuales" formControlName="ingresosMensuales" [class.is-invalid]="ingresosMensualesNoValido">
                        <small *ngIf="ingresosMensualesNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Apoyo Familiar</label>
                        <input type="number" class="form-control" placeholder="Apoyo Familiar" formControlName="apoyoFamiliar" [class.is-invalid]="apoyoFamiliarNoValido">
                        <small *ngIf="apoyoFamiliarNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Otros Ingresos</label>
                        <input type="number" class="form-control" placeholder="Otros Ingresos" formControlName="otroIngreso" [class.is-invalid]="otroIngresoNoValido">
                        <small *ngIf="otroIngresoNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Total ingresos mensuales</label>
                        <input type="number" class="form-control" placeholder="Total Ingreso Mensual" formControlName="totalIngresoMensual" [class.is-invalid]="totalIngresoMensualNoValido" disabled>
                        <small *ngIf="totalIngresoMensualNoValido" class="text-danger">El total del ingreso mensual debe ser mayor a cero</small>
                    </div>
                </div>
            </div>

            <div class="card-title">
                <b> Gastos Mensuales</b>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Alimentos</label>
                        <input type="number" class="form-control" placeholder="Alimentos" formControlName="alimento" [class.is-invalid]="alimentoNoValido">
                        <small *ngIf="alimentoNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Servicios Básicos</label>
                        <input type="number" class="form-control" placeholder="Servicios Básicos" formControlName="servicioBasico" [class.is-invalid]="servicioBasicoNoValido">
                        <small *ngIf="servicioBasicoNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Renta o Pago</label>
                        <input type="number" class="form-control" placeholder="Renta o Pago" formControlName="rentaPago" [class.is-invalid]="rentaPagoNoValido">
                        <small *ngIf="rentaPagoNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Materia Prima</label>
                        <input type="number" class="form-control" placeholder="Materia Prima" formControlName="materiaPrima" [class.is-invalid]="materiaPrimaNoValido">
                        <small *ngIf="materiaPrimaNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Otros Préstamos</label>
                        <input type="number" class="form-control" placeholder="Otros Préstamos" formControlName="otroPrestamo" [class.is-invalid]="otroPrestamoNoValido">
                        <small *ngIf="otroPrestamoNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Otros Gastos</label>
                        <input type="number" class="form-control" placeholder="Otros Gastos" formControlName="otroGasto" [class.is-invalid]="otroGastoNoValido">
                        <small *ngIf="otroGastoNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Total gastos mensuales</label>
                        <input type="number" class="form-control" placeholder="Total Gasto Mensual" formControlName="totalGastoMensual" [class.is-invalid]="totalGastoMensualNoValido" disabled>
                        <small *ngIf="totalGastoMensualNoValido" class="text-danger">El total del gasto mensual debe ser mayor a cero</small>
                    </div>
                </div>
            </div>

            <!-- DATOS BANCARIOS -->
            <h4 class="text-primary">Datos bancarios</h4>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Beneficiario</label>
                        <input type="text" class="form-control" placeholder="Beneficiario" formControlName="beneficiario" [class.is-invalid]="beneficiarioNoValido">
                        <small *ngIf="beneficiarioNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Banco</label>
                        <input type="text" class="form-control" placeholder="Banco" formControlName="banco" [class.is-invalid]="bancoNoValido">
                        <small *ngIf="bancoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Número De Cuenta</label>
                        <input type="text" class="form-control" placeholder="Número De Cuenta" formControlName="numeroCuenta" [class.is-invalid]="numeroCuentaNoValido">
                        <small *ngIf="numeroCuentaNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Número De Tarjeta</label>
                        <input type="text" class="form-control" placeholder="Número De Tarjeta" formControlName="numeroTarjeta" [class.is-invalid]="numeroTarjetaNoValido">
                        <small *ngIf="numeroTarjetaNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Cuenta CLABE</label>
                        <input type="text" class="form-control" placeholder="Cuenta CLABE" formControlName="cuentaClabe" [class.is-invalid]="cuentaClabeNoValido">
                        <small *ngIf="cuentaClabeNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 m-t-20">
                    <button type="submit" class="btn btn-primary float-right" *ngIf="numSolicitud && !negocio.idNegocio">Guardar</button>
                    <button type="submit" class="btn btn-primary float-right" *ngIf="negocio.idNegocio">Actualizar</button>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- END card -->