<!-- START TABLE -->
<div class="tab-pane active">
    <div *ngIf="!interesadosPorRevisar || interesadosPorRevisar.length == 0">
        <p class="v-align-middle bold text-center col-12">No existen registros para mostrar</p>
    </div>
    <div class="table-responsive vertical-scroll">
        <table class="table table-hover table-condensed table-responsive" id="interesadosPorRevisar" aria-describedby="Solicitudes interesados por revisar">
            <thead>
                <tr *ngIf="interesadosPorRevisar && interesadosPorRevisar.length > 0">
                    <th id="nombre" class="v-align-middle bold text-left w-20-vw">Nombre</th>
                    <th id="apellidoPaterno" class="v-align-middle bold text-left w-20-vw">Apellido Paterno</th>
                    <th id="apellidoMaterno" class="v-align-middle bold text-left w-20-vw">Apellido Materno</th>
                    <th id="montoSolicitado" class="v-align-middle bold text-left w-20-vw">Monto Solicitado</th>
                    <th id="acciones" class="v-align-middle bold text-left w-20-vw"></th>
                </tr>
            </thead>
            <tbody>
                <tr class="cursor" *ngFor="let interesado of interesadosPorRevisar; let i = index" (click)="visualizarInformacion(interesado)" [class.selected]="solicitudInteresado && interesado.idInteresado === solicitudInteresado.idInteresado">
                    <td class="v-align-middle normal text-left">{{interesado.nombre}}</td>
                    <td class="v-align-middle normal text-left">{{interesado.apellidoPaterno}}</td>
                    <td class="v-align-middle normal text-left">{{interesado.apellidoMaterno}}</td>
                    <td class="v-align-middle normal text-left">{{interesado.cotizacion.montoCredito | currency}}</td>
                    <td class="v-align-middle normal text-left">
                        <button id="enviarValidacion" type="button" (click)="validarDatos(interesado, claveValidacionDatos)" data-tooltip="Crear solicitud" class="btn-sm btn-success" [ngClass]="solicitudInteresado && interesado.idInteresado === solicitudInteresado.idInteresado ? 'btn-secondary' : 'btn-primary'">
                            <i class="fa fa-check"></i>
                        </button>
                        <button type="button" (click)="validarDatos(interesado, claveClientePotencial)" data-tooltip="Guardar como cliente potencial" class="btn-sm btn-add" [ngClass]="solicitudInteresado && interesado.idInteresado === solicitudInteresado.idInteresado ? 'btn-secondary' : 'btn-primary'">
                            <i class="fa fa-pause"></i>
                        </button>
                        <button id="eliminarInteresado" type="button" (click)="eliminar(interesado)" data-tooltip="Eliminar registro" class="btn-danger btn-sm btn-delete">
                            <i class="fas fa-minus"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>