<nav class="navbar navbar-dark bg-primary nav-header fixed-top">
    <a class="navbar-brand" href="#">
        <img src="assets/img/logo-point.png" width="70" height="25" class="d-inline-block align-top" alt="">
    </a>
    <button class="btn btn-lg btn-default" (click)="cambiarSucursal()">{{nombreSucursal}}</button>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0 ">
        <li class="nav-item dropdown">
            <a class="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="profile dropdown">
                <div class="d-flex align-items-center">
                    <div class="ml-2 p-r-10 fs-14 d-lg-block d-none text-white">
                        <span class="mb-4 semi-bold">¡Hola {{nombrePersonaUsuario}}!</span>
                    </div>
                    <span class="text-white">
                    <i class="fas fa-user-circle" style="font-size: 30px;"></i>
                </span>
                </div>
            </a>
            <div class="dropdown-menu profile-dropdown dropdown-menu-right" role="menu" style="will-change: transform;">
                <div class="dropdown-header noti-title">
                    <span class="text-overflow m-0"> <b>{{nombrePersonaUsuario}}</b></span>
                </div>
                <a [routerLink]="['/cambiar-password']" class="dropdown-item">
                    <i class="fas fa-key"></i> &nbsp;
                    <span>Cambiar contraseña</span>
                </a>
                <div class="dropdown-divider"></div>
                <button (click)="logout()" class="dropdown-item">
                <i class="fas fa-sign-out-alt"></i> &nbsp;
                <span>Cerrar sesión</span>
            </button>
            </div>
        </li>
    </ul>
</nav>