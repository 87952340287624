<div class="row mt-2">
    <div class="col-md-12 card-title">
        <button type="button" (click)="agregarTelefono()" class="btn btn-primary btn-sm btn-add">
            <i class="fas fa-plus"></i>
        </button>
        <strong class="mr-2">Teléfonos</strong>
        <small *ngIf="arrayFormTelefonos.controls.length === 0" class="text-danger">
            Es necesario agregar por lo menos un teléfono
        </small>
    </div>
</div>

<div [formGroup]="formTelefonos">
    <div formArrayName="telefonos">
        <div *ngFor="let telForm of arrayFormTelefonos.controls; let i=index">
            <div class="row" [formGroup]="telForm">
                <div class="btn-del-margin">
                    <button type="button" (click)="eliminarTelefono(i)" class="btn btn-danger btn-sm btn-delete">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Teléfono</label>
                        <input type="tel" placeholder="Teléfono" class="form-control" formControlName="telefono" [class.is-invalid]="telForm.controls['telefono']?.invalid">
                        <small *ngIf="telForm.controls['telefono']?.invalid" class="text-danger">
                            El campo no es válido
                        </small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Tipo Teléfono</label>
                        <div class="form-group">
                            <div class="form-check-inline form-radio-buttons">
                                <input class="form-check-input" type="radio" [value]="claveTel" formControlName="tipoTelefono">
                                <label class="form-check-label">Teléfono</label> &nbsp;
                                <input class="form-check-input" type="radio" [value]="claveCel" formControlName="tipoTelefono">
                                <label class="form-check-label">Celular</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>