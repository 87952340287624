<div id="map" *ngIf="apiLoaded | async">
  <google-map [options]="options" width="100%">
    <map-marker
      #marker="mapMarker"
      *ngFor="let prop of markers; let i = index"
      [position]="prop.position"
      [options]="prop.options"
      (mapClick)="openInfoWindow(marker, prop)"
      width="50px"
      heigth="50px"
    >
    </map-marker>
    <map-info-window>
      <strong>{{clientName}}</strong>
    </map-info-window>
  </google-map>
</div>