<div class="timeline-container top-circle">
    <section class="timeline" *ngIf="tracking && tracking.length > 0">
        <div class="timeline-block" *ngFor="let track of tracking">
            <div class="timeline-point primary">
                <i class="fas fa-check-circle"></i>
            </div>
            <!-- timeline-point -->
            <div class="timeline-content">
                <div class="card social-card share full-width">
                    <div class="circle" data-toggle="tooltip" title="Label" data-container="body">
                    </div>
                    <div class="card-header clearfix">
                        <h5>{{track.persona.nombre}} {{track.persona.apellidoPaterno}}</h5>
                        <h6 class="d-flex align-items-center">{{track.codigoUsuario}}</h6>
                    </div>
                    <div class="card-description">
                        <p>{{track.estatus.descripcion}}</p>
                        <small class="fs-12 hint-text">{{track.fechaUltMod}}</small>
                    </div>
                </div>
            </div>
            <!-- timeline-content -->
        </div>
    </section>
</div>