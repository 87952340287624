import { Component, NgZone, OnInit } from '@angular/core';
import { Client } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { NegociosCobranza, RutaCobranzaModel } from 'src/app/models/collections/cobranza.model';
import { ResponseModel } from 'src/app/models/generic/responde.model';
import { AuthService } from 'src/app/services/auth.service';
import { LoadjsService } from 'src/app/services/loadjs.service';
import { CobranzaService } from 'src/app/services/cobranza.service';
import { WebsocketsService } from 'src/app/services/websockets.service';
import UtilsRest from 'src/utils/UtilsRest';
import { Constants } from '../../classes/constants.class';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ColocadorModel } from 'src/app/models/collections/cobrador.model';
import { MapMarkerProps } from 'src/app/models/maps/map-marker.model';
import { isNullUndefined } from '../../../utils/nonNullUndefined';

@Component({
  selector: 'app-cobranza',
  templateUrl: './cobranza.component.html',
  styleUrls: ['./cobranza.component.scss']
})
export class CobranzaComponent implements OnInit {

  private token: string = sessionStorage.getItem('token');
  private idSucursal: string = sessionStorage.getItem('idSucursal');

  // VARIABLES LOCALES
  ruta: RutaCobranzaModel;
  rutaComplete: RutaCobranzaModel;
  client: Client;
  collectorList: ColocadorModel[] = [];
  collectorSelect: ColocadorModel;

  // DATOS EXPORTAR OTROS COMPONENTES
  totalVisitados: number = 0;
  totalNoVisitados: number = 0;
  totalCreditos: number = 0;
  markers: MapMarkerProps[];
  size: google.maps.Size = {
    height: 50,
    width: 50,
    equals: null
  }

  constructor(
    private authService: AuthService, 
    private cobranzaService: CobranzaService,
    private usuarioService: UsuarioService, 
    private websocketsService: WebsocketsService,
    private loadjsService: LoadjsService,
    private router: Router, 
    private _ngZone: NgZone
  ) {}

  ngOnInit(): void {
    
    this.loadjsService.loadScript();
    this.client = new Client();
    this.client.webSocketFactory = () => {
      return new SockJS(this.websocketsService.getPathSockCobranzaJS());
    }

    this.client.activate();
    this.client.onConnect = () => {
      this.client.subscribe('/subs/ruta-cobranza', resp => {
        const respuesta = JSON.parse(resp.body) as ResponseModel;
        const rutaRespuesta: RutaCobranzaModel = respuesta.resultado;
        if (rutaRespuesta.idSucursal == this.idSucursal) {
          this.rutaComplete = rutaRespuesta;
          // Reload route by socket;
          this.showRoute();
          this.collectorSelect = emptyCollectorSelect
          this.reloadComponent();
        }
      });
    }
    // Fill route inthe first component load
    this.getCobradores();
    this.obtenerRuta();
  }

  // METODOS PRIVADOS
  reloadComponent() {
    this._ngZone.run(()=>{
      this.router.navigate([this.router.url])
    });
  }

  onChange( cobrador:ColocadorModel ) {
    this.showRoute(cobrador?.idUsuario?.toString())
  }

  showRoute( idUsuario?: string ) {
    // Route filtered
    if(isNullUndefined(idUsuario)) {
      this.ruta = this.rutaComplete
      this.generateMapMarkers(this.ruta.negociosCobranza);
    // Route filter by collector  
    } else {
      const routeFiltered = this.rutaComplete.negociosCobranza.filter( 
        (negocioCobranza) => {
          const visitasCobrador = negocioCobranza.cobranza.visitas.filter(
            (visita) => visita.cobrador.idUsuario == idUsuario
          )
          if(visitasCobrador.length > 0) {
            negocioCobranza.cobranza.visitas = visitasCobrador;
            return negocioCobranza
          }
        }
      );
      this.ruta = {
        fechaRuta: this.rutaComplete.fechaRuta,
        idCobranza: this.rutaComplete.idCobranza,
        idSucursal: this.rutaComplete.idSucursal,
        negociosCobranza: routeFiltered
      };
      this.generateMapMarkers(routeFiltered);
    }

    // Counters
    this.totalCreditos = this.ruta.negociosCobranza.length;
    this.totalVisitados = this.ruta.negociosCobranza
      .filter( nc => nc.cobranza.estatus.clave === Constants.CLAVE_NEGOCIO_VISITADO)
      .length;
    this.totalNoVisitados = this.ruta.negociosCobranza
      .filter( nc => nc.cobranza.estatus.clave === Constants.CLAVE_NEGOCIO_NO_VISITADO)
      .length;
  }

  generateMapMarkers(negociosCobranza: NegociosCobranza[]) {
    // Reduce like a flatMap
    this.markers = negociosCobranza.reduce(
      (accumulator1, negocioCobranza) => accumulator1.concat(
        // Reduce like a flatMap
        negocioCobranza.cobranza.visitas
        .filter((visita) => visita.coordenadas)
        .reduce(
          (accumulator2:MapMarkerProps[] , visita)=> accumulator2.concat(
            ({
              position: {
                lat: Number(visita.coordenadas.latitud),
                lng: Number(visita.coordenadas.longitud),
              },
              options: {
                icon: {
                  url: visita.referencia ? './assets/img/cancel.png' : './assets/img/check.png',
                  scaledSize: this.size,
                },
                draggable: true,
                opacity: 1
              },
              clientName: `${negocioCobranza.negocio.cliente.persona.nombre} ${negocioCobranza.negocio.cliente.persona.apellidoPaterno}`,
            })
          ), []
        )
      ), []
    )
  }
  
  // SERVICIOS DE TERCEROS
  obtenerRuta() {
    this.cobranzaService.obtenerRutaCobranza(this.token, this.idSucursal)
      .subscribe(resp => {
        this.rutaComplete = resp.resultado;
        this.showRoute();
      }, (err) => {
        const status = UtilsRest.validaApiError(err, false, this.authService)
        if(status.codigo == 401) {
          this.authService.guardarToken(status.accessToken, status.refreshToken)
          this.obtenerRuta();
        }
      });
  }

  private getCobradores() {
    this.usuarioService.obtenerCobradores(this.token, this.idSucursal.toString())
    .subscribe(resp => {
      this.collectorList = resp.resultados;
      this.collectorSelect = emptyCollectorSelect
      this.collectorList.unshift(this.collectorSelect);
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.getCobradores();
      }
    });
  }
}

const emptyCollectorSelect = {
  idUsuario: null,
  codigoUsuario: null,
  persona: {
    idPersona: null,
    nombre: 'Ruta completa',
    apellidoPaterno: '',
    apellidoMaterno: '',
  }
}
