import { PersonaModel } from '../person/persona.model';

export class CobradorModel {
    idUsuario:     number;
    codigoUsuario: string;
    persona:       PersonaModel;
}

export class ColocadorModel {
    idUsuario:     number;
    codigoUsuario: string;
    persona:       PersonaModel;
}