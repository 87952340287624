// MODULES 
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AppRoutingModule } from './app-routing.module';
import { GoogleMapsModule } from '@angular/google-maps';

// PAGES
import { ClientesPageComponent } from './pages/clientes/clientes.component';
import { CreditosPageComponent } from './pages/creditos-page/creditos-page.component';
import { HomePageComponent } from './pages/home/home.component';
import { LoginPageComponent } from './pages/login/login.component';
import { ReportesPageComponent } from './pages/reportes/reportes.component';
import { SolicitudesPageComponent } from './pages/solicitudes/solicitudes.component';
import { CambiaPasswordPageComponent } from './pages/cambia-password/cambia-password.component';
import { CobranzaComponent } from './pages/cobranza/cobranza.component';
import { HistoricoComponent } from './pages/historico/historico.component';
import { CapturarSolicitudComponent } from './pages/capturar-solicitud/capturar-solicitud.component';
import { DetalleSolicitudRenovacionComponent } from './pages/detalle-solicitud-renovacion/detalle-solicitud-renovacion.component';
import { AutorizarSolicitudPageComponent } from './pages/autorizar-solicitud-page/autorizar-solicitud-page.component';
import { DetalleSolicitudNuevaComponent } from './pages/detalle-solicitud-nueva/detalle-solicitud-nueva.component';
import { FirmaContratoPagareComponent } from './pages/firma-contrato-pagare/firma-contrato-pagare.component';
import { DatosTemporalesPageComponent } from './pages/datos-temporales-page/datos-temporales-page.component';
import { CapturarDocumentosComponent } from './pages/capturar-documentos/capturar-documentos.component';
import { CotizadorRenovacionComponent } from './pages/cotizador/cotizador-renovacion/cotizador-renovacion.component';
import { CotizadorClientesNuevosComponent } from './pages/cotizador/cotizador-clientes-nuevos/cotizador-clientes-nuevos.component';

// COMPONENTS
import { AppComponent } from './app.component';
import { InfoGeneralComponent } from 'src/app/components/cobranza/info-general/info-general.component';
import { RutaComponent } from 'src/app/components/cobranza/ruta/ruta.component';
import { ReferenciasComponent } from 'src/app/components/forms/referencias-form/referencias.component';
import { ReporteCobranzaComponent } from 'src/app/components/reportes/reporte-cobranza/reporte-cobranza.component';
import { ReporteEdoCuentaComponent } from 'src/app/components/reportes/reporte-edo-cuenta/reporte-edo-cuenta.component';
import { ReporteGeneralComponent } from 'src/app/components/reportes/reporte-general/reporte-general.component';
import { FooterComponent } from 'src/app/components/shared/footer/footer.component';
import { HeaderComponent } from 'src/app/components/shared/header/header.component';
import { CliNegCredComponent } from './components/autocomplete/cli-neg-cred/cli-neg-cred.component';
import { DetalleClienteComponent } from './components/clientes/detalle-cliente/detalle-cliente.component';
import { ModificarClienteComponent } from './components/clientes/modificar-cliente/modificar-cliente.component';
import { DetalleCreditoComponent } from './components/creditos/detalle-credito/detalle-credito.component';
import { AdeudosComponent } from './components/cuotas/adeudos/adeudos.component';
import { CuotaCobranzaComponent } from './components/cuotas/cuota-cobranza/cuota-cobranza.component';
import { CuotasComponent } from './components/cuotas/cuotas.component';
import { InteresesComponent } from './components/cuotas/intereses/intereses.component';
import { PagosComponent } from './components/cuotas/pagos/pagos.component';
import { ClientesFormComponent } from './components/forms/clientes-form/clientes-form.component';
import { DireccionesComponent } from './components/forms/datos-generales/direcciones/direcciones.component';
import { TelefonosComponent } from './components/forms/datos-generales/telefonos/telefonos.component';
import { ViviendasComponent } from './components/forms/datos-generales/viviendas/viviendas.component';
import { NegociosFormComponent } from './components/forms/negocios-form/negocios-form.component';
import { PersonaFormComponent } from './components/forms/persona-form/persona-form.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { EstatusSolicitudesComponent } from './components/solicitudes/estatus-solicitudes/estatus-solicitudes.component';
import { SolicitudCreadaComponent } from './components/solicitudes/estatus-solicitudes/solicitud-creada/solicitud-creada.component';
import { SolicitudDatosTemporalesComponent } from './components/solicitudes/estatus-solicitudes/solicitud-datos-temporales/solicitud-datos-temporales.component';
import { SolicitudValidacionDocumentosComponent } from './components/solicitudes/estatus-solicitudes/solicitud-validacion-documentos/solicitud-validacion-documentos.component';
import { SolicitudCapturaDatosComponent } from './components/solicitudes/estatus-solicitudes/solicitud-captura-datos/solicitud-captura-datos.component';
import { SolicitudPreautorizadaComponent } from './components/solicitudes/estatus-solicitudes/solicitud-preautorizada/solicitud-preautorizada.component';
import { SolicitudContratoPagareComponent } from './components/solicitudes/estatus-solicitudes/solicitud-contrato-pagare/solicitud-contrato-pagare.component';
import { SolicitudAutorizadaDispersadaComponent } from './components/solicitudes/estatus-solicitudes/solicitud-autorizada-dispersada/solicitud-autorizada-dispersada.component';
import { VisitasComponent } from './components/cobranza/visitas/visitas.component';
import { CotizadorClientesNuevosFormComponent } from './components/forms/cotizador-clientes-nuevos-form/cotizador-clientes-nuevos-form.component';
import { BuscarClienteComponent } from './components/clientes/buscar-cliente/buscar-cliente.component';
import { CotizadorRenovacionFormComponent } from './components/forms/cotizador-renovacion-form/cotizador-renovacion-form.component';
import { DatosTemporalesFormComponent } from './components/forms/datos-temporales-form/datos-temporales-form.component';
import { DatosGeneralesSolicitudComponent } from './components/solicitudes/datos-generales-solicitud/datos-generales-solicitud.component';
import { TrackingSolicitudComponent } from './components/solicitudes/tracking-solicitud/tracking-solicitud.component';
import { PreautorizarComponent } from './components/solicitudes/preautorizar/preautorizar.component';
import { AutorizarComponent } from './components/solicitudes/autorizar/autorizar.component';
import { ExpedienteFormComponent } from './components/forms/expediente-form/expediente-form.component';
import { ImageFormComponent } from './components/forms/image-form/image-form.component';
import { ActualizarEstatusSolicitudComponent } from './components/solicitudes/actualizar-estatus-solicitud/actualizar-estatus-solicitud.component';
import { ArchivosExpedienteComponent } from './components/digitalizacion/archivos-expediente/archivos-expediente.component';
import { DatosTemporalesComponent } from './components/solicitudes/datos-temporales/datos-temporales.component';
import { ContratoPagareComponent } from './components/digitalizacion/contrato-pagare/contrato-pagare.component';
import { PaginaWebComponent } from './pages/pagina-web/pagina-web.component';
import { EstatusInteresadosPaginaWebComponent } from './components/pagina-web/estatus-interesados-pagina-web/estatus-interesados-pagina-web.component';
import { InteresadosPorRevisarComponent } from './components/pagina-web/interesados-por-revisar/interesados-por-revisar.component';
import { InteresadosValidacionDatosComponent } from './components/pagina-web/interesados-validacion-datos/interesados-validacion-datos.component';
import { ClientesPotencialesComponent } from './components/pagina-web/clientes-potenciales/clientes-potenciales.component';
import { CreditosOtorgadosPaginaWebComponent } from './components/pagina-web/creditos-otorgados-pagina-web/creditos-otorgados-pagina-web.component';
import { DetalleSolicitudInteresadoComponent } from './components/pagina-web/detalle-solicitud-interesado/detalle-solicitud-interesado.component';
import { ContadoresSolicitudesPaginaWebComponent } from './components/pagina-web/contadores-solicitudes-pagina-web/contadores-solicitudes-pagina-web.component';
import { CollectorMapComponent } from './components/maps/collector-map/collector-map.component';
import { ComboDatepickerComponent } from './components/combo-datepicker/combo-datepicker.component';

// DIRECTIVES
import { DropFileDirective } from './directives/drop-file.directive';
import { DropImageDirective } from './directives/drop-image.directive';

// SERVICES
import { NgxImageCompressService } from 'ngx-image-compress';

// PIPES
import { DatoBancarioPipe } from './pipes/datos-bancarios.pipe';
import { DomseguroPipe } from './pipes/domseguro.pipe';
import { NofileimagePipe } from './pipes/nofileimage.pipe';
import { NoimagePipe } from './pipes/noimage.pipe';

@NgModule({
  declarations: [
    AppComponent,

    // PAGES
    LoginPageComponent,
    HomePageComponent,
    CambiaPasswordPageComponent,
    ClientesPageComponent,
    SolicitudesPageComponent,
    CreditosPageComponent,
    ReportesPageComponent,
    HistoricoComponent,
    CobranzaComponent,
    DetalleSolicitudRenovacionComponent,
    CapturarSolicitudComponent,
    DatosTemporalesPageComponent,
    AutorizarSolicitudPageComponent,
    DetalleSolicitudNuevaComponent,
    FirmaContratoPagareComponent,
    CapturarDocumentosComponent,
    CotizadorRenovacionComponent,
    CotizadorClientesNuevosComponent,

    // COMPONENTS
    HeaderComponent,
    FooterComponent,
    RutaComponent,
    ReporteCobranzaComponent,
    ReporteEdoCuentaComponent,
    ReporteGeneralComponent,
    InfoGeneralComponent,
    ReporteCobranzaComponent,
    SidebarComponent,
    ReferenciasComponent,
    DetalleClienteComponent,
    ModificarClienteComponent,
    PersonaFormComponent,
    NegociosFormComponent,
    DireccionesComponent,
    TelefonosComponent,
    ViviendasComponent,
    ClientesFormComponent,
    CliNegCredComponent,
    CuotaCobranzaComponent,
    DetalleCreditoComponent,
    CuotasComponent,
    AdeudosComponent,
    PagosComponent,
    InteresesComponent,
    EstatusSolicitudesComponent,
    SolicitudCreadaComponent,
    SolicitudDatosTemporalesComponent,
    SolicitudValidacionDocumentosComponent,
    SolicitudCapturaDatosComponent,
    SolicitudPreautorizadaComponent,
    SolicitudContratoPagareComponent,
    SolicitudAutorizadaDispersadaComponent,
    VisitasComponent,
    CotizadorClientesNuevosFormComponent,
    BuscarClienteComponent,
    CotizadorRenovacionFormComponent,
    DatosTemporalesFormComponent,
    DatosTemporalesComponent,
    DatosGeneralesSolicitudComponent,
    TrackingSolicitudComponent,
    PreautorizarComponent,
    AutorizarComponent,
    ExpedienteFormComponent,
    ImageFormComponent,
    ActualizarEstatusSolicitudComponent,
    ArchivosExpedienteComponent,
    ContratoPagareComponent,
    PaginaWebComponent,
    EstatusInteresadosPaginaWebComponent,
    InteresadosPorRevisarComponent,
    InteresadosValidacionDatosComponent,
    ClientesPotencialesComponent,
    CreditosOtorgadosPaginaWebComponent,
    DetalleSolicitudInteresadoComponent,
    ContadoresSolicitudesPaginaWebComponent,
    CollectorMapComponent,
    ComboDatepickerComponent,
    
    // DIRECTIVES
    DropImageDirective,
    DropFileDirective,

    // PIPES
    DomseguroPipe,
    NofileimagePipe,
    NoimagePipe,
    DatoBancarioPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AutocompleteLibModule,
    GoogleMapsModule,
  ],
  providers: [
    NgxImageCompressService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
