import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import Swal from 'sweetalert2';

@Directive({
  selector: '[appDropImage]'
})
export class DropImageDirective {
  @Output() mouseOver: EventEmitter<boolean> = new EventEmitter();
  @Input() archivos: File[] = [];

  constructor() { }

  @HostListener('dragover', ['$event']) 
  public onDragOver(event: any) {
    this.mouseOver.emit(true);
    this._noAbrir(event);
  }

  @HostListener('dragleave', ['$event']) 
  public onDragLeave(event: any) {
    this.mouseOver.emit(false);
  }

  @HostListener('drop', ['$event']) 
  public onDrop(event: any) {
    const transferencia = this._getTransfer(event);

    if(!transferencia) {
      return;
    }

    this._extraerArchivos(transferencia.files);
    this._noAbrir(event);
    this.mouseOver.emit(false);
  }

  private _getTransfer( event: any) {
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
  }

  private _extraerArchivos(archivosLista: FileList) {
    let file: File = null;
    for (const propiedad in Object.getOwnPropertyNames(archivosLista)) {
      file = archivosLista[propiedad];

      if (this.archivos != null && this.archivos.length >= 1) {
        Swal.fire({
          icon: 'error',
          title: 'Error al cargar archivo',
          text: 'Sólo se puede cargar un archivo, intenta eliminar el archivo cargado para subir uno nuevo'
        });
        return false;
      }

      if (!this._validarTipoArchivo(file.type)) {
        Swal.fire({
          icon: 'error',
          title: 'Error al cargar archivo',
          text: 'El archivo que intentas cargar no es correcto, asegurate que sea un PDF'
        });
        return false;
      }

      if (!this._validarSize(file.size)) {
        Swal.fire({
          icon: 'error',
          title: 'Tamaño de archivo muy grande',
          text: 'El archivo no puede ser mayor a 5 MB, intenta cargar un archivo de menor tamaño'
        });
        return false;
      }

      this.archivos.push(file);

      return true;
    }
  }

  private _validarSize(size:number) {
    return ((size / 1024) > 5120 ? false : true);
  }

  private _noAbrir(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  private _validarTipoArchivo(tipoArchivo: string): boolean {
    return( tipoArchivo === '' || tipoArchivo === undefined) ? false : tipoArchivo.startsWith('application/pdf');
  }
}
