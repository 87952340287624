import { Component, Input, OnInit, OnChanges, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { ArchivoDigitalizacion, VerArchivoModel } from 'src/app/models/files/archivo-solicitud.model';
import { AuthService } from 'src/app/services/auth.service';
import { DigitalizacionService } from 'src/app/services/digitalizacion.service';
import UtilsSwal from 'src/utils/UtilsSwal';
import Swal from 'sweetalert2';
import { Constants } from '../../../classes/constants.class';
import Utils from '../../../../utils/Utils';
import UtilsRest from 'src/utils/UtilsRest';
import { ArchivoModel } from 'src/app/models/files/archivo.model';

@Component({
  selector: 'app-image-form',
  templateUrl: './image-form.component.html',
  styleUrls: ['./image-form.component.scss'],
})
export class ImageFormComponent implements OnInit, OnChanges {

  private token: string = sessionStorage.getItem('token');

  // PARAMETROS ENTRADA
  @Input() tipo: string;
  @Input() numArchivo: number;
  @Input() verArchivos: VerArchivoModel[] = [];

  // EVENTOS DE SALIDA
  @Output() archivoEmit = new EventEmitter<ArchivoDigitalizacion>();

  // PARAMETROS LOCALES
  archivos: File[] = [];
  archivoSelected: ArchivoModel;
  verArchivo: VerArchivoModel;

  constructor(
    private authService: AuthService,
    private digitalizacionService: DigitalizacionService,
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {

    // Si se actualiza la lista de verArchivos
    if(changes.verArchivos.currentValue) {

      this.verArchivos = changes.verArchivos.currentValue;
      this.verArchivo = this.verArchivos
        .find((archivo) => archivo.numArchivo === this.numArchivo);
        
    } else {
      this.verArchivos = null;
      this.verArchivo = null;
    }
  }

  // METODOS HTML
  onSelectFile(event){
    this._extraerArchivos(event);
  }

  limpiar() {
    this.archivos = [];
    this._enviarArchivo(false);
  }

  verImagen(){
    if(this.tipo === Constants.TIPO_DIGITALIZACION_PDF) {
      this._getPdf();
    } else {
      this._getImagen();
    }
  }

  // SERVICIOS EXTERNOS
  private _getImagen() {

    UtilsSwal.buscandoInformacion();
    Swal.showLoading();

    this.digitalizacionService.obtenerArchivo(
      this.token, 
      this.verArchivo.idDinamico, 
      this.verArchivo.tipoArchivo, 
      this.verArchivo.numArchivo
    ).subscribe(resp => {

      this.archivoSelected = resp.resultado.archivos[0];

      const contentType = `image/${this.archivoSelected.extension}}`;
      const blob = Utils.b64toBlob(this.archivoSelected.base64, contentType);
      const blobUrl = URL.createObjectURL(blob);

      Swal.close();
      Swal.fire({
        imageUrl: blobUrl,
        imageWidth: '100%',
        imageAlt: this.archivoSelected.nombre
      });
    }, (err) => {

      Swal.close();

      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getImagen();
      }
    });
  }

  private _getPdf() {

    UtilsSwal.buscandoInformacion();
    Swal.showLoading();

    this.digitalizacionService.obtenerArchivo(
      this.token, 
      this.verArchivo.idDinamico, 
      this.verArchivo.tipoArchivo, 
      this.verArchivo.numArchivo
    ).subscribe(resp => {

      this.archivoSelected = resp.resultado.archivos[0];

      const contentType = 'application/pdf';
      const blob = Utils.b64toBlob(this.archivoSelected.base64, contentType);
      const blobUrl = URL.createObjectURL(blob);

      var link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('target', '_blank');
      link.click();
      Swal.close();

    }, (err) => {

      Swal.close();
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getImagen();
      }
    });
  }

  // METODOS PRIVADOS
  private _extraerArchivos(archivosLista: FileList) {
    let file: File = null;
    for (const propiedad in Object.getOwnPropertyNames(archivosLista)) {
      file = archivosLista[propiedad];

      if(this.tipo === Constants.EXTENSION_PDF && !this._validarArchivosPdf(file.type)) {
        Swal.fire({
          icon: 'error',
          title: 'Error al cargar archivo',
          text: 'El archivo que intentas cargar no es correcto, verifica que se un PDF'
        });
        return false;
      } else if(this.tipo !== Constants.EXTENSION_PDF && !this._validarImagenes(file.type)){
        Swal.fire({
          icon: 'error',
          title: 'Error al cargar archivo',
          text: 'El archivo que intentas cargar no es correcto, verifica que sea una imagen'
        });
        return false;
      }

      if(!this._validarTamanioArchivo(file.size)) {
        Swal.fire({
          icon: 'error',
          title: 'Tamaño de archivo muy grande',
          text: 'El archivo no puede ser mayor a 5 MB, intenta cargar un archivo de menor tamaño'
        });
        return false;
      }

      this.archivos.push(file);
      this._enviarArchivo(true);
      return true;
    }
  }

  private async _enviarArchivo(vacio: boolean) {

    const archivo: ArchivoDigitalizacion = vacio 
      ? {
        numArchivo: this.numArchivo,
        base64: await Utils.readFileBase64(this.archivos[0]),
        extension: this.archivos[0].name.split('.').pop()
      }
      : {
        numArchivo: this.numArchivo,
        base64: null,
        extension: null
      };

    // Se sube archivo a componente de expedientes
    this.archivoEmit.emit(archivo);
  }

  private _validarArchivosPdf(tipoArchivo: string): boolean {
    return( tipoArchivo === '' || tipoArchivo === undefined) 
      ? false 
      : tipoArchivo.startsWith('application/pdf');
  }

  private _validarImagenes(tipoArchivo: string): boolean {

    const validaExtensionImagenes = tipoArchivo.startsWith('image/png') 
      || tipoArchivo.startsWith('image/jpeg') 
      || tipoArchivo.startsWith('image/jpg');

    return( tipoArchivo === '' || tipoArchivo === undefined) 
      ? false 
      : validaExtensionImagenes;
  }

  private _validarTamanioArchivo(size:number) {
    return ((size / 1024) > 5120 ? false : true);
  }

}
