import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AdeudosModel, DetalleCuotaModel, InteresesModel } from 'src/app/models/credits/cuota.model';
import { PagoModel } from 'src/app/models/credits/pagos.model';

@Component({
  selector: 'app-cuotas',
  templateUrl: './cuotas.component.html',
  styleUrls: ['./cuotas.component.scss']
})
export class CuotasComponent implements OnDestroy, OnChanges {

  // PARAMETROS RECIBIDOS
  @Input() cuotas: DetalleCuotaModel[];

  // VARIABLES LOCALES
  cuotaSelect: DetalleCuotaModel;

  // PARAMETROS LOCALES
  adeudos: AdeudosModel;
  pagoModel: PagoModel;
  intereses: InteresesModel[];

  constructor() {}

  ngOnDestroy(): void {
    this.cuotas = []
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes.cuotas.currentValue) {

      this.cuotas = null;
      this.cuotaSelect = null;
    
    } else {

      this.cuotas = changes.cuotas.currentValue;
      this.cuotaSelect = this.cuotas[0];
      this.actualizarCuotaSeleccionada();
    }
  }

  // METODOS HTML
  setCuotaSelect(cuota: DetalleCuotaModel) {
    this.cuotaSelect = cuota;
    this.actualizarCuotaSeleccionada();
  }

  // METODOS PRIVADOS
  actualizarCuotaSeleccionada() {
    this.adeudos = this.cuotaSelect.adeudos
    this.intereses = this.cuotaSelect.intereses
    this.pagoModel = {
      pagos: this.cuotaSelect.pagos,
      idNegocioCobranza: null,
      idVisitaCobranza: null,
      idCuotaCredito: null,
      estatusVisita: null,
    } 
  }
}
