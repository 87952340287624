<!-- START ADEUDOS SECTION -->
<div class="card social-card share full-width m-b-10 no-border" data-social="item" *ngIf="adeudos">
    <div class="card-header clearfix">
        <h5 class="text-primary">Adeudos</h5>
    </div>
    <div class="grid-random">
        <div class="card-body">
            <p>
                <span class="title-labels">No. Adeudos: </span> {{adeudos.numAdeudos}}
            </p>
            <p>
                <span class="title-labels">Adeudo Capital: </span> {{adeudos.adeudoCapital | currency }}
            </p>
            <p>
                <span class="title-labels">Adeudo Intereses: </span> {{adeudos.adeudoIntereses | currency }}
            </p>
            <p>
                <span class="title-labels">Adeudo Capital Intereses: </span> {{adeudos.adeudoCapitalIntereses | currency }}
            </p>
        </div>
    </div>
</div>