<app-sidebar></app-sidebar>
<div class="horizontal-menu horizontal-app-menu dashboard">
    <app-header></app-header>
    <div class="page-container pl-5">
        <div class="page-content-wrapper">
            <div class="content sm-gutter">
                <div class="container sm-padding-10 p-t-20 p-l-0 p-r-0">
                    <div class="card card-default">
                        <div class=" card-body">
                            <button type="button" (click)="anteriorPaso()" class="btn btn-primary float-left btn-continue m-l-10" [disabled]="paso <= 1">
                                <i class="fas fa-arrow-left"></i>
                            </button>
                            <button type="button" (click)="siguientePaso()" class="btn btn-primary float-right btn-continue" [disabled]="paso >= 3">
                                <i class="fas fa-arrow-right"></i>
                            </button>

                            <app-persona-form *ngIf="paso == 1" [persona]="persona" [catEstadoCivil]="catEstadoCivil" [catTipoVivienda]="catTipoVivienda"></app-persona-form>
                            <app-clientes-form *ngIf="paso == 2" [cliente]="cliente" [catMediosConocimiento]="catMediosConocimiento" [catNivelesEscolares]="catNivelesEscolares" [catOcupaciones]="catOcupaciones" [catFuentesIngresos]="catFuentesIngresos"></app-clientes-form>
                            <app-negocios-form *ngIf="paso == 3" [negocio]="negocio"></app-negocios-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>