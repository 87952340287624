<app-sidebar></app-sidebar>
<div class="horizontal-menu horizontal-app-menu dashboard">
    <app-header></app-header>
    <div class="page-container pl-5">
        <div class="page-content-wrapper ">
            <div class="content sm-gutter">
                <div class="container sm-padding-10 p-t-20 p-l-0 p-r-0">
                    <div class="row">
                        <div class="col-lg-12 flex-column p-b-10">
                            <app-estatus-interesados-pagina-web [interesadosPorRevisar]="interesadosPorRevisar" [interesadosValidacionDatos]="interesadosValidacionDatos" [clientesPotenciales]="clientesPotenciales" [creditosOtorgados]="creditosOtorgados">
                            </app-estatus-interesados-pagina-web>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>