import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActualizarEstatusInteresadoModel } from '../models/credits-application/point.model';

@Injectable({
  providedIn: 'root'
})
export class PointService {

  // TODO: Obtener tokens directamente desde la clase service

  constructor(private http: HttpClient) { }

  // GET - INTERESADOS
  obtenerInteresados(accessToken: string): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/point/v1/interesados`, 
      {headers: headers}
    );
  }

  // PUT - ESTATUS INTERESADO
  actualizarEstatusInteresado(
    accessToken: string, 
    actualizarEstatusInteresado: ActualizarEstatusInteresadoModel
  ): Observable<any> {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.put(
      `${environment.url}/point/v1/interesado/estatus`, 
      JSON.stringify(actualizarEstatusInteresado), 
      {headers: headers}
    );
  }

  // DELETE - ELIMINAR INTERESADO
  eliminarInteresado(
    accessToken: string, 
    idCotizacion: number,
    idInteresado: number,
  ): Observable<any> {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.delete(
      `${environment.url}/point/v1/interesado?idInteresado=${idInteresado}&idCotizacion=${idCotizacion}`, 
      {headers: headers}
    );
  }
}
