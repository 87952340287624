<!-- Se muestra sección para seleccionar el tipo de archivo -->
<div class="card card-default m-t-20">
    <div class="card-body">

        <!-- Select con los tipos de archivos -->
        <div class="row p-r-10">
            <div class="full-width">
                <div class="form-group form-select">
                    <label>Generar contrato y pagaré del cliente</label>
                    <div class="row">
                        <div class="d-flex">
                            <button type="button" class="btn btn-primary float-right" (click)="generarContrato()">
                                Generar contrato
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <app-image-form class="full-width" [tipo]="tipo" [numArchivo]="numArchivo" [verArchivos]="verArchivos" (archivoEmit)="addFile($event)"></app-image-form>
        </div>
        <div class="m-t-20" *ngIf="archivos.length > 0">
            <button type="button" class="btn btn-primary boton float-left" (click)="guardarArchivos()">
                Subir archivo(s)
            </button>
        </div>
        <div class="m-t-20" *ngIf="verArchivos">
            <button type="button" class="btn btn-danger boton float-left" (click)="borrarArchivos()">
                Eliminar archivo(s)
            </button>
        </div>
    </div>
</div>

<div class="row" *ngIf="verArchivos">
    <app-actualizar-estatus-solicitud class="full-width" [numSolicitud]="numSolicitud" [claveEstatusSolicitud]="claveFirmaContrato" [titulo]="tituloActualizarEstatus"></app-actualizar-estatus-solicitud>
</div>