<div class="card card-default">
    <div class=" card-body">
        <h4 class="text-primary">Datos del cliente</h4>
        <form autocomplete="off" [formGroup]="formCliente" (ngSubmit)="guardarInformacion()">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Dependientes Económicos</label>
                        <input type="number" class="form-control" placeholder="Dependientes Económicos" formControlName="depEconomicos" [class.is-invalid]="depEconomicosNoValido">
                        <small *ngIf="depEconomicosNoValido" class="text-danger">El campo no es válido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Financiera anterior</label>
                        <input type="text" class="form-control" placeholder="Financiera Anterior" formControlName="otraFinanciera">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group form-select">
                        <label>Medio Conocimiento</label>
                        <select class="selectpicker" formControlName="medioConocimiento" [class.is-invalid]="medioConocimientoNoValido">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let medio of catMediosConocimiento" [ngValue]="medio.clave">{{medio.descripcion}}</option>
                        </select>
                        <small *ngIf="medioConocimientoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group form-select">
                        <label>Nivel Escolar</label>
                        <select class="selectpicker" formControlName="nivelEscolar" [class.is-invalid]="nivelEscolarNoValido">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let nivelEscolar of catNivelesEscolares" [ngValue]="nivelEscolar.clave">{{nivelEscolar.descripcion}}</option>
                        </select>
                        <small *ngIf="nivelEscolarNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group form-select">
                        <label>Ocupación</label>
                        <select class="selectpicker" formControlName="ocupacion" [class.is-invalid]="ocupacionNoValido">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let ocupacion of catOcupaciones" [ngValue]="ocupacion.clave">{{ocupacion.descripcion}}</option>
                        </select>
                        <small *ngIf="ocupacionNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group form-select">
                        <label>Fuente de Ingresos</label>
                        <select class="selectpicker" (change)="cambiarFuenteIngresos()" formControlName="fuenteIngresos" [class.is-invalid]="fuenteIngresosNoValido">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let fuenteIngresos of catFuentesIngresos" [ngValue]="fuenteIngresos.clave">{{fuenteIngresos.descripcion}}</option>
                        </select>
                        <small *ngIf="fuenteIngresosNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="row mt-2" *ngIf="showFuenteIngresos">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Indique la cantidad de fuente de ingresos</label>
                        <input type="text" class="form-control" placeholder="Cantidad fuente ingresos" formControlName="cantidadFuenteIngresos">
                        <small *ngIf="cantidadFuenteIngresosNoValido" class="text-danger">Campo no válido, solo numeros enteros</small>
                    </div>
                </div>
            </div>
            <br>

            <div class="row">
                <div class="col-lg-12 m-t-20">
                    <button type="submit" class="btn btn-primary float-right" *ngIf="numSolicitud && !cliente.idCliente">Guardar</button>
                    <button type="submit" class="btn btn-primary float-right" *ngIf="cliente.idCliente">Actualizar</button>
                </div>
            </div>
        </form>
    </div>
</div>