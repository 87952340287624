import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LoadjsService } from 'src/app/services/loadjs.service';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.scss']
})
export class ReportesPageComponent implements OnInit {

  // VALIDACION ROL
  permisoUsuario: boolean;

  constructor(
    private authService: AuthService,
    private loadjsService: LoadjsService
  ) {}

  ngOnInit(): void {
    this.permisoUsuario = this.authService.permisosUsuario();
    this.loadjsService.loadScript();
  }
}
