import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/classes/constants.class';
import { SolicitudResumidasModel } from 'src/app/models/credits-application/solicitud.model';

@Component({
  selector: 'app-solicitud-creada',
  templateUrl: './solicitud-creada.component.html',
  styleUrls: ['./solicitud-creada.component.scss']
})
export class SolicitudCreadaComponent implements OnChanges {

  // PARAMETROS RECIBIDOS
  @Input() solicitudesCreadas: SolicitudResumidasModel[];

  constructor(
    private router: Router,
  ) { }

  ngOnChanges(): void {}

  // METODOS HTML
  visualizarSolicitud(solicitud: SolicitudResumidasModel) {

    const route = solicitud.tipoSolicitud.clave === Constants.CLAVE_TIPO_SOLICITUD_RENOVACION 
      ? '/detalle-solicitud-renovacion'  
      : '/datos-temporales';
  
    this.router.navigate([route], {
      queryParams: {
        numSolicitud: solicitud.numSolicitud
      }
    });
  }

}
