import { Component, Input, OnChanges } from '@angular/core';
import { SolicitudResumidasModel } from 'src/app/models/credits-application/solicitud.model';

@Component({
  selector: 'app-detalle-solicitud-interesado',
  templateUrl: './detalle-solicitud-interesado.component.html',
  styleUrls: ['./detalle-solicitud-interesado.component.scss']
})
export class DetalleSolicitudInteresadoComponent implements OnChanges {

  // PARAMETROS RECIBIDOS
  @Input() solicitudInteresado: SolicitudResumidasModel;

  constructor() { }

  ngOnChanges(): void {}

}
