<app-sidebar></app-sidebar>
<div class="horizontal-menu horizontal-app-menu dashboard">
    <app-header></app-header>
    <div class="page-container pl-5" *ngIf="permisoUsuario">
        <div class="page-content-wrapper ">
            <div class="content sm-gutter">
                <div class="container sm-padding-10 p-t-20 p-l-0 p-r-0">
                    <!-- START ROW -->
                    <div class="row">
                        <div class="col-lg-6 d-flex">
                            <!-- FORM COTIZADOR CLIENTES NUEVOS -->
                            <app-cotizador-clientes-nuevos-form [claveTipoSolicitud]="claveTipoSolicitud" (cotizacionEvent)="setCotizacion($event)"></app-cotizador-clientes-nuevos-form>
                        </div>
                        <div class="col-lg-6">
                            <!-- START ITEM -->
                            <div class="card social-card share full-width m-b-10 no-border" data-social="item" *ngIf="cotizacionForm">
                                <div class="card-body">
                                    <div class="card-header clearfix">
                                        <h4 class="text-primary">Cotizaciones</h4>
                                    </div>
                                    <!-- START TABLE -->
                                    <div *ngFor="let esquemaPago of cotizacionForm.cotizacion.esquemasPago">
                                        <div class="card-header clearfix">
                                            <h5>{{ esquemaPago.descripcion }}</h5>
                                        </div>
                                        <div class="table-responsive vertical-scroll">
                                            <div class="card card-borderless table-condensed">
                                                <table class="table table-hover" id="condensedTable" aria-describedby="Cotizaciones créditos nuevos">
                                                    <thead>
                                                        <tr>
                                                            <th class="v-align-middle bold text-center">Num. Pagos</th>
                                                            <th class="v-align-middle bold text-center">Cuota recurrente</th>
                                                            <th class="v-align-middle bold text-center">Tasa Intereses</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let cuota of esquemaPago.cuotas; let i = index" (click)="setCuotaSelect(cuota, esquemaPago.clave)" [class.selected]="cuotaSelect && cuota.numPagos === cuotaSelect.numPagos">
                                                            <td class="v-align-middle normal text-center">{{ cuota.numPagos }}</td>
                                                            <td class="v-align-middle normal text-center">{{ cuota.cuotaRecurrente | currency }}</td>
                                                            <td class="v-align-middle normal text-center">{{ (cuota.tasaInteres / 100) | percent:'1.2' }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-t-20">
                                        <button type="button" class="btn btn-primary float-right" (click)="solicitar()" *ngIf="cuotaSelect">Solicitar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>