<div class="m-t-20">

    <!-- Se valida que no existan archivos guardados -->
    <div *ngIf="!verArchivo">

        <!-- Dropzone images -->
        <div *ngIf="archivos.length === 0" appDropImage [archivos]="archivos" (mouseOver)="elementOver = $event" id="fileDrop" [ngClass]="{'file-over': elementOver}" class="full-width dropzone dropzone-empty mb-3">
            <!-- <div class="row">
                <h6>Arrastra tu archivo aquí</h6>
            </div> -->
            <div class="row">
                <label for="uploader-input" class="btn btn-primary">
                    Buscar en archivos
                </label>
            </div>
            <input type="file" (change)="onSelectFile($event.target.files)" id="uploader-input" multiple="multiple" style="display:none" />
        </div>

        <!-- Archivos cargados -->
        <div *ngIf="archivos.length > 0">
            <div class="full-width dropzone dropzone-upload" *ngFor="let archivo of archivos">
                <span *ngIf="tipo !== 'pdf'"> <i class="fas fa-image image-icon"></i> </span>
                <span *ngIf="tipo === 'pdf'"> <i class="fas fa-file-alt image-icon"></i> </span>

                <div class="file-details">
                    <span class="etiqueta"> <b>Archivo cargado</b> </span>
                    <p class="m-b-10"> {{archivo.name}} </p>
                </div>

                <div class="btn-end">
                    <button type="button" class="btn btn-secondary btn-trash" (click)="limpiar()">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Se muestran imágenes guardadas -->
    <div *ngIf="verArchivo">
        <h6>No. Archivo: {{numArchivo}} </h6>
        <div class="full-width file-zone dropzone-upload">
            <div class="file-details">
                <button type="button" class="btn btn-secondary float-right" (click)="verImagen()">
                    Ver archivo
                </button>
            </div>
        </div>
    </div>
</div>