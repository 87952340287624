import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SolicitudResumidasModel } from 'src/app/models/credits-application/solicitud.model';

@Component({
  selector: 'app-tracking-solicitud',
  templateUrl: './tracking-solicitud.component.html',
  styleUrls: ['./tracking-solicitud.component.scss']
})
export class TrackingSolicitudComponent implements OnChanges {

  // PARAMETROS RECIBIDOS
  @Input() tracking: SolicitudResumidasModel[];

  constructor() {}

  ngOnChanges() {}

}
