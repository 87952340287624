import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noimage'
})
export class NoimagePipe implements PipeTransform {

  transform(image: any): string {
    if (!image || !image.extension || !image.base64) {
      return 'assets/img/noimage.png'
    }
    return `data:image/${image.extension.split('.').pop()};base64,${image.base64}`
  }

}
