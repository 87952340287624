import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  constructor(private http: HttpClient) { }

  generarReporteGral(accessToken: string, fechaInicio: string, fechaFin: string, codigoUsuario: string, claveEstatus: string, idSucursal: number, idColocador: number): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`
    });
    let uri = `/reportes/v1/general?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&codigoUsuario=${codigoUsuario}&idSucursal=${idSucursal}`;

    if (claveEstatus != null) {
      uri += `&claveEstatus=${claveEstatus}`;
    }

    if (idColocador != null) {
      uri += `&idColocador=${idColocador}`;
    }

    return this.http.get<Blob>(
      `${environment.url}${uri}`, 
      {headers: headers,  observe: 'response', responseType: 'blob' as 'json'}
    );
  }

  generarReporteCobranza(accessToken: string, fecha: string, idSucursal: string): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get<Blob>(
      `${environment.url}/reportes/v1/cobranza?fecha=${fecha}&idSucursal=${idSucursal}`, 
      {headers: headers,  observe: 'response', responseType: 'blob' as 'json'}
    );
  }

  generarReporteEdoCta(accessToken: string, numContrato: string, idSucursal: string): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get<Blob>(
      `${environment.url}/reportes/v1/estado-cuenta?numContrato=${numContrato}&idSucursal=${idSucursal}`, 
      {headers: headers,  observe: 'response', responseType: 'blob' as 'json'}
    );
  }

  generarContratoPagare(accessToken: string, numContrato: string): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get<Blob>(
      `${environment.url}/reportes/v1/contrato-pagare?numContrato=${numContrato}`, 
      {headers: headers,  observe: 'response', responseType: 'blob' as 'json'}
    );
  }

  generarDatosBancarios(accessToken: string, numContrato: string, numSolicitud: string): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get<Blob>(
      `${environment.url}/reportes/v1/datos-bancarios?numContrato=${numContrato}&numSolicitud=${numSolicitud}`, 
      {headers: headers,  observe: 'response', responseType: 'blob' as 'json'}
    );
  }
}
