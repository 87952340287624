import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CatResponse } from 'src/app/models/catalogues/cat-response.model';
import { PersonaInfoCompleta, Vivienda } from 'src/app/models/person/persona.model';
import Utils from 'src/utils/Utils';
import UtilsFormats from '../../../../utils/UtilsFormats';
import UtilsSwal from '../../../../utils/UtilsSwal';
import { ClienteService } from '../../../services/cliente.service';
import UtilsPersona from './UtilsPersona';
import { Telefono } from 'src/app/models/person/telefono.model';
import { direccionEmpty } from '../datos-generales/direcciones/direcciones.component';
import { ViviendaDireccionForms } from '../datos-generales/viviendas/viviendas.component';
import { Router } from '@angular/router';
import { CreditoService } from '../../../services/credito.service';
import { PersonaCredito, PersonaRequest } from '../../../models/person/persona-request.model';
import { PersonaService } from '../../../services/persona.service';

@Component({
  selector: 'app-persona-form',
  templateUrl: './persona-form.component.html',
  styleUrls: ['./persona-form.component.scss'],
})
export class PersonaFormComponent implements OnInit, OnChanges {

  private token: string = sessionStorage.getItem('token');
  private idUsuario: string = sessionStorage.getItem('idUsuario');

  // PARAMETROS RECIBIDOS
  @Input() persona: PersonaInfoCompleta;
  @Input() numSolicitud: string | null;
  @Input() catEstadoCivil: CatResponse[];
  @Input() catTipoVivienda: CatResponse[];

  // EVENTOS DE SALIDA
  @Output() personaEmit = new EventEmitter<boolean>();

  // ELEMENTOS DEL HTML
  @ViewChild('appViviendas') appViviendas: any;
  @ViewChild('appTelefonos') appTelefonos: any;

  // PARAMETROS LOCAL
  vivienda: Vivienda;
  telefonos: Telefono[];
  fechaNacimiento: string;

  // FORMS
  formPersona: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private clienteService: ClienteService,
    private creditoService: CreditoService,
    private personaService: PersonaService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.persona) {
      this._crearFormularioPersona();
      this.vivienda = this.persona.viviendas[0];
      this.telefonos = this.persona.telefonos;
    }
  }

  ngOnInit(): void {}
  
  guardarInformacion() {

    // Se ya existe una persona, se actualiza la información
    if(this.persona.idPersona) {

      if(this.numSolicitud) {
        // Si la petición viene de la solicitud, únicamente se actualiza la persona
        this.actualizarPersona();
      } else {
        // Si la petición viene del modulo de clientes, se actualiza todo el cliente
        // También se guarda esta información en bitacora
        this.actualizarPersonaCliente();
      }

    } else {
      // Se crea un registro nuevo (desde la solicitud de crédito)
      this.guardarPersona();
    }
  }

  // LISTENERS
  _crearListenerForCurpByApellidoPaterno() {
    this.apellidoPaterno.valueChanges.subscribe((apellido:string) => {
      this._generarInicioCurp(apellido, this.nombre.value, this.apellidoMaterno.value);
    });
  }

  _crearListenerForCurpByNombre() {
    this.nombre.valueChanges.subscribe((nombre:string) => {
      this._generarInicioCurp(this.apellidoPaterno.value, nombre, this.apellidoMaterno.value);
    });
  }

  _crearListenerForCurpByApellidoMaterno() {
    this.apellidoMaterno.valueChanges.subscribe((apellidoMaterno:string) => {
      this._generarInicioCurp(this.apellidoPaterno.value, this.nombre.value, apellidoMaterno);
    });
  }

  _crearListenerForFechaNacimiento() {
    this.formPersona.get('fechaNacimiento').valueChanges
      .subscribe(() => this._generarInicioCurp(this.apellidoPaterno.value, this.nombre.value,this. apellidoMaterno.value));
  }

  // METODOS PRIVADOS
  private _esVocal(letra: string): boolean {
    const vocales = ['a', 'e', 'i', 'o', 'u'];

    if ( vocales.indexOf(letra.toLowerCase())) {
      return true;
    }

    return false;
  }

  private _generarInicioCurp(apellidoPaterno, nombre, apellidoMaterno) {
    
    if (apellidoPaterno?.length > 1 && 
      nombre?.length > 0 && 
      apellidoMaterno?.length > 0
    ) {

      let curp: string = apellidoPaterno.substring(0,1);
      let apellidoPaternoSinPrimerLetra = apellidoPaterno.slice(1);
      
      for (let value of apellidoPaternoSinPrimerLetra) {
        if (this._esVocal(value)) {
          curp += value;
          break;
        }
      }

      curp += apellidoMaterno.substring(0,1);
      curp += nombre.substring(0,1);

      if(this.formPersona.get('fechaNacimiento').valid) {
        this.fechaNacimiento = UtilsFormats.dateToString(this.formPersona.get('fechaNacimiento').value);
        curp += `${this.fechaNacimiento.substring(2,4)}${this.fechaNacimiento.substring(5,7)}${this.fechaNacimiento.substring(8, 10)}`;
      }
      
      this.curp.setValue(curp.toUpperCase());
    } else {
      this.curp.setValue('');
    }
  }

  // METODOS FORMS
  _crearFormularioPersona() {
    this.formPersona = this.fb.group({
      idPersona: [this.persona.idPersona],
      nombre: [this.persona.nombre, Validators.required],
      apellidoPaterno: [this.persona.apellidoPaterno, Validators.required],
      apellidoMaterno: [this.persona.apellidoMaterno],
      lugarNacimiento: [this.persona.nacimiento.lugar, Validators.required],
      paisNacimiento: [this.persona.nacimiento.pais, Validators.required],
      fechaNacimiento: [UtilsFormats.stringToDate(this.persona.nacimiento.fecha), Validators.required],
      nacionalidad: [this.persona.nacionalidad, Validators.required],
      rfc: [this.persona.rfc],
      curp: [this.persona.curp, [Validators.required, Validators.minLength(18), Validators.maxLength(18)]],
      genero: [this.persona.sexo],
      estadoCivil: [this.persona.estadoCivil.clave, Validators.required],
      numIdentificacion: [this.persona.numIdentificacion, [Validators.required]],
      correo: [this.persona.email, [Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
    });

    this._crearListenerForCurpByApellidoPaterno();
    this._crearListenerForCurpByApellidoMaterno();
    this._crearListenerForCurpByNombre();
    this._crearListenerForFechaNacimiento();
  }

  // GUARDAR INFORMACIÓN
  actualizarPersona() {
    
    const persona = this.mapearPersona();
    if(persona) {

      UtilsSwal.enviarInformacion(
        "¿Estás seguro de actualizar los datos de la persona?",
        "Actualizando información",
        "Actualizar",
        this.personaService.actualizarPersona(this.token, persona),
        true
      ).then(() => this.personaEmit.emit(true));
    }
  }

  actualizarPersonaCliente() {

    const persona = this.mapearPersona();
    if(persona) {

      const actualizarPersona = {
        persona: persona,
        usuarioUltMod: this.idUsuario
      }

      UtilsSwal.enviarInformacion(
        "¿Estás seguro de actualizar los datos de la persona?",
        "Actualizando información",
        "Actualizar",
        this.clienteService.actualizarInfoPersonas(this.token, actualizarPersona),
        true
      ).then(() => this.personaEmit.emit(true));
    }
  }

  guardarPersona() {

    const persona = this.mapearPersona();
    if(persona) {
      const guardarPersona: PersonaRequest = {
        numSolicitud: this.numSolicitud,
        persona: persona,
        usuarioUltMod: this.idUsuario
      }

      UtilsSwal.enviarInformacion(
        "¿Estás seguro de guardar los datos de la persona?",
        "Guardando información",
        "Guardar",
        this.creditoService.guardarPersona(this.token, guardarPersona),
        false
      ).then(() => this.personaEmit.emit(true));
    }
  }

  // METODOS PRIVADOS
  mapearPersona(): PersonaCredito | void {

    if (this.formPersona.invalid) {
      return Utils.validateForms(this.formPersona)
    } else {

      // Se valida que la fecha del CURP corresponda con la fecha de nacimiento
      this.fechaNacimiento = UtilsFormats.dateToString(this.formPersona.get('fechaNacimiento').value);
      const fechaSeleccionada = `${this.fechaNacimiento.substring(2,4)}${this.fechaNacimiento.substring(5,7)}${this.fechaNacimiento.substring(8, 10)}`;
      let curp = this.formPersona.get('curp');

      if(curp.value.substring(4,10) !== fechaSeleccionada) {
        return curp.setErrors({'La fecha no corresponde con la fecha de nacimiento': true});
      }
    }

    // Se manda a llamar el método validarFormHandler del componente direcciones
    // Para obtener el form de direcciones
    const viviendaDireccionForms:ViviendaDireccionForms = this.appViviendas.validarFormHandler();
    if (viviendaDireccionForms.vivienda.invalid) {
      return Utils.validateForms(viviendaDireccionForms.vivienda)
    }
    if (viviendaDireccionForms.direccion.invalid) {
      return Utils.validateForms(viviendaDireccionForms.direccion)
    }

    // Se manda a llamar el método validarFormHandler del componente teléfonos
    // Para obtener el form de teléfonos
    const formTelefonos = this.appTelefonos.validarFormHandler();
    if (formTelefonos.invalid) {
      return Utils.validateForms(formTelefonos)
    }

    return UtilsPersona.mapFormtoDto(
      this.formPersona, 
      viviendaDireccionForms.vivienda, 
      viviendaDireccionForms.direccion, 
      formTelefonos, 
      this.idUsuario, 
      this.persona.idPersona
    );
  } 

  // VALIDACIONES (DATOS NO VALIDOS)
  get nombreNoValido() {
    return this.formPersona.get('nombre').invalid && this.formPersona.get('nombre').touched
  }

  get apellidoPaternoNoValido() {
    return this.formPersona.get('apellidoPaterno').invalid && this.formPersona.get('apellidoPaterno').touched
  }

  get lugarNacimientoNoValido() {
    return this.formPersona.get('lugarNacimiento').invalid && this.formPersona.get('lugarNacimiento').touched
  }

  get paisNacimientoNoValido() {
    return this.formPersona.get('paisNacimiento').invalid && this.formPersona.get('paisNacimiento').touched
  }

  get nacionalidadNoValido() {
    return this.formPersona.get('nacionalidad').invalid && this.formPersona.get('nacionalidad').touched
  }

  get curpNoValido() {
    return this.formPersona.get('curp').invalid && this.formPersona.get('curp').touched
  }

  get estadoCivilNoValido() {
    return this.formPersona.get('estadoCivil').invalid && this.formPersona.get('estadoCivil').touched
  }

  get numIdentificacionNoValido() {
    return this.formPersona.get('numIdentificacion').invalid && this.formPersona.get('numIdentificacion').touched
  }

  get correoNoValido() {
    return this.formPersona.get('correo').invalid && this.formPersona.get('correo').touched;
  }

  get fechaNacimientoNoValido() {
    return this.formPersona.get('fechaNacimiento').invalid && this.formPersona.get('fechaNacimiento').touched;
  }

  // DATOS FORM
  get nombre() {
    return this.formPersona.get('nombre');
  }

  get apellidoPaterno() {
    return this.formPersona.get('apellidoPaterno');
  }

  get apellidoMaterno() {
    return this.formPersona.get('apellidoMaterno');
  }

  get curp() {
    return this.formPersona.get('curp');
  }
}

export const personaEmpty:PersonaInfoCompleta = {
  idPersona: null,
  nombre: '',
  apellidoPaterno: '',
  apellidoMaterno: '',
  sexo: 'M',
  email: '',
  curp: '',
  rfc: '',
  numIdentificacion: '',
  nacionalidad: 'Mexicana',
  estadoCivil: {
    clave: '',
    descripcion: ''
  },
  nacimiento: {
    fecha: '',
    lugar: '',
    pais: 'México'
  },
  telefonos: [],
  viviendas: [{
    escrituras: false,
    tipoVivienda: {
      clave: '',
      descripcion: ''
    },
    direccion: direccionEmpty
  }]
};
