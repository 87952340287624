<!-- START TABLE -->
<div class="tab-pane active">
    <div *ngIf="!creditosOtorgados || creditosOtorgados.length == 0">
        <p class="v-align-middle bold text-center col-12">No existen registros para mostrar</p>
    </div>
    <div class="table-responsive vertical-scroll">
        <table class="table table-hover table-condensed table-responsive" id="creditosOtorgados" aria-describedby="Solicitudes interesados por revisar">
            <thead>
                <tr *ngIf="creditosOtorgados && creditosOtorgados.length > 0">
                    <th id="nombre" class="v-align-middle bold text-left w-25-vw">Nombre</th>
                    <th id="apellidoPaterno" class="v-align-middle bold text-left w-25-vw">Apellido Paterno</th>
                    <th id="apellidoMaterno" class="v-align-middle bold text-left w-25-vw">Apellido Materno</th>
                    <th id="montoSolicitado" class="v-align-middle bold text-right w-25-vw">Monto Solicitado</th>
                </tr>
            </thead>
            <tbody>
                <tr class="cursor" *ngFor="let interesado of creditosOtorgados; let i = index" (click)="visualizarInformacion(interesado)" [class.selected]="solicitudInteresado && interesado.idInteresado === solicitudInteresado.idInteresado">
                    <td class="v-align-middle normal text-left">{{interesado.nombre}}</td>
                    <td class="v-align-middle normal text-left">{{interesado.apellidoPaterno}}</td>
                    <td class="v-align-middle normal text-left">{{interesado.apellidoMaterno}}</td>
                    <td class="v-align-middle normal text-right">{{interesado.cotizacion.montoCredito | currency}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>