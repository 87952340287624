<div class="card-body full-width" *ngIf="reglasExpediente">

    <!-- Se muestra sección para seleccionar la clasificación de los archivos -->
    <div class="card card-default">
        <div class="card-body">
            <div class="row">
                <div class="full-width">
                    <h4 class="text-primary">{{reglasExpediente.descripcion}}</h4>
                    <div class="scroll-menu m-t-20">
                        <div class="col-md-3 scroll-menu-item cursor" *ngFor="let clasificacionArchivo of reglasExpediente.clasificacionesArchivos">
                            <div class="card no-border widget-loader-bar m-b-10 menu-item-size" (click)="setClasificacionArchivoSelect(clasificacionArchivo)" [class.selected]="clasificacionArchivoSelect && clasificacionArchivo.clave === clasificacionArchivoSelect.clave" [class.complete]="clasificacionArchivo.complete">
                                <div class="card-body text-inline">
                                    <h5 class='no-margin text-center'>{{clasificacionArchivo.descripcion}}</h5>
                                    <h6 class='no-margin text-center' *ngIf="clasificacionArchivo.obligatorio">(obligatorio)</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Se muestra sección para seleccionar el tipo de archivo -->
    <div class="card card-default m-t-20" *ngIf="clasificacionArchivoSelect">
        <div class="card-body">

            <!-- Select con los tipos de archivos -->
            <div class="row">
                <div class="full-width">
                    <div class="form-group form-select">
                        <label>Tipo de archivo para {{ clasificacionArchivoSelect.descripcion }}</label>
                        <div class="row">
                            <div class="col-md-6">
                                <select class="selectpicker" (change)="setTipoArchivoSelect($event)">
                                    <option [value]="null">Selecciona una opción</option>
                                    <option *ngFor="let tipoArchivo of clasificacionArchivoSelect.tiposArchivos" [value]="tipoArchivo.clave">{{tipoArchivo.descripcion}}</option>
                                </select>
                            </div>
                            <div class="d-flex" *ngIf="clasificacionArchivoSelect.complete">
                                <i class="far fa-check-circle icon-text text-success"></i>
                                <h4 class="no-margin text-info">Completo</h4>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!-- Sección cuando se selecciona un tipo de archivo -->
            <div *ngIf="tipoArchivoSelect">

                <!-- Si el expediente del tipo de archivo no esta completo -->
                <div class="row full-width" *ngIf="!clasificacionArchivoSelect.complete">
                    <div class="col-md-6" *ngFor="let _ of [].constructor(tipoArchivoSelect.numMaxArchivos); let i = index">
                        <app-image-form [tipo]="tipo" [numArchivo]="(i+1)" [verArchivos]="verArchivos" (archivoEmit)="addFile($event)"></app-image-form>
                    </div>
                </div>

                <!-- Si el expediente del tipo de archivo esta completo y el tipo de archivo tiene información -->
                <div class="row full-width" *ngIf="clasificacionArchivoSelect.complete && verArchivos">
                    <div class="col-md-6" *ngFor="let _ of [].constructor(verArchivos.length); let i = index">
                        <app-image-form [tipo]="tipo" [numArchivo]="(i+1)" [verArchivos]="verArchivos" (archivoEmit)="addFile($event)"></app-image-form>
                    </div>
                </div>

                <div class="m-t-20" *ngIf="archivos.length > 0">
                    <button type="button" class="btn btn-primary boton float-left" (click)="guardarArchivos()">
                        Subir archivo(s)
                    </button>
                </div>

                <div class="m-t-20" *ngIf="verArchivos">
                    <button type="button" class="btn btn-danger boton float-left" (click)="borrarArchivos()">
                        Eliminar archivo(s)
                    </button>
                </div>

                <!-- Si el expediente del tipo de archivo esta completo y el tipo de archivo no tiene información -->
                <div *ngIf="clasificacionArchivoSelect.complete && !verArchivos">
                    <p class="v-align-middle bold text-left full-width">Ya subieron archivos de otro tipo</p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="validarAutorizarExpedienteCompleto()">
        <app-actualizar-estatus-solicitud [numSolicitud]="numSolicitud" [claveEstatusSolicitud]="claveValidacionDocumentos" [titulo]="tituloActualizarEstatus"></app-actualizar-estatus-solicitud>
    </div>

    <div class="full-width m-t-20">
        <button type="button" (click)="eliminarSolicitud()" class="btn btn-danger float-left" *ngIf="mostrarBotonEliminar()">Cancelar Solicitud</button>
    </div>
</div>