import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SolicitudModel, TrackingSolicitudModel } from 'src/app/models/credits-application/solicitud.model';
import { AuthService } from 'src/app/services/auth.service';
import { SolicitudesService } from 'src/app/services/solicitudes.service';
import UtilsRest from 'src/utils/UtilsRest';

@Component({
  selector: 'app-detalle-solicitud-renovacion',
  templateUrl: './detalle-solicitud-renovacion.component.html',
  styleUrls: ['./detalle-solicitud-renovacion.component.scss']
})
export class DetalleSolicitudRenovacionComponent implements OnInit {

  private token: string = sessionStorage.getItem('token');

  // VALIDACION ROL
  permisoUsuario: boolean;
  permisoAdmin: boolean;

  // PARAMETROS LOCALES
  tracking: TrackingSolicitudModel[];
  solicitud: SolicitudModel;
  numSolicitud: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private solicitudesService: SolicitudesService,
  ) { }

  ngOnInit(): void {

    this.permisoUsuario = this.authService.permisosUsuario();
    this.permisoAdmin = this.authService.permisosAdmin();
    
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.numSolicitud = params['numSolicitud'];
        this.buscarSolicitud();
      }
    );
  }

  // SERVICIOS EXTERNOS
  buscarSolicitud() {
    this.solicitudesService.obtenerSolicitud(
      this.token, 
      this.numSolicitud, 
      true
    ).subscribe(resp => {

      this.solicitud = resp.resultado;
      this.tracking = this.solicitud.tracking;

    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarSolicitud();
      }
    });
  }

}
