import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from '@stomp/stompjs';
import { SendSocket } from 'src/app/models/collections/cobranza.model';
import { ActualizarEstatusInteresadoModel, InteresadoPaginaWebModel } from 'src/app/models/credits-application/point.model';
import { PointService } from 'src/app/services/point.service';
import { SolicitudesService } from 'src/app/services/solicitudes.service';
import { WebsocketsService } from 'src/app/services/websockets.service';
import UtilsSwal from 'src/utils/UtilsSwal';
import { Constants } from '../../../classes/constants.class';
import * as SockJS from 'sockjs-client';
import { GuardarSolicitudModel } from 'src/app/models/credits-application/solicitud.model';

@Component({
  selector: 'app-interesados-por-revisar',
  templateUrl: './interesados-por-revisar.component.html',
  styleUrls: ['./interesados-por-revisar.component.scss']
})
export class InteresadosPorRevisarComponent implements OnInit, OnChanges {

  private token: string = sessionStorage.getItem('token');
  private idUsuario: string = sessionStorage.getItem('idUsuario');
  private idSucursal: string = sessionStorage.getItem('idSucursal');

  // EVENTOS DE SALIDA
  @Output() interesadoEmit = new EventEmitter<InteresadoPaginaWebModel>();

  // PARAMETROS RECIBIDOS
  @Input() interesadosPorRevisar: InteresadoPaginaWebModel[];

  // PARAMETROS LOCALES
  solicitudInteresado: InteresadoPaginaWebModel;

  // CONSTANTES
  claveClientePotencial: string =  Constants.CLAVE_CLIENTE_POTENCIAL;
  claveValidacionDatos: string = Constants.CLAVE_VALIDACION_DATOS;

  // SOCKETS
  private client: Client;

  constructor(
    private router: Router,
    private pointService: PointService,
    private websocketsService: WebsocketsService,
    private solicitudesService: SolicitudesService,
  ) {}

  ngOnInit(): void {
    // Se realiza conexión a sockets
    this.client = new Client();
    this.client.webSocketFactory = () => {
      return new SockJS(this.websocketsService.getPathSockSolicitudesJS());
    }

    this.client.activate();
  }

  ngOnChanges(): void {
    this.solicitudInteresado = null;
  }

  // METODOS PRIVADOS
  visualizarInformacion(interesado: InteresadoPaginaWebModel) {
    this.solicitudInteresado = interesado;
    this.interesadoEmit.emit(interesado);
  }

  // METODOS HTML
  validarDatos(interesado: InteresadoPaginaWebModel, clave: string) {

    const actualizarEstatusInteresado: ActualizarEstatusInteresadoModel = {
      idCotizacion: interesado.cotizacion.idCotizacion,
      idInteresado: interesado.idInteresado,
      clave: clave
    }

    if(clave === this.claveClientePotencial) {
      this.guardarClientePotencial(actualizarEstatusInteresado);
    } else {
      this.guardarSolicitud(interesado, actualizarEstatusInteresado);
    }
  }

  guardarClientePotencial(actualizarEstatusInteresado: ActualizarEstatusInteresadoModel) {
    UtilsSwal.enviarInformacion(
      "¿Estás seguro de mandar esta solicitud a los clientes potenciales?",
      "Enviando información",
      "Enviar",
      this.pointService.actualizarEstatusInteresado(this.token, actualizarEstatusInteresado),
      true
    );
  }


  guardarSolicitud(
    interesado: InteresadoPaginaWebModel, 
    actualizarEstatusInteresado: ActualizarEstatusInteresadoModel
  ) {
    const guardarSolicitud: GuardarSolicitudModel = {
      claveEsquemaPago: interesado.cotizacion.esquemaPago === "PAGOS_DIARIOS" ? "D" : "S", //FIXME:
      claveTipoSolicitud: Constants.CLAVE_TIPO_SOLICITUD_NUEVA_PAG_WEB,
      nombreSolicitud: `[Página web] - ${interesado.nombreNegocio}`,
      idSucursal: this.idSucursal,
      idCotizacionPagWeb: interesado.idInteresado,
      idInteresadoPagWeb: interesado.cotizacion.idCotizacion,
      usuarioUltMod: this.idUsuario,
      credito: {
        comision: 150, //FIXME:
        montoCredito: interesado.cotizacion.montoCredito,
        numPagos: interesado.cotizacion.numPagos,
        tasaInteres: interesado.cotizacion.tasaInteres
      }
    }

    UtilsSwal.enviarInformacion(
      'Guardar solicitud creada desde la página web',
      "Guardando solicitud",
      "Guardar",
      this.solicitudesService.guardarSolicitud(this.token, guardarSolicitud), 
      false
    )
    .then(() => this._enviarInfoSocket())
    .then(() => this.router.navigate(['/solicitudes']));

    this.pointService.actualizarEstatusInteresado(this.token, actualizarEstatusInteresado)
    .subscribe(() => {}, err => {
      UtilsSwal.lanzarError(
        `Ocurrio un error al actualizar la información`,
        err.error.detalles[0].mensaje
      );
    });
  }

  eliminar(interesado: InteresadoPaginaWebModel) {
    UtilsSwal.enviarInformacion(
      "¿Estás seguro de eliminar la información?",
      "Eliminando información",
      "Eliminar",
      this.pointService.eliminarInteresado(
        this.token, 
        interesado.cotizacion.idCotizacion, 
        interesado.idInteresado
      ),
      true
    );
  }

  // METODOS PRIVADOS
  private _enviarInfoSocket() {
    const sendSocket: SendSocket = {
      idSucursal: this.idSucursal,
    }
    this.client.publish({ 
      destination: '/send/solicitudes', 
      body: JSON.stringify(sendSocket) 
    });
  }

}
