<app-sidebar></app-sidebar>
<div class="horizontal-menu horizontal-app-menu dashboard">
  <app-header></app-header>
  <div class="page-container pl-5">
    <div class="page-content-wrapper ">
      <div class="content sm-gutter">
        <div class="container sm-padding-10 p-t-20 p-l-0 p-r-0">
          <div class="row" *ngIf="ruta && ruta.negociosCobranza">
            <div class="col-md-5 mt-2">
              <div
                class="card social-card share  full-width m-b-10 no-border"
                data-social="item"
              >
                <div class="card-header clearfix">
                  <h5 class="pull-left fs-12">FILTRAR RUTA DE COBRANZA</h5>
                </div>
                <div class="card-description">
                  <div class="form-group">
                    <label>Selecciona un cobrador</label>
                      <select
                        class="selectpicker"
                        [(ngModel)]="collectorSelect"
                        (ngModelChange)="onChange($event)"
                      >
                        <option
                          *ngFor="let collector of collectorList"
                          [ngValue]="collector"
                        >
                          {{collector.persona.nombre}}
                          {{collector.persona.apellidoPaterno}}
                        </option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-7 mt-2">
              <app-info-general
                [totalVisitados]="totalVisitados"
                [totalNoVisitados]="totalNoVisitados"
                [totalCreditos]="totalCreditos"
              ></app-info-general>
            </div>
          </div>
          <div *ngIf="ruta && ruta.negociosCobranza">
            <app-collector-map [markers]="markers"></app-collector-map>
          </div>
          <div class="row">
            <div class="col-lg-12 flex-column p-b-10">
              <app-ruta [ruta]="ruta"></app-ruta>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
