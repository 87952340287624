<app-sidebar></app-sidebar>
<div class="horizontal-menu horizontal-app-menu dashboard">
  <app-header></app-header>
  <div class="page-container pl-5" *ngIf="permisoUsuario">
    <div class="page-content-wrapper ">
      <div class="content sm-gutter">
        <div class="container sm-padding-10 p-t-20 p-l-0 p-r-0">
          <div class="row">
            <div class="col-lg-7 mt-2">
              <div
                class="card social-card share  full-width m-b-10 no-border"
                data-social="item"
              >
                <div class="card-header clearfix">
                  <h5 class="pull-left fs-12">HISTORICO</h5>
                </div>
                <div class="card-description">
                  <h5 class="no-margin text-primary">
                    Buscar ruta de cobranza por fecha
                  </h5>
                  <div
                    class="form-check input-daterange input-group  mt-4"
                    id="datepicker-range"
                  >
                    <input
                      type="text"
                      class="input-sm form-control"
                      name="fechaRuta"
                      id="fechaRuta"
                      autocomplete="off"
                      placeholder="Fecha de ruta de cobranza"
                    />
                    <button
                      (click)="buscarRuta()"
                      class="btn btn-primary float-right"
                    >
                      Buscar ruta
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5 mt-2" *ngIf="ruta">
              <app-info-general
                [totalVisitados]="totalVisitados"
                [totalNoVisitados]="totalNoVisitados"
                [totalCreditos]="totalCreditos"
              ></app-info-general>
            </div>
          </div>
          <div class="row" *ngIf="ruta">
            <div class="col-lg-12 flex-column p-b-10" id="rutaCobranza">
              <app-ruta [ruta]="ruta" [historico]="historico"></app-ruta>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
