import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CuotaModel } from 'src/app/models/credits/cuota.model';
import { SolicitudesService } from '../../../services/solicitudes.service';
import UtilsSwal from '../../../../utils/UtilsSwal';
import { GuardarSolicitudModel } from '../../../models/credits-application/solicitud.model';
import { CotizacionForm } from '../../../models/credits-application/cotizacion.model';
import * as SockJS from 'sockjs-client';
import { WebsocketsService } from 'src/app/services/websockets.service';
import { Client } from '@stomp/stompjs';
import { SendSocket } from 'src/app/models/collections/cobranza.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-cotizador-clientes-nuevos',
  templateUrl: './cotizador-clientes-nuevos.component.html',
  styleUrls: ['./cotizador-clientes-nuevos.component.scss']
})
export class CotizadorClientesNuevosComponent implements OnInit, OnChanges, OnDestroy {

  private token: string = sessionStorage.getItem('token');
  private idUsuario: string = sessionStorage.getItem('idUsuario');
  private idSucursal: string = sessionStorage.getItem('idSucursal');

  // VALIDACION ROL
  permisoUsuario: boolean;

  // PARAMETROS RECIBIDOS
  cotizacionForm: CotizacionForm;

  // PARAMETROS LOCALES
  cuotaSelect: CuotaModel;
  claveEsquemaPago: string;
  claveTipoSolicitud: string;

  // SOCKETS
  private client: Client;

  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute, 
    private solicitudesService: SolicitudesService,
    private websocketsService: WebsocketsService,
  ) { }

  ngOnInit(): void {
    this.permisoUsuario = this.authService.permisosUsuario();
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.claveTipoSolicitud = params['tipoSolicitud'];
      }
    );

    // Se realiza conexión a sockets
    this.client = new Client();
    this.client.webSocketFactory = () => {
      return new SockJS(this.websocketsService.getPathSockSolicitudesJS());
    }

    this.client.activate();
  }

  ngOnChanges(): void {}

  ngOnDestroy(): void {
    this.cotizacionForm = null
    this.cuotaSelect = null
    this.claveEsquemaPago = null
  }

  // METODOS HTML
  setCotizacion(cotizacionForm: CotizacionForm) {
    this.cotizacionForm = cotizacionForm;
  }

  setCuotaSelect(cuota: CuotaModel, esquemaPago: string) {
    this.cuotaSelect = cuota;
    this.claveEsquemaPago = esquemaPago;
  }

  solicitar() {
    const guardarSolicitud: GuardarSolicitudModel = {
      claveEsquemaPago: this.claveEsquemaPago,
      claveTipoSolicitud: this.cotizacionForm.cotizacion.tipoSolicitud.clave,
      nombreSolicitud: this.cotizacionForm.nombreSolicitud,
      idSucursal: this.idSucursal,
      usuarioUltMod: this.idUsuario,
      credito: {
        comision: this.cotizacionForm.cotizacion.comision,
        montoCredito: this.cotizacionForm.cotizacion.montoCredito,
        numPagos: this.cuotaSelect.numPagos,
        tasaInteres: this.cuotaSelect.tasaInteres
      }
    }

    UtilsSwal.enviarInformacion(
      'Guardar solicitud',
      "Guardando solicitud",
      "Guardar",
      this.solicitudesService.guardarSolicitud(this.token, guardarSolicitud),
      false
    )
    .then(() => this._enviarInfoSocket())
    .then(() => this.router.navigate(['/solicitudes']));
  }

  // METODOS PRIVADOS
  private _enviarInfoSocket() {
    const sendSocket: SendSocket = {
      idSucursal: this.idSucursal,
    }
    this.client.publish({ 
      destination: '/send/solicitudes', 
      body: JSON.stringify(sendSocket) 
    });
  }

}
