<body class="fixed-header ">
    <div class="login-wrapper ">
        <!-- START Login Right Container-->
        <div class="login-container bg-white">
            <div class="p-l-50 p-r-50 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
                <img src="assets/img/point-logo.png" alt="logo" data-src="assets/img/point-logo.png" data-src-retina="assets/img/point-logo.png" width="100" height="35">
                <h2 class="p-t-25">Sistema de administración</h2>
                <p class="mw-80 m-t-5">Iniciar sesión</p>
                <!-- START Login Form -->
                <form (ngSubmit)="login(loginForm)" role="form" #loginForm="ngForm">
                    <div class="form-group form-group-default">
                        <label>Usuario</label>
                        <div class="controls">
                            <input type="text" name="username" placeholder="Ingresa tu nombre de usuario" class="form-control" [(ngModel)]="username" required autofocus>
                        </div>
                    </div>
                    <label *ngIf="loginForm.submitted && loginForm.controls['username'].errors" class="error animated fadeIn">
                        El usuario es obligatorio
                    </label>
                    <div class="form-group form-group-default">
                        <label>Contraseña</label>
                        <div class="controls input-group">
                            <input [type]="tipoCampo ? 'text' : 'password'" class="form-control" name="password" placeholder="Ingresa tu contraseña" [(ngModel)]="password" required>
                            <i class="fas" style="cursor: pointer;" [ngClass]="{'fa-eye-slash': !tipoCampo, 'fa-eye': tipoCampo}" (click)="cambiarTipoCampo()"></i>
                        </div>
                    </div>
                    <label *ngIf="loginForm.submitted && loginForm.controls['password'].errors" class="error animated fadeIn">
                        La contraseña es obligatoria
                    </label>
                    <div class="row">
                        <div class="col-md-6 no-padding sm-p-l-10">
                            <div class="form-check">
                                <input type="checkbox" value="1" id="checkbox1">
                                <label for="checkbox1">Recordar usuario</label>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex align-items-center justify-content-end">
                            <button aria-label="" class="btn btn-primary btn-lg m-t-10" type="submit">Ingresar</button>
                        </div>
                    </div>
                    <!-- END Form Control-->
                </form>
                <div class="pull-bottom sm-pull-bottom">
                    <div class="m-b-30 p-r-80 sm-m-t-20 sm-p-r-15 sm-p-b-20 clearfix">
                        <div class="col-sm-12 no-padding m-t-10">
                            <p class="small-text normal hint-text">
                                &copy; {{ anio }} Point Financiera. All Rights Reserved. <br> <a href="https://www.grupofragy.com" target="_blank">Powered by Grupo Fragy</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Login Right Container-->
    </div>
</body>