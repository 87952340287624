<!-- START WIDGET widget_tableRankings-->
<div class="row">
    <div class="card share full-width full-height no-border">
        <div class="card-body">
            <div class="d-flex flex-column full-height p-l-10">
                <!-- Title -->
                <div class="row">
                    <div class="col-10-flex">
                        <h5 class="pull-left fs-12">ESTATUS SOLICITUDES</h5>
                    </div>
                    <div class="col-2 btn-align-right">
                        <button type="button" (click)="buscarCatalogoTipoSolicitudes()" class="btn btn-primary btn-sm btn-add">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="d-flex flex-row">
                    <h2 class="text-primary">Solicitudes de crédito</h2>
                </div>
                <!-- Card table -->
                <div class="card card-borderless">
                    <div class="card card-borderless">
                        <ul class="nav nav-tabs nav-tabs-simple" role="tablist" data-init-reponsive-tabs="dropdownfx">
                            <li class="nav-item" (click)="setTab(claveSolicitudCreada)">
                                <a class="active" href="#" data-toggle="tab" role="tab">Creadas</a>
                            </li>
                            <li class="nav-item" (click)="setTab(claveDatosTemporales)">
                                <a href="#" data-toggle="tab" role="tab">Datos temporales</a>
                            </li>
                            <li class="nav-item" (click)="setTab(claveRecepcionValidacionDocumentos)">
                                <a href="#" data-toggle="tab" role="tab">Documentos</a>
                            </li>
                            <li class="nav-item" (click)="setTab(claveCapturaDatos)">
                                <a href="#" data-toggle="tab" role="tab">Captura datos</a>
                            </li>
                            <li class="nav-item" (click)="setTab(claveSolicitudPreautorizada)">
                                <a href="#" data-toggle="tab" role="tab">Preautorizadas</a>
                            </li>
                            <li class="nav-item" (click)="setTab(claveFirmaContratoPagare)">
                                <a href="#" data-toggle="tab" role="tab">Firma contrato</a>
                            </li>
                            <li class="nav-item" (click)="setTab(claveSolicitudAutorizada)">
                                <a href="#" data-toggle="tab" role="tab">Autorizadas</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <app-solicitud-creada [solicitudesCreadas]="solicitudesCreadas" *ngIf="tabSelect === claveSolicitudCreada"></app-solicitud-creada>
                            <app-solicitud-datos-temporales [solicitudesDatosTemporales]="solicitudesDatosTemporales" *ngIf="tabSelect === claveDatosTemporales"></app-solicitud-datos-temporales>
                            <app-solicitud-validacion-documentos [solicitudesDocumentos]="solicitudesDocumentos" *ngIf="tabSelect === claveRecepcionValidacionDocumentos"></app-solicitud-validacion-documentos>
                            <app-solicitud-captura-datos [solicitudesDatos]="solicitudesDatos" *ngIf="tabSelect === claveCapturaDatos"></app-solicitud-captura-datos>
                            <app-solicitud-preautorizada [solicitudesPreautorizadas]="solicitudesPreautorizadas" *ngIf="tabSelect === claveSolicitudPreautorizada"></app-solicitud-preautorizada>
                            <app-solicitud-contrato-pagare [solicitudesFirmaContrato]="solicitudesFirmaContrato" *ngIf="tabSelect === claveFirmaContratoPagare"></app-solicitud-contrato-pagare>
                            <app-solicitud-autorizada-dispersada [solicitudesAutorizadas]="solicitudesAutorizadas" *ngIf="tabSelect === claveSolicitudAutorizada"></app-solicitud-autorizada-dispersada>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>