import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from '@stomp/stompjs';
import { Constants } from 'src/app/classes/constants.class';
import { AutorizacionModel } from 'src/app/models/auth/autorizacion.model';
import { SendSocket } from 'src/app/models/collections/cobranza.model';
import { AuthService } from 'src/app/services/auth.service';
import { SolicitudesService } from 'src/app/services/solicitudes.service';
import { WebsocketsService } from 'src/app/services/websockets.service';
import Utils from 'src/utils/Utils';
import UtilsSwal from 'src/utils/UtilsSwal';
import Swal from 'sweetalert2';
import * as SockJS from 'sockjs-client';
import { SolicitudModel } from 'src/app/models/credits-application/solicitud.model';
import { DigitalizacionService } from 'src/app/services/digitalizacion.service';
import UtilsRest from 'src/utils/UtilsRest';
import { ReporteService } from 'src/app/services/reporte.service';

@Component({
  selector: 'app-autorizar',
  templateUrl: './autorizar.component.html',
  styleUrls: ['./autorizar.component.scss']
})
export class AutorizarComponent implements OnInit, OnChanges {

  private token: string = sessionStorage.getItem('token');
  private idSucursal: string = sessionStorage.getItem('idSucursal');
  private idUsuario: string = sessionStorage.getItem('idUsuario');

  // VALIDACION ROL
  permisoSuperAdmin: boolean;

  // PARAMETROS RECIBIDOS
  @Input() solicitud: SolicitudModel;

  // CONSTANTES
  numArchivo = 1;

  // SOCKETS
  private client: Client;

  constructor(
    private router: Router,
    private authService: AuthService,
    private reporteService: ReporteService,
    private solicitudesService: SolicitudesService,
    private websocketsService: WebsocketsService,
    private digitalizacionService: DigitalizacionService
  ) { }

  ngOnChanges(): void {}

  ngOnInit(): void {

    this.permisoSuperAdmin = this.authService.permisosSuperAdmin();

    // Se realiza conexión a sockets
    this.client = new Client();
    this.client.webSocketFactory = () => {
      return new SockJS(this.websocketsService.getPathSockSolicitudesJS());
    }

    this.client.activate();
  }

  obtenerDatosBancarios() {
    UtilsSwal.generarReporte(
      this.reporteService.generarDatosBancarios(
        this.token, 
        this.solicitud.credito.numContrato, 
        this.solicitud.numSolicitud
      )
    );
  }

  obtenerContratoFirmado() {

    UtilsSwal.buscandoInformacion();
    Swal.showLoading();

    this.digitalizacionService.obtenerArchivo(
      this.token, 
      this.solicitud.numSolicitud, 
      Constants.CLAVE_TIPO_ARCHIVO_CONTRATO,
      this.numArchivo
    ).subscribe(resp => {

      const contrato = resp.resultado.archivos[0];

      const contentType = 'application/pdf';
      const blob = Utils.b64toBlob(contrato.base64, contentType);
      const blobUrl = URL.createObjectURL(blob);

      var link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('target', '_blank');
      link.click();
      Swal.close();

    }, (err) => {

      Swal.close();
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.obtenerContratoFirmado();
      }
    });
  }

  autorizar() {
    Swal.fire({
      title: 'Confirmación de autorización',
      html: `<input type="text" id="user" class="swal2-input" placeholder="Usuario">
      <input type="password" id="password" class="swal2-input" placeholder="Contraseña">`,
      confirmButtonText: 'Confirmar',
      focusConfirm: false,
      preConfirm: () => {
        const user = (<HTMLInputElement> Swal.getPopup().querySelector('#user')).value;
        const password = (<HTMLInputElement> Swal.getPopup().querySelector('#password')).value;

        if (!user || !password) {
          Swal.showValidationMessage(`Por favor inserta usuario y contraseña para continuar`)
        }
        return { user: user, password: password }
      }
    }).then((result) => {
      if (result.isConfirmed) {

        const autorizacion: AutorizacionModel = {
          numSolicitud: this.solicitud.numSolicitud,
          usuarioUltMod: this.idUsuario,
          numContrato: this.solicitud.credito.numContrato,
          autentificacion: {
            codigoUsuario: result.value.user.trim(),
            password: Utils.b64EncodeUnicode(result.value.password.trim())
          }
        }

        this._autorizarService(autorizacion);
      }
    });
  }

  // SERVICIOS EXTERNOS
  private _autorizarService(autorizacion: AutorizacionModel) {

    UtilsSwal.enviarInformacion(
      "¿Estás seguro de autorizar esta solicitud?",
      "Autorizando solicitud",
      "Autorizar",
      this.solicitudesService.autorizar(this.token, autorizacion),
      false
    )
    .then(() => this._enviarInfoSocket())
    .then(() => this.router.navigate(['/solicitudes']));;
  }

  private _enviarInfoSocket() {
    const sendSocket: SendSocket = {
      idSucursal: this.idSucursal,
    }
    this.client.publish({ 
      destination: '/send/solicitudes', 
      body: JSON.stringify(sendSocket) 
    });
  }

}
