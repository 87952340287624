import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CatResponse } from 'src/app/models/catalogues/cat-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { PersonaService } from 'src/app/services/persona.service';
import UtilsRest from 'src/utils/UtilsRest';
import * as SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import { WebsocketsService } from 'src/app/services/websockets.service';
import { SendSocket } from 'src/app/models/collections/cobranza.model';
import { Router } from '@angular/router';
import Utils from 'src/utils/Utils';
import { Telefono } from 'src/app/models/person/telefono.model';
import UtilsDatosTemporales from './UtilsDatosTemporales';
import UtilsSwal from 'src/utils/UtilsSwal';
import { SolicitudesService } from 'src/app/services/solicitudes.service';
import Swal from 'sweetalert2';
import { EstatusSolicitudRequest } from 'src/app/models/credits-application/estatus-solicitud.model';
import { Constants } from 'src/app/classes/constants.class';

@Component({
  selector: 'app-datos-temporales-form',
  templateUrl: './datos-temporales-form.component.html',
  styleUrls: ['./datos-temporales-form.component.scss']
})
export class DatosTemporalesFormComponent implements OnInit, OnChanges {

  private token: string = sessionStorage.getItem('token');
  private idSucursal: string = sessionStorage.getItem('idSucursal');
  private idUsuario: string = sessionStorage.getItem('idUsuario');

  // PARAMETROS RECIBIDOS
  @Input() numSolicitud: string;

  // ELEMENTOS DEL HTML
  @ViewChild('appTelefonosCliente') appTelefonosCliente: any;
  @ViewChild('appTelefonosNegocio') appTelefonosNegocio: any;

  // CATALOGOS
  catMediosConocimiento: CatResponse[] = [];
  catNivelesEscolares: CatResponse[] = [];
  catOcupaciones: CatResponse[] = [];
  catFuentesIngresos: CatResponse[] = [];
  catEstadoCivil: CatResponse[] = [];
  catTipoVivienda: CatResponse[] = [];

  // FORMS
  formDatosTemporales: UntypedFormGroup;

  // SOCKETS
  private client: Client;

  // PARAMETROS LOCALES
  telefonosCliente: Telefono[] = [];
  telefonosNegocio: Telefono[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService, 
    private clienteService: ClienteService,
    private personaService: PersonaService,
    private websocketsService: WebsocketsService,
    private solicitudesService: SolicitudesService,
  ) { }

  ngOnInit(): void {

    this._crearFormularioDatosTemporales();

    // Se realiza conexión a sockets
    this.client = new Client();
    this.client.webSocketFactory = () => {
      return new SockJS(this.websocketsService.getPathSockSolicitudesJS());
    }

    this.client.activate();

    // Se obtiene información de los catálogos
    this._getMediosConocimiento();
    this._getNivelesEscolares();
    this._getFuentesIngresos();
    this._getOcupaciones();
    this._getTipoVivienda();
    this._getEstadoCivil();
  }

  ngOnChanges(): void {}

  // METODOS FORMS
  _crearFormularioDatosTemporales() {
    this.formDatosTemporales = this.fb.group({
      estadoCivil: ['', Validators.required],
      nivelEscolar: ['', Validators.required],
      ocupacion: ['', Validators.required],
      tipoVivienda: ['', Validators.required],
      fuenteIngreso: ['', Validators.required],
      medioConocimiento: ['', Validators.required],
      email: ['', [Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      escrituras: ['N'],
      numDependientesEconomicos: ['0', [Validators.min(0)]],
      escriturasVivienda: [''],
      actividadEconomicaNegocio: [''],
      antiguedadNegocio: ['0', [Validators.min(1)]],
      entidadFinancieraAnterior: [''],
      ingresoCliente: ['0', [Validators.required, Validators.min(0)]],
      ingresoNegocio: ['0', [Validators.required, Validators.min(0)]],
      ingresoApoyoFamiliar: ['0', [Validators.required, Validators.min(0)]],
      otroIngreso: ['0', [Validators.required, Validators.min(0)]],
      gastosAlimento: ['0', [Validators.required, Validators.min(0)]],
      gastosServiciosBasicos: ['0', [Validators.required, Validators.min(0)]],
      gastosPrestamos: ['0', [Validators.required, Validators.min(0)]],
      gastosRenta: ['0', [Validators.required, Validators.min(0)]],
      gastosMateriaPrima: ['0', [Validators.required, Validators.min(0)]],
      otroGasto: ['0', [Validators.required, Validators.min(0)]],
    });
  }

  // METODOS HTML
  guardarDatosTemporales() {

    if (this.formDatosTemporales.invalid) {
      return Utils.validateForms(this.formDatosTemporales)
    }

    // Se manda a llamar el método validarFormHandler del componente teléfonos
    const formTelefonosCliente = this.appTelefonosCliente.validarFormHandler();
    if (formTelefonosCliente.invalid) {
      return Utils.validateForms(formTelefonosCliente)
    }

    // Se manda a llamar el método validarFormHandler del componente teléfonos
    const formTelefonosNegocio = this.appTelefonosNegocio.validarFormHandler();
    if (formTelefonosNegocio.invalid) {
      return Utils.validateForms(formTelefonosNegocio)
    }

    const datosTemporales = UtilsDatosTemporales.mapFormtoDto(
      this.formDatosTemporales,
      formTelefonosCliente,
      formTelefonosNegocio,
      this.numSolicitud,
      this.idUsuario
    );

    UtilsSwal.enviarInformacion(
      'Guardar datos temporales',
      "Guardando información",
      "Guardar",
      this.solicitudesService.guardarDatosTemporales(this.token, datosTemporales),
      false
    )
    .then(() => this._enviarInfoSocket())
    .then(() => this.router.navigate(['/solicitudes']));
  }

  eliminarSolicitud() {
    Swal.fire({
      title: 'Confirmación de cancelación',
      html: `<textarea id="motivo" placeholder="Inserta un motivo de cancelación" cols="50"></textarea>`,
      confirmButtonText: 'Confirmar',
      focusConfirm: false,
      preConfirm: () => {
        const motivo = (<HTMLInputElement> Swal.getPopup().querySelector('#motivo')).value;

        if (!motivo) {
          Swal.showValidationMessage(`Por favor inserta un motivo para continuar`)
        }
        return { motivo: motivo }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        
        const estatusSolicitud: EstatusSolicitudRequest = {
          numSolicitud: this.numSolicitud,
          usuarioUltMod: this.idUsuario,
          motivo: result.value.motivo.trim(),
          claveEstatusSolicitud: Constants.CLAVE_TIPO_ESTATUS_CANCELACION
        }

        this._cancelarSolicitud(estatusSolicitud);
      }
    });
  }

  // METODOS HTML - VALIDACIONES
  mostrarBotonEliminar() {
    if(!this.authService.hasRole(Constants.ROLE_ADMIN)) return false;
    return true;
  }

  // SERVICIOS EXTERNOS
  private _getMediosConocimiento() {
    this.clienteService.obtenerMedioConocimiento(this.token)
    .subscribe(resp => {
      this.catMediosConocimiento = resp.resultados;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getMediosConocimiento();
      }
    });
  }

  private _getNivelesEscolares() {
    this.clienteService.obtenerNivelEscolar(this.token)
    .subscribe(resp => {
      this.catNivelesEscolares = resp.resultados;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getNivelesEscolares();
      }
    });
  }

  private _getOcupaciones() {
    this.clienteService.obtenerOcupacion(this.token)
    .subscribe(resp => {
      this.catOcupaciones = resp.resultados;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getOcupaciones();
      }
    });
  }

  private _getFuentesIngresos() {
    this.clienteService.obtenerFuenteIngresos(this.token)
    .subscribe(resp => {
      this.catFuentesIngresos = resp.resultados;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getFuentesIngresos();
      }
    });
  }

  private _getTipoVivienda() {
    this.personaService.obtenerTipoVivienda(this.token)
    .subscribe(resp => {
      this.catTipoVivienda = resp.resultados;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getTipoVivienda();
      }
    });
  }

  private _getEstadoCivil() {
    this.personaService.obtenerEstadoCivil(this.token)
    .subscribe(resp => {
      this.catEstadoCivil = resp.resultados;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getEstadoCivil();
      }
    });
  }

  private _cancelarSolicitud(estatusSolicitud: EstatusSolicitudRequest) {

    UtilsSwal.enviarInformacion(
      "¿Estás seguro de cancelar esta solicitud?",
      "Cancelando solicitud",
      "Cancelar",
      this.solicitudesService.cancelarSolicitud(this.token, estatusSolicitud),
      false
    )
    .then(() => this._enviarInfoSocket())
    .then(() => this.router.navigate(['/solicitudes']));
  }

  // METODOS PRIVADOS
  private _enviarInfoSocket() {
    const sendSocket: SendSocket = {
      idSucursal: this.idSucursal,
    }
    this.client.publish({ 
      destination: '/send/solicitudes', 
      body: JSON.stringify(sendSocket) 
    });
  }

  // DATOS FORM
  get nivelEscolar() {
    return this.formDatosTemporales.get('nivelEscolar');
  }

  get medioConocimiento() {
    return this.formDatosTemporales.get('medioConocimiento');
  }

  get ocupacion() {
    return this.formDatosTemporales.get('ocupacion');
  }

  get fuenteIngreso() {
    return this.formDatosTemporales.get('fuenteIngreso');
  }

  get estadoCivil() {
    return this.formDatosTemporales.get('estadoCivil');
  }

  get tipoVivienda() {
    return this.formDatosTemporales.get('tipoVivienda');
  }

  get numDependientesEconomicos() {
    return this.formDatosTemporales.get('numDependientesEconomicos');
  }

  // VALIDACIONES (DATOS NO VALIDOS)
  get numDependientesEconomicosNoValido() {
    return this.formDatosTemporales.get('numDependientesEconomicos').invalid && this.formDatosTemporales.get('numDependientesEconomicos').touched
  }

  get emailNoValido() {
    return this.formDatosTemporales.get('email').invalid && this.formDatosTemporales.get('email').touched
  }

  get estadoCivilNoValido() {
    return this.formDatosTemporales.get('estadoCivil').invalid && this.formDatosTemporales.get('estadoCivil').touched
  }

  get nivelEscolarNoValido() {
    return this.formDatosTemporales.get('nivelEscolar').invalid && this.formDatosTemporales.get('nivelEscolar').touched
  }

  get ocupacionNoValido() {
    return this.formDatosTemporales.get('ocupacion').invalid && this.formDatosTemporales.get('ocupacion').touched
  }

  get tipoViviendaNoValido() {
    return this.formDatosTemporales.get('tipoVivienda').invalid && this.formDatosTemporales.get('tipoVivienda').touched
  }

  get fuenteIngresoNoValido() {
    return this.formDatosTemporales.get('fuenteIngreso').invalid && this.formDatosTemporales.get('fuenteIngreso').touched
  }

  get medioConocimientoNoValido() {
    return this.formDatosTemporales.get('medioConocimiento').invalid && this.formDatosTemporales.get('medioConocimiento').touched
  }

  get escriturasViviendaNoValido() {
    return this.formDatosTemporales.get('escriturasVivienda').invalid && this.formDatosTemporales.get('escriturasVivienda').touched
  }

  get actividadEconomicaNegocioNoValido() {
    return this.formDatosTemporales.get('actividadEconomicaNegocio').invalid && this.formDatosTemporales.get('actividadEconomicaNegocio').touched
  }

  get antiguedadNegocioNoValido() {
    return this.formDatosTemporales.get('antiguedadNegocio').invalid && this.formDatosTemporales.get('antiguedadNegocio').touched
  }

  get entidadFinancieraAnteriorNoValido() {
    return this.formDatosTemporales.get('entidadFinancieraAnterior').invalid && this.formDatosTemporales.get('entidadFinancieraAnterior').touched
  }

  get ingresoClienteNoValido() {
    return this.formDatosTemporales.get('ingresoCliente').invalid && this.formDatosTemporales.get('ingresoCliente').touched
  }

  get ingresoNegocioNoValido() {
    return this.formDatosTemporales.get('ingresoNegocio').invalid && this.formDatosTemporales.get('ingresoNegocio').touched
  }

  get ingresoApoyoFamiliarNoValido() {
    return this.formDatosTemporales.get('ingresoApoyoFamiliar').invalid && this.formDatosTemporales.get('ingresoApoyoFamiliar').touched
  }

  get otroIngresoNoValido() {
    return this.formDatosTemporales.get('otroIngreso').invalid && this.formDatosTemporales.get('otroIngreso').touched
  }

  get gastosAlimentoNoValido() {
    return this.formDatosTemporales.get('gastosAlimento').invalid && this.formDatosTemporales.get('gastosAlimento').touched
  }

  get gastosServiciosBasicosNoValido() {
    return this.formDatosTemporales.get('gastosServiciosBasicos').invalid && this.formDatosTemporales.get('gastosServiciosBasicos').touched
  }

  get gastosPrestamosNoValido() {
    return this.formDatosTemporales.get('gastosPrestamos').invalid && this.formDatosTemporales.get('gastosPrestamos').touched
  }

  get gastosRentaNoValido() {
    return this.formDatosTemporales.get('gastosRenta').invalid && this.formDatosTemporales.get('gastosRenta').touched
  }

  get gastosMateriaPrimaNoValido() {
    return this.formDatosTemporales.get('gastosMateriaPrima').invalid && this.formDatosTemporales.get('gastosMateriaPrima').touched
  }

  get otroGastoNoValido() {
    return this.formDatosTemporales.get('otroGasto').invalid && this.formDatosTemporales.get('otroGasto').touched
  }

}
