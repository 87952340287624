import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { NegociosCobranza, VisitaComponentModel } from 'src/app/models/collections/cobranza.model';
import { AdeudosModel, InteresesModel } from 'src/app/models/credits/cuota.model';
import { PagoModel } from 'src/app/models/credits/pagos.model';

@Component({
  selector: 'app-cuota-cobranza',
  templateUrl: './cuota-cobranza.component.html',
  styleUrls: ['./cuota-cobranza.component.scss']
})
export class CuotaCobranzaComponent implements OnDestroy, OnChanges {

  // PARAMETROS RECIBIDOS
  @Input() negocioCobranzaSelect: NegociosCobranza;
  @Input() historico: boolean;

  // PARAMETROS LOCALES
  adeudos: AdeudosModel;
  pagoModel: PagoModel;
  intereses: InteresesModel[];
  visitaComponentModel: VisitaComponentModel;

  constructor() {}

  ngOnDestroy(): void {
    this.negocioCobranzaSelect = null
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes.negocioCobranzaSelect || !changes.negocioCobranzaSelect.currentValue) {

      this.negocioCobranzaSelect = null;
      this.visitaComponentModel = null;
      this.pagoModel = null;
      this.adeudos = null;

    } else {

      this.negocioCobranzaSelect = changes.negocioCobranzaSelect.currentValue
      this.adeudos = changes.negocioCobranzaSelect.currentValue.cobranza.cuota.adeudos
      this.intereses = changes.negocioCobranzaSelect.currentValue.cobranza.cuota.intereses

      this.visitaComponentModel = {
        visitas: changes.negocioCobranzaSelect.currentValue.cobranza.visitas,
        idCuotaCredito: changes.negocioCobranzaSelect.currentValue.cobranza.cuota.idCuotaCredito,
        idCredito: changes.negocioCobranzaSelect.currentValue.credito.idCredito,
        idNegocio: changes.negocioCobranzaSelect.currentValue.negocio.idNegocio,
        idNegocioCobranza: changes.negocioCobranzaSelect.currentValue.cobranza.idNegocioCobranza,
        pagoRequerido: changes.negocioCobranzaSelect.currentValue.cobranza.cuota.pagoRequerido
      }

      if(changes.historico && changes.historico.currentValue) {
        this.historico = changes.historico.currentValue;
      }

    }
  }

  // METODOS RECIBIDIOS DEL OUTPUT
  selectPagos(pagoModel: PagoModel) {
    this.pagoModel = pagoModel;
  }
}
