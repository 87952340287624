<div class="card card-default">
    <div class=" card-body">
        <h4 class="text-primary">Cotizador clientes nuevos</h4>
        <div class="row m-b-10">
            <div class="form-check">
                <input type="checkbox" [checked]="promocion" (change)="onCheckboxSelect($event)" id="check-promocion">
                <label for="check-promocion"> <b>Promoción</b></label>
            </div>
        </div>
        <form autocomplete="off" [formGroup]="formCotizacion" (ngSubmit)="generarCotizacion()">
            <div class="row">
                <div class="form-group full-width">
                    <label>Nombre solicitud</label>
                    <input type="text" class="form-control" placeholder="Nombre solicitud" formControlName="nombreSolicitud" [class.is-invalid]="nombreSolicitudsNoValido">
                    <small *ngIf="nombreSolicitudsNoValido" class="text-danger">El campo no es válido</small>
                </div>
                <div class="form-group form-select full-width">
                    <label>Esquema pago</label>
                    <select class="selectpicker" formControlName="esquemaPago" [class.is-invalid]="esquemaPagooNoValido">
                        <option value="">Selecciona una opción</option>
                        <option *ngFor="let esquemaPago of catalogoEsquemasPago" [ngValue]="esquemaPago.clave">{{esquemaPago.descripcion}}</option>
                    </select>
                    <small *ngIf="esquemaPagooNoValido" class="text-danger">Campo requerido</small>
                </div>
                <div class="form-group full-width">
                    <label>Monto</label>
                    <input type="number" min="5000" step="1000" class="form-control" placeholder="Monto" formControlName="monto" [class.is-invalid]="montoNoValido">
                    <small *ngIf="montoNoValido" class="text-danger">El campo no es válido</small>
                </div>
            </div>
            <div class="row">
                <div class="full-width m-t-20">
                    <button type="submit" class="btn btn-primary float-right">Generar cotización</button>
                    <button (click)="limpiar()" class="btn btn-secondary float-right m-r-10" *ngIf="cotizacion">Limpiar</button>
                </div>
            </div>
        </form>
    </div>
</div>