<!-- ROW-->
<div class="row">
    <app-contadores-solicitudes-pagina-web [totalInteresadosPorRevisar]="totalInteresadosPorRevisar" [totalInteresadosValidacionDatos]="totalInteresadosValidacionDatos" [totalClientesPotenciales]="totalClientesPotenciales" [totalCreditosOtorgados]="totalCreditosOtorgados"></app-contadores-solicitudes-pagina-web>
</div>

<!-- ROW-->
<div class="row">
    <div class="card share full-width full-height no-border">
        <div class="card-body">
            <div class="d-flex flex-column full-height p-l-10">
                <!-- Title -->
                <div class="row">
                    <div class="col-10-flex">
                        <h5 class="pull-left fs-12">PÁGINA WEB</h5>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="d-flex flex-row">
                    <h2 class="text-primary">Interesados desde la página web</h2>
                </div>
                <!-- Card table -->
                <div class="card card-borderless">
                    <div class="card card-borderless">
                        <ul class="nav nav-tabs nav-tabs-simple content-center" role="tablist" data-init-reponsive-tabs="dropdownfx">
                            <li class="nav-item" (click)="setTab(clavePorRevisar)">
                                <a class="active" href="#" data-toggle="tab" role="tab">Por revisar</a>
                            </li>
                            <li class="nav-item" (click)="setTab(claveClientePotencial)">
                                <a href="#" data-toggle="tab" role="tab">Clientes potenciales</a>
                            </li>
                            <li class="nav-item" (click)="setTab(claveValidacionDatos)">
                                <a href="#" data-toggle="tab" role="tab">Solicitudes creadas</a>
                            </li>
                            <li class="nav-item" (click)="setTab(claveCreditoOtorgado)">
                                <a href="#" data-toggle="tab" role="tab">Creditos otorgados</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <app-interesados-por-revisar [interesadosPorRevisar]="interesadosPorRevisar" (interesadoEmit)="setSolicitudInteresado($event)" *ngIf="tabSelect === clavePorRevisar"></app-interesados-por-revisar>
                            <app-clientes-potenciales [clientesPotenciales]="clientesPotenciales" (interesadoEmit)="setSolicitudInteresado($event)" *ngIf="tabSelect === claveClientePotencial"></app-clientes-potenciales>
                            <app-interesados-validacion-datos [interesadosValidacionDatos]="interesadosValidacionDatos" (interesadoEmit)="setSolicitudInteresado($event)" *ngIf="tabSelect === claveValidacionDatos"></app-interesados-validacion-datos>
                            <app-creditos-otorgados-pagina-web [creditosOtorgados]="creditosOtorgados" (interesadoEmit)="setSolicitudInteresado($event)" *ngIf="tabSelect === claveCreditoOtorgado"></app-creditos-otorgados-pagina-web>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ROW-->
<app-detalle-solicitud-interesado [solicitudInteresado]="solicitudInteresado"></app-detalle-solicitud-interesado>
