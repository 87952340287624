<div class="ng-autocomplete">
    <form [formGroup]="form">
        <label class="form-control-label" for="input-cliente" style="margin-top: 8px">Selecciona cliente</label>
        <ng-autocomplete [data]="autocompleteClientes" [searchKeyword]="keyword" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" placeholder="Buscar cliente" (selected)='selectEvent($event)' (inputCleared)='clearedEvent()'>
        </ng-autocomplete>
        <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.nombre"></a>
        </ng-template>
        <ng-template #notFoundTemplate let-notFound>
            <div>No existen registros</div>
        </ng-template>

        <label *ngIf="clienteSelect" class="form-control-label" for="input-negocio" style="margin-top: 8px">Selecciona negocio</label>
        <select *ngIf="clienteSelect" id="input-negocio" class="selectpicker" style="width: 100%;" formControlName="negocio" [(ngModel)]='negocioSelect' [class.is-invalid]="negocioInvalido">
            <option *ngFor="let negocio of negocios; let i = index" [ngValue]="negocio">
                {{negocio.nombre}}
            </option>
        </select>
        <small *ngIf="negocioInvalido" class="text-danger">Selecciona un negocio</small>

        <label *ngIf="clienteSelect && negocioSelect" class="form-control-label" for="input-credito" style="margin-top: 8px">Selecciona credito</label>
        <select *ngIf="clienteSelect && negocioSelect" id="input-credito" style="width: 100%;" class="selectpicker" formControlName="credito" [(ngModel)]='creditoSelect' [class.is-invalid]="creditoInvalido">
            <option *ngFor="let credito of creditos; let i = index" [ngValue]="credito">
                {{credito.numContrato}}
            </option>
        </select>
        <small *ngIf="creditoInvalido" class="text-danger">Selecciona un credito</small>
    </form>
</div>