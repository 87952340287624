<div class="row">
    <div class="col-lg-12">
        <!-- START VISITAS -->
        <app-visitas [visitaComponentModel]="visitaComponentModel" [historico]="historico" (visitaEmit)="selectPagos($event)"> </app-visitas>
    </div>
</div>

<div class="row">

    <!-- START DERTALLES NEGOCIO / CREDITOS -->
    <div class="col-lg-6" *ngIf="negocioCobranzaSelect">

        <!-- DETALLES NEGOCIO-->
        <div class="card social-card share full-width m-b-10 no-border" data-social="item" *ngIf="negocioCobranzaSelect.negocio">
            <div class="card-header clearfix">
                <h5 class="text-primary">Detalle del negocio</h5>
            </div>
            <div class="grid-random">
                <div class="card-body">
                    <p>
                        <span class="title-labels">No. Negocio: </span> {{negocioCobranzaSelect.negocio.numNegocio}}
                    </p>
                    <p>
                        <span class="title-labels">Nombre negocio: </span> {{negocioCobranzaSelect.negocio.nombre}}
                    </p>
                    <p>
                        <span class="title-labels">No. Cliente: </span> {{negocioCobranzaSelect.negocio.cliente.numCliente}}
                    </p>
                    <p>
                        <span class="title-labels">Nombre cliente: </span> {{negocioCobranzaSelect.negocio.cliente.persona.nombre }} {{ negocioCobranzaSelect.negocio.cliente.persona.apellidoPaterno}}
                    </p>
                </div>
            </div>
        </div>

        <!-- DETALLES CREDITO-->
        <div class="card social-card share full-width m-b-10 no-border" data-social="item" *ngIf="negocioCobranzaSelect.credito">
            <div class="card-header clearfix">
                <h5 class="text-primary">Detalle del crédito</h5>
            </div>
            <div class="grid-random">
                <div class="card-body">
                    <p>
                        <span class="title-labels">No. Crédito: </span> {{negocioCobranzaSelect.credito.numContrato}}
                    </p>
                    <p>
                        <span class="title-labels">Tipo de crédito: </span> {{negocioCobranzaSelect.credito.tipoCredito.descripcion}}
                    </p>
                    <p>
                        <span class="title-labels">Monto solicitado: </span> {{negocioCobranzaSelect.credito.montoSolicitado | currency}}
                    </p>
                    <p>
                        <span class="title-labels">Cuota recurrente: </span> {{negocioCobranzaSelect.credito.cuotaRecurrente | currency}}
                    </p>
                    <p>
                        <span class="title-labels">Total cuotas: </span> {{negocioCobranzaSelect.credito.totalCuotas}}
                    </p>
                </div>
            </div>
        </div>

        <!-- DETALLES CUOTA-->
        <div class="card social-card share full-width m-b-10 no-border" data-social="item" *ngIf="negocioCobranzaSelect.cobranza && negocioCobranzaSelect.cobranza.cuota">
            <div class="card-header clearfix">
                <h5 class="text-primary">Detalles de la cuota</h5>
            </div>
            <div class="grid-random">
                <div class="card-body">
                    <p>
                        <span class="title-labels">No. Cuota: </span> {{negocioCobranzaSelect.cobranza.cuota.numCuota}}
                    </p>
                    <p>
                        <span class="title-labels">Fecha inicio: </span> {{negocioCobranzaSelect.cobranza.cuota.fechaInicioCuota}}
                    </p>
                    <p>
                        <span class="title-labels">Fecha limite: </span> {{negocioCobranzaSelect.cobranza.cuota.fechaFinCuota}}
                    </p>
                    <p>
                        <span class="title-labels">Pago requerido: </span> {{negocioCobranzaSelect.cobranza.cuota.pagoRequerido | currency}}
                    </p>
                    <p>
                        <span class="title-labels">Saldo capital: </span> {{negocioCobranzaSelect.cobranza.cuota.saldoCapital | currency}}
                    </p>
                    <p>
                        <span class="title-labels">Saldo intereses: </span> {{negocioCobranzaSelect.cobranza.cuota.saldoIntereses | currency}}
                    </p>
                    <p>
                        <span class="title-labels">Saldo capital + intereses: </span> {{negocioCobranzaSelect.cobranza.cuota.saldoCapitalIntereses | currency}}
                    </p>
                    <p>
                        <span class="title-labels">Saldo liquidar: </span> {{negocioCobranzaSelect.cobranza.cuota.saldoLiquidar | currency}}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6" *ngIf="negocioCobranzaSelect">
        <!-- START INTERESES -->
        <app-intereses [intereses]="intereses"> </app-intereses>
        <!-- START ADEUDOS -->
        <app-adeudos [adeudos]="adeudos"> </app-adeudos>
        <!-- START PAGOS -->
        <app-pagos [pagoModel]="pagoModel" [historico]="historico"> </app-pagos>
    </div>
</div>