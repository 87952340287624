<!-- START WIDGET widget_tableRankings-->
<div class="row">
    <div class="card share full-width full-height no-border">
        <div class="card-body">
            <div class="d-flex flex-column full-height p-l-10">
                <h5 class="pull-left fs-12">ESTATUS NEGOCIOS</h5>
                <div class="clearfix"></div>
                <div class="d-flex flex-row">
                    <h2 class="text-primary">Ruta de cobranza</h2>
                </div>
                <div class="card card-borderless text-center" *ngIf="!ruta">
                    <div class="card-body">
                        <label>El día está tranquilo, no hay negocios por visitar</label>
                    </div>
                    <img src="assets/img/404.jpeg" alt="Ruta no cargada" height="330" class="not-found-img">
                </div>

                <div class="card card-borderless" *ngIf="ruta">
                    <div class="card card-borderless">
                        <ul class="nav nav-tabs nav-tabs-simple" role="tablist" data-init-reponsive-tabs="dropdownfx">
                            <li class="nav-item" (click)="clearNegocio()">
                                <a class="active" href="#" data-toggle="tab" role="tab" data-target="#tabNoVisitados">No visitados</a>
                            </li>
                            <li class="nav-item" (click)="clearNegocio()">
                                <a href="#" data-toggle="tab" role="tab" data-target="#tabVisitados">Visitados</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <!-- START TABLE - NO COBRADOS -->
                            <div class="tab-pane active" id="tabNoVisitados">
                                <div *ngIf="!cobranzaNoCobrados || cobranzaNoCobrados.length == 0">
                                    <p class="v-align-middle bold text-center col-12">No existen registros para mostrar</p>
                                </div>
                                <div class="table-responsive vertical-scroll">
                                    <table class="table table-hover table-condensed table-responsive" id="cobranzaNoCobrados" aria-describedby="Cuotas no cobradas">
                                        <thead>
                                            <tr *ngIf="cobranzaNoCobrados && cobranzaNoCobrados.length > 0">
                                                <th id="nombreCliente" class="v-align-middle bold text-left col-3">Nombre del cliente</th>
                                                <th id="numContrato" class="v-align-middle bold text-left col-2">No. Contrato</th>
                                                <th id="cuota" class="v-align-middle bold text-center col-1">Cuota</th>
                                                <th id="fechaInicio" class="v-align-middle bold text-center col-2">Fecha inicio</th>
                                                <th id="fechaFin" class="v-align-middle bold text-center col-2">Fecha fin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let cobranzaN of cobranzaNoCobrados; let i = index" (click)="setNegocioCobranzaSelect(cobranzaN)" [class.selected]="negocioCobranzaSelect != null && negocioCobranzaSelect.cobranza.idNegocioCobranza === cobranzaN.cobranza.idNegocioCobranza">
                                                <td class="v-align-middle normal text-left">{{cobranzaN.negocio.cliente.persona.nombre}} {{cobranzaN.negocio.cliente.persona.apellidoPaterno}}</td>
                                                <td class="v-align-middle normal text-left">{{cobranzaN.credito.numContrato}}</td>
                                                <td class="v-align-middle normal text-center">{{cobranzaN.cobranza.cuota.numCuota}} / {{cobranzaN.credito.totalCuotas}}</td>
                                                <td class="v-align-middle normal text-center">{{cobranzaN.cobranza.cuota.fechaInicioCuota}}</td>
                                                <td class="v-align-middle normal text-center">{{cobranzaN.cobranza.cuota.fechaFinCuota}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- START TABLE - COBRADOS -->
                            <div class="tab-pane" id="tabVisitados">
                                <div *ngIf="!cobranzaCobrados || cobranzaCobrados.length == 0">
                                    <p class="v-align-middle bold text-center col-12">No existen registros para mostrar</p>
                                </div>
                                <div class="table-responsive vertical-scroll">
                                    <table class="table table-hover table-condensed table-responsive" id="cobranzaCobrados" aria-describedby="Cuotas cobradas">
                                        <thead>
                                            <tr *ngIf="cobranzaCobrados && cobranzaCobrados.length > 0">
                                                <th id="nombreCliente" class="v-align-middle bold text-left col-3">Nombre del cliente</th>
                                                <th id="numContrato" class="v-align-middle bold text-left col-2">No. Contrato</th>
                                                <th id="cuota" class="v-align-middle bold text-center col-1">Cuota</th>
                                                <th id="fechaInicio" class="v-align-middle bold text-center col-2">Fecha inicio</th>
                                                <th id="fechaFin" class="v-align-middle bold text-center col-2">Fecha fin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let cobranzaC of cobranzaCobrados; let i = index" (click)="setNegocioCobranzaSelect(cobranzaC)" [class.selected]="negocioCobranzaSelect != null && negocioCobranzaSelect.cobranza.idNegocioCobranza === cobranzaC.cobranza.idNegocioCobranza">
                                                <td class="v-align-middle normal text-left ">{{cobranzaC.negocio.cliente.persona.nombre}} {{cobranzaC.negocio.cliente.persona.apellidoPaterno}}</td>
                                                <td class="v-align-middle normal text-left ">{{cobranzaC.credito.numContrato}}</td>
                                                <td class="v-align-middle normal text-center ">{{cobranzaC.cobranza.cuota.numCuota}} / {{cobranzaC.credito.totalCuotas}}</td>
                                                <td class="v-align-middle normal text-center ">{{cobranzaC.cobranza.cuota.fechaInicioCuota}}</td>
                                                <td class="v-align-middle normal text-center ">{{cobranzaC.cobranza.cuota.fechaFinCuota}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-12 mt-2" *ngIf="negocioCobranzaSelect">
        <app-cuota-cobranza [negocioCobranzaSelect]="negocioCobranzaSelect" [historico]="historico"></app-cuota-cobranza>
    </div>
</div>