import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/classes/constants.class';
import { RutaCobranzaModel } from 'src/app/models/collections/cobranza.model';
import { AuthService } from 'src/app/services/auth.service';
import { CobranzaService } from 'src/app/services/cobranza.service';
import { LoadjsService } from 'src/app/services/loadjs.service';
import UtilsRest from 'src/utils/UtilsRest';
import UtilsSwal from 'src/utils/UtilsSwal';
declare var $: any;

@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.scss']
})
export class HistoricoComponent implements OnInit {

  private token: string = sessionStorage.getItem('token');
  private idSucursal: string = sessionStorage.getItem('idSucursal');

  // VALIDACION ROL
  permisoUsuario: boolean;

  // VARIABLES LOCALES
  ruta: RutaCobranzaModel;
  historico: boolean = true;

  // DATOS EXPORTAR OTROS COMPONENTES
  totalVisitados: number = 0;
  totalNoVisitados: number = 0;
  totalCreditos: number = 0;

  constructor(
    private authService: AuthService, 
    private cobranzaService: CobranzaService,
    private loadjsService: LoadjsService,
  ) {}

  ngOnInit(): void {
    this.permisoUsuario = this.authService.permisosUsuario();
    this.loadjsService.loadScript();
  }

  // METODOOS HTML
  buscarRuta() {

    if ($('#fechaRuta').val() == '') {
      UtilsSwal.lanzarWarning(
        'Campo invalido',
        'Necesitas seleccionar una fecha para visualizar su ruta de cobranza'
      );
      return;
    }

    this.obtenerRuta($('#fechaRuta').val());
  }

  // SERVICIOS DE TERCEROS
  obtenerRuta(fechaRuta: string): Promise<any> {

    return new Promise((resolve, reject) => {
      this.cobranzaService.obtenerRutaCobranza(this.token, this.idSucursal, fechaRuta)
      .subscribe(resp => {
  
        this.ruta = resp.resultado;
        this.totalCreditos = this.ruta.negociosCobranza.length;

        this.totalVisitados = this.ruta.negociosCobranza
          .filter( nc => nc.cobranza.estatus.clave === Constants.CLAVE_NEGOCIO_VISITADO)
          .length;

        this.totalNoVisitados = this.ruta.negociosCobranza
          .filter( nc => nc.cobranza.estatus.clave === Constants.CLAVE_NEGOCIO_NO_VISITADO)
          .length;

        resolve(true);
  
      }, (err) => {
        const status = UtilsRest.validaApiError(err, true, this.authService)
        if(status.codigo == 401) {
          this.authService.guardarToken(status.accessToken, status.refreshToken)
          this.obtenerRuta(fechaRuta);
        }
        this.ruta = null;
        reject();
      });
    });
  }

}
