<div class="full-width" *ngIf="permisosPreautorizar()">
    <div class="card card-default">
        <div class="card-body">
            <div class="row">
                <div class="col-10-flex">
                    <h4 class="text-primary">Preautorizar solicitud de crédito</h4>
                </div>
                <div class="col-2 btn-align-right">
                    <button type="button" (click)="autorizar()" class="btn btn-primary boton float-right">Preautorizar</button>
                </div>
            </div>
        </div>
    </div>
</div>