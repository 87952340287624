import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SolicitudResumidasModel } from 'src/app/models/credits-application/solicitud.model';

@Component({
  selector: 'app-solicitud-validacion-documentos',
  templateUrl: './solicitud-validacion-documentos.component.html',
  styleUrls: ['./solicitud-validacion-documentos.component.scss']
})
export class SolicitudValidacionDocumentosComponent implements OnChanges {

  // PARAMETROS RECIBIDOS
  @Input() solicitudesDocumentos: SolicitudResumidasModel[];

  constructor(
    private router: Router,
  ) { }

  ngOnChanges(): void {}

  // METODOS HTML
  visualizarSolicitud(solicitud: SolicitudResumidasModel) {
    
      const route = '/capturar-solicitud';
      this.router.navigate([route], {
        queryParams: {
          numSolicitud: solicitud.numSolicitud,
          claveEstatusSolicitud: solicitud.estatus.clave,
          claveTipoSolicitud: solicitud.tipoSolicitud.clave
        }
      });
  }

}
