import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CatResponse } from 'src/app/models/catalogues/cat-response.model';
import { Vivienda } from 'src/app/models/person/persona.model';
import { DireccionConCoordenadas } from '../../../../models/person/direccion.model';

export type ViviendaDireccionForms = { 
  vivienda: UntypedFormGroup; 
  direccion: UntypedFormGroup; 
};

@Component({
  selector: 'app-viviendas',
  templateUrl: './viviendas.component.html',
  styleUrls: ['./viviendas.component.scss']
})
export class ViviendasComponent implements OnInit, OnChanges {

  // PARAMETROS RECIBIDOS
  @Input() vivienda: Vivienda;
  @Input() catTipoVivienda: CatResponse[];

  // ELEMENTOS DEL HTML
  @ViewChild('appDirecciones') appDirecciones: any;

  // FORMS
  formViviendas: UntypedFormGroup;

  // PARAMETROS LOCAL
  direccion: DireccionConCoordenadas;
  
  constructor(
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.vivienda) this._crearFormularioVivienda();
    this.direccion = this.vivienda.direccion;
  }

  // HANDLERS
  validarFormHandler(): ViviendaDireccionForms {
    return {
      vivienda: this.formViviendas,
      direccion: this.appDirecciones.validarFormHandler()
    }
  }
  
  // METODOS FORMS
  _crearFormularioVivienda() {
    this.formViviendas = this.fb.group({
      tipoVivienda: [this.vivienda.tipoVivienda.clave, Validators.required],
      escrituras: [this.vivienda.escrituras ? "S" : "N"],
    });
  }

  // VALIDACIONES (DATOS NO VALIDOS)
  get tipoViviendaNoValido() {
    return this.formViviendas.get('tipoVivienda').invalid && this.formViviendas.get('tipoVivienda').touched
  }
}
