<app-sidebar></app-sidebar>
<div class="horizontal-menu horizontal-app-menu dashboard">
    <app-header></app-header>
    <!-- START PAGE-CONTAINER -->
    <div class="page-container pl-5">
        <!-- START PAGE CONTENT WRAPPER -->
        <div class="page-content-wrapper ">
            <!-- START PAGE CONTENT -->
            <div class="content sm-gutter">
                <!-- START CONTAINER FLUID -->
                <div class="container sm-padding-10 p-t-20 p-l-0 p-r-0">
                    <div class="row">
                        <div class="col-lg-6" *ngIf="permisoUsuario">
                            <app-reporte-edo-cuenta></app-reporte-edo-cuenta>
                            <app-reporte-cobranza></app-reporte-cobranza>
                        </div>
                        <div class="col-lg-6">
                            <app-reporte-general></app-reporte-general>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>