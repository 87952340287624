import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketsService {

  constructor() { }

  public getPathSockCobranzaJS(): string {
    return `${environment.url}/wsCobranza/v1`;
  }

  public getPathSockSolicitudesJS(): string {
    return `${environment.url}/wsCobranza/v1`;
  }
}
