// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: "https://dev.ronquillo.tech:9090",
  encryptKey: 'SusvvR2IQ@5q!bqkFmJ#KC*stUCZa1fZ',
  secretKeyEncrypt: 'U2FsdGVkX1/GddswChnux1VDq7GPqT4D25BzGrLs6J+t9VB11endlA7NX71qho3/5vlTpUvCJ9EbiDtck1BYNuv68sm146stRw9Zc7rZsVxTTdPzjlPY/xMxlTsoXXFc',
  userAuth: 'Point-Web',
  googleMapsApiKey: "AIzaSyBDoTCNRZ1O1VSm9cSARr0mxPpEC9a-Jbk",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
