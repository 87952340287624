import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from '@stomp/stompjs';
import { SendSocket } from 'src/app/models/collections/cobranza.model';
import { ActualizarEstatusSolicitud } from 'src/app/models/credits-application/estatus-solicitud.model';
import { SolicitudesService } from 'src/app/services/solicitudes.service';
import { WebsocketsService } from 'src/app/services/websockets.service';
import UtilsSwal from 'src/utils/UtilsSwal';
import * as SockJS from 'sockjs-client';

@Component({
  selector: 'app-actualizar-estatus-solicitud',
  templateUrl: './actualizar-estatus-solicitud.component.html',
  styleUrls: ['./actualizar-estatus-solicitud.component.scss']
})
export class ActualizarEstatusSolicitudComponent implements OnInit, OnChanges {

  private token: string = sessionStorage.getItem('token');
  private idSucursal: string = sessionStorage.getItem('idSucursal');
  private idUsuario: string = sessionStorage.getItem('idUsuario');

  // PARAMETROS RECIBIDOS
  @Input() numSolicitud: string;
  @Input() claveEstatusSolicitud: string;
  @Input() titulo: string;

  // SOCKETS
  private client: Client;

  constructor(
    private solicitudesService: SolicitudesService,
    private websocketsService: WebsocketsService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    // Se realiza conexión a sockets
    this.client = new Client();
    this.client.webSocketFactory = () => {
      return new SockJS(this.websocketsService.getPathSockSolicitudesJS());
    }

    this.client.activate();
  }

  ngOnChanges(): void {}

  // METODOS HTML
  permisoRol() {
    // TODO: Agregar permisos por rol para actualizar estatus de la solicitud
    return true
  }

  actualizarEstatus() {
    this._actualizarEstatusSolicitud();
  }

  // SERVICIOS EXTERNOS
  private _actualizarEstatusSolicitud() {

    const actualizarEstatus: ActualizarEstatusSolicitud = {
      claveEstatusSolicitud: this.claveEstatusSolicitud,
      numSolicitud: this.numSolicitud,
      usuarioUltMod: this.idUsuario
    }

    UtilsSwal.enviarInformacion(
      "¿Estás seguro de actualizar el estatus de esta solicitud?",
      "Actualizando estatus",
      "Actualizar",
      this.solicitudesService.actualizarEstatus(this.token, actualizarEstatus),
      false
    )
    .then(() => this._enviarInfoSocket())
    .then(() => this.router.navigate(['/solicitudes']));
  }

  private _enviarInfoSocket() {
    const sendSocket: SendSocket = {
      idSucursal: this.idSucursal,
    }
    this.client.publish({ 
      destination: '/send/solicitudes', 
      body: JSON.stringify(sendSocket) 
    });
  }

}
