import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SolicitudResumidasModel } from 'src/app/models/credits-application/solicitud.model';

@Component({
  selector: 'app-solicitud-contrato-pagare',
  templateUrl: './solicitud-contrato-pagare.component.html',
  styleUrls: ['./solicitud-contrato-pagare.component.scss']
})
export class SolicitudContratoPagareComponent implements OnChanges {

  // PARAMETROS RECIBIDOS
  @Input() solicitudesFirmaContrato: SolicitudResumidasModel[];

  constructor(
    private router: Router,
  ) { }

  ngOnChanges(): void {}

  // METODOS HTML
  visualizarSolicitud(solicitud: SolicitudResumidasModel) {

    const route = '/autorizar-solicitud';
    this.router.navigate([route], {
      queryParams: {
        numSolicitud: solicitud.numSolicitud
      }
    });
  }

}
