import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { DetalleClienteComponent } from './components/clientes/detalle-cliente/detalle-cliente.component';
import { ModificarClienteComponent } from './components/clientes/modificar-cliente/modificar-cliente.component';
import { CambiaPasswordPageComponent } from './pages/cambia-password/cambia-password.component';
import { ClientesPageComponent } from './pages/clientes/clientes.component';
import { CobranzaComponent } from './pages/cobranza/cobranza.component';
import { CreditosPageComponent } from './pages/creditos-page/creditos-page.component';
import { HomePageComponent } from './pages/home/home.component';
import { LoginPageComponent } from './pages/login/login.component';
import { ReportesPageComponent } from './pages/reportes/reportes.component';
import { SolicitudesPageComponent } from './pages/solicitudes/solicitudes.component';
import { HistoricoComponent } from './pages/historico/historico.component';
import { CotizadorClientesNuevosComponent } from './pages/cotizador/cotizador-clientes-nuevos/cotizador-clientes-nuevos.component';
import { CotizadorRenovacionComponent } from './pages/cotizador/cotizador-renovacion/cotizador-renovacion.component';
import { DatosTemporalesPageComponent } from './pages/datos-temporales-page/datos-temporales-page.component';
import { DetalleSolicitudRenovacionComponent } from './pages/detalle-solicitud-renovacion/detalle-solicitud-renovacion.component';
import { CapturarSolicitudComponent } from './pages/capturar-solicitud/capturar-solicitud.component';
import { CapturarDocumentosComponent } from './pages/capturar-documentos/capturar-documentos.component';
import { DetalleSolicitudNuevaComponent } from './pages/detalle-solicitud-nueva/detalle-solicitud-nueva.component';
import { FirmaContratoPagareComponent } from './pages/firma-contrato-pagare/firma-contrato-pagare.component';
import { AutorizarSolicitudPageComponent } from './pages/autorizar-solicitud-page/autorizar-solicitud-page.component';
import { PaginaWebComponent } from './pages/pagina-web/pagina-web.component';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: 'home', component: HomePageComponent, canActivate: [AuthGuard] },
  { path: 'cambiar-password', component: CambiaPasswordPageComponent, canActivate: [AuthGuard] },
  { path: 'clientes', component: ClientesPageComponent, canActivate: [AuthGuard] },
  { path: 'cobranza', component: CobranzaComponent, canActivate: [AuthGuard] },
  { path: 'creditos', component: CreditosPageComponent, canActivate: [AuthGuard] },
  { path: 'historico', component: HistoricoComponent, canActivate: [AuthGuard] },
  { path: 'solicitudes', component: SolicitudesPageComponent, canActivate: [AuthGuard] },
  { path: 'solicitudes-pagina-web', component: PaginaWebComponent, canActivate: [AuthGuard] },
  { path: 'reportes', component: ReportesPageComponent, canActivate: [AuthGuard] },

  { path: 'detalle-cliente', component: DetalleClienteComponent, canActivate: [AuthGuard] }, 
  { path: 'modificar-cliente', component: ModificarClienteComponent, canActivate: [AuthGuard] },
  { path: 'capturar-documentos', component: CapturarDocumentosComponent, canActivate: [AuthGuard] },
  { path: 'capturar-solicitud', component: CapturarSolicitudComponent, canActivate: [AuthGuard] },
  { path: 'detalle-solicitud-nueva', component: DetalleSolicitudNuevaComponent, canActivate: [AuthGuard] },
  { path: 'detalle-solicitud-renovacion', component: DetalleSolicitudRenovacionComponent, canActivate: [AuthGuard] },
  { path: 'firma-contrato-pagare', component: FirmaContratoPagareComponent, canActivate: [AuthGuard] },
  { path: 'autorizar-solicitud', component: AutorizarSolicitudPageComponent, canActivate: [AuthGuard] },
  { path: 'cotizador-clientes-nuevos', component: CotizadorClientesNuevosComponent, canActivate: [AuthGuard] },
  { path: 'cotizador-renovacion', component: CotizadorRenovacionComponent, canActivate: [AuthGuard] },
  { path: 'datos-temporales', component: DatosTemporalesPageComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'home' }, 
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, {useHash: true, onSameUrlNavigation: 'reload'},
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
