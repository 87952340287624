<div class="full-width">
    <div class="card card-default">
        <div class="card-body">
            <div class="row">
                <div class="full-width d-flex">
                    <h4 class="text-primary">Autorizar solicitud de crédito</h4>
                    <div class="btn-align-right">
                        <button type="button" (click)="obtenerDatosBancarios()" class="btn btn-secondary btn-margin boton float-right" *ngIf="permisoSuperAdmin">Datos bancarios</button>
                        <button type="button" (click)="obtenerContratoFirmado()" class="btn btn-secondary btn-margin boton float-right">Contrato firmado</button>
                        <button type="button" (click)="autorizar()" class="btn btn-primary btn-margin boton float-right" *ngIf="permisoSuperAdmin">Autorizar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>