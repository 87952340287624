import { Pipe } from "@angular/core";

@Pipe({
    name: "datobancario"
})
export class DatoBancarioPipe {
    transform(rawNum) {
        
        let newStr = "";
        let i = 0;

        if(rawNum) {
            for (; i < Math.floor(rawNum.length / 4); i++) {
                newStr = newStr + rawNum.substr(i * 4, 4) + "-";
            }
    
            return newStr + rawNum.substr(i * 4);
        } else {
            return newStr;
        }       
    }
}