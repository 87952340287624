import { Direccion } from "./direccion.model";
import { Telefono } from "./telefono.model";

export class PersonaRequest {
    numSolicitud: string;
    usuarioUltMod: string;
    persona: PersonaCredito;
}

export class PersonaCredito {
    idPersona?: number;
    nombre: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    sexo: string;
    email: string;
    curp: string;
    rfc: string;
    numIdentificacion: string;
    nacionalidad: string;
    claveEstadoCivil: string;
    usuarioUltMod: string;
    nacimiento: Nacimiento;
    telefonos: Telefono[];
    viviendas: Vivienda[];
}

export class Nacimiento {
    fecha: string;
    lugar: string;
    pais: string;
}

export class Vivienda {
    claveTipoVivienda: string;
    escrituras: string;
    direccion: Direccion;
}