import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Constants } from 'src/app/classes/constants.class';
import { CatResponse } from 'src/app/models/catalogues/cat-response.model';
import { Direccion } from 'src/app/models/person/direccion.model';
import { Nacimiento, PersonaCredito, Vivienda } from 'src/app/models/person/persona-request.model';
import { ReferenciaModel } from 'src/app/models/credits-application/referencia.model';
import { Telefono } from 'src/app/models/person/telefono.model';
import { AuthService } from 'src/app/services/auth.service';
import { CreditoService } from 'src/app/services/credito.service';
import { LoadjsService } from 'src/app/services/loadjs.service';
import { PersonaService } from 'src/app/services/persona.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-referencias',
  templateUrl: './referencias.component.html',
  styleUrls: ['./referencias.component.scss']
})
export class ReferenciasComponent implements OnInit {

  private idUsuario: string = sessionStorage.getItem('idUsuario');
  private token: string = sessionStorage.getItem('token');

  @Input() numCliente: string;
  @Input() idCliente: string;
  @Input() numNegocio: string;
  @Input() idNegocio: string;
  @Input() numSolicitud: string;
  formPersona: UntypedFormGroup;
  catEstadoCivil: CatResponse[] = [];
  catTipoVivienda: CatResponse[] = [];
  showEscrituras = false;
  claveTel = Constants.CLAVE_TELEFONO;
  claveCel = Constants.CLAVE_CELULAR;
  numReferencias = 1;

  constructor(private personaService: PersonaService, 
              private authService: AuthService, 
              private creditoService: CreditoService,
              private fb: UntypedFormBuilder, 
              private loadjsService: LoadjsService, 
              private router: Router) { 
    this._crearFormularioPersona();
  }

  ngOnInit(): void {
    this.loadjsService.loadScript();
    this._getEstadoCivil();
    this._getTipoVivienda();
  }

  get nombreNoValido() {
    return this.formPersona.get('nombre').invalid && this.formPersona.get('nombre').touched
  }

  get apellidoPaternoNoValido() {
    return this.formPersona.get('apellidoPaterno').invalid && this.formPersona.get('apellidoPaterno').touched
  }

  get lugarNacimientoNoValido() {
    return this.formPersona.get('lugarNacimiento').invalid && this.formPersona.get('lugarNacimiento').touched
  }

  get paisNacimientoNoValido() {
    return this.formPersona.get('paisNacimiento').invalid && this.formPersona.get('paisNacimiento').touched
  }

  get nacionalidadNoValido() {
    return this.formPersona.get('nacionalidad').invalid && this.formPersona.get('nacionalidad').touched
  }

  get curpNoValido() {
    return this.formPersona.get('curp').invalid && this.formPersona.get('curp').touched
  }

  get estadoCivilNoValido() {
    return this.formPersona.get('estadoCivil').invalid && this.formPersona.get('estadoCivil').touched
  }

  get numIdentificacionNoValido() {
    return this.formPersona.get('numIdentificacion').invalid && this.formPersona.get('numIdentificacion').touched
  }

  get tipoViviendaNoValido() {
    return this.formPersona.get('tipoVivienda').invalid && this.formPersona.get('tipoVivienda').touched
  }

  get calleNoValido() {
    return this.formPersona.get('calle').invalid && this.formPersona.get('calle').touched
  }

  get numExtNoValido() {
    return this.formPersona.get('numExt').invalid && this.formPersona.get('numExt').touched
  }

  get coloniaNoValido() {
    return this.formPersona.get('colonia').invalid && this.formPersona.get('colonia').touched
  }

  get municipioNoValido() {
    return this.formPersona.get('municipio').invalid && this.formPersona.get('municipio').touched
  }

  get estadoNoValido() {
    return this.formPersona.get('estado').invalid && this.formPersona.get('estado').touched
  }

  get paisNoValido() {
    return this.formPersona.get('pais').invalid && this.formPersona.get('pais').touched
  }

  get cpNoValido() {
    return this.formPersona.get('cp').invalid && this.formPersona.get('cp').touched
  }

  get correoNoValido() {
    return this.formPersona.get('correo').invalid && this.formPersona.get('correo').touched;
  }

  get telefonos() {
    return this.formPersona.get('telefonos') as UntypedFormArray;
  }

  get tipoVivienda() {
    return this.formPersona.get('tipoVivienda');
  }

  get estadoCivil() {
    return this.formPersona.get('estadoCivil');
  }

  agregarTelefono() {
    const telefonosForm = this.fb.group({
      telefono: ['', Validators.required],
      tipoTelefono: [Constants.CLAVE_CELULAR]
    });

    this.telefonos.push(telefonosForm);
  }

  eliminarTelefono(index: number) {
    this.telefonos.removeAt(index);
  }

  cambiarEstadoCivil(e) {
    this.estadoCivil.setValue(e.target.value, {
      onlySelf: true
    });
  }

  cambiarTipoVivienda(e) {
    this.tipoVivienda.setValue(e.target.value, {
      onlySelf: true
    });

    if (JSON.stringify(e.target.value).includes(Constants.CLAVE_PROPIA)) {
      this.showEscrituras = true;
    } else {
      this.showEscrituras = false;
    }
  }

  guardarReferencia() {
    if (this.formPersona.invalid) {
      return Object.values(this.formPersona.controls).forEach( control => {
        control.markAsTouched();
      });
    }

    let persona = new PersonaCredito();
    let nacimiento = new Nacimiento();
    let vivienda = new Vivienda();
    let direccion = new Direccion();
    let viviendas: Vivienda[] = [];
    let telefonos: Telefono[] = [];
    let referencia = new ReferenciaModel();
    let personas: PersonaCredito[] = [];

    nacimiento.fecha = $('#datepicker-component').val();
    nacimiento.lugar = this.capitalizeEachWord(this.formPersona.get('lugarNacimiento').value);
    nacimiento.pais = this.capitalizeEachWord(this.formPersona.get('pais').value);

    direccion.calle = this.capitalizeEachWord(this.formPersona.get('calle').value);
    direccion.colonia = this.capitalizeEachWord(this.formPersona.get('colonia').value);
    direccion.cp = this.formPersona.get('cp').value;
    direccion.estado = this.capitalizeEachWord(this.formPersona.get('estado').value);
    direccion.latitud = null;
    direccion.longitud = null;
    direccion.municipio = this.capitalizeEachWord(this.formPersona.get('municipio').value);
    direccion.numExt = this.formPersona.get('numExt').value.toUpperCase();
    direccion.numInt = this.formPersona.get('numInt').value?.toUpperCase();
    direccion.pais = this.capitalizeEachWord(this.formPersona.get('pais').value);

    vivienda.claveTipoVivienda = this.tipoVivienda.value.split(": ")[1];
    vivienda.escrituras = this.formPersona.get('escrituras').value == 'S' ? "true" : "false";
    vivienda.direccion = direccion;

    viviendas.push(vivienda);

    this.formPersona.get('telefonos').value.forEach(telefono => {
      let tel = new Telefono();

      tel.telefono = telefono['telefono'];
      tel.claveTipoTelefono = telefono['tipoTelefono'];

      telefonos.push(tel);
    });

    persona.apellidoMaterno = this.capitalizeEachWord(this.formPersona.get('apellidoMaterno').value);
    persona.apellidoPaterno = this.capitalizeEachWord(this.formPersona.get('apellidoPaterno').value);
    persona.claveEstadoCivil = this.estadoCivil.value.split(": ")[1];
    persona.curp = this.formPersona.get('curp').value.toUpperCase();
    persona.email = this.formPersona.get('correo').value?.toLowerCase();
    persona.nacimiento = nacimiento;
    persona.nacionalidad = this.capitalizeEachWord(this.formPersona.get('nacionalidad').value);
    persona.nombre = this.capitalizeEachWord(this.formPersona.get('nombre').value);
    persona.numIdentificacion = this.formPersona.get('numIdentificacion').value;
    persona.rfc = this.formPersona.get('rfc').value?.toUpperCase();
    persona.sexo = this.formPersona.get('genero').value;
    persona.telefonos = telefonos;
    persona.viviendas = viviendas;
    persona.usuarioUltMod = this.idUsuario;

    personas.push(persona);

    referencia.idNegocio = +this.idNegocio;
    referencia.numNegocio = this.numNegocio;
    referencia.idCliente = +this.idCliente;
    referencia.numCliente = this.numCliente;
    referencia.numSolicitud = this.numSolicitud;
    referencia.usuarioUltMod = this.idUsuario;
    referencia.referencias = personas;

    Swal.fire({
      title: '¿Estás seguro de guardar los datos de la referencia?',
      text: 'Esta acción no se podrá revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8973D9',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Guardar',
      heightAuto: false
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Guardando...'
        });
        Swal.showLoading();

        this._guardarReferencia(referencia);
      }
    });
  }

  private capitalizeEachWord(str) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  _crearFormularioPersona() {
    this.formPersona = this.fb.group({
      nombre: ['', Validators.required],
      apellidoPaterno: ['', Validators.required],
      apellidoMaterno: [''],
      lugarNacimiento: ['', Validators.required],
      paisNacimiento: ['México', Validators.required],
      nacionalidad: ['Mexicana', Validators.required],
      rfc: [null],
      curp: ['', [Validators.required, Validators.maxLength(18)]],
      genero: ['M'],
      estadoCivil: ['', Validators.required],
      numIdentificacion: ['', [Validators.required]],
      calle: ['', [Validators.required]],
      numExt: ['', [Validators.required]],
      numInt: [null],
      colonia: ['', [Validators.required]],
      municipio: ['', [Validators.required]],
      estado: ['', [Validators.required]],
      pais: ['México', [Validators.required]],
      cp: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      tipoVivienda: ['', Validators.required],
      escrituras: ['N'],
      correo: [null, [Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      telefonos: this.fb.array([this.fb.group({
        telefono: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        tipoTelefono: [Constants.CLAVE_CELULAR]
      })])
    });
  }

  private _guardarReferencia(referencia: ReferenciaModel) {
    this.creditoService.guardarReferencia(this.token, referencia)
    .subscribe(resp => {
      this.numReferencias++;
      Swal.close();

      if (this.numReferencias < 4) {
        Swal.fire({
          title: 'La referencia se ha guardado con éxito',
          text: '¿Deseas agregar otra referencia?',
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#8973D9',
          cancelButtonText: 'Salir',
          confirmButtonText: 'Agregar',
          heightAuto: false
        }).then((result) => {
           if (result.isConfirmed) {
            this.formPersona.reset();
          } else {
            this.router.navigate(['/creditos']);
          }
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'La referencia se ha guardado con éxito',
          text: 'Se han alcanzado el limite de referencias',
          showConfirmButton: false,
          timer: 3000,
          heightAuto: false
        });
        this.router.navigate(['/creditos']);
      }
    }, err => {
      Swal.close();
      if (err.status == 401) {
        this.authService.refrescarToken().subscribe((resp: any) => {
          this.token = resp.access_token;
          this.authService.guardarToken(resp.access_token, resp.refresh_token);
          this._guardarReferencia(referencia);
        }, err => {
          Swal.fire({
            icon: 'info',
            title: 'Ha pasado demasiado tiempo',
            text: 'Por seguridad es necesario que vuelva a iniciar sesión',
            heightAuto: false
          });
          this.authService.logout();
          return;
        });
      } else {
        this._validarEstatusError(err, 'guardarReferencia');
      }
    });
  }

  private _getEstadoCivil() {
    this.personaService.obtenerEstadoCivil(this.token)
    .subscribe(resp => {
      this.catEstadoCivil = resp.resultados;
    }, (err) => {
      if (err.status == 401) {
        this.authService.refrescarToken().subscribe((resp: any) => {
          this.token = resp.access_token;
          this.authService.guardarToken(resp.access_token, resp.refresh_token);
          this._getEstadoCivil();
        }, err => {
          Swal.fire({
            icon: 'info',
            title: 'Ha pasado demasiado tiempo',
            text: 'Por seguridad es necesario que vuelva a iniciar sesión',
            heightAuto: false
          });
          this.authService.logout();
          return;
        });
      } else {
        this._validarEstatusError(err, 'catEstadoCivil');
      }
    });
  }

  private _getTipoVivienda() {
    this.personaService.obtenerTipoVivienda(this.token)
    .subscribe(resp => {
      this.catTipoVivienda = resp.resultados;
    }, (err) => {
      if (err.status == 401) {
        this.authService.refrescarToken().subscribe((resp: any) => {
          this.token = resp.access_token;
          this.authService.guardarToken(resp.access_token, resp.refresh_token);
          this._getTipoVivienda();
        }, err => {
          Swal.fire({
            icon: 'info',
            title: 'Ha pasado demasiado tiempo',
            text: 'Por seguridad es necesario que vuelva a iniciar sesión',
            heightAuto: false
          });
          this.authService.logout();
          return;
        });
      } else {
        this._validarEstatusError(err, 'catTipoVivienda');
      }
    });
  }

  private _validarEstatusError(err, origen) {
    if (err.status == 400) {
      this._lanzarError('Error de comunicación', err.error.detalles[0].mensaje.split(".", 1));
    } else if (err.status == 403) {
      this._lanzarError('Acceso denegado', err.error.detalles[0].mensaje);
    } else if (err.status == 404) {
      if (origen == 'catEstadoCivil') {
        this.catEstadoCivil = [];
      }
      if (origen == 'catTipoVivienda') {
        this.catTipoVivienda = [];
      }
    } else if (err.status == 500 || err.status == 503) {
      this._lanzarError('Error de comunicación', err.error.detalles[0].mensaje);
    } else {
      this._lanzarError('Error desconocido', 'Lo sentimos, ocurrio un error al intentar procesar la petición, si el problema persiste reportalo a soporte@grupofragy.com');
    }
  }

  private _lanzarError(titulo: string, texto: string) {
    Swal.fire({
      icon: 'error',
      title: titulo,
      text: texto,
      heightAuto: false
    });
  }

}
