import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-datos-temporales-page',
  templateUrl: './datos-temporales-page.component.html',
  styleUrls: ['./datos-temporales-page.component.scss']
})
export class DatosTemporalesPageComponent implements OnInit {

  // VALIDACION ROL
  permisoUsuario: boolean;

  // PARAMETROS LOCALES
  numSolicitud: string;

  constructor(
    private authService: AuthService, 
    private activatedRoute: ActivatedRoute, 
  ) { }

  ngOnInit(): void {

    this.permisoUsuario = this.authService.permisosUsuario();

    this.activatedRoute.queryParams.subscribe(
      params => {
        this.numSolicitud = params['numSolicitud'];
      }
    );
  }

}
