import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { InteresesModel } from 'src/app/models/credits/cuota.model';

@Component({
  selector: 'app-intereses',
  templateUrl: './intereses.component.html',
  styleUrls: ['./intereses.component.scss']
})
export class InteresesComponent implements OnDestroy, OnChanges {

  // PARAMETROS RECIBIDOS
  @Input() intereses: InteresesModel[];

  constructor() { }

  ngOnDestroy(): void {
    this.intereses = []
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes.intereses.currentValue) {
      this.intereses = null;
    } else {
      this.intereses = changes.intereses.currentValue
    }
  }

}
