import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from 'src/app/classes/constants.class';
import { CatResponse } from 'src/app/models/catalogues/cat-response.model';
import { ClienteInfoCompleta } from 'src/app/models/clients/cliente.model';
import UtilsCliente from 'src/app/pages/clientes/UtilsCliente';
import { ClienteService } from 'src/app/services/cliente.service';
import { CreditoService } from 'src/app/services/credito.service';
import Utils from 'src/utils/Utils';
import UtilsSwal from 'src/utils/UtilsSwal';
import { personaEmpty } from '../persona-form/persona-form.component';
import { ClienteRequest } from '../../../models/clients/cliente-request.model';

@Component({
  selector: 'app-clientes-form',
  templateUrl: './clientes-form.component.html',
  styleUrls: ['./clientes-form.component.scss'],
})
export class ClientesFormComponent implements OnInit, OnChanges {

  private token: string = sessionStorage.getItem('token');
  private idUsuario: string = sessionStorage.getItem('idUsuario');

  //PARAMETROS RECIBIDOS
  @Input() numSolicitud: string | null;
  @Input() cliente: ClienteInfoCompleta;
  @Input() catMediosConocimiento: CatResponse[];
  @Input() catNivelesEscolares: CatResponse[];
  @Input() catOcupaciones: CatResponse[];
  @Input() catFuentesIngresos: CatResponse[];

  // PARAMETROS LOCALES
  showFuenteIngresos = false;

  // FORMS
  formCliente: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private clienteService: ClienteService,
    private creditoService: CreditoService,
  ) { }

  ngOnInit(): void {}

  ngOnChanges(_changes: SimpleChanges): void {
    if(this.cliente) {
      this._crearFormularioClientes();
      this.cambiarFuenteIngresos();
    }
  }

  // METODOS HTML
  guardarInformacion() {
    (this.numSolicitud && !this.cliente.idCliente)
      ? this.guardarCliente()
      : this.actualizarCliente();
  }

  // METODOS FORMS
  _crearFormularioClientes() {
    this.formCliente = this.fb.group({
      idCliente: [this.cliente.idCliente],
      ocupacion: [this.cliente.ocupacion.clave, Validators.required],
      nivelEscolar: [this.cliente.nivelEscolar.clave, Validators.required],
      fuenteIngresos: [this.cliente.fuenteIngreso.clave, Validators.required],
      depEconomicos: [this.cliente.numDependientesEconomicos ?? 0, [Validators.required, Validators.min(0), Validators.maxLength(2), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      otraFinanciera: [this.cliente.entidadFinancieraAnterior],
      cantidadFuenteIngresos: [Utils.b64DecodeUnicode(this.cliente.fuenteIngreso.ingresoMensual) ?? 0,[Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      medioConocimiento: [this.cliente.medioConocimiento.clave, Validators.required]
    });
  }

  // GUARDAR INFORMACIÓN
  guardarCliente() {

    const cliente = this.mapearCliente();
    if(cliente) {

      const clienteCredito: ClienteRequest = {
        cliente: cliente,
        numSolicitud: this.numSolicitud,
        usuarioUltMod: this.idUsuario
      }
      
      UtilsSwal.enviarInformacion(
        "¿Estás seguro de guardar los datos del cliente?",
        "Guardando información",
        "Guardar",
        this.creditoService.guardarCliente(this.token, clienteCredito),
        true
      );
    }

  }
  
  actualizarCliente() {

    const cliente = this.mapearCliente(this.cliente.idCliente.toString());
    if(cliente) {      
      UtilsSwal.enviarInformacion(
        "¿Estás seguro de actualizar los datos del cliente?",
        "Actualizando información",
        "Actualizar",
        this.clienteService.guardarCliente(this.token, cliente),
        true
      );
    }

  }

  // METODOS PRIVADOS
  mapearCliente(idCliente: string = null) {
    if (this.formCliente.invalid) {
      return Utils.validateForms(this.formCliente)
    }

    return UtilsCliente.mapFormtoDto(
      this.formCliente, 
      this.cliente.persona.idPersona.toString(), 
      this.idUsuario, 
      idCliente
    )
  }

  cambiarFuenteIngresos() {

    if (this.formCliente.get('fuenteIngresos').value == Constants.CLAVE_TIPO_FUENTE_INGRESO_NINGUNO || 
      this.formCliente.get('fuenteIngresos').value == Constants.CLAVE_TIPO_FUENTE_INGRESO_SIN_DEFINIR ||
      this.formCliente.get('fuenteIngresos').value == ''
    ) {
      this.showFuenteIngresos = false;
    } else {
      this.showFuenteIngresos = true;
    }
  }

  // VALIDACIONES (DATOS NO VALIDOS)
  get nivelEscolarNoValido() {
    return this.formCliente.get('nivelEscolar').invalid && this.formCliente.get('nivelEscolar').touched
  }

  get medioConocimientoNoValido() {
    return this.formCliente.get('medioConocimiento').invalid && this.formCliente.get('medioConocimiento').touched
  }

  get ocupacionNoValido() {
    return this.formCliente.get('ocupacion').invalid && this.formCliente.get('ocupacion').touched
  }

  get depEconomicosNoValido() {
    return this.formCliente.get('depEconomicos').invalid && this.formCliente.get('depEconomicos').touched
  }

  get fuenteIngresosNoValido() {
    return this.formCliente.get('fuenteIngresos').invalid && this.formCliente.get('fuenteIngresos').touched
  }

  get cantidadFuenteIngresosNoValido() {
    return this.formCliente.get('cantidadFuenteIngresos').invalid && this.formCliente.get('cantidadFuenteIngresos').touched
  }

  // DATOS FORM
  get nivelEscolar() {
    return this.formCliente.get('nivelEscolar');
  }

  get medioConocimiento() {
    return this.formCliente.get('medioConocimiento');
  }

  get ocupacion() {
    return this.formCliente.get('ocupacion');
  }

  get fuenteIngresos() {
    return this.formCliente.get('fuenteIngresos');
  }

}

export const clienteEmpty:ClienteInfoCompleta = {
  idCliente: null,
  numCliente: '',
  usuario: null,
  numDependientesEconomicos: null,
  entidadFinancieraAnterior: '',
  ocupacion: {
    clave: '',
    descripcion: ''
  },
  nivelEscolar: {
    clave: '',
    descripcion: ''
  },
  fuenteIngreso: {
    clave: '',
    descripcion: '',
    ingresoMensual: null
  },
  medioConocimiento: {
    clave: '',
    descripcion: ''
  },
  persona: personaEmpty
}
