import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Constants } from '../../../classes/constants.class';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MapMarkerProps } from 'src/app/models/maps/map-marker.model';

@Component({
  selector: 'app-collector-map',
  templateUrl: './collector-map.component.html',
  styleUrls: ['./collector-map.component.scss']
})
export class CollectorMapComponent implements OnInit, OnChanges {
  
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  @Input() markers: MapMarkerProps[] = [];

  // KEY
  key = environment.googleMapsApiKey;

  // GOOGLE MAPS OPTIONS
  apiLoaded: Observable<boolean>;
  options: google.maps.MapOptions = {
    center: {
      lat: Constants.LATITUD_POINT, 
      lng: Constants.LONGITUD_POINT
    },
    zoom: 14,
    streetViewControl: false,
    mapTypeControl: false,
    draggable: true
  };

  // VARIABLES LOCALES
  clientName

  constructor(
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.loadMap();
  }

  loadMap() {
    this.apiLoaded = this.httpClient
      .jsonp(`https://maps.googleapis.com/maps/api/js?key=${this.key}`, 'callback')
      .pipe(
        map(() => true),
        catchError((error) => {
          console.error("No se pudo cargar el mapa", error)
          return of(false)
        }),
      );
  }

  openInfoWindow(marker: MapMarker, props: MapMarkerProps) {
    this.clientName = props.clientName;
    this.infoWindow.open(marker);
  }

  ngOnChanges(changes: SimpleChanges): void {}
}
