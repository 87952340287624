import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClienteService } from 'src/app/services/cliente.service';
import Utils from 'src/utils/Utils';
import UtilsSwal from 'src/utils/UtilsSwal';
import { NegocioInfoCompleta, NegocioRequest } from '../../../models/business/negocio-request.model';
import { clienteEmpty } from '../clientes-form/clientes-form.component';
import { DireccionConCoordenadas } from '../../../models/person/direccion.model';
import { Telefono } from 'src/app/models/person/telefono.model';
import { direccionEmpty } from '../datos-generales/direcciones/direcciones.component';
import { Router } from '@angular/router';
import UtilsNegocio from './UtilsNegocio';
import { IdsCliente } from 'src/app/models/clients/cliente-request.model';
import { CreditoService } from 'src/app/services/credito.service';

@Component({
  selector: 'app-negocios-form',
  templateUrl: './negocios-form.component.html',
  styleUrls: ['./negocios-form.component.scss'],
})
export class NegociosFormComponent implements OnInit, OnChanges {

  private token: string = sessionStorage.getItem('token');
  private idUsuario: string = sessionStorage.getItem('idUsuario');
  private idSucursal: string = sessionStorage.getItem('idSucursal');

  // PARAMETROS RECIBIDOS
  @Input() negocio: NegocioInfoCompleta;
  @Input() numSolicitud: string | null;

  // ELEMENTOS DEL HTML
  @ViewChild('appDirecciones') appDirecciones: any;
  @ViewChild('appTelefonos') appTelefonos: any;

  // PARAMETROS LOCAL
  direccion: DireccionConCoordenadas;
  telefonos: Telefono[];
  sameDir: boolean = false;
  sameTel: boolean = false;
  totalIngreso: number = 0;
  totalGasto: number = 0;

  // FORMS
  formNegocio: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private clienteService: ClienteService,
    private creditoService: CreditoService,
  ) {}

  ngOnInit(): void {
    this._crearListenerIngresos();
    this._crearListenerGastos();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.negocio) {
      this._crearFormularioNegocio();
      this.direccion = this.negocio.direccion;
      this.telefonos = this.negocio.telefonos;
    }
  }
  
  // METODOS HTML
  guardarInformacion() {
    (this.numSolicitud && !this.negocio.idNegocio)
      ? this.guardarNegocio()
      : this.actualizarNegocio();
  }

  onCheckboxSelect(event) {
    // Check de dirección de la persona
    if(event.target.id === 'check-dir') {
      if (event.target.checked === true) {
        this.direccion = this.negocio.cliente.persona.viviendas[0].direccion;
      }
      if (event.target.checked === false) {
        this.direccion = direccionEmpty;
      }
    }

    // Check de dirección de los telefonos
    if(event.target.id === 'check-tel') {
      if (event.target.checked === true) {
        this.telefonos = this.negocio.cliente.persona.telefonos;
      }
      if (event.target.checked === false) {
        this.telefonos = []
      }
    }
  }

  // METODOS FORMS
  _crearFormularioNegocio() {

    this.totalIngreso = Number(Utils.b64DecodeUnicode(this.negocio.capacidadPago.ingresoMensual));
    this.totalGasto = Number(Utils.b64DecodeUnicode(this.negocio.capacidadPago.gastoMensual));

    this.formNegocio = this.fb.group({
      nombreNegocio: [this.negocio.nombre, Validators.required],
      actividadEconomica: [this.negocio.actividadEconomica],
      antiguedad: [this.negocio.antiguedad, [Validators.required, Validators.maxLength(3), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      ingresosMensuales: ['0', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]], // TODO: Validar si este dato se debe guardar en base de datos
      apoyoFamiliar: ['0', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      otroIngreso: ['0', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      alimento: ['0', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      servicioBasico: ['0', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      rentaPago: ['0', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      materiaPrima: ['0', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      otroPrestamo: ['0', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      otroGasto: ['0', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]], 
      totalIngresoMensual: [this.totalIngreso, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]], 
      totalGastoMensual: [this.totalGasto, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]], 
      beneficiario: [
        {
          value: this.negocio.datosBancarios.beneficiario 
            ? Utils.b64DecodeUnicode(this.negocio.datosBancarios.beneficiario)
            : `${this.negocio.cliente.persona.nombre} ${this.negocio.cliente.persona.apellidoPaterno}`, 
          disabled: true
        }, 
        Validators.required
      ],
      banco: [
        Utils.b64DecodeUnicode(this.negocio.datosBancarios.banco), 
        [Validators.required]
      ],
      numeroCuenta: [
        Utils.b64DecodeUnicode(this.negocio.datosBancarios.cuenta), 
        [Validators.pattern(/^-?\d*$/), Validators.minLength(8), Validators.maxLength(15), Validators.required]
      ],
      numeroTarjeta: [
        Utils.b64DecodeUnicode(this.negocio.datosBancarios.numTarjeta), 
        [Validators.pattern(/^-?\d*$/), Validators.minLength(16), Validators.maxLength(16)]
      ],
      cuentaClabe: [
        Utils.b64DecodeUnicode(this.negocio.datosBancarios.cuentaClabe), 
        [Validators.pattern(/^-?\d*$/), Validators.minLength(18), Validators.maxLength(18), Validators.required]
      ]
    });
  }

  // LISTENERS
  _crearListenerIngresos() {
    this.ingresosMensuales.valueChanges.subscribe(() => this._sumarTotalIngresos());
    this.apoyoFamiliar.valueChanges.subscribe(() => this._sumarTotalIngresos());
    this.otroIngreso.valueChanges.subscribe(() => this._sumarTotalIngresos());
  }

  _crearListenerGastos() {
    this.alimento.valueChanges.subscribe(() => this._sumarTotalGastos());
    this.servicioBasico.valueChanges.subscribe(() => this._sumarTotalGastos());
    this.rentaPago.valueChanges.subscribe(() => this._sumarTotalGastos());
    this.materiaPrima.valueChanges.subscribe(() => this._sumarTotalGastos());
    this.otroPrestamo.valueChanges.subscribe(() => this._sumarTotalGastos());
    this.otroGasto.valueChanges.subscribe(() => this._sumarTotalGastos());
  }

  _sumarTotalIngresos() {
    this.totalIngreso = Number(this.ingresosMensuales.value) + Number(this.apoyoFamiliar.value) + Number(this.otroIngreso.value);
    this.totalIngresoMensual.setValue(this.totalIngreso)
  }

  _sumarTotalGastos() {
    this.totalGasto = Number(this.alimento.value) + Number(this.servicioBasico.value) + Number(this.rentaPago.value)
        + Number(this.materiaPrima.value) + Number(this.otroPrestamo.value) + Number(this.otroGasto.value);
    this.totalGastoMensual.setValue(this.totalGasto)
  }

  // GUARDAR INFORMACIÓN
  guardarNegocio() {

    const negocio = this.mapearNegocio();
    if(negocio) {

      const negocioRequest: NegocioRequest =  {
        numSolicitud: this.numSolicitud,
        negocio: negocio,
        usuarioUltMod: this.idUsuario
      }

      UtilsSwal.enviarInformacion(
        "¿Estás seguro de guardar los datos del negocio?",
        "Guardando información",
        "Guardar",
        this.creditoService.guardarNegocio(this.token, negocioRequest),
        true
      );
    }

  }

  actualizarNegocio() {

    const negocio = this.mapearNegocio();
    if(negocio) {

      UtilsSwal.enviarInformacion(
        "¿Estás seguro de actualizar los datos del negocio?",
        "Actualizando información",
        "Actualizar",
        this.clienteService.guardarNegocio(this.token, negocio),
        true
      );
    }
  }

  // METODOS PRIVADOS
  mapearNegocio() {
    if (this.formNegocio.invalid) {
      return Utils.validateForms(this.formNegocio)
    }

    // Se manda a llamar el método validarFormHandler del componente direcciones
    // Para obtener el form de direcciones
    const formDireccion = this.appDirecciones.validarFormHandler();
    if (formDireccion.invalid) {
      return Utils.validateForms(formDireccion)
    }

    // Se manda a llamar el método validarFormHandler del componente teléfonos
    // Para obtener el form de teléfonos
    const formTelefonos = this.appTelefonos.validarFormHandler();
    if (formTelefonos.invalid) {
      return Utils.validateForms(formTelefonos)
    }

    const cliente: IdsCliente = {
      idCliente: this.negocio.cliente.idCliente.toString(),
      numCliente: this.negocio.cliente.numCliente,
    } 

    return UtilsNegocio.mapFormtoDto(
      this.formNegocio,
      formDireccion,
      formTelefonos,
      cliente,
      this.idSucursal,
      this.idUsuario,
      this.negocio.idNegocio
    );
  }


  // DATOS FORM
  get totalIngresoMensual() {
    return this.formNegocio.get('totalIngresoMensual');
  }

  get totalGastoMensual() {
    return this.formNegocio.get('totalGastoMensual');
  }

  get ingresosMensuales() {
    return this.formNegocio.get('ingresosMensuales');
  }

  get apoyoFamiliar() {
    return this.formNegocio.get('apoyoFamiliar');
  }

  get otroIngreso() {
    return this.formNegocio.get('otroIngreso');
  }

  get alimento() {
    return this.formNegocio.get('alimento');
  }

  get servicioBasico() {
    return this.formNegocio.get('servicioBasico');
  }

  get rentaPago() {
    return this.formNegocio.get('rentaPago');
  }

  get materiaPrima() {
    return this.formNegocio.get('materiaPrima');
  }

  get otroPrestamo() {
    return this.formNegocio.get('otroPrestamo');
  }

  get otroGasto() {
    return this.formNegocio.get('otroGasto');
  }

  // VALIDACIONES FORM
  get rentaPagoNoValido() {
    return this.formNegocio.get('rentaPago').invalid && this.formNegocio.get('rentaPago').touched
  }

  get materiaPrimaNoValido() {
    return this.formNegocio.get('materiaPrima').invalid && this.formNegocio.get('materiaPrima').touched
  }

  get servicioBasicoNoValido() {
    return this.formNegocio.get('servicioBasico').invalid && this.formNegocio.get('servicioBasico').touched
  }

  get alimentoNoValido() {
    return this.formNegocio.get('alimento').invalid && this.formNegocio.get('alimento').touched
  }

  get otroIngresoNoValido() {
    return this.formNegocio.get('otroIngreso').invalid && this.formNegocio.get('otroIngreso').touched
  }

  get apoyoFamiliarNoValido() {
    return this.formNegocio.get('apoyoFamiliar').invalid && this.formNegocio.get('apoyoFamiliar').touched
  }

  get ingresosMensualesNoValido() {
    return this.formNegocio.get('ingresosMensuales').invalid && this.formNegocio.get('ingresosMensuales').touched
  }

  get totalIngresoMensualNoValido() {
    return this.formNegocio.get('totalIngresoMensual').invalid && this.formNegocio.get('totalIngresoMensual').touched
  }

  get totalGastoMensualNoValido() {
    return this.formNegocio.get('totalGastoMensual').invalid && this.formNegocio.get('totalGastoMensual').touched
  }

  get antiguedadNoValido() {
    return this.formNegocio.get('antiguedad').invalid && this.formNegocio.get('antiguedad').touched;
  }

  get nombreNegocioNoValido() {
    return this.formNegocio.get('nombreNegocio').invalid && this.formNegocio.get('nombreNegocio').touched;
  }

  get cuentaClabeNoValido() {
    return this.formNegocio.get('cuentaClabe').invalid && this.formNegocio.get('cuentaClabe').touched
  }

  get numeroTarjetaNoValido() {
    return this.formNegocio.get('numeroTarjeta').invalid && this.formNegocio.get('numeroTarjeta').touched
  }

  get numeroCuentaNoValido() {
    return this.formNegocio.get('numeroCuenta').invalid && this.formNegocio.get('numeroCuenta').touched
  }

  get bancoNoValido() {
    return this.formNegocio.get('banco').invalid && this.formNegocio.get('banco').touched
  }

  get beneficiarioNoValido() {
    return this.formNegocio.get('beneficiario').invalid && this.formNegocio.get('beneficiario').touched
  }

  get otroGastoNoValido() {
    return this.formNegocio.get('otroGasto').invalid && this.formNegocio.get('otroGasto').touched
  }

  get otroPrestamoNoValido() {
    return this.formNegocio.get('otroPrestamo').invalid && this.formNegocio.get('otroPrestamo').touched
  }

}

export const negocioEmpty:NegocioInfoCompleta = {
  idNegocio: null,
  numNegocio: null,
  nombre: '',
  actividadEconomica: '',
  antiguedad: '0',
  capacidadPago: {
    gastoMensual: null,
    ingresoMensual: null
  },
  datosBancarios: {
    banco: null,
    beneficiario: null,
    cuenta: null,
    cuentaClabe: null,
    numTarjeta: null
  },
  telefonos: [],
  cliente: clienteEmpty,
  direccion: direccionEmpty,
  referencias: []
}
