export class Direccion {
    calle: string;
    colonia: string;
    cp: string;
    estado: string;
    municipio: string;
    numExt: string;
    numInt: string;
    pais: string;
    latitud: string;
    longitud: string;
}

export class DireccionConCoordenadas {
    idDireccion: string;
    calle: string;
    colonia: string;
    cp: string;
    estado: string;
    municipio: string;
    numExt: string;
    numInt: string;
    pais: string;
    coordenadas: Coordenadas;
}

export class Coordenadas {
    latitud: string;
    longitud: string;
}