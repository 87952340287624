import { UntypedFormGroup } from '@angular/forms';
import { GastosTemporal, IngresosTemporal, TelefonoTemporal } from 'src/app/models/clients/datos-temporales.model';
import { DatosTemporales } from '../../../models/clients/datos-temporales.model';

export default class UtilsDatosTemporales{ 
    
    static mapFormtoDto(
        formDatosTemporales: UntypedFormGroup,
        formTelefonosCliente: UntypedFormGroup,
        formTelefonosNegocio: UntypedFormGroup,
        numSolicitud: string,
        idUsuario: string
    ):DatosTemporales {
        
        const ingresos: IngresosTemporal = {
            ingresoCliente: formDatosTemporales.get('ingresoCliente').value,
            ingresoNegocio: formDatosTemporales.get('ingresoNegocio').value,
            ingresoApoyoFamiliar: formDatosTemporales.get('ingresoApoyoFamiliar').value,
            otroIngreso: formDatosTemporales.get('otroIngreso').value,
        } 

        const gastos: GastosTemporal = {
            gastoAlimentos: formDatosTemporales.get('gastosAlimento').value,
            gastoServiciosBasicos: formDatosTemporales.get('gastosServiciosBasicos').value,
            gastoPrestamos: formDatosTemporales.get('gastosPrestamos').value,
            gastoRenta: formDatosTemporales.get('gastosRenta').value,
            gastoMateriaPrima: formDatosTemporales.get('gastosMateriaPrima').value,
            otroGasto: formDatosTemporales.get('otroGasto').value,
        } 

        const telefonosCasa: TelefonoTemporal[] = formTelefonosCliente.get('telefonos').value
            .map( e => ({
                telefono: e.telefono,
                claveTipoTelefono: e.tipoTelefono,
                lugar: "Casa"
            }));

        const telefonosNegocio: TelefonoTemporal[] = formTelefonosNegocio.get('telefonos').value
            .map( e => ({
                telefono: e.telefono,
                claveTipoTelefono: e.tipoTelefono,
                lugar: "Negocio"
            }));

        const telefonos = [...telefonosCasa, ...telefonosNegocio];
        
        const datosTemporales: DatosTemporales = {
            numSolicitud: numSolicitud,
            claveEstadoCivil: formDatosTemporales.get('estadoCivil').value,
            claveNivelEscolar: formDatosTemporales.get('nivelEscolar').value,
            claveOcupacion: formDatosTemporales.get('ocupacion').value,
            claveTipoVivienda: formDatosTemporales.get('tipoVivienda').value,
            claveFuenteIngreso: formDatosTemporales.get('fuenteIngreso').value,
            claveMedioConocimiento: formDatosTemporales.get('medioConocimiento').value,
            email: formDatosTemporales.get('email').value,
            numDependientesEconomicos: formDatosTemporales.get('numDependientesEconomicos').value,
            escriturasVivienda: formDatosTemporales.get('escriturasVivienda').value,
            actividadEconomicaNegocio: formDatosTemporales.get('actividadEconomicaNegocio').value,
            antiguedadNegocio: formDatosTemporales.get('antiguedadNegocio').value,
            entidadFinancieraAnterior: formDatosTemporales.get('entidadFinancieraAnterior').value,
            ingresos: ingresos,
            gastos: gastos,
            telefonos: telefonos,
            usuarioUltMod: idUsuario
        }

        return datosTemporales;
    }
}