<!-- START card -->
<div class="card card-default">
    <div class="card-body" *ngIf="solicitud">
        <h4 class="text-primary">Datos de la solicitud</h4>
        <div *ngIf="solicitud.credito && solicitud.credito.numContrato">
            <p class="text-primary">
                <span class="title-labels text-primary">Número de contrato: </span> {{solicitud.credito.numContrato}}
            </p>
        </div>
        <p>
            <span class="title-labels">Número de solicitud: </span> {{solicitud.numSolicitud}}
        </p>
        <p>
            <span class="title-labels">Nombre solicitud: </span> {{solicitud.nombreSolicitud}}
        </p>

        <div *ngIf="cotizacionSolicitud">
            <p>
                <span class="title-labels">Tipo de solicitud: </span> {{cotizacionSolicitud.tipoSolicitud.descripcion }}
            </p>
            <p>
                <span class="title-labels">Esquema de pago: </span> {{cotizacionSolicitud.esquemasPago[0].descripcion}}
            </p>
            <p>
                <span class="title-labels">Monto a solicitar: </span> {{cotizacionSolicitud.montoCredito | currency}}
            </p>
            <p>
                <span class="title-labels">Comisión: </span> {{cotizacionSolicitud.comision | currency}}
            </p>
            <p>
                <span class="title-labels">Cuotas: </span> {{cotizacionSolicitud.esquemasPago[0].cuotas[0].numPagos}} cuotas de {{cotizacionSolicitud.esquemasPago[0].cuotas[0].cuotaRecurrente | currency}} pesos
            </p>
            <p>
                <span class="title-labels">Tasa de interés: </span> {{(cotizacionSolicitud.esquemasPago[0].cuotas[0].tasaInteres / 100) | percent:'1.2' }}
            </p>
        </div>

        <div class="full-width m-t-20">
            <button type="button" (click)="eliminarSolicitud()" class="btn btn-danger float-left" *ngIf="mostrarBotonEliminar()">Cancelar Solicitud</button>
        </div>
    </div>
</div>