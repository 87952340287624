import { UntypedFormGroup } from '@angular/forms';
import { Direccion } from 'src/app/models/person/direccion.model';
import { Vivienda } from 'src/app/models/person/persona-request.model';
import { Telefono } from 'src/app/models/person/telefono.model';
import Utils from 'src/utils/Utils';
import UtilsFormats from 'src/utils/UtilsFormats';
import { PersonaCredito, Nacimiento } from '../../../models/person/persona-request.model';

export default class UtilsPersona { 
    static mapFormtoDto(
        form: UntypedFormGroup,
        formViviendas: UntypedFormGroup,
        formDireccion: UntypedFormGroup,
        formTelefonos: UntypedFormGroup,
        idUsuario: string,
        idPersona: number | null
    ):PersonaCredito {
                
        const nacimiento: Nacimiento = {
            fecha: UtilsFormats.dateToString(form.get('fechaNacimiento').value),
            lugar: Utils.capitalizeEachWord(form.get('lugarNacimiento').value),
            pais: Utils.capitalizeEachWord(form.get('paisNacimiento').value),
        } 

        const direccion: Direccion = {
            calle: Utils.capitalizeEachWord(formDireccion.get('calle').value),
            colonia: Utils.capitalizeEachWord(formDireccion.get('colonia').value),
            cp: formDireccion.get('cp').value,
            estado: Utils.capitalizeEachWord(formDireccion.get('estado').value),
            municipio: Utils.capitalizeEachWord(formDireccion.get('municipio').value),
            numExt: formDireccion.get('numExt').value.toUpperCase(),
            numInt: formDireccion.get('numInt').value?.toUpperCase(),
            pais: Utils.capitalizeEachWord(formDireccion.get('pais').value),
            latitud: null,
            longitud: null,
        }

        const viviendas: Vivienda[] = [{
            claveTipoVivienda: formViviendas.get('tipoVivienda').value,
            escrituras: formViviendas.get('escrituras').value == 'S' ? "true" : "false",
            direccion: direccion,
        }]

        const telefonos: Telefono[] = formTelefonos.get('telefonos').value
            .map( e => {
                const telefono: Telefono = {
                    idTelefono: null, // FIXME: Este dato no se tiene que mandar
                    telefono: e.telefono,
                    claveTipoTelefono: e.tipoTelefono
                }
                return telefono;
            })
        
        const persona: PersonaCredito = {
            idPersona: idPersona ?? null,
            apellidoMaterno: Utils.capitalizeEachWord(form.get('apellidoMaterno').value),
            apellidoPaterno: Utils.capitalizeEachWord(form.get('apellidoPaterno').value),
            claveEstadoCivil: form.get('estadoCivil').value,
            curp: form.get('curp').value.toUpperCase(),
            email: form.get('correo').value?.toLowerCase(),
            nacionalidad: Utils.capitalizeEachWord(form.get('nacionalidad').value),
            nombre: Utils.capitalizeEachWord(form.get('nombre').value),
            numIdentificacion: form.get('numIdentificacion').value,
            rfc: form.get('rfc').value?.toUpperCase(),
            sexo: form.get('genero').value,
            nacimiento: nacimiento,
            telefonos: telefonos,
            viviendas: viviendas,
            usuarioUltMod: idUsuario,
        }

        return persona;
    }
}