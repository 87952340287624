<!-- START ITEM -->
<div class="card social-card share  full-width m-b-10 no-border" data-social="item">
    <div class="card-header clearfix">
        <h5 class="pull-left fs-12">REPORTES</h5>
    </div>
    <div class="card-description">
        <h5 class='no-margin text-primary'>General</h5>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-12 m-b-5">
                <div class="form-group">
                    <label>Seleccionar colocador</label>
                    <form [formGroup]="formColocador">
                        <select class="selectpicker" formControlName="colocador" [(ngModel)]='colocadorSelect'>
                            <option *ngFor="let colocador of colocadorList" [ngValue]="colocador">
                                {{colocador.persona.nombre}} {{colocador.persona.apellidoPaterno}}
                            </option>
                        </select>
                    </form>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label>Seleccionar estatus</label>
                    <form [formGroup]="formEstatus">
                        <select class="selectpicker" formControlName="estatus" [(ngModel)]='estatusSelect'>
                            <option *ngFor="let estatus of estatusList" [ngValue]="estatus">
                                {{estatus.descripcion}}
                            </option>
                        </select>
                    </form>
                </div>
            </div>
        </div>
        <br>
        <label>Seleccionar fechas</label>
        <div class="input-daterange input-group" id="datepicker-range">
            <input type="text" class="input-sm form-control" name="start" id="startdate" autocomplete="off" placeholder="Fecha inicio apertura" />
            <div class="input-group-addon">a</div>
            <input type="text" class="input-sm form-control" name="end" id="enddate" autocomplete="off" placeholder="Fecha fin apertura" />
        </div>
        <br>
        <button (click)="generarReporte()" class="btn btn-primary float-right">Generar reporte</button>
    </div>
</div>
<!-- END ITEM -->