<!-- START TABLE -->
<div class="tab-pane active">
    <div *ngIf="!solicitudesPreautorizadas || solicitudesPreautorizadas.length == 0">
        <p class="v-align-middle bold text-center col-12">No existen registros para mostrar</p>
    </div>
    <div class="table-responsive vertical-scroll">
        <table class="table table-hover" id="solicitudesPreautorizadas" aria-describedby="Solicitudes preautorizadas">
            <thead>
                <tr *ngIf="solicitudesPreautorizadas && solicitudesPreautorizadas.length > 0">
                    <th id="numSolicitud" class="v-align-middle bold text-left col-4">Número de solicitud</th>
                    <th id="nombreSolicitud" class="v-align-middle bold text-left col-4">Identificador</th>
                    <th id="tipoSolicitud" class="v-align-middle bold text-left col-4">Tipo de solicitud</th>
                </tr>
            </thead>
            <tbody>
                <tr class="cursor" *ngFor="let solicitud of solicitudesPreautorizadas; let i = index" (click)="visualizarSolicitud(solicitud)">
                    <td class="v-align-middle normal text-left">{{solicitud.numSolicitud}}</td>
                    <td class="v-align-middle normal text-left">{{solicitud.nombreSolicitud}}</td>
                    <td class="v-align-middle normal text-left">{{solicitud.tipoSolicitud.descripcion}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>