<!-- START INTERESES SECTION -->
<div class="card social-card share full-width m-b-10 no-border" data-social="item">
    <div class="card-header clearfix">
        <h5 class="text-primary">Intereses</h5>
    </div>
    <div class="grid-random">
        <!-- SIN INTERESES -->
        <div class="grid-random" *ngIf="!intereses || intereses.length === 0">
            <div class="card-body">
                <p> Esta cuota no generó intereses </p>
            </div>
        </div>
        <!-- START TABLE -->
        <div class="table-responsive vertical-scroll" *ngIf="intereses && intereses.length > 0">
            <div class="card card-borderless">
                <table class="table table-hover table-condensed table-responsive" id="interesesTable" aria-describedby="Intereses de la cuota">
                    <thead>
                        <tr>
                            <th id="fechaInteres" class="v-align-middle bold text-center col-1">Fecha</th>
                            <th id="Monto Interes" class="v-align-middle bold text-center col-2">Monto</th>
                            <th id="tipoInteres" class="v-align-middle bold text-left col-2">Tipo Interes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let interes of intereses; let i = index">
                            <td class="v-align-middle normal text-center">{{interes.fechaInteres}}</td>
                            <td class="v-align-middle normal text-center">{{interes.montoInteres | currency}}</td>
                            <td class="v-align-middle normal text-left">{{interes.tipoInteres.descripcion}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>