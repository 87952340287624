<div class="row" *ngIf="solicitudInteresado">
    <div class="col-lg-6">
        <!-- DATOS INTERESADO -->
        <div class="card social-card share full-width m-b-10 no-border" data-social="item">
            <div class="card-header clearfix">
                <h5 class="text-primary">Datos interesado(a)</h5>
            </div>
            <div class="grid-random">
                <!-- START -->
                <div class="card-body">
                    <p>
                        <span class="title-labels">Nombre: </span> {{solicitudInteresado.nombre}}
                    </p>
                    <p>
                        <span class="title-labels">Apellido paterno: </span> {{solicitudInteresado.apellidoPaterno}}
                    </p>
                    <p>
                        <span class="title-labels">Apellido materno: </span> {{solicitudInteresado.apellidoMaterno}}
                    </p>
                    <p>
                        <span class="title-labels">Teléfono: </span> {{solicitudInteresado.telefono}}
                    </p>
                    <p>
                        <span class="title-labels">Codigo postal: </span> {{solicitudInteresado.codigoPostal}}
                    </p>
                    <p>
                        <span class="title-labels">Correo electrónico: </span> {{solicitudInteresado.correoElectronico}}
                    </p>
                    <p>
                        <span class="title-labels">Nombre del negocio: </span> {{solicitudInteresado.nombreNegocio}}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <!-- DATOS INTERESADO -->
        <div class="card social-card share full-width m-b-10 no-border" data-social="item">
            <div class="card-header clearfix">
                <h5 class="text-primary">Datos cotización</h5>
            </div>
            <div class="grid-random">
                <!-- START -->
                <div class="card-body">
                    <p>
                        <span class="title-labels">Monto solicitado: </span> {{solicitudInteresado.cotizacion.montoCredito | currency}}
                    </p>
                    <p>
                        <span class="title-labels">Tasa interes: </span> {{solicitudInteresado.cotizacion.tasaInteres}}%
                    </p>
                    <p>
                        <span class="title-labels">Número de pagos: </span> {{solicitudInteresado.cotizacion.numPagos}}
                    </p>
                    <p>
                        <span class="title-labels">Esquema de pago: </span> {{solicitudInteresado.cotizacion.esquemaPago === 'PAGOS_DIARIOS' ? "Diario" : "Semanal"}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>