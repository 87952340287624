import { PersonaModel } from '../person/persona.model'
import { RolModel } from './rol.model'
import { SucursalModel } from '../branch-office/sucursal.model'

export class UsuarioModel {
  codigoUsuario: string
  password: string
  nombre: string
  apellidos: string
  roles: RolModel[]
  emailEmpresa: string
  estatus: string
  idUsuario: number
  sexo: string
  persona: PersonaModel
  usuarioUltMod: number
}

export class UsuarioResumidoModel {
  idUsuario: string
  codigoUsuario: string
  persona: PersonaModel
}

export class UsuarioGeneralModel {
	idUsuario: number;
	codigoUsuario: string;
	bloqueo: boolean;
	actualizado: boolean;
	persona: PersonaModel;
	roles: RolModel[]
	sucursales: SucursalModel[];
}

export class LockUserModel {
  codigoUsuario: string
  usuarioUltMod: string
}

export class ResetPasswordModel {
  codigoUsuario: string
  password: string
  usuarioUltMod: string
}
