import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nofileimage'
})
export class NofileimagePipe implements PipeTransform {

  transform(image: any): string {
    if (!image) {
      return 'assets/img/file.png'
    }
    return image;
  }

}