import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsuarioModel } from '../models/users/usuario.model';
import { EncriptarService } from './encriptar.service';
import { Router } from '@angular/router';
import Utils from 'src/utils/Utils';
import { Constants } from '../classes/constants.class';

const SECRET_KEY_ENCRYPT = environment.secretKeyEncrypt;
const USER_AUTH = environment.userAuth;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string;
  private usuario: UsuarioModel;

  constructor(private encriptarService: EncriptarService, private http: HttpClient, private router: Router) { }

  public get getToken(): string {
    if (this.token != null) {
      return this.token;
    } else if (this.token == null && sessionStorage.getItem('token') != null) {
      this.token = sessionStorage.getItem('token');
      return this.token;
    }
    return null;
  }

  public get getUsuario(): UsuarioModel {
    if (this.usuario != null) {
      return this.usuario;
    } else if (this.usuario == null && sessionStorage.getItem('usuario') != null) {
      this.usuario = JSON.parse(sessionStorage.getItem('usuario')) as UsuarioModel;
      return this.usuario;
    }
    return new UsuarioModel();
  }

  isLoggin(): boolean {
    let usuario = this._obtenerToken(this.getToken);

    if (usuario != null && usuario.user_name && usuario.user_name.length > 0) {
      return true;
    }
    return false;
  }

  login(username: string, password: string): Observable<any> {
    let secret: string = this.encriptarService.desencriptarTexto(SECRET_KEY_ENCRYPT);

    const httpOptions = {
     headers: new HttpHeaders({
       'Content-Type': 'application/x-www-form-urlencoded',
       'Authorization': 'Basic ' + Utils.b64EncodeUnicode(`${USER_AUTH}:${secret}`)
     })
    }

    const body = new URLSearchParams();

    body.set('grant_type', 'password');
    body.set('username', username);
    body.set('password', Utils.b64EncodeUnicode(password));
    
    return this.http.post(
      `${environment.url}/security/oauth/token`, body.toString(), httpOptions
      );
  }

  guardarUsuario (accessToken: string): void {
    let userData = this.obtenerToken(accessToken);
    this.usuario = new UsuarioModel();

    this.usuario.nombre = userData.user_name;
    this.usuario.roles = userData.authorities;
 
    sessionStorage.setItem('usuario', JSON.stringify(this.usuario));
  }

  guardarToken(accessToken: string, refreshToken: string): void {
    sessionStorage.setItem('token', accessToken);
    sessionStorage.setItem('refreshToken', refreshToken);
  }

  obtenerToken(accessToken: string): any {
    if (accessToken != null) {
      return JSON.parse(Utils.b64DecodeUnicode(accessToken.split('.')[1]));
    }
    return null;
  }

  logout() {
    this.token = null;
    this.usuario = null;
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('usuario');
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  refrescarToken(): Observable<any> {
    let secret: string = this.encriptarService.desencriptarTexto(SECRET_KEY_ENCRYPT);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + Utils.b64EncodeUnicode(`${USER_AUTH}:${secret}`)
      })
     }
 
     const body = new URLSearchParams();
 
     body.set('grant_type', 'refresh_token');
     body.set('refresh_token', sessionStorage.getItem('refreshToken'));
     body.set('username', sessionStorage.getItem('codigoUsuario'));
     body.set('password', sessionStorage.getItem('codigoUsuario'));
     
     return this.http.post(
       `${environment.url}/security/oauth/token`, body.toString(), httpOptions
       );
  }

  private _obtenerToken(accessToken: string): any {
    if (accessToken != null) {
      return JSON.parse(Utils.b64DecodeUnicode(accessToken.split('.')[1]));
    }
    return null;
  }

  hasRole(role: string): boolean {
    let userRoles = JSON.parse(sessionStorage.getItem('usuario')).roles;
    if (userRoles.includes(role)) {
      return true;
    }
    return false;
  }

  permisosUsuario(): boolean {
    const userRoles = JSON.parse(sessionStorage.getItem('usuario')).roles;

    if (userRoles.includes(Constants.ROLE_USER) ||
      userRoles.includes(Constants.ROLE_ADMIN) ||
      userRoles.includes(Constants.ROLE_SUPER_ADMIN)
    ) { return true; }
    return false;
  }

  permisosAdmin(): boolean {
    const userRoles = JSON.parse(sessionStorage.getItem('usuario')).roles;

    if (userRoles.includes(Constants.ROLE_ADMIN) ||
      userRoles.includes(Constants.ROLE_SUPER_ADMIN)
    ) { return true; }
    return false;
  }

  permisosSuperAdmin(): boolean {
    const userRoles = JSON.parse(sessionStorage.getItem('usuario')).roles;
    if (userRoles.includes(Constants.ROLE_SUPER_ADMIN)) return true;
    return false;
  }
}

