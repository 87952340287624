import { UntypedFormGroup } from '@angular/forms';
import { IdsCliente } from 'src/app/models/clients/cliente-request.model';
import { Direccion } from 'src/app/models/person/direccion.model';
import { Telefono } from 'src/app/models/person/telefono.model';
import Utils from 'src/utils/Utils';
import { NegocioModel, CapacidadPago, DatosBancarios } from '../../../models/business/negocio-request.model';

export default class UtilsNegocio{ 
    
    static mapFormtoDto(
        form: UntypedFormGroup,
        formDireccion: UntypedFormGroup,
        formTelefonos: UntypedFormGroup,
        cliente: IdsCliente,
        idSucursal: string,
        idUsuario: string,
        idNegocio: string = null
    ):NegocioModel {
        
        const datosBancarios: DatosBancarios = {
            beneficiario: Utils.b64EncodeUnicode(form.get('beneficiario').value),
            banco: Utils.b64EncodeUnicode(form.get('banco').value),
            cuenta: Utils.b64EncodeUnicode(form.get('numeroCuenta').value),
            numTarjeta: Utils.b64EncodeUnicode(form.get('numeroTarjeta').value),
            cuentaClabe: Utils.b64EncodeUnicode(form.get('cuentaClabe').value),
        } 
                
        const capacidadPago: CapacidadPago = {
            ingresoMensual: Utils.b64EncodeUnicode(form.get('totalIngresoMensual').value),
            gastoMensual: Utils.b64EncodeUnicode(form.get('totalGastoMensual').value),
        } 

        const direccion: Direccion = {
            calle: Utils.capitalizeEachWord(formDireccion.get('calle').value),
            colonia: Utils.capitalizeEachWord(formDireccion.get('colonia').value),
            cp: formDireccion.get('cp').value,
            estado: Utils.capitalizeEachWord(formDireccion.get('estado').value),
            municipio: Utils.capitalizeEachWord(formDireccion.get('municipio').value),
            numExt: formDireccion.get('numExt').value.toUpperCase(),
            numInt: formDireccion.get('numInt').value?.toUpperCase(),
            pais: Utils.capitalizeEachWord(formDireccion.get('pais').value),
            latitud: null,
            longitud: null,
        }

        const telefonos: Telefono[] = formTelefonos.get('telefonos').value
            .map( e => {
                const telefono: Telefono = {
                    idTelefono: null, // FIXME: Este dato no se tiene que mandar
                    telefono: e.telefono,
                    claveTipoTelefono: e.tipoTelefono
                }
                return telefono;
            })
        
        const negocio: NegocioModel = {
            idNegocio: idNegocio,
            nombre: form.get('nombreNegocio').value,
            actividadEconomica: form.get('actividadEconomica').value,
            antiguedad: form.get('antiguedad').value,
            idSucursal: idSucursal,
            usuarioUltMod: idUsuario,
            cliente: cliente,
            datosBancarios: datosBancarios,
            capacidadPago: capacidadPago,
            telefonos: telefonos,
            direccion: direccion
        }

        return negocio;
    }
}