import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { DetalleCreditoModel } from 'src/app/models/credits/credito.model';
import { AuthService } from 'src/app/services/auth.service';
import UtilsRest from 'src/utils/UtilsRest';
import UtilsSwal from 'src/utils/UtilsSwal';
import Swal from 'sweetalert2';
import { CreditoService } from '../../../services/credito.service';

@Component({
  selector: 'app-detalle-credito',
  templateUrl: './detalle-credito.component.html',
  styleUrls: ['./detalle-credito.component.scss']
})
export class DetalleCreditoComponent implements OnDestroy, OnChanges {

  private token: string = sessionStorage.getItem('token');

  // PARAMETROS RECIBIDOS
  @Input() numContrato: string;

  // VARIABLES LOCALES
  credito: DetalleCreditoModel;

  constructor(
    private authService: AuthService, 
    private creditoService: CreditoService,
  ) {}

  ngOnDestroy(): void {
    this.credito = null
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.numContrato) {
      this._getInfoCredito(this.numContrato);
    } else {
      this.credito = null;
    }
  }

  // SERVICIOS EXTERNOS
  private _getInfoCredito(numContrato: string) {

    UtilsSwal.buscandoInformacion();
    Swal.showLoading();

    this.creditoService.obtenerDetalleCredito(this.token, numContrato)
    .subscribe(resp => {

      Swal.close();
      this.credito = resp.resultado;

      this.credito.tasaInteres = resp.resultado.tasaInteres / 100;
      this.credito.tasaInteresAnual = resp.resultado.tasaInteresAnual / 100;

    }, err => {

      Swal.close();
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getInfoCredito(numContrato);
      }

    });
  }

}
