import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AdeudosModel } from 'src/app/models/credits/cuota.model';

@Component({
  selector: 'app-adeudos',
  templateUrl: './adeudos.component.html',
  styleUrls: ['./adeudos.component.scss']
})
export class AdeudosComponent implements OnDestroy, OnChanges {

  // PARAMETROS RECIBIDOS
  @Input() adeudos: AdeudosModel;

  constructor() { }

  ngOnDestroy(): void {
    this.adeudos = null
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes.adeudos.currentValue) {
      this.adeudos = null;
    } else {
      this.adeudos = changes.adeudos.currentValue
    }
  }

}
