import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AutorizacionModel } from '../models/auth/autorizacion.model';
import { BuscarCotizacionModel, BuscarCotizacionRenovacionModel } from '../models/credits-application/cotizacion.model';
import { DatosTemporales } from '../models/clients/datos-temporales.model';
import { ActualizarEstatusSolicitud, EstatusSolicitudRequest } from '../models/credits-application/estatus-solicitud.model';
import { GuardarSolicitudModel } from '../models/credits-application/solicitud.model';

@Injectable({
  providedIn: 'root'
})
export class SolicitudesService {

  constructor(private http: HttpClient) { }

  // GET - SOLICITUDES

  obtenerSolicitudes(accessToken: string, idSucursal: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/creditos/v1/solicitudes?idSucursal=${idSucursal}`, 
      {headers: headers}
    );
  }
  
  obtenerCatalogoTipoSolicitud(accessToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/creditos/v1/catalogo/tipos-solicitudes`, 
      {headers: headers}
    );
  }

  obtenerCotizacionClienteNuevo(
    accessToken: string, 
    buscarCotizacion: BuscarCotizacionModel
  ): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    var url = `${environment.url}/creditos/v1/cotizador/cliente-nvo?montoCredito=${buscarCotizacion.montoCredito}&claveTipoSolicitud=${buscarCotizacion.claveTipoSolicitud}&claveEsquemaPago=${buscarCotizacion.claveEsquemaPago}&promocion=${buscarCotizacion.promocion}&idSucursal=${buscarCotizacion.idSucursal}`
    if(buscarCotizacion.numSolicitud) url = url + `&numSolicitud=${buscarCotizacion.numSolicitud}`

    return this.http.get(
      url,
      {headers: headers}
    );
  }

  obtenerCotizacionRenovacion(
    accessToken: string, 
    buscarCotizacion: BuscarCotizacionRenovacionModel
  ): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    var url = `${environment.url}/creditos/v1/cotizador/renovacion?montoCredito=${buscarCotizacion.montoCredito}&claveTipoCredito=${buscarCotizacion.claveTipoCredito}&claveEsquemaPago=${buscarCotizacion.claveEsquemaPago}&idNegocio=${buscarCotizacion.idNegocio}&idSucursal=${buscarCotizacion.idSucursal}`

    return this.http.get(
      url,
      {headers: headers}
    );
  }

  obtenerSolicitud(
    accessToken: string, 
    numSolicitud: string, 
    tracking: boolean
  ) : Observable<any> {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/creditos/v1/solicitud?numSolicitud=${numSolicitud}&tracking=${tracking}`, 
      {headers: headers}
    );
  }

  obtenerCotizacionSolicitud(
    accessToken: string, 
    numSolicitud: string
  ) : Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/creditos/v1/cotizacion?numSolicitud=${numSolicitud}`, 
      {headers: headers}
    );
  }

  obtenerDatosTemporales(accessToken: string, numSolicitud: string) : Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/creditos/v1/solicitud/datos-temporales?numSolicitud=${numSolicitud}`, 
      {headers: headers}
    );
  }

  // POST - SOLICITUDES

  guardarSolicitud(
    accessToken:string, 
    solicitud: GuardarSolicitudModel
  ): Observable<any> {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/creditos/v1/solicitud`,
      JSON.stringify(solicitud),
      {headers: headers}
    );
  }

  guardarDatosTemporales(
    accessToken:string, 
    datosTemporales: DatosTemporales
  ): Observable<any> {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/creditos/v1/solicitud/datos-temporales`,
      JSON.stringify(datosTemporales),
      {headers: headers}
    );
  }

  // PUT

  cancelarSolicitud(
    accessToken: string, 
    estatusSolicitud: EstatusSolicitudRequest
  ): Observable<any> {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.put(
      `${environment.url}/creditos/v1/solicitud/cancelacion`, JSON.stringify(estatusSolicitud), {headers: headers});
  }

  autorizar(
    accessToken: string, 
    autorizacion: AutorizacionModel
  ): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.put(
      `${environment.url}/creditos/v1/credito/autorizacion`, JSON.stringify(autorizacion), {headers: headers});
  }

  preautorizar(
    accessToken: string, 
    autorizacion: AutorizacionModel
  ): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.put(
      `${environment.url}/creditos/v1/credito/preautorizacion`, JSON.stringify(autorizacion), {headers: headers});
  }

  actualizarEstatus(
    accessToken: string,
    actualizarEstatus: ActualizarEstatusSolicitud
  ) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.put(
      `${environment.url}/creditos/v1/solicitud/estatus`, JSON.stringify(actualizarEstatus), {headers: headers});
  }
}
