import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from '@stomp/stompjs';
import { SendSocket } from 'src/app/models/collections/cobranza.model';
import { SolicitudesService } from 'src/app/services/solicitudes.service';
import { WebsocketsService } from 'src/app/services/websockets.service';
import Utils from 'src/utils/Utils';
import UtilsSwal from 'src/utils/UtilsSwal';
import Swal from 'sweetalert2';
import * as SockJS from 'sockjs-client';
import { AutorizacionModel } from 'src/app/models/auth/autorizacion.model';
import { Constants } from 'src/app/classes/constants.class';
import { AuthService } from 'src/app/services/auth.service';
import { SolicitudModel } from 'src/app/models/credits-application/solicitud.model';

@Component({
  selector: 'app-preautorizar',
  templateUrl: './preautorizar.component.html',
  styleUrls: ['./preautorizar.component.scss']
})
export class PreautorizarComponent implements OnInit, OnChanges {

  private token: string = sessionStorage.getItem('token');
  private idSucursal: string = sessionStorage.getItem('idSucursal');
  private idUsuario: string = sessionStorage.getItem('idUsuario');

  // PARAMETROS RECIBIDOS
  @Input() solicitud: SolicitudModel;

  // SOCKETS
  private client: Client;

  constructor(
    private authService: AuthService,
    private solicitudesService: SolicitudesService,
    private websocketsService: WebsocketsService,
    private router: Router,
  ) { }

  ngOnChanges(): void {}

  ngOnInit(): void {
    // Se realiza conexión a sockets
    this.client = new Client();
    this.client.webSocketFactory = () => {
      return new SockJS(this.websocketsService.getPathSockSolicitudesJS());
    }

    this.client.activate();
  }

  // METODOS HTML
  permisosPreautorizar() {
    if(this.authService.hasRole(Constants.ROLE_ADMIN)) return true;
    return false;
  }

  autorizar() {
    Swal.fire({
      title: 'Confirmación de autorización',
      html: `<input type="text" id="user" class="swal2-input" placeholder="Usuario">
      <input type="password" id="password" class="swal2-input" placeholder="Contraseña">`,
      confirmButtonText: 'Confirmar',
      focusConfirm: false,
      preConfirm: () => {
        const user = (<HTMLInputElement> Swal.getPopup().querySelector('#user')).value;
        const password = (<HTMLInputElement> Swal.getPopup().querySelector('#password')).value;

        if (!user || !password) {
          Swal.showValidationMessage(`Por favor inserta usuario y contraseña para continuar`)
        }
        return { user: user, password: password }
      }
    }).then((result) => {
      if (result.isConfirmed) {

        const autorizacion: AutorizacionModel = {
          numSolicitud: this.solicitud.numSolicitud,
          usuarioUltMod: this.idUsuario,
          autentificacion: {
            codigoUsuario: result.value.user.trim(),
            password: Utils.b64EncodeUnicode(result.value.password.trim())
          }
        }

        this._preautorizarService(autorizacion);
      }
    });
  }

  // SERVICIOS EXTERNOS
  private _preautorizarService(autorizacion: AutorizacionModel) {

    UtilsSwal.enviarInformacion(
      "¿Estás seguro de preautorizar esta solicitud?",
      "Preautorizando solicitud",
      "Preautorizar",
      this.solicitudesService.preautorizar(this.token, autorizacion),
      false
    )
    .then(() => this._enviarInfoSocket())
    .then(() => this.router.navigate(['/solicitudes']));
  }

  private _enviarInfoSocket() {
    const sendSocket: SendSocket = {
      idSucursal: this.idSucursal,
    }
    this.client.publish({ 
      destination: '/send/solicitudes', 
      body: JSON.stringify(sendSocket) 
    });
  }

}
