<app-sidebar></app-sidebar>
<div class="horizontal-menu horizontal-app-menu dashboard">
    <app-header></app-header>
    <div class="page-container pl-5" *ngIf="permisoUsuario">
        <div class="page-content-wrapper ">
            <div class="content sm-gutter">
                <div class="container sm-padding-10 p-t-20 p-l-0 p-r-0">
                    <div class="row">
                        <app-archivos-expediente class="full-width" [numSolicitud]="numSolicitud" [claveTipoSolicitud]="claveTipoSolicitud"></app-archivos-expediente>
                        <app-actualizar-estatus-solicitud class="full-width" [numSolicitud]="numSolicitud" [claveEstatusSolicitud]="claveCapturaDatos" [titulo]="tituloActualizarEstatus" *ngIf="datosCompletos"></app-actualizar-estatus-solicitud>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <app-datos-temporales class="full-width" [numSolicitud]="numSolicitud" [catalogosSolicitud]="catalogosSolicitud"></app-datos-temporales>
                            <app-tracking-solicitud [tracking]="tracking"></app-tracking-solicitud>
                        </div>
                        <div class="col-lg-8">
                            <app-datos-generales-solicitud [solicitud]="solicitud"></app-datos-generales-solicitud>
                            <div class="card card-default">
                                <div class=" card-body">
                                    <button type="button" (click)="anteriorPaso()" class="btn btn-primary float-left btn-continue m-l-10" [disabled]="paso <= 1">
                                        <i class="fas fa-arrow-left"></i>
                                    </button>
                                    <button type="button" (click)="siguientePaso()" class="btn btn-primary float-right btn-continue" [disabled]="paso >= pasoMax" *ngIf="pasoMax > 1">
                                        <i class="fas fa-arrow-right"></i>
                                    </button>

                                    <app-persona-form *ngIf="paso == 1" [persona]="persona" [numSolicitud]="numSolicitud" [catEstadoCivil]="catEstadoCivil" [catTipoVivienda]="catTipoVivienda" (personaEmit)="updateInfo()"></app-persona-form>
                                    <app-clientes-form *ngIf="paso == 2" [cliente]="cliente" [numSolicitud]="numSolicitud" [catMediosConocimiento]="catMediosConocimiento" [catNivelesEscolares]="catNivelesEscolares" [catOcupaciones]="catOcupaciones" [catFuentesIngresos]="catFuentesIngresos"></app-clientes-form>
                                    <app-negocios-form *ngIf="paso == 3" [negocio]="negocio" [numSolicitud]="numSolicitud"></app-negocios-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>