<div class="row m-b-10">
    <!-- SOLICITUDES EN REVISION -->
    <div class="col-lg-3 m-b-10">
        <div class="card share full-width full-height no-border">
            <div class="card-body">
                <h5 class='no-margin text-primary'>Total de solicitudes recibidas</h5>
                <h2 class="no-margin p-t-5">{{totalInteresadosPorRevisar}}</h2>
            </div>
        </div>
    </div>
    <!-- POTENCIALES CLIENTES -->
    <div class="col-lg-3 m-b-10">
        <div class="card share full-width full-height no-border">
            <div class="card-body">
                <h5 class='no-margin text-primary'>Total de potenciales clientes</h5>
                <h2 class="no-margin p-t-5">{{totalClientesPotenciales}}</h2>
            </div>
        </div>
    </div>
    <!-- EN VALIDACION DE DATOS -->
    <div class="col-lg-3 m-b-10">
        <div class="card share full-width full-height no-border">
            <div class="card-body">
                <h5 class='no-margin text-primary'>Total de solicitudes creadas</h5>
                <h2 class="no-margin p-t-5">{{totalInteresadosValidacionDatos}}</h2>
            </div>
        </div>
    </div>
    <!-- CREDITOS OTORGADOS-->
    <div class="col-lg-3 m-b-10">
        <div class="card share full-width full-height no-border">
            <div class="card-body">
                <h5 class='no-margin text-primary'>Total de créditos otorgados</h5>
                <h2 class="no-margin p-t-5">{{totalCreditosOtorgados}}</h2>
            </div>
        </div>
    </div>
</div>