<div class="row" *ngIf="negocio">
  <div class="col-lg-6">

    <!-- START ITEM -->
    <div class="card social-card share full-width no-border" data-social="item">
      <div class="card-header clearfix m-b-20">
        <h5 class="no-margin text-primary">CLIENTE</h5>
      </div>
      <!-- SECTION 1 -->
      <div class="card-body">
        <p>
          <span class="title-labels">Número de Cliente: </span>
          {{negocio.cliente.numCliente}}
        </p>
        <p>
          <span class="title-labels">Nombre: </span>
          {{negocio.cliente.persona.nombre}}
        </p>
        <p>
          <span class="title-labels">Apellido Paterno: </span>
          {{negocio.cliente.persona.apellidoPaterno}}
        </p>
        <p>
          <span class="title-labels">Apellido Materno: </span>
          {{negocio.cliente.persona.apellidoMaterno}}
        </p>
        <p>
          <span class="title-labels">Fecha de Nacimiento: </span>
          {{negocio.cliente.persona.nacimiento.fecha}}
        </p>
        <p>
          <span class="title-labels">CURP: </span>
          {{negocio.cliente.persona.curp}}
        </p>
        <p>
          <span class="title-labels">RFC: </span>
          {{negocio.cliente.persona.rfc}}
        </p>
        <p>
          <span class="title-labels">Número de Identificación: </span>
          {{negocio.cliente.persona.numIdentificacion}}
        </p>
        <p>
          <span class="title-labels">Lugar de Nacimiento: </span>
          {{negocio.cliente.persona.nacimiento.lugar}}
        </p>
        <p>
          <span class="title-labels">País de Nacimiento: </span>
          {{negocio.cliente.persona.nacimiento.pais}}
        </p>
        <p>
          <span class="title-labels">Nacionalidad: </span>
          {{negocio.cliente.persona.nacionalidad}}
        </p>
        <p>
          <span class="title-labels">Estado Civil: </span>
          {{negocio.cliente.persona.estadoCivil.descripcion}}
        </p>
        <p>
          <span class="title-labels">Sexo: </span>
          {{negocio.cliente.persona.sexo == "M" ? "Masculino" : "Femenino"}}
        </p>
      </div>
      <!-- SECTION 2 -->
      <div class="card-subtitle">
        <h5 class="pull-left fs-12 text-primary">CONTACTO</h5>
      </div>
      <div class="card-body">
        <p>
          <span class="title-labels">Correo Electrónico: </span>
          {{negocio.cliente.persona.email}}
        </p>
        <div
          *ngFor="let tel of negocio.cliente.persona.telefonos; let i = index"
        >
          <p>
            <span class="title-labels">Número de Teléfono: </span>
            {{tel.telefono}}
          </p>
          <p>
            <span class="title-labels">Tipo de Teléfono: </span>
            {{tel.claveTipoTelefono}}
          </p>
        </div>
      </div>
      <!-- SECTION 3 -->
      <div class="card-subtitle">
        <h5 class="pull-left fs-12 text-primary">VIVIENDAS</h5>
      </div>
      <div
        class="card-body"
        *ngFor="let vivienda of negocio.cliente.persona.viviendas; let i = index"
      >
        <p>
          <span class="title-labels">Calle: </span> {{vivienda.direccion.calle}}
        </p>
        <p>
          <span class="title-labels">Colonia: </span>
          {{vivienda.direccion.colonia}}
        </p>
        <p>
          <span class="title-labels">Número Exterior: </span>
          {{vivienda.direccion.numExt}}
        </p>
        <p>
          <span class="title-labels">Número Interior: </span>
          {{vivienda.direccion.numInt}}
        </p>
        <p>
          <span class="title-labels">Código Postal: </span>
          {{vivienda.direccion.cp}}
        </p>
        <p>
          <span class="title-labels">Municipio: </span>
          {{vivienda.direccion.municipio}}
        </p>
        <p>
          <span class="title-labels">Estado: </span>
          {{vivienda.direccion.estado}}
        </p>
        <p>
          <span class="title-labels">País: </span> {{vivienda.direccion.pais}}
        </p>
        <p>
          <span class="title-labels">Tipo de Vivienda: </span>
          {{vivienda.tipoVivienda.descripcion}}
        </p>
        <p>
          <span class="title-labels">Con Escrituras: </span>
          {{vivienda.escrituras ? "Si" : "No"}}
        </p>
      </div>
    </div>

    <!-- START ITEM -->
    <div
      class="card social-card share full-width no-border grid-card"
      data-social="item"
    >
      <div class="card-header clearfix m-b-20">
        <h5 class="no-margin text-primary">DATOS SOCIOECONÓMICOS</h5>
      </div>
      <div class="card-body">
        <p>
          <span class="title-labels">Ocupación: </span>
          {{negocio.cliente.ocupacion.descripcion}}
        </p>
        <p>
          <span class="title-labels">Nivel Educativo: </span>
          {{negocio.cliente.nivelEscolar.descripcion}}
        </p>
        <p>
          <span class="title-labels">Fuente Ingreso: </span>
          {{negocio.cliente.fuenteIngreso.descripcion}}
        </p>
        <p>
          <span class="title-labels">Número de Dependientes Económicos: </span>
          {{negocio.cliente.numDependientesEconomicos}}
        </p>
        <p>
          <span class="title-labels">Entidad Financiera Anterior: </span>
          {{negocio.cliente.entidadFinancieraAnterior}}
        </p>
      </div>
    </div>
  </div>

  <div class="col-lg-6">
    <!-- START ITEM -->
    <div
      class="card social-card share full-width no-border grid-card"
      data-social="item"
    >
      <div class="card-header clearfix m-b-20">
        <h5 class="no-margin text-primary">USUARIO PORTAL WEB</h5>
      </div>
      <div class="card-body">
        <div class="icon-center">
          <!-- The client don't have user -->
          <div class="d-flex" *ngIf="!usuario">
            <div class="icon-circle bg-info">
              <i class="icon-large text-white fas fa-user-times"></i>
            </div>
            <div class="instructions-body">
              <h4 class="no-margin text-info">SIN USUARIO</h4>
              <p class="no-margin">
                No se le ha asignado un usuario al cliente para el portal web
              </p>
            </div>
          </div>
          <!-- The client have user -->
          <div class="d-flex" *ngIf="usuario">
            <div class="icon-circle" [ngClass]="usuario.bloqueo ? 'bg-lock' : 'bg-success'">
              <i class="icon-large text-white" [ngClass]="usuario.bloqueo ? 'fas fa-user-lock' : 'fas fa-user'"></i>
            </div>
            <div class="instructions-body">
              <h4 class="no-margin" [ngClass]="usuario.bloqueo ? 'text-danger' : 'text-success-dark'">
                {{ (usuario && usuario.bloqueo) ? "BLOQUEADO" : "ACTIVO"  }}
              </h4>
              <p class="no-margin">
                El usuario del cliente es: 
                <span class="title-labels"> {{ usuario.codigoUsuario }} </span>
              </p>
            </div>
          </div>
        </div>
        <!-- Buttons -->
        <div class="button-position">
          <!-- Clients who doesn't have user -->
          <button
            (click)="createUser()"
            class="btn btn-primary button-margin"
            *ngIf="!usuario"
          >
            Generar usuario
          </button>
          <!-- Clients with a user -->
          <button
            (click)="lockUser()"
            class="btn button-margin" [ngClass]=" usuario.bloqueo ? 'btn-primary' : 'btn-danger'"
            *ngIf="usuario"
          >
            {{ (usuario.bloqueo) ? "Desbloquear" : "Bloquear"  }}
          </button>
          <button
            (click)="resetPassword()"
            class="btn button-margin"
            *ngIf="usuario"
          >
            Resetear contraseña
          </button>
        </div>
      </div>
    </div>

    <!-- START ITEM -->
    <div
      class="card social-card share full-width no-border grid-card"
      data-social="item"
    >
      <div class="card-header clearfix m-b-20">
        <h5 class="no-margin text-primary">CAPACIDAD DE PAGO</h5>
      </div>
      <div class="card-body">
        <p>
          <span class="title-labels">Ingreso Mensual: </span>
          {{negocio.capacidadPago.ingresoMensual | currency}}
        </p>
        <p>
          <span class="title-labels">Gasto Mensual: </span>
          {{negocio.capacidadPago.gastoMensual | currency}}
        </p>
        <p>
          <span class="title-labels">Capacidad de Pago: </span> {{capacidadPago | currency}}
        </p>
      </div>
    </div>

    <!-- START ITEM -->
    <div
      class="card social-card share full-width no-border grid-card"
      data-social="item"
    >
      <div class="card-header clearfix m-b-20">
        <h5 class="no-margin text-primary">DATOS BANCARIOS</h5>
      </div>
      <div class="card-body">
        <p>
          <span class="title-labels">Beneficiario: </span>
          {{negocio.datosBancarios.beneficiario}}
        </p>
        <p>
          <span class="title-labels">Banco: </span>
          {{negocio.datosBancarios.banco}}
        </p>
        <p>
          <span class="title-labels">Cuenta: </span>
          {{negocio.datosBancarios.cuenta | datobancario}}
        </p>
        <p>
          <span class="title-labels">Número de Tarjeta: </span>
          {{negocio.datosBancarios.numTarjeta | datobancario}}
        </p>
        <p>
          <span class="title-labels">Clabe Interbancaria: </span>
          {{negocio.datosBancarios.cuentaClabe | datobancario}}
        </p>
      </div>
    </div>

    <!-- START ITEM -->
    <div
      class="card social-card share full-width no-border grid-card"
      data-social="item"
    >
      <!-- SECTION 1 -->
      <div class="card-header clearfix m-b-20">
        <h5 class="no-margin text-primary">NEGOCIO</h5>
      </div>
      <div class="card-body">
        <p>
          <span class="title-labels">Número de Negocio: </span>
          {{negocio.numNegocio}}
        </p>
        <p><span class="title-labels">Nombre: </span> {{negocio.nombre}}</p>
        <p>
          <span class="title-labels">Actividad Económica: </span>
          {{negocio.actividadEconomica}}
        </p>
        <p>
          <span class="title-labels">Antigüedad: </span> {{negocio.antiguedad}}
        </p>
      </div>
      <!-- SECTION 2 -->
      <div class="card-subtitle">
        <h5 class="pull-left fs-12 text-primary">TELÉFONOS</h5>
      </div>
      <div class="card-body">
        <div *ngFor="let tel of negocio.telefonos; let i = index">
          <p>
            <span class="title-labels">Número de Teléfono: </span>
            {{tel.telefono}}
          </p>
          <p>
            <span class="title-labels">Tipo de Teléfono: </span>
            {{tel.claveTipoTelefono}}
          </p>
        </div>
      </div>
      <!-- SECTION 3 -->
      <div class="card-subtitle">
        <h5 class="pull-left fs-12 text-primary">DIRECCIÓN</h5>
      </div>
      <div class="card-body">
        <p>
          <span class="title-labels">Calle: </span> {{negocio.direccion.calle}}
        </p>
        <p>
          <span class="title-labels">Colonia: </span>
          {{negocio.direccion.colonia}}
        </p>
        <p>
          <span class="title-labels">Número Exterior: </span>
          {{negocio.direccion.numExt}}
        </p>
        <p>
          <span class="title-labels">Número Interior: </span>
          {{negocio.direccion.numInt}}
        </p>
        <p>
          <span class="title-labels">Código Postal: </span>
          {{negocio.direccion.cp}}
        </p>
        <p>
          <span class="title-labels">Municipio: </span>
          {{negocio.direccion.municipio}}
        </p>
        <p>
          <span class="title-labels">Estado: </span>
          {{negocio.direccion.estado}}
        </p>
        <p>
          <span class="title-labels">País: </span> {{negocio.direccion.pais}}
        </p>
      </div>
    </div>

    <!-- START ITEM -->
    <div *ngIf="negocio.referencias != null && negocio.referencias.length > 0">
      <div *ngFor="let referencia of negocio.referencias; let i = index">
        <div
          class="card social-card share full-width no-border grid-card"
          data-social="item"
        >
          <div class="card-header clearfix m-b-20">
            <h5 class="no-margin text-primary">REFERENCIA {{ i + 1 }}</h5>
          </div>
          <!-- SECTION 1 -->
          <div class="card-body">
            <p>
              <span class="title-labels">Nombre: </span>
              {{referencia.persona.nombre}}
            </p>
            <p>
              <span class="title-labels">Apellido Paterno: </span>
              {{referencia.persona.apellidoPaterno}}
            </p>
            <p>
              <span class="title-labels">Apellido Materno: </span>
              {{referencia.persona.apellidoMaterno}}
            </p>
            <p>
              <span class="title-labels">Fecha de Nacimiento: </span>
              {{referencia.persona.nacimiento.fecha}}
            </p>
            <p>
              <span class="title-labels">CURP: </span>
              {{referencia.persona.curp}}
            </p>
            <p>
              <span class="title-labels">RFC: </span> {{referencia.persona.rfc}}
            </p>
            <p>
              <span class="title-labels">Número de Identificación: </span>
              {{referencia.persona.numIdentificacion}}
            </p>
            <p>
              <span class="title-labels">Lugar de Nacimiento: </span>
              {{referencia.persona.nacimiento.lugar}}
            </p>
            <p>
              <span class="title-labels">País de Nacimiento: </span>
              {{referencia.persona.nacimiento.pais}}
            </p>
            <p>
              <span class="title-labels">Nacionalidad: </span>
              {{referencia.persona.nacionalidad}}
            </p>
            <p>
              <span class="title-labels">Estado Civil: </span>
              {{referencia.persona.estadoCivil.descripcion}}
            </p>
            <p>
              <span class="title-labels">Sexo: </span> {{referencia.persona.sexo
              == "M" ? "Masculino" : "Femenino"}}
            </p>
          </div>
          <!-- SECTION 2 -->
          <div class="card-subtitle">
            <h5 class="pull-left fs-12 text-primary">CONTACTO</h5>
          </div>
          <div class="card-body">
            <p>
              <span class="title-labels">Correo Electrónico: </span>
              {{referencia.email}}
            </p>
            <div
              *ngFor="let tel of referencia.persona.telefonos; let i = index"
            >
              <p>
                <span class="title-labels">Número de Teléfono: </span>
                {{tel.telefono}}
              </p>
              <p>
                <span class="title-labels">Tipo de Teléfono: </span>
                {{tel.claveTipoTelefono}}
              </p>
            </div>
          </div>
          <!-- SECTION 3 -->
          <div class="card-subtitle">
            <h5 class="pull-left fs-12 text-primary">VIVIENDAS</h5>
          </div>
          <div
            class="card-body"
            *ngFor="let vivienda of referencia.persona.viviendas; let i = index"
          >
            <p>
              <span class="title-labels">Calle: </span>
              {{vivienda.direccion.calle}}
            </p>
            <p>
              <span class="title-labels">Colonia: </span>
              {{vivienda.direccion.colonia}}
            </p>
            <p>
              <span class="title-labels">Número Exterior: </span>
              {{vivienda.direccion.numExt}}
            </p>
            <p>
              <span class="title-labels">Número Interior: </span>
              {{vivienda.direccion.numInt}}
            </p>
            <p>
              <span class="title-labels">Código Postal: </span>
              {{vivienda.direccion.cp}}
            </p>
            <p>
              <span class="title-labels">Municipio: </span>
              {{vivienda.direccion.municipio}}
            </p>
            <p>
              <span class="title-labels">Estado: </span>
              {{vivienda.direccion.estado}}
            </p>
            <p>
              <span class="title-labels">País: </span>
              {{vivienda.direccion.pais}}
            </p>
            <p>
              <span class="title-labels">Tipo de Vivienda: </span>
              {{vivienda.tipoVivienda.descripcion}}
            </p>
            <p>
              <span class="title-labels">Con Escrituras: </span>
              {{vivienda.escrituras ? "Si" : "No"}}
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
