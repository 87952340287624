export default class UtilsFormats {

    static stringToDate(date: string):Date { 
        try {
            return new Date(date + "T00:00:00");
        } catch(e) {
            return null;
        }
    }

    static stringToDateEnd(date: string):Date { 
        try {
            return new Date(date + "T23:59:59");
        } catch(e) {
            return null;
        }
    }

    static dateToString(date: Date):string { 
        try {
            return date.toISOString().split('T')[0];
        } catch(e) {
            return null;
        }
    }
}