import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constants } from 'src/app/classes/constants.class';
import { negocioEmpty } from 'src/app/components/forms/negocios-form/negocios-form.component';
import { personaEmpty } from 'src/app/components/forms/persona-form/persona-form.component';
import { CatalogosSolicitud, CatResponse } from 'src/app/models/catalogues/cat-response.model';
import { ClienteInfoCompleta } from 'src/app/models/clients/cliente.model';
import { NegocioInfoCompleta } from 'src/app/models/business/negocio-request.model';
import { PersonaInfoCompleta } from 'src/app/models/person/persona.model';
import { TrackingSolicitudModel, SolicitudModel } from 'src/app/models/credits-application/solicitud.model';
import { AuthService } from 'src/app/services/auth.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { PersonaService } from 'src/app/services/persona.service';
import { SolicitudesService } from 'src/app/services/solicitudes.service';
import UtilsRest from 'src/utils/UtilsRest';
import { clienteEmpty } from '../../components/forms/clientes-form/clientes-form.component';

@Component({
  selector: 'app-capturar-solicitud',
  templateUrl: './capturar-solicitud.component.html',
  styleUrls: ['./capturar-solicitud.component.scss']
})
export class CapturarSolicitudComponent implements OnInit {

  private token: string = sessionStorage.getItem('token');

  // ELEMENTOS DEL HTML
  @ViewChild('pasoAdelante') pasoAdelante: any;
  @ViewChild('pasoAtras') pasoAtras: any;

  // VALIDACION ROL
  permisoUsuario: boolean;

  // PARAMETROS LOCALES
  numSolicitud: string;
  claveEstatusSolicitud: string;
  claveTipoSolicitud: string;

  // PARAMETROS LOCALES FORMULARIO
  negocio: NegocioInfoCompleta;
  persona: PersonaInfoCompleta;
  cliente: ClienteInfoCompleta;

  // PARAMETROS NAVEGACION
  paso: number = 1;
  pasoMax: number = 1;
  datosCompletos: boolean;

  // PARAMETRO VARIABLES
  catalogosSolicitud: CatalogosSolicitud;

  // CATALOGOS
  catMediosConocimiento: CatResponse[] = [];
  catNivelesEscolares: CatResponse[] = [];
  catOcupaciones: CatResponse[] = [];
  catFuentesIngresos: CatResponse[] = [];
  catEstadoCivil: CatResponse[] = [];
  catTipoVivienda: CatResponse[] = [];

  // PARAMETROS SERVICIOS EXTERNOS
  tracking: TrackingSolicitudModel[];
  solicitud: SolicitudModel;

  // CONSTANTES
  claveCapturaDatos = Constants.CLAVE_CAPTURA_DATOS;
  tituloActualizarEstatus = "Confirmar datos completos";

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private solicitudesService: SolicitudesService,
    private clienteService: ClienteService,
    private personaService: PersonaService,
  ) {}

  ngOnInit(): void {

    this.permisoUsuario = this.authService.permisosUsuario();

    this.activatedRoute.queryParams.subscribe(
      params => {
        this.numSolicitud = params['numSolicitud'];
        this.claveEstatusSolicitud = params['claveEstatusSolicitud'];
        this.claveTipoSolicitud = params['claveTipoSolicitud'];
      }
    );

    // Se obtiene información de los catálogos (uno tras otro)
    this.buscarSolicitud();
    this._getMediosConocimiento();
  }

  // METODOS HTML
  siguientePaso() {
    this.paso++
    if(this.paso >= 3) this.paso = 3;
  }

  anteriorPaso() {
    this.paso--;
    if(this.paso <= 1) this.paso = 1;
  }

  updateInfo() {
    this.buscarSolicitud();
  }

  // SERVICIOS EXTERNOS
  private buscarSolicitud() {
    this.solicitudesService.obtenerSolicitud(
      this.token, 
      this.numSolicitud, 
      true
    ).subscribe(resp => {

      this.solicitud = resp.resultado;
      this.tracking = this.solicitud.tracking;

      // Se busca información del persona / cliente / negocio
      this.buscarInformacion();

    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarSolicitud();
      }
    });
  }

  private _getInfoPersona() {
    const idsPersonas = [this.solicitud.negocio.idPersona];
    this.personaService.obtenerInfoPersonas(this.token, idsPersonas)
    .subscribe(resp => {

      this.persona = resp.resultados[0];
      
      // Se busca la información del cliente si existe
      if(this.solicitud.negocio.idCliente) {
        this._getInfoCliente();
      } else {
        this.cliente = clienteEmpty;
        // FIXME: Recuperar este dato desde backend
        this.cliente.persona = this.persona;

        this.pasoMax = 2;
        this.paso = 2;
      }

    }, err => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getInfoPersona();
      }
      if(status.codigo == 404) {
        this.negocio = negocioEmpty;
        this.persona = personaEmpty;
        this.cliente = clienteEmpty;
      } 
    });
  }

  private _getInfoCliente() {
    this.clienteService.obtenerCliente(
      this.token, 
      this.solicitud.negocio.idCliente
    )
    .subscribe(resp => {

      this.cliente = resp.resultado;
      this.paso = 3;
      this.pasoMax = 3;
      
      // FIXME: Recuperar este dato desde backend
      this.cliente.persona = this.persona;
      
      // Se busca la información del negocio si existe
      if(this.solicitud.negocio.idNegocio) {
        this._getInfoClienteNegocio(this.solicitud.negocio.idNegocio.toString());
      } else {
        this.negocio = negocioEmpty;
        // FIXME: Recuperar este dato desde backend
        this.negocio.cliente = this.cliente;
      }

    }, err => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getInfoPersona();
      }
      if(status.codigo == 404) {
        this.negocio = negocioEmpty;
        this.cliente = clienteEmpty;
        // FIXME: Recuperar este dato desde backend
        this.cliente.persona = this.persona;
        this.negocio.cliente = this.cliente;
      } 
    });
  }
  

  private _getInfoClienteNegocio(idNegocio: string) {
    const idsNegocios = [idNegocio];
    this.clienteService.obtenerClienteByIdNegocio(this.token, idsNegocios, true)
    .subscribe(resp => {

      this.negocio = resp.resultados[0];
      this.persona = this.negocio.cliente.persona;
      this.cliente = this.negocio.cliente;

      this.paso = 1;
      this.pasoMax = 3;
      this.datosCompletos = true;

    }, err => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getInfoClienteNegocio(idNegocio);
      }
      if(status.codigo == 404) {
        this.negocio = negocioEmpty;
        this.persona = personaEmpty;
        this.cliente = clienteEmpty;
      } 
    });
  }

  // SERVICIOS EXTERNOS (CATALOGOS)
  private _getMediosConocimiento() {
    this.clienteService.obtenerMedioConocimiento(this.token)
    .subscribe(resp => {
      this.catMediosConocimiento = resp.resultados;
      this._getNivelesEscolares();
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getMediosConocimiento();
      }
    });
  }

  private _getNivelesEscolares() {
    this.clienteService.obtenerNivelEscolar(this.token)
    .subscribe(resp => {
      this.catNivelesEscolares = resp.resultados;
      this._getFuentesIngresos();
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getNivelesEscolares();
      }
    });
  }

  private _getFuentesIngresos() {
    this.clienteService.obtenerFuenteIngresos(this.token)
    .subscribe(resp => {
      this.catFuentesIngresos = resp.resultados;
      this._getOcupaciones();
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getFuentesIngresos();
      }
    });
  }

  private _getOcupaciones() {
    this.clienteService.obtenerOcupacion(this.token)
    .subscribe(resp => {
      this.catOcupaciones = resp.resultados;
      this._getTipoVivienda();
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getOcupaciones();
      }
    });
  }

  private _getTipoVivienda() {
    this.personaService.obtenerTipoVivienda(this.token)
    .subscribe(resp => {
      this.catTipoVivienda = resp.resultados;
      this._getEstadoCivil();
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getTipoVivienda();
      }
    });
  }

  private _getEstadoCivil() {
    this.personaService.obtenerEstadoCivil(this.token)
    .subscribe(resp => {

      this.catEstadoCivil = resp.resultados;
      this.catalogosSolicitud = {
        catEstadoCivil: this.catEstadoCivil,
        catFuentesIngresos: this.catFuentesIngresos,
        catMediosConocimiento: this.catFuentesIngresos,
        catNivelesEscolares: this.catNivelesEscolares,
        catOcupaciones: this.catOcupaciones,
        catTipoVivienda: this.catTipoVivienda
      }
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getEstadoCivil();
      }
    });
  }

  // METODOS PRIVADOS
  buscarInformacion() {
    if(this.solicitud.negocio.idCliente && 
      this.solicitud.negocio.idPersona && 
      this.solicitud.negocio.idNegocio
    ){
      this._getInfoClienteNegocio(this.solicitud.negocio.idNegocio.toString());
    } else {
      this._getInfoPersona();
    }
  }

}
