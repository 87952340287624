import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Client } from '@stomp/stompjs'
import { NegociosIds } from 'src/app/components/clientes/buscar-cliente/buscar-cliente.component'
import { AutocompleteCliente } from 'src/app/models/clients/autocomplete-cliente.model'
import { SendSocket } from 'src/app/models/collections/cobranza.model'
import { CotizacionForm } from 'src/app/models/credits-application/cotizacion.model'
import { CuotaModel } from 'src/app/models/credits/cuota.model'
import { GuardarSolicitudModel } from 'src/app/models/credits-application/solicitud.model'
import { SolicitudesService } from 'src/app/services/solicitudes.service'
import UtilsSwal from 'src/utils/UtilsSwal'
import { Constants } from '../../../classes/constants.class'
import * as SockJS from 'sockjs-client'
import { WebsocketsService } from 'src/app/services/websockets.service'
import { AuthService } from 'src/app/services/auth.service'
import { NegocioModel } from 'src/app/models/business/negocio.model'

@Component({
  selector: 'app-cotizador-renovacion',
  templateUrl: './cotizador-renovacion.component.html',
  styleUrls: ['./cotizador-renovacion.component.scss']
})
export class CotizadorRenovacionComponent implements OnInit {
  private token: string = sessionStorage.getItem('token')
  private idUsuario: string = sessionStorage.getItem('idUsuario')
  private idSucursal: string = sessionStorage.getItem('idSucursal')

  // VALIDACION ROL
  permisoUsuario: boolean

  // PARAMETROS RECIBIDOS
  cotizacionForm: CotizacionForm

  // PARAMETROS LOCALES
  clienteSelect: AutocompleteCliente
  negocioSelect: NegocioModel
  cuotaSelect: CuotaModel
  nombreSolicitud: string
  claveEsquemaPago: string

  // CONSTANTES
  claveTipoSolicitud = Constants.CLAVE_TIPO_SOLICITUD_RENOVACION

  // SOCKETS
  private client: Client

  constructor (
    private router: Router,
    private authService: AuthService,
    private solicitudesService: SolicitudesService,
    private websocketsService: WebsocketsService
  ) {}

  ngOnInit (): void {
    this.permisoUsuario = this.authService.permisosUsuario()

    // Se realiza conexión a sockets
    this.client = new Client()
    this.client.webSocketFactory = () => {
      return new SockJS(this.websocketsService.getPathSockSolicitudesJS())
    }

    this.client.activate()
  }

  ngOnDestroy (): void {
    this.cotizacionForm = null
    this.cuotaSelect = null
    this.claveEsquemaPago = null
  }

  // METODOS HTML
  setInformation (negociosIds: NegociosIds) {
    this.clienteSelect = negociosIds.cliente
    this.negocioSelect = negociosIds.negocio

    if (this.negocioSelect) {
      this.nombreSolicitud = negociosIds.cliente.nombre
    } else {
      this.nombreSolicitud = null
      this.cotizacionForm = null
    }
  }

  setCotizacion (cotizacionForm: CotizacionForm) {
    this.cotizacionForm = cotizacionForm
  }

  setCuotaSelect (cuota: CuotaModel, esquemaPago: string) {
    this.cuotaSelect = cuota
    this.claveEsquemaPago = esquemaPago
  }

  solicitar () {
    const guardarSolicitud: GuardarSolicitudModel = {
      claveEsquemaPago: this.claveEsquemaPago,
      claveTipoSolicitud: this.cotizacionForm.cotizacion.tipoSolicitud.clave,
      nombreSolicitud: this.cotizacionForm.nombreSolicitud,
      claveTipoCredito: this.cotizacionForm.tipoCredito,
      idNegocio: this.negocioSelect.idNegocio.toString(),
      idSucursal: this.idSucursal,
      usuarioUltMod: this.idUsuario,
      credito: {
        comision: this.cotizacionForm.cotizacion.comision,
        montoCredito: this.cotizacionForm.cotizacion.montoCredito,
        numPagos: this.cuotaSelect.numPagos,
        tasaInteres: this.cuotaSelect.tasaInteres
      }
    }

    UtilsSwal.enviarInformacion(
      'Guardar solicitud',
      'Guardando solicitud',
      'Guardar',
      this.solicitudesService.guardarSolicitud(this.token, guardarSolicitud),
      false
    )
      .then(() => this._enviarInfoSocket())
      .then(() => this.router.navigate(['/solicitudes']))
  }

  // METODOS PRIVADOS
  private _enviarInfoSocket () {
    const sendSocket: SendSocket = {
      idSucursal: this.idSucursal
    }
    this.client.publish({
      destination: '/send/solicitudes',
      body: JSON.stringify(sendSocket)
    })
  }
}
