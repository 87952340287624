import { Component, OnInit } from '@angular/core';
import { InteresadoPaginaWebModel } from 'src/app/models/credits-application/point.model';
import { LoadjsService } from 'src/app/services/loadjs.service';
import { PointService } from '../../services/point.service';
import { Constants } from 'src/app/classes/constants.class';
import UtilsRest from 'src/utils/UtilsRest';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-pagina-web',
  templateUrl: './pagina-web.component.html',
  styleUrls: ['./pagina-web.component.scss']
})
export class PaginaWebComponent implements OnInit {

  private token: string = sessionStorage.getItem('token');

  // VARIABLES LOCALES
  interesados: InteresadoPaginaWebModel[];
  interesadosPorRevisar: InteresadoPaginaWebModel[];
  interesadosValidacionDatos: InteresadoPaginaWebModel[];
  clientesPotenciales: InteresadoPaginaWebModel[];
  creditosOtorgados: InteresadoPaginaWebModel[];

  constructor(
    private loadjsService: LoadjsService,
    private authService: AuthService, 
    private pointService: PointService,
  ) { }

  ngOnInit(): void {
    this.loadjsService.loadScript();
    this.obtenerSolicitudes();
  }

  // METODOS PRIVADOS
  filtrarInteresadosEstatus(interesados: InteresadoPaginaWebModel[]) {

    this.interesadosPorRevisar = interesados
      .filter(interesado => interesado.cotizacion.estatus.clave === Constants.CLAVE_POR_REVISAR);

    this.interesadosValidacionDatos = interesados
      .filter(interesado => interesado.cotizacion.estatus.clave === Constants.CLAVE_VALIDACION_DATOS);
      
    this.clientesPotenciales = interesados
      .filter(interesado => interesado.cotizacion.estatus.clave === Constants.CLAVE_CLIENTE_POTENCIAL);

    this.creditosOtorgados = interesados
      .filter(interesado => interesado.cotizacion.estatus.clave === Constants.CLAVE_CREDITO_OTROGADO);
  }

  // SERVICIOS DE TERCEROS
  obtenerSolicitudes(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.pointService.obtenerInteresados(this.token)
      .subscribe(resp => {
        
        this.interesados = resp.resultados;
        this.filtrarInteresadosEstatus(resp.resultados);
        resolve(true);
  
      }, (err) => {

        const status = UtilsRest.validaApiError(err, false, this.authService)
        if(status.codigo == 401) {
          this.authService.guardarToken(status.accessToken, status.refreshToken)
          this.obtenerSolicitudes();
        }

        reject();
      });
    });
  }

}
