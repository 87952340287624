import { Observable } from "rxjs";
import Swal from "sweetalert2";

export default class UtilsSwal {

  static buscandoInformacion() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Buscando información...',
      heightAuto: false
    });
  }
  
  static sesionExpirada() {
    Swal.fire({
      icon: 'info',
      title: 'Ha pasado demasiado tiempo',
      text: 'Por seguridad es necesario que vuelva a iniciar sesión',
      heightAuto: false
    });
  }

  static lanzarError(titulo: string, texto: string) {
    Swal.fire({
      icon: 'error',
      title: titulo,
      text: texto,
      heightAuto: false
    });
  }

  static lanzarWarning(titulo: string, texto: string) {
    Swal.fire({
      icon: 'warning',
      title: titulo,
      text: texto,
      heightAuto: false
    });
  }

  static enviarInformacion(
    title:string, 
    secondTitle: string,
    action: string,
    func: Observable<any>, 
    reloadPage: boolean
  ): Promise<any>{

    return new Promise((resolve, reject) => {
      Swal.fire({
        title: title,
        text: 'Esta acción no se podrá revertir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#8973D9',
        cancelButtonText: 'Cancelar',
        confirmButtonText: action,
        heightAuto: false
      })
      .then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            allowOutsideClick: false,
            icon: 'info',
            text: secondTitle
          });
          Swal.showLoading();
          func.subscribe((apiResponse) => {
            Swal.close();
            if (reloadPage) {
              window.location.reload();
            }
            resolve(apiResponse);
          }, err => {
            // TODO: Agregar 401
            this.lanzarError(
              `Ocurrio un error al ${action.toLowerCase()} la información`,
              err.error.detalles[0].mensaje
            );
            reject();
          });
        }
      }, err => {
        reject();
      });
    });
  }

  static generarReporte(func: Observable<any>) {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Generando Reporte...'
    });
    Swal.showLoading();
    func.subscribe((resp) => {
      Swal.close();
      var downloadURL = window.URL.createObjectURL(resp.body);
      var link = document.createElement('a');

      link.href = downloadURL;
      link.setAttribute('target', '_blank');
      link.click();
    }, err => {
      // TODO: Agregar 401
      if( err.status === 404) {
        this.lanzarWarning (
          "Información no encontrada",
          "Lo sentimos, no encontramos información con los datos enviados"
        );
      } else {
        this.lanzarError(
          "Ocurrio un error al generar el reporte", 
          "Lo sentimos, no pudimos generar el reporte"
        ) 
      }
    })
  }
}