<!-- START card -->
<div class="card card-default">
    <div class=" card-body">
        <h4 class="text-primary">Datos del cliente</h4>
        <form autocomplete="off" [formGroup]="formDatosTemporales" (ngSubmit)="guardarDatosTemporales()">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Dependientes económicos</label>
                        <input type="number" min="0" class="form-control" placeholder="Dependientes económicos" formControlName="numDependientesEconomicos" [class.is-invalid]="numDependientesEconomicosNoValido" id="numDependientesEconomicos">
                        <small *ngIf="numDependientesEconomicosNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Correo electrónico</label>
                        <input type="text" class="form-control" placeholder="Correo electrónico" formControlName="email" [class.is-invalid]="emailNoValido" id="email">
                        <small *ngIf="emailNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group form-select">
                        <label>Estado Civil</label>
                        <select class="selectpicker" formControlName="estadoCivil" [class.is-invalid]="estadoCivilNoValido">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let estadoCivil of catEstadoCivil" [ngValue]="estadoCivil.clave">{{estadoCivil.descripcion}}</option>
                        </select>
                        <small *ngIf="estadoCivilNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group form-select">
                        <label>Nivel escolar</label>
                        <select class="selectpicker" formControlName="nivelEscolar" [class.is-invalid]="nivelEscolarNoValido">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let nivelEscolar of catNivelesEscolares" [ngValue]="nivelEscolar.clave">{{nivelEscolar.descripcion}}</option>
                        </select>
                        <small *ngIf="nivelEscolarNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group form-select">
                        <label>Ocupación</label>
                        <select class="selectpicker" formControlName="ocupacion" [class.is-invalid]="ocupacionNoValido">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let ocupacion of catOcupaciones" [ngValue]="ocupacion.clave">{{ocupacion.descripcion}}</option>
                        </select>
                        <small *ngIf="ocupacionNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group form-select">
                        <label>Tipo de vivienda</label>
                        <select class="selectpicker" formControlName="tipoVivienda" [class.is-invalid]="tipoViviendaNoValido">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let tipoVivienda of catTipoVivienda" [ngValue]="tipoVivienda.clave">{{tipoVivienda.descripcion}}</option>
                        </select>
                        <small *ngIf="tipoViviendaNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="row m-b-30">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Tiene escrituras</label>
                        <div class="form-group">
                            <div class="form-check-inline form-radio-buttons">
                                <input class="form-check-input" type="radio" value="S" formControlName="escrituras">
                                <label class="form-check-label">Si</label> &nbsp; &nbsp;
                                <input class="form-check-input" type="radio" value="N" formControlName="escrituras">
                                <label class="form-check-label">No</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <app-telefonos #appTelefonosCliente [telefonos]="telefonosCliente"></app-telefonos>

            <div class="row">
                <h5 class="text-primary">Datos del negocio</h5>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Antiguedad (meses)</label>
                        <input type="number" min="0" class="form-control" placeholder="Antiguedad" formControlName="antiguedadNegocio" [class.is-invalid]="antiguedadNegocioNoValido" id="antiguedadNegocio">
                        <small *ngIf="antiguedadNegocioNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Actividad económica</label>
                        <input type="text" class="form-control" placeholder="Actividad económica" formControlName="actividadEconomicaNegocio" [class.is-invalid]="actividadEconomicaNegocioNoValido" id="actividadEconomicaNegocio">
                        <small *ngIf="actividadEconomicaNegocioNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Entidad financiera anterior</label>
                        <input type="text" class="form-control" placeholder="Entidad financiera anterior" formControlName="entidadFinancieraAnterior" [class.is-invalid]="entidadFinancieraAnteriorNoValido" id="entidadFinancieraAnterior">
                        <small *ngIf="entidadFinancieraAnteriorNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group form-select">
                        <label>Medio de conocimiento</label>
                        <select class="selectpicker" formControlName="medioConocimiento" [class.is-invalid]="medioConocimientoNoValido">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let medioConocimiento of catMediosConocimiento" [ngValue]="medioConocimiento.clave">{{medioConocimiento.descripcion}}</option>
                        </select>
                        <small *ngIf="medioConocimientoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group form-select">
                        <label>Fuente de ingresos</label>
                        <select class="selectpicker" formControlName="fuenteIngreso" [class.is-invalid]="fuenteIngresoNoValido">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let fuenteIngreso of catFuentesIngresos" [ngValue]="fuenteIngreso.clave">{{fuenteIngreso.descripcion}}</option>
                        </select>
                        <small *ngIf="fuenteIngresoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>


            <app-telefonos #appTelefonosNegocio [telefonos]="telefonosNegocio"></app-telefonos>

            <div class="row">
                <h5 class="text-primary">Ingresos</h5>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Ingresos cliente</label>
                        <input type="number" min="0" class="form-control" placeholder="Ingresos cliente" formControlName="ingresoCliente" [class.is-invalid]="ingresoClienteNoValido" id="ingresoCliente">
                        <small *ngIf="ingresoClienteNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Ingresos negocio</label>
                        <input type="number" min="0" class="form-control" placeholder="Ingresos negocio" formControlName="ingresoNegocio" [class.is-invalid]="ingresoNegocioNoValido" id="ingresoNegocio">
                        <small *ngIf="ingresoNegocioNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Ingresos apoyo familiar</label>
                        <input type="number" min="0" class="form-control" placeholder="Entidad financiera anterior" formControlName="ingresoApoyoFamiliar" [class.is-invalid]="ingresoApoyoFamiliarNoValido" id="ingresoApoyoFamiliar">
                        <small *ngIf="ingresoApoyoFamiliarNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Otro ingreso</label>
                        <input type="number" min="0" class="form-control" placeholder="Ingresos cliente" formControlName="otroIngreso" [class.is-invalid]="otroIngresoNoValido" id="otroIngreso">
                        <small *ngIf="otroIngresoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <h5 class="text-primary">Gastos</h5>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Gastos alimentos</label>
                        <input type="number" min="0" class="form-control" placeholder="Gastos alimentos" formControlName="gastosAlimento" [class.is-invalid]="gastosAlimentoNoValido" id="gastosAlimento">
                        <small *ngIf="gastosAlimentoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Gastos servicios básicos</label>
                        <input type="number" min="0" class="form-control" placeholder="Gastos servicios básicos" formControlName="gastosServiciosBasicos" [class.is-invalid]="gastosServiciosBasicosNoValido" id="gastosServiciosBasicos">
                        <small *ngIf="gastosServiciosBasicosNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Gastos prestamos</label>
                        <input type="number" min="0" class="form-control" placeholder="Gastos prestamos" formControlName="gastosPrestamos" [class.is-invalid]="gastosPrestamosNoValido" id="gastosPrestamos">
                        <small *ngIf="gastosPrestamosNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Gastos renta</label>
                        <input type="number" min="0" class="form-control" placeholder="Gastos renta" formControlName="gastosRenta" [class.is-invalid]="gastosRentaNoValido" id="gastosRenta">
                        <small *ngIf="gastosRentaNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Gastos materia prima</label>
                        <input type="number" min="0" class="form-control" placeholder="Gastos materia prima" formControlName="gastosMateriaPrima" [class.is-invalid]="gastosMateriaPrimaNoValido" id="gastosMateriaPrima">
                        <small *ngIf="gastosMateriaPrimaNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Otro gasto</label>
                        <input type="number" min="0" class="form-control" placeholder="Otro gasto" formControlName="otroGasto" [class.is-invalid]="otroGastoNoValido" id="otroGasto">
                        <small *ngIf="otroGastoNoValido" class="text-danger">Campo requerido</small>
                    </div>
                </div>
            </div>

            <div class="full-width m-t-20">
                <button type="button" (click)="eliminarSolicitud()" class="btn btn-danger float-left" *ngIf="mostrarBotonEliminar()">Cancelar Solicitud</button>
                <button type="submit" class="btn btn-primary float-right">Guardar</button>
            </div>
        </form>
    </div>
</div>
<!-- END card -->