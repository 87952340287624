<div class="card social-card share full-width m-b-10 no-border" data-social="item">
    <div class="card-header clearfix">
        <h5 class="pull-left fs-12">REPORTES</h5>
    </div>
    <div class="card-description">
        <h5 class='no-margin text-primary'>Cobranza</h5>
    </div>
    <div class="card-body">
        <label>Seleccionar fecha</label>
        <div class="input-group date col-md-6 p-l-0">
            <input type="text" class="form-control" id="datepicker-cobranza">
        </div>
        <br>
        <button (click)="generarReporteCobranza()" class="btn btn-primary float-right">Generar reporte</button>
    </div>
</div>