<div [formGroup]="formViviendas">

    <app-direcciones #appDirecciones [direccion]="direccion"></app-direcciones>

    <div class="row">
        <div class="col-md-4">
            <div class="form-group form-select">
                <label>Tipo Vivienda</label>
                <select class="selectpicker" formControlName="tipoVivienda" [class.is-invalid]="tipoViviendaNoValido">
                    <option value="">Selecciona una opción</option>
                    <option *ngFor="let tipoVivienda of catTipoVivienda" [ngValue]="tipoVivienda.clave">{{tipoVivienda.descripcion}}</option>
                </select>
                <small *ngIf="tipoViviendaNoValido" class="text-danger">Campo requerido</small>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Tiene escrituras</label>
                <div class="form-group">
                    <div class="form-check-inline form-radio-buttons">
                        <input class="form-check-input" type="radio" value="S" formControlName="escrituras">
                        <label class="form-check-label">Si</label> &nbsp;
                        <input class="form-check-input" type="radio" value="N" formControlName="escrituras">
                        <label class="form-check-label">No</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>