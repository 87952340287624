<div class="row full-height p-b-10">
  <!-- TOTAL CREDITOS-->
  <div class="col-md-4 phone-space">
    <div class="card share full-width full-height no-border">
      <div class="card-body d-grid">
        <div class="container-center">
          <h5 class="no-margin text-primary text-center">Total de créditos</h5>
        </div>
        <div class="container-center">
          <h1 class="no-margin p-t-5">{{totalCreditos}}</h1>
        </div>
      </div>
    </div>
  </div>
  <!-- NO COBRADOS -->
  <div class="col-md-4 phone-space">
    <div class="card share full-width full-height no-border">
      <div class="card-body d-grid">
        <div class="container-center">
          <h5 class="no-margin text-primary text-center">Por visitar</h5>
        </div>
        <div class="container-center">
          <h1 class="no-margin p-t-5">{{totalNoVisitados}}</h1>
        </div>
      </div>
    </div>
  </div>
  <!-- COBRADOS -->
  <div class="col-md-4 phone-space">
    <div class="card share full-width full-height no-border">
      <div class="card-body d-grid">
        <div class="container-center">
          <h5 class="no-margin text-primary text-center">Visitados</h5>
        </div>
        <div class="container-center">
          <h1 class="no-margin p-t-5">{{totalVisitados}}</h1>
        </div>
      </div>
    </div>
  </div>
</div>
