<app-sidebar></app-sidebar>
<div class="horizontal-menu horizontal-app-menu dashboard">
  <app-header></app-header>
  <div class="page-container pl-5" *ngIf="permisoUsuario">
    <div class="page-content-wrapper ">
      <div class="content sm-gutter">
        <div class="container sm-padding-10 p-t-20 p-l-0 p-r-0">

          <!-- START ROW -->
          <div class="row">

            <!-- BUSCAR CLIENTE NEGOCIO -->
            <div class="col-lg-7 d-flex">
              <app-buscar-cliente
                class="full-width"
                (negocioEmit)="setInformation($event)"
              ></app-buscar-cliente>
            </div>

            <!-- ESTATUS VERIFICACION DATOS -->
            <div class="col-lg-5">
              <div
                class="card social-card share full-width m-b-10 no-border"
                data-social="item"
              >

                <div class="card-header clearfix">
                  <h5 class="pull-left fs-12">VERIFICACIÓN DE DATOS</h5>
                </div>
                <div class="h-200" *ngIf="!estatusVerificacion">
                  <div class="icon-center">
                    <i
                      class="far fa-hand-point-left text-complete icon-instructions-big"
                    ></i>
                    <div class="instructions-body">
                      <h4 class="no-margin text-primary">INSTRUCCIONES</h4>
                      <p class="no-margin">
                        Busca un cliente por su nombre en la sección indicada
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="h-200"
                  *ngIf="estatusVerificacion && estatusVerificacion.clave == 'DC'"
                >
                  <div class="icon-center">
                    <i class="far fa-times-circle icon-big text-warning"></i>
                    <div class="subtitle-body">
                      <h4 class="no-margin text-info">SIN VERIFICAR</h4>
                      <p>
                        La información ya fue capturada pero no se ha verificado
                        su autenticidad
                      </p>
                    </div>
                  </div>
                  <div class="button-position">
                    <button
                      (click)="modificarDatos()"
                      class="btn btn-primary button-margin"
                    >
                      Modificar
                    </button>
                    <button
                      (click)="realizarVerificacion()"
                      class="btn btn-primary button-margin"
                      *ngIf="permisoUsuario"
                    >
                      Verificar
                    </button>
                  </div>
                </div>

                <div
                  class="h-200"
                  *ngIf="estatusVerificacion && estatusVerificacion.clave == 'DA'"
                >
                  <div class="icon-center">
                    <i class="far fa-times-circle icon-big text-warning"></i>
                    <div class="subtitle-body">
                      <h4 class="no-margin text-info">DATOS ACTUALIZADOS</h4>
                      <p>
                        La información fue actualizada recientemente y no se ha
                        verificado su autenticidad
                      </p>
                    </div>
                  </div>
                  <div class="button-position">
                    <button
                      (click)="modificarDatos()"
                      class="btn btn-primary button-margin"
                    >
                      Modificar
                    </button>
                    <button
                      (click)="realizarVerificacion()"
                      class="btn btn-primary button-margin"
                      *ngIf="permisoUsuario"
                    >
                      Verificar
                    </button>
                  </div>
                </div>

                <div
                  class="h-200"
                  *ngIf="estatusVerificacion && estatusVerificacion.clave == 'VER'"
                >
                  <div class="icon-center">
                    <i class="far fa-check-circle icon-big text-success"></i>
                    <div class=" subtitle-body ">
                      <h4 class="no-margin text-success-dark">VERIFICADOS</h4>
                      <p>La información ya fue verificada por un usuario</p>
                    </div>
                  </div>
                  <div class="button-position">
                    <button
                      (click)="modificarDatos()"
                      class="btn btn-primary button-margin"
                    >
                      Modificar
                    </button>
                  </div>
              </div>
            </div>
          </div>

          <!-- DETALLE DEL CLIENTE -->
          <app-detalle-cliente
            [idNegocio]="negocioSelect.idNegocio"
            *ngIf="negocioSelect"
          ></app-detalle-cliente>

        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
