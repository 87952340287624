<app-sidebar></app-sidebar>
<div class="horizontal-menu horizontal-app-menu dashboard">
    <app-header></app-header>
    <div class="page-container pl-5">
        <div class="page-content-wrapper ">
            <div class="content sm-gutter">
                <div class="container sm-padding-10 p-t-20 p-l-0 p-r-0">
                    <!-- START ROW -->
                    <div class="row">
                        <div class="col-lg-7 d-flex">
                            <!-- START ITEM CREDITOS -->
                            <div class="card social-card share  full-width m-b-10 no-border" data-social="item">
                                <div class="card-header clearfix">
                                    <h5 class="pull-left fs-12">CRÉDITOS</h5>
                                </div>
                                <div class="card-description">
                                    <h5 class='no-margin text-primary'>Buscar información del crédito</h5>
                                    <div class="form-check m-t-5">
                                        <input type="checkbox" (change)="onCheckboxSelect($event)" id="check-cred-act" [checked]="true">
                                        <label for="check-cred-act"> Buscar solo créditos activos </label>
                                    </div>
                                </div>

                                <div class="inner-card p-b-10">
                                    <!-- Radio buttons -->
                                    <p>Tipo de busqueda</p>
                                    <div class="form-group form-check-inline">
                                        <input class="form-check-input" type="radio" value="cliente" name="radTipoBusqueda" (change)="onItemChange($event.target.value)">
                                        <label class="form-check-label">Cliente</label> &nbsp; &nbsp;
                                        <input class="form-check-input" type="radio" value="credito" name="radTipoBusqueda" [checked]="true" (change)="onItemChange($event.target.value)">
                                        <label class="form-check-label">No. crédito</label>
                                    </div>
                                </div>

                                <div class="inner-card p-b-20">
                                    <!-- Busqueda por cliente -->
                                    <app-cli-neg-cred #appCliNegCred [creditosActivos]="creditosActivos" (enableButton)="updateButtonState($event)" *ngIf="tipoBusqueda === 'cliente'"></app-cli-neg-cred>

                                    <!-- Busqueda por numero de credito -->
                                    <div *ngIf="tipoBusqueda === 'credito'">
                                        <label class="form-control-label" for="input-cliente" style="margin-top: 8px">Buscar crédito</label>
                                        <ng-autocomplete placeholder="Buscar crédito por número de contrato" [data]="todosNumContratos" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [initialValue]="numContrato" (selected)='selectEvent($event)' (inputCleared)='clearedEvent()'>
                                        </ng-autocomplete>
                                        <ng-template #itemTemplate let-item>
                                            <a [innerHTML]="item"></a>
                                        </ng-template>
                                        <ng-template #notFoundTemplate let-notFound>
                                            <div>No existen registros</div>
                                        </ng-template>
                                    </div>

                                    <button *ngIf="showButton" (click)="buscarCredito()" type="button" class="btn btn-primary float-right m-t-20">
                                        Buscar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <app-detalle-credito [numContrato]="numContrato"></app-detalle-credito>
                        </div>
                    </div>

                    <!-- START CUOTAS -->
                    <app-cuotas [cuotas]="cuotas"></app-cuotas>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>