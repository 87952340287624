import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClienteRequest } from '../models/clients/cliente-request.model';
import { EstatusSolicitudRequest } from '../models/credits-application/estatus-solicitud.model';
import { NegocioRequest } from '../models/business/negocio-request.model';
import { PersonaRequest } from '../models/person/persona-request.model';
import { ReferenciaModel } from '../models/credits-application/referencia.model';

@Injectable({
  providedIn: 'root'
})
export class CreditoService {

  constructor(private http: HttpClient) { }

  // GET - CREDITOS
  obtenerCreditosAutocomplete(accessToken: string, numContrato: string, idSucursal: string, activos: boolean): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/creditos/v1/creditos/autocomplete?numContrato=${numContrato}&idSucursal=${idSucursal}&activos=${activos}`, 
      {headers: headers}
    );
  }

  obtenerDetalleCredito(accessToken: string, numContrato: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/creditos/v1/credito?numContrato=${numContrato}`, 
      {headers: headers}
    );
  }

  obtenerCreditoByNegocio(accessToken: string, idNegocio: number, activos: boolean): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/creditos/v1/creditos/negocio?idNegocio=${idNegocio}&activos=${activos}`, 
      {headers: headers}
    );
  }

  obtenerEstatusCreditos(accessToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/creditos/v1/catalogo/estatus/creditos`, 
      {headers: headers}
    );
  }

  obtenerCatalogoTiposCredito(accessToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/creditos/v1/catalogo/tipos-creditos`, 
      {headers: headers}
    );
  }

  obtenerCatalogoEsquemaPago(accessToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/creditos/v1/catalogo/esquemas-pagos`, 
      {headers: headers}
    );
  }

  // PUT - CREDITOS
  actualizarEstatus(accessToken: string, estatusSolicitud: EstatusSolicitudRequest): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.put(
      `${environment.url}/creditos/v1/solicitud/estatus`, JSON.stringify(estatusSolicitud), {headers: headers});
  }

  // GET - CUOTAS

  obtenerHistoricoCuotas(accessToken: string, numContrato: string, usuarioCompleto: boolean, orden: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/creditos/v1/cuotas/historico?numContrato=${numContrato}&usuarioCompleto=${usuarioCompleto}&orden=${orden}`, 
      {headers: headers}
    );
  }
  
  // POST - CREDITOS (PERSONAS)

  guardarPersona(accessToken: string, personaRequest: PersonaRequest): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/creditos/v1/persona`,
      JSON.stringify(personaRequest),
      {headers: headers}
    );
  }

  guardarReferencia(accessToken: string, referencia: ReferenciaModel): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/creditos/v1/referencias`,
      JSON.stringify(referencia),
      {headers: headers}
    );
  }

  // POST - CREDITOS (CLIENTES)

  guardarCliente(accessToken: string, clienteRequest: ClienteRequest): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/creditos/v1/cliente`,
      JSON.stringify(clienteRequest),
      {headers: headers}
    );
  }

  // POST - CREDITOS (NEGOCIOS)

  guardarNegocio(accessToken: string, negocioRequest: NegocioRequest): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/creditos/v1/negocio`,
      JSON.stringify(negocioRequest),
      {headers: headers}
    );
  }
}
