import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CatResponse } from 'src/app/models/catalogues/cat-response.model';
import { CotizacionForm } from 'src/app/models/credits-application/cotizacion.model';
import { AuthService } from 'src/app/services/auth.service';
import { CreditoService } from 'src/app/services/credito.service';
import Utils from 'src/utils/Utils';
import UtilsRest from 'src/utils/UtilsRest';
import { BuscarCotizacionModel } from '../../../models/credits-application/cotizacion.model';
import { Constants } from '../../../classes/constants.class';
import { SolicitudesService } from 'src/app/services/solicitudes.service';
import UtilsSwal from 'src/utils/UtilsSwal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cotizador-clientes-nuevos-form',
  templateUrl: './cotizador-clientes-nuevos-form.component.html',
  styleUrls: ['./cotizador-clientes-nuevos-form.component.scss']
})
export class CotizadorClientesNuevosFormComponent implements OnInit, OnChanges {

  private token: string = sessionStorage.getItem('token');
  private idSucursal: string = sessionStorage.getItem('idSucursal');

  // EVENTOS DE SALIDA
  @Output() cotizacionEvent = new EventEmitter<CotizacionForm>();

  // PARAMETROS RECIBIDOS
  @Input() claveTipoSolicitud: string;

  // PARAMETROS LOCALES
  catalogoEsquemasPago: CatResponse[];
  catalogoTiposCredito: CatResponse[];
  cotizacion: CotizacionForm;
  promocion: boolean;

  // FORMS
  formCotizacion: UntypedFormGroup;

  // CONSTANTES
  claveCreditoGeneral = Constants.CLAVE_TIPO_CREDITO_GENERAL

  constructor(
    private fb: UntypedFormBuilder,
    private creditoService: CreditoService,
    private solicitudesService: SolicitudesService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.buscarCatalogoEsquemaPago();
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.crearFormulario();
    this.claveTipoSolicitud = changes.claveTipoSolicitud.currentValue;

    if(this.claveTipoSolicitud == Constants.CLAVE_TIPO_SOLICITUD_PREMIUM) {
      this.promocion = true;
    } else {
      this.promocion = false;
    }
  }

  // METODOS FORMS
  crearFormulario() {
    this.formCotizacion = this.fb.group({
      nombreSolicitud: ['', Validators.required],
      esquemaPago: ['', Validators.required],
      monto: ['', Validators.required],
      promocion: [false],
    });
  }

  // METODOS HTNL
  limpiar(){
    this.formCotizacion.get('esquemaPago').setValue(null);
    this.formCotizacion.get('monto').setValue(null);

    this.cotizacion = null;
    this.cotizacionEvent.emit(null);
  }

  generarCotizacion() {
    if (this.formCotizacion.invalid) {
      return Utils.validateForms(this.formCotizacion)
    }

    const buscarCotizacion: BuscarCotizacionModel = {
      claveEsquemaPago: this.formCotizacion.get('esquemaPago').value,
      montoCredito: this.formCotizacion.get('monto').value,
      promocion: this.promocion,
      claveTipoSolicitud: this.claveTipoSolicitud,
      idSucursal: this.idSucursal
    }
    
    this.buscarCotizacion(buscarCotizacion);
  }

  onCheckboxSelect(event) {
    // Check de dirección de la persona
    if(event.target.checked === true) {
      this.promocion = true;
    } else {
      this.promocion = false;
    }
  }

  // SERVICIOS EXTERNOS
  buscarCatalogoEsquemaPago() {
    this.creditoService.obtenerCatalogoEsquemaPago(
      this.token, 
    ).subscribe(resp => {
      
      var catalogo = resp.resultados;
      this.catalogoEsquemasPago = catalogo;

    }, (err) => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarCatalogoEsquemaPago();
      }
      if(status.codigo == 404) {
        this.catalogoEsquemasPago = [];
      }
    });
  }

  buscarCotizacion(buscarCotizacion: BuscarCotizacionModel) {
    UtilsSwal.buscandoInformacion();
    Swal.showLoading();

    this.solicitudesService.obtenerCotizacionClienteNuevo(
      this.token, 
      buscarCotizacion
    ).subscribe(resp => {

      Swal.close();

      const cotizacionForm: CotizacionForm = {
        cotizacion: resp.resultado,
        nombreSolicitud: this.formCotizacion.get('nombreSolicitud').value,
        tipoCredito: this.claveCreditoGeneral
      }

      this.cotizacion = cotizacionForm;
      this.cotizacionEvent.emit(this.cotizacion);

    }, err => {

      Swal.close();
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarCotizacion(buscarCotizacion);
      }

    });
  }

  // VALIDACIONES (DATOS NO VALIDOS)
  get nombreSolicitudsNoValido() {
    return this.formCotizacion.get('nombreSolicitud').invalid && this.formCotizacion.get('nombreSolicitud').touched
  }

  get esquemaPagooNoValido() {
    return this.formCotizacion.get('esquemaPago').invalid && this.formCotizacion.get('esquemaPago').touched
  }

  get montoNoValido() {
    return this.formCotizacion.get('monto').invalid && this.formCotizacion.get('monto').touched
  }
}
