import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NegociosIds } from 'src/app/components/clientes/buscar-cliente/buscar-cliente.component'
import { AutocompleteCliente } from 'src/app/models/clients/autocomplete-cliente.model'
import { CatResponse } from 'src/app/models/catalogues/cat-response.model'
import { ClienteVerificacionDatos } from 'src/app/models/clients/cliente.model'
import { AuthService } from 'src/app/services/auth.service'
import { ClienteService } from 'src/app/services/cliente.service'
import UtilsRest from 'src/utils/UtilsRest'
import UtilsSwal from 'src/utils/UtilsSwal'
import { NegocioModel } from 'src/app/models/business/negocio.model'

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesPageComponent implements OnInit {
  private token: string = sessionStorage.getItem('token')
  private idSucursal: string = sessionStorage.getItem('idSucursal')
  private idUsuario: string = sessionStorage.getItem('idUsuario')

  // VALIDACION ROL
  permisoUsuario: boolean

  // PARAMETROS LOCALES
  clienteSelect: AutocompleteCliente
  negocioSelect: NegocioModel
  estatusVerificacion: CatResponse

  constructor (
    private clienteService: ClienteService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit (): void {
    this.permisoUsuario = this.authService.permisosUsuario()
  }

  // METODOS HTML
  setInformation (negociosIds: NegociosIds) {
    this.clienteSelect = negociosIds.cliente
    this.negocioSelect = negociosIds.negocio

    if (this.clienteSelect && this.negocioSelect) {
      this._buscarEstatusVerificacion(
        this.clienteSelect.idCliente.toString(),
        this.negocioSelect.idNegocio.toString()
      )
    } else {
      this.estatusVerificacion = null
    }
  }

  _buscarEstatusVerificacion (idCliente: string, idNegocio: string) {
    this.clienteService
      .obtenerEstatusVerificacion(
        this.token,
        idCliente,
        idNegocio,
        this.idSucursal
      )
      .subscribe(
        resp => {
          this.estatusVerificacion = resp.resultado
        },
        err => {
          const status = UtilsRest.validaApiError(err, false, this.authService)
          if (status.codigo == 401) {
            this.authService.guardarToken(
              status.accessToken,
              status.refreshToken
            )
            this._buscarEstatusVerificacion(idCliente, idNegocio)
          }
          if (status.codigo == 404) {
            this.estatusVerificacion = null
          }
        }
      )
  }

  modificarDatos () {
    this.router.navigate(['/modificar-cliente'], {
      queryParams: {
        idNegocio: this.negocioSelect.idNegocio
      }
    })
  }

  realizarVerificacion () {
    const request: ClienteVerificacionDatos = {
      idCliente: this.clienteSelect.idCliente.toString(),
      idNegocio: this.negocioSelect.idNegocio.toString(),
      idSucursal: this.idSucursal,
      usuarioUltMod: this.idUsuario
    }

    UtilsSwal.enviarInformacion(
      '¿Estás seguro de autorizar la verificación de datos?',
      'Verificando datos',
      'Verificar',
      this.clienteService.realizarVerificacion(this.token, request),
      false
    ).then(() =>
      this._buscarEstatusVerificacion(
        this.clienteSelect.idCliente.toString(),
        this.negocioSelect.idNegocio.toString()
      )
    )
  }
}
