import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  // VALIDACION ROL
  permisoUsuario: boolean;

  constructor(
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.permisoUsuario = this.authService.permisosUsuario();
  }

  logout() {
    Swal.fire({
      title: '¿Estás seguro de salir?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8973D9',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Salir',
      heightAuto: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.authService.logout();
      }
    });
  }

}
