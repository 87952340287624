<!-- START ITEM -->
<div class="card social-card share  full-width m-b-10 no-border" data-social="item">
    <div class="card-header clearfix">
        <h5 class="pull-left fs-12">REPORTES</h5>
    </div>
    <div class="card-description">
        <h5 class='no-margin text-primary'>Estado de cuenta</h5>
        <div class="form-check m-t-5">
            <input type="checkbox" (change)="onCheckboxSelect($event)" id="check-cred-act" [checked]="true">
            <label for="check-cred-act"> Buscar solo créditos activos </label>
        </div>
    </div>

    <div class="inner-card p-b-10">
        <!-- Radio buttons -->
        <p>Tipo de busqueda</p>
        <div class="form-group form-check-inline">
            <input class="form-check-input" type="radio" value="cliente" name="radTipoBusqueda" (change)="onItemChange($event.target.value)">
            <label class="form-check-label">Cliente</label> &nbsp; &nbsp;
            <input class="form-check-input" type="radio" value="credito" name="radTipoBusqueda" [checked]="true" (change)="onItemChange($event.target.value)">
            <label class="form-check-label">No. crédito</label>
        </div>
    </div>

    <div class="inner-card p-b-20">
        <!-- Busqueda por cliente -->
        <app-cli-neg-cred #appCliNegCred [creditosActivos]="creditosActivos" (enableButton)="updateButtonState($event)" *ngIf="tipoBusqueda === 'cliente'"></app-cli-neg-cred>

        <!-- Busqueda por numero de credito -->
        <div *ngIf="tipoBusqueda === 'credito'">
            <label class="form-control-label" for="input-cliente" style="margin-top: 8px">Buscar crédito</label>
            <ng-autocomplete [data]="todosNumContratos" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" placeholder="Buscar crédito por número de contrato" (selected)='selectEvent($event)' (inputCleared)='clearedEvent()'>
            </ng-autocomplete>
            <ng-template #itemTemplate let-item>
                <a [innerHTML]="item"></a>
            </ng-template>
            <ng-template #notFoundTemplate let-notFound>
                <div>No existen registros</div>
            </ng-template>
        </div>

        <button *ngIf="showButton" (click)="buscarCredito()" type="button" class="btn btn-primary float-right m-t-20">
            Buscar
        </button>
    </div>
</div>
<!-- END ITEM -->