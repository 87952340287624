import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CatResponse } from 'src/app/models/catalogues/cat-response.model';
import { SolicitudResumidasModel } from 'src/app/models/credits-application/solicitud.model';
import { AuthService } from 'src/app/services/auth.service';
import UtilsRest from 'src/utils/UtilsRest';
import Swal from 'sweetalert2';
import { Constants } from '../../../classes/constants.class';
import { SolicitudesService } from '../../../services/solicitudes.service';

@Component({
  selector: 'app-estatus-solicitudes',
  templateUrl: './estatus-solicitudes.component.html',
  styleUrls: ['./estatus-solicitudes.component.scss']
})
export class EstatusSolicitudesComponent implements OnChanges {

  private token: string = sessionStorage.getItem('token');

  // PARAMETROS RECIBIDOS
  @Input() solicitudesCreadas: SolicitudResumidasModel[];
  @Input() solicitudesDatosTemporales: SolicitudResumidasModel[];
  @Input() solicitudesDocumentos: SolicitudResumidasModel[];
  @Input() solicitudesDatos: SolicitudResumidasModel[];
  @Input() solicitudesPreautorizadas: SolicitudResumidasModel[];
  @Input() solicitudesFirmaContrato: SolicitudResumidasModel[];
  @Input() solicitudesAutorizadas: SolicitudResumidasModel[];

  // PARAMETROS LOCALES
  tabSelect: string = Constants.CLAVE_SOLICITUD_CREADA;
  catalogoTipoSolicitud: CatResponse[] = [];

  // Constantes
  claveSolicitudCreada: string = Constants.CLAVE_SOLICITUD_CREADA;
  claveDatosTemporales: string = Constants.CLAVE_CAPTURA_DATOS_TEMPORALES;
  claveRecepcionValidacionDocumentos: string = Constants.CLAVE_RECEPCION_VALIDACION_DOCUMENTOS;
  claveCapturaDatos: string = Constants.CLAVE_CAPTURA_DATOS;
  claveSolicitudPreautorizada: string = Constants.CLAVE_SOLICITUD_PREAUTORIZADA;
  claveFirmaContratoPagare: string = Constants.CLAVE_FIRMA_CONTRATO_PAGARE;
  claveSolicitudAutorizada: string = Constants.CLAVE_SOLICITUD_AUTORIZADA_DISPERSADA;

  constructor(
    private authService: AuthService,
    private solicitudesService: SolicitudesService,
    private router: Router,
  ) { }

  ngOnChanges() {}

  // METODOS HTML
  setTab(tab: string) {
    this.tabSelect = tab;
  }
  
  // BUSCAR CATALOGO TIPO SOLICITUD
  buscarCatalogoTipoSolicitudes() {
    this.solicitudesService.obtenerCatalogoTipoSolicitud(
      this.token, 
    ).subscribe(resp => {
      
      let catalogo = resp.resultados;
      this.catalogoTipoSolicitud = catalogo;
      this.crearSolicitud();

    }, (err) => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarCatalogoTipoSolicitudes();
      }
      if(status.codigo == 404) {
        this.catalogoTipoSolicitud = [];
      }
    });
  }

  // MODALES
  crearSolicitud() {

    const options: Map<string, string> = new Map<string, string>();
    this.catalogoTipoSolicitud.forEach((e) => {
      // TODO: Agregar validación de roles para cobrador
      options.set(e.clave, e.descripcion);
    });

    Swal.fire({
      title: 'Crear solicitud de crédito',
      input: 'select',
      inputOptions: options,
      inputPlaceholder: 'Selecciona el tipo de solicitud',
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      inputValidator: function (value) {
        return !value && 'Debes indicar el tipo de solicitud';
      }
    }).then((result) => { 
      if(result.isConfirmed) {
        if(result.value === Constants.CLAVE_TIPO_SOLICITUD_RENOVACION) {
          // Cotizador renovacion
          this.router.navigate(['/cotizador-renovacion']);
        } else {
          //Cotizador cliente nuevo / promoción
          this.router.navigate(['/cotizador-clientes-nuevos'], {
            queryParams: {
              tipoSolicitud: result.value
            }
          });
        }
      }
    });
  }

}
