import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-contadores-solicitudes-pagina-web',
  templateUrl: './contadores-solicitudes-pagina-web.component.html',
  styleUrls: ['./contadores-solicitudes-pagina-web.component.scss']
})
export class ContadoresSolicitudesPaginaWebComponent implements OnChanges {

  @Input() totalInteresadosPorRevisar: number = 0;
  @Input() totalInteresadosValidacionDatos: number = 0;
  @Input() totalClientesPotenciales: number = 0;
  @Input() totalCreditosOtorgados: number = 0;

  constructor() { }

  ngOnChanges(): void {}

}
