import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/classes/constants.class';
import { SolicitudResumidasModel } from 'src/app/models/credits-application/solicitud.model';

@Component({
  selector: 'app-solicitud-captura-datos',
  templateUrl: './solicitud-captura-datos.component.html',
  styleUrls: ['./solicitud-captura-datos.component.scss']
})
export class SolicitudCapturaDatosComponent implements OnChanges {

  // PARAMETROS RECIBIDOS
  @Input() solicitudesDatos: SolicitudResumidasModel[];

  constructor(
    private router: Router,
  ) { }

  ngOnChanges(): void {}

  // METODOS HTML
  visualizarSolicitud(solicitud: SolicitudResumidasModel) {

    const route = solicitud.tipoSolicitud.clave === Constants.CLAVE_TIPO_SOLICITUD_RENOVACION 
      ? '/detalle-solicitud-renovacion' 
      : '/detalle-solicitud-nueva';
  
    this.router.navigate([route], {
      queryParams: {
        numSolicitud: solicitud.numSolicitud,
        claveEstatusSolicitud: solicitud.estatus.clave,
        claveTipoSolicitud: solicitud.tipoSolicitud.clave
      }
    });
  }


}
