<!-- START ROW VISITAS-->
<div class="row">
    <div class="col-lg-12 d-flex">
        <div class="card social-card share full-width m-b-10 no-border" data-social="item" *ngIf="visitaComponentModel != null">
            <div class="card-body">
                <div class="card-header clearfix">
                    <div class="row">
                        <div class="col-10-flex">
                            <h4 class="text-primary">Visitas al negocio</h4>
                        </div>
                        <div class="col-2 btn-align-right" *ngIf="!historico">
                            <button type="button" (click)="realizarPago()" data-tooltip="Registrar pago" class="btn btn-primary btn-sm btn-add">
                                <i class="fas fa-plus"></i>
                            </button>
                            <button type="button" (click)="buscarCatalogoImpago()" data-tooltip="Registrar visita"  class="btn btn-info btn-sm btn-add">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- START TABLE -->
                <div class="table-responsive vertical-scroll" *ngIf="visitas != null && visitas.length > 0">
                    <div class="card card-borderless">
                        <table class="table table-hover table-condensed" id="condensedTable" aria-describedby="Visitas de cobranza">
                            <thead>
                                <tr>
                                    <th class="v-align-middle bold text-center col-2">No.</th>
                                    <th class="v-align-middle bold text-center col-2">Fecha</th>
                                    <th class="v-align-middle bold text-center col-5">Cobrador</th>
                                    <th class="v-align-middle bold text-right col-3">Estatus</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let visita of visitas; let i = index" (click)="setVisitaSelect(visita)" [class.selected]="visitaSelect && visita.idVisitaCobranza === visitaSelect.idVisitaCobranza">
                                    <td class="v-align-middle normal text-center">{{ i + 1 }}</td>
                                    <td class="v-align-middle normal text-center">{{visita.fechaVisita}}</td>
                                    <td class="v-align-middle normal text-center">{{visita.cobrador.persona.nombre}} {{visita.cobrador.persona.apellidoPaterno}}</td>
                                    <td class="v-align-middle normal text-right">{{visita.estatus.descripcion}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="grid-random" *ngIf="visitas === null || visitas.length === 0">
                    <div class="card-body">
                        <p> Este negocio aún no ha recibido visitas de cobranza </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>