import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Constants } from 'src/app/classes/constants.class';
import { ArchivoModel } from 'src/app/models/files/archivo.model';
import { ExpedienteModel, TiposArchivosModel } from 'src/app/models/files/expediente.model';
import { AuthService } from 'src/app/services/auth.service';
import { DigitalizacionService } from 'src/app/services/digitalizacion.service';
import UtilsRest from 'src/utils/UtilsRest';
import UtilsSwal from 'src/utils/UtilsSwal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-archivos-expediente',
  templateUrl: './archivos-expediente.component.html',
  styleUrls: ['./archivos-expediente.component.scss']
})
export class ArchivosExpedienteComponent implements OnInit, OnChanges {

  private token: string = sessionStorage.getItem('token');

  // PARAMETROS RECIBIDOS
  @Input() numSolicitud: string;
  @Input() claveTipoSolicitud: string;

  // PARAMETROS LOCALES
  archivoSelected: ArchivoModel;
  claveTipoExpediente: string;

  // PARAMETROS SERVICIOS EXTERNOS
  tiposArchivo: TiposArchivosModel[];;

  constructor(
    private authService: AuthService,
    private digitalizacionService: DigitalizacionService,
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.claveTipoSolicitud.currentValue === Constants.CLAVE_TIPO_SOLICITUD_NUEVA 
        || changes.claveTipoSolicitud.currentValue === Constants.CLAVE_TIPO_SOLICITUD_NUEVA_PAG_WEB
    ) {
      this.claveTipoExpediente = Constants.CLAVE_TIPO_EXPEDIENTE_NUEVO;
    } else {
      this.claveTipoExpediente = Constants.CLAVE_TIPO_EXPEDIENTE_PREMIUM;
    }
    this.buscarExpediente();
  }

  // METODOS HTML
  selectImage(imagen: ArchivoModel, claveTipoArchivo: string) {
    if (imagen.idArchivo == this.archivoSelected.idArchivo) {
      return;
    }
 
    this._getArchivo(claveTipoArchivo, imagen.numArchivo);
   }

  // SERVICIOS EXTERNOS
  private buscarExpediente() {
    this.digitalizacionService.obtenerExpediente(
      this.token, 
      this.numSolicitud,
      this.claveTipoExpediente, 
    ).subscribe(resp => {

      const expediente: ExpedienteModel = resp.resultado;
      this.mapearTiposArchivos(expediente);

    }, (err) => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarExpediente();
      }
      if(status.codigo == 404) {
        this.tiposArchivo = null;
      }
    });
  }


  private _getArchivo(
    claveTipoArchivo: string,
    numArchivo: number
  ) {

    UtilsSwal.buscandoInformacion();
    Swal.showLoading();

    this.digitalizacionService.obtenerArchivo(
      this.token, 
      this.numSolicitud, 
      claveTipoArchivo, 
      numArchivo
    ).subscribe(resp => {

      this.archivoSelected = resp.resultado.archivos[0];
      Swal.close();

    }, (err) => {

      Swal.close();
      const status = UtilsRest.validaApiError(err, false, this.authService)

      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getArchivo(claveTipoArchivo, numArchivo);
      }

      if(status.codigo == 404) this.archivoSelected = null;

    });
  }

  // METODOS PRIVADOS
  private mapearTiposArchivos(expediente: ExpedienteModel) {

    if(!expediente.clasificacionesArchivos) this.tiposArchivo = null;
    let listTpoArchivos: TiposArchivosModel[] = [];

    expediente.clasificacionesArchivos.forEach((clasificacion) => {
      clasificacion.tiposArchivos.forEach((tipoArchivo) => {
        listTpoArchivos.push(tipoArchivo);
      });
    }); 

    this.tiposArchivo = listTpoArchivos;

    // Seleccionar la primer imagen
    this._getArchivo(this.tiposArchivo[0].clave, 1);
  }

}
