import { Component, Input, OnChanges } from '@angular/core';
import { Constants } from 'src/app/classes/constants.class';
import { InteresadoPaginaWebModel } from 'src/app/models/credits-application/point.model';

@Component({
  selector: 'app-estatus-interesados-pagina-web',
  templateUrl: './estatus-interesados-pagina-web.component.html',
  styleUrls: ['./estatus-interesados-pagina-web.component.scss']
})
export class EstatusInteresadosPaginaWebComponent implements OnChanges {

  // PARAMETROS RECIBIDOS
  @Input() interesadosPorRevisar: InteresadoPaginaWebModel[];
  @Input() interesadosValidacionDatos: InteresadoPaginaWebModel[];
  @Input() clientesPotenciales: InteresadoPaginaWebModel[];
  @Input() creditosOtorgados: InteresadoPaginaWebModel[];

  // PARAMETROS LOCALES
  tabSelect: string = Constants.CLAVE_POR_REVISAR;
  solicitudInteresado: InteresadoPaginaWebModel;
  totalInteresadosPorRevisar: number = 0;
  totalInteresadosValidacionDatos: number = 0;
  totalClientesPotenciales: number = 0;
  totalCreditosOtorgados: number = 0;

  // Constantes
  clavePorRevisar: string = Constants.CLAVE_POR_REVISAR;
  claveValidacionDatos: string = Constants.CLAVE_VALIDACION_DATOS;
  claveClientePotencial: string = Constants.CLAVE_CLIENTE_POTENCIAL;
  claveCreditoOtorgado: string = Constants.CLAVE_CREDITO_OTROGADO;

  constructor() {}

  ngOnChanges(): void {
    this.totalInteresadosPorRevisar = this.interesadosPorRevisar ? this.interesadosPorRevisar.length : 0;
    this.totalInteresadosValidacionDatos = this.interesadosValidacionDatos ? this.interesadosValidacionDatos.length : 0;
    this.totalClientesPotenciales = this.clientesPotenciales ? this.clientesPotenciales.length : 0;
    this.totalCreditosOtorgados = this.creditosOtorgados ? this.creditosOtorgados.length : 0;
  }

  // METODOS HTML
  setTab(tab: string) {
    this.solicitudInteresado = null;
    this.tabSelect = tab;
  }

  setSolicitudInteresado(solicitudInteresado: InteresadoPaginaWebModel) {
    this.solicitudInteresado = solicitudInteresado;
  }

}
