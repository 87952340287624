<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="register-container pl-5">
    <div class="d-flex justify-content-center flex-column full-height ">
        <img src="assets/img/logo-black.png" alt="logo" data-src="assets/img/logo-black.png" data-src-retina="assets/img/logo-black.png" width="100" height="35">
        <h3>Cambiar contraseña</h3>
        <p>
            Recuerda guardar tu contraseña en un lugar seguro y no compartirla con nadie
        </p>
        <form id="form-register" class="p-t-15" (ngSubmit)="actualizarPassword(loginForm)" role="form" #loginForm="ngForm">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Password</label>
                        <input type="password" [type]="tipoCampo ? 'text' : 'password'" name="passwordAnterior" placeholder="Contraseña anterior" class="form-control" [(ngModel)]="passwordAnterior" required>
                    </div>
                    <label *ngIf="loginForm.submitted && loginForm.controls['passwordAnterior'].errors" class="error animated fadeIn">
                        La contraseña anterior es obligatoria
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Password</label>
                        <input type="password" [type]="tipoCampo ? 'text' : 'password'" name="passwordNuevo" placeholder="Nueva contraseña" class="form-control" [(ngModel)]="passwordNuevo" required>
                    </div>
                    <label *ngIf="loginForm.submitted && loginForm.controls['passwordNuevo'].errors" class="error animated fadeIn">
                        La contraseña nueva es obligatoria
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Password</label>
                        <input type="password" [type]="tipoCampo ? 'text' : 'password'" name="passwordNuevo2" placeholder="Repetir contraseña" class="form-control" [(ngModel)]="passwordNuevo2" required>
                    </div>
                    <label *ngIf="loginForm.submitted && loginForm.controls['passwordNuevo2'].errors" class="error animated fadeIn">
                        Repetir la contraseña nueva es obligatorio
                    </label>
                </div>
            </div>
            <p *ngIf="(passwordAnterior != null && passwordAnterior != '') || 
                      (passwordNuevo != null && passwordNuevo != '') || 
                      (passwordNuevo2 != null && passwordNuevo2 != '')" style="cursor: pointer; text-align: right;" (click)="cambiarTipoCampo()">
                {{textoPassword}} contraseñas <i class="fas" [ngClass]="{'fa-eye-slash': !tipoCampo, 'fa-eye': tipoCampo}"></i>
            </p>
            <button aria-label="" class="btn btn-primary btn-cons m-t-10" type="submit">Enviar</button>
        </form>
    </div>
</div>
<app-footer></app-footer>