import { Component, OnInit } from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms'
import { ColocadorModel } from 'src/app/models/collections/cobrador.model'
import UtilsRest from 'src/utils/UtilsRest'
import { UsuarioService } from 'src/app/services/usuario.service'
import { ReporteService } from 'src/app/services/reporte.service'
import { AuthService } from 'src/app/services/auth.service'
import { CreditoService } from 'src/app/services/credito.service'
import { LoadjsService } from 'src/app/services/loadjs.service'
import UtilsSwal from 'src/utils/UtilsSwal'
import { EstatusCreditoModel } from 'src/app/models/credits/estatus-credito.model'
declare var $: any

@Component({
  selector: 'app-reporte-general',
  templateUrl: './reporte-general.component.html',
  styleUrls: ['./reporte-general.component.scss']
})
export class ReporteGeneralComponent implements OnInit {
  private token: string = sessionStorage.getItem('token')
  private codigoUsuario: string = sessionStorage.getItem('username')
  private idSucursal: number = +sessionStorage.getItem('idSucursal')

  // FORMS
  formEstatus: UntypedFormGroup
  formColocador: UntypedFormGroup

  // PARAMETROS LOCALES
  estatusList: EstatusCreditoModel[] = []
  estatusSelect: EstatusCreditoModel = new EstatusCreditoModel()

  colocadorList: ColocadorModel[] = []
  colocadorSelect: ColocadorModel = new ColocadorModel()

  constructor (
    private reporteService: ReporteService,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private creditoService: CreditoService,
    private usuarioService: UsuarioService,
    private loadjsService: LoadjsService
  ) {
    this.crearFormulario()
  }

  ngOnInit (): void {
    this.loadjsService.loadScript()
    this._getEstatus()
    this._getColocadores()
  }

  // FORM
  crearFormulario () {
    this.formEstatus = this.fb.group({
      estatus: [null, [Validators.required]]
    })

    this.formColocador = this.fb.group({
      colocador: [null, [Validators.required]]
    })
  }

  // OBTENER INFORMACIÓN
  private _getColocadores () {
    this.usuarioService
      .obtenerColocadores(this.token, this.idSucursal.toString())
      .subscribe(
        resp => {
          this.colocadorList = resp.resultados
          this.colocadorSelect = {
            idUsuario: null,
            codigoUsuario: null,
            persona: {
              idPersona: null,
              nombre: 'TODOS',
              apellidoPaterno: '',
              apellidoMaterno: ''
            }
          }

          this.colocadorList.unshift(this.colocadorSelect)
        },
        err => {
          const status = UtilsRest.validaApiError(err, true, this.authService)
          if (status.codigo == 401) {
            this.authService.guardarToken(
              status.accessToken,
              status.refreshToken
            )
            this._getEstatus()
          }
        }
      )
  }

  private _getEstatus () {
    this.creditoService.obtenerEstatusCreditos(this.token).subscribe(
      resp => {
        this.estatusList = resp.resultados

        this.estatusSelect.descripcion = 'TODOS'
        this.estatusSelect.clave = null

        this.estatusList.unshift(this.estatusSelect)
      },
      err => {
        const status = UtilsRest.validaApiError(err, true, this.authService)
        if (status.codigo == 401) {
          this.authService.guardarToken(status.accessToken, status.refreshToken)
          this._getEstatus()
        }
      }
    )
  }

  generarReporte () {
    if ($('#startdate').val() == '' || $('#enddate').val() == '') {
      UtilsSwal.lanzarWarning(
        'Fechas incompletas',
        'Fecha inicio y fecha final son necesarias para la generación del reporte'
      )
      return
    }

    UtilsSwal.generarReporte(
      this.reporteService.generarReporteGral(
        this.token,
        $('#startdate').val(),
        $('#enddate').val(),
        this.codigoUsuario,
        this.estatusSelect.clave,
        this.idSucursal,
        this.colocadorSelect.idUsuario
      )
    )
  }
}
