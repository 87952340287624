import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Utils from 'src/utils/Utils';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cambia-password',
  templateUrl: './cambia-password.component.html',
  styleUrls: ['./cambia-password.component.scss']
})
export class CambiaPasswordPageComponent implements OnInit {

  passwordAnterior: string;
  passwordNuevo: string;
  passwordNuevo2: string;
  token = sessionStorage.getItem('token');
  idUsuario = sessionStorage.getItem('idUsuario');
  tipoCampo: boolean = false;
  textoPassword: string = 'Mostrar';

  constructor(private usuarioService: UsuarioService, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
  }

  cambiarTipoCampo() {
    this.tipoCampo = !this.tipoCampo;
    this.textoPassword = this.tipoCampo ? 'Ocultar' : 'Mostrar'; 
  }

  actualizarPassword(form: NgForm) {
    if (form.invalid) {
      return;
    }

    if (this.passwordNuevo != this.passwordNuevo2) {
      Swal.fire({
        icon: 'info',
        title: 'Contraseñas inválidas',
        text: 'Las nuevas contraseñas no coinciden, verificalas para poder continuar',
        heightAuto: false
      });
      return;
    }

    Swal.fire({
      title: '¿Estás seguro de actualizar la contraseña?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8973D9',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Actualizar',
      heightAuto: false
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Guardando...',
          heightAuto: false
        });
        Swal.showLoading();

        this._actualizarPasswordService(
          Utils.b64EncodeUnicode(this.passwordAnterior), 
          Utils.b64EncodeUnicode(this.passwordNuevo)
        );
      }
    });
  }

  _actualizarPasswordService(passwordAnterior: string, passwordNuevo: string) {
    this.usuarioService.actualizarPassword(this.token, sessionStorage.getItem('username'), passwordAnterior, passwordNuevo, this.idUsuario)
      .subscribe( resp => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Contraseña actualizada con éxito',
          showConfirmButton: false,
          timer: 2500,
          heightAuto: false
        });
        this.router.navigate(['/home']);
    }, (err) => {
      Swal.close();
      if (err.status == 401) {
        this.authService.refrescarToken().subscribe((resp: any) => {
          this.token = resp.access_token;
          this.authService.guardarToken(resp.access_token, resp.refresh_token);
          this._actualizarPasswordService(passwordAnterior, passwordNuevo);
        }, err => {
          Swal.fire({
            icon: 'info',
            title: 'Ha pasado demasiado tiempo',
            text: 'Por seguridad es necesario que vuelva a iniciar sesión',
            heightAuto: false
          });
          this.authService.logout();
          return;
        });
      } else {
        this._validarEstatusError(err);
      }
    });
  }

  private _validarEstatusError(err) {
    if (err.status == 400) {
      this.lanzarError('Error de comunicación', err.error.detalles[0].mensaje.split(".", 1));
    } else if (err.status == 403) {
      this.lanzarError('Acceso denegado', err.error.detalles[0].mensaje);
    } else if (err.status == 404) {
      this.lanzarError('Error de usuario', err.error.detalles[0].mensaje);
    } else if (err.status == 500 || err.status == 503) {
      this.lanzarError('Error de comunicación', err.error.detalles[0].mensaje);
    } else {
      this.lanzarError('Error desconocido', 'Lo sentimos, ocurrio un error al intentar procesar la petición, si el problema persiste reportalo a soporte@grupofragy.com');
    }
  }

  private lanzarError(titulo: string, texto: string) {
    Swal.fire({
      icon: 'error',
      title: titulo,
      text: texto,
      heightAuto: false
    });
  }

}
