import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { InteresadoPaginaWebModel } from 'src/app/models/credits-application/point.model';

@Component({
  selector: 'app-creditos-otorgados-pagina-web',
  templateUrl: './creditos-otorgados-pagina-web.component.html',
  styleUrls: ['./creditos-otorgados-pagina-web.component.scss']
})
export class CreditosOtorgadosPaginaWebComponent implements OnChanges {

  // EVENTOS DE SALIDA
  @Output() interesadoEmit = new EventEmitter<InteresadoPaginaWebModel>();

  // PARAMETROS RECIBIDOS
  @Input() creditosOtorgados: InteresadoPaginaWebModel[];

  // PARAMETROS LOCALES
  solicitudInteresado: InteresadoPaginaWebModel;

  constructor() { }

  ngOnChanges(): void {
    this.solicitudInteresado = null;
  }

  // METODOS PRIVADOS
  visualizarInformacion(interesado: InteresadoPaginaWebModel) {
    this.solicitudInteresado = interesado;
    this.interesadoEmit.emit(interesado);
  }
}
