import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CancelarPagoModel, ComplementoPagoModel, DetallePagosModel, PagoModel } from 'src/app/models/credits/pagos.model';
import { CobranzaService } from 'src/app/services/cobranza.service';
import UtilsSwal from 'src/utils/UtilsSwal';
import Swal from 'sweetalert2';
import { Constants } from '../../../classes/constants.class';
import { Client } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { SendSocket } from 'src/app/models/collections/cobranza.model';
import { WebsocketsService } from 'src/app/services/websockets.service';
import { AuthService } from 'src/app/services/auth.service';
import { isNullUndefined } from 'src/utils/nonNullUndefined';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.scss']
})
export class PagosComponent implements OnDestroy, OnChanges, OnInit  {

  private token: string = sessionStorage.getItem('token');
  private idUsuario: string = sessionStorage.getItem('idUsuario');
  private idSucursal: string = sessionStorage.getItem('idSucursal');

  // VALIDACION ROL
  permisoSuperAdmin: boolean;

  // PARAMETROS RECIBIDOS
  @Input() pagoModel: PagoModel;
  @Input() historico: boolean;

  // SOCKETS
  private client: Client;

  // CONSTANTES
  claveCuotaPagada = Constants.CLAVE_CUOTA_PAGADA
  clavePagoCancelado = Constants.CLAVE_PAGO_CANCELADO
  claveTransferencia = Constants.CLAVE_PAGO_TRANSFERENCIA

  constructor(
    private authService: AuthService,
    private cobranzaService: CobranzaService,
    private websocketsService: WebsocketsService,
  ) { }

  // METODOS 
  ngOnInit(): void {
    this.permisoSuperAdmin = this.authService.permisosSuperAdmin();
  }

  ngOnDestroy(): void {
    this.pagoModel = null
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes.pagoModel.currentValue) {
      this.pagoModel = null;
    } else {
      
      this.pagoModel = changes.pagoModel.currentValue;

      // Se realiza conexión a sockets
      this.client = new Client();
      this.client.webSocketFactory = () => {
        return new SockJS(this.websocketsService.getPathSockCobranzaJS());
      }

      this.client.activate();
    }

    if(changes.historico && changes.historico.currentValue) {
      this.historico = changes.historico.currentValue;
    }
  }

  // MODALES
  cancelarPago(idPagoCredito: number) {

    Swal.fire({
      title: 'Motivo de cancelación',
      html: `<textarea rows="3" id="motivoCancelacion" class="swal2-input" style="max-width: 100%" placeholder="Motivo de la cancelación">`,
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      showLoaderOnConfirm: true,
      preConfirm: () => {

        const motivoCancelacion = (<HTMLInputElement> Swal.getPopup().querySelector('#motivoCancelacion')).value;

        if (motivoCancelacion === null || motivoCancelacion === "") {
          Swal.showValidationMessage(`Ingresa el motivo de la cancelación`)
        }

        return { motivo: motivoCancelacion }
      }
    }).then((result) => {

      const motivo = result.value.motivo;

      const cancelarPago: CancelarPagoModel = {
        idCobrador: this.idUsuario,
        idCuotaCredito: this.pagoModel.idCuotaCredito,
        idVisitaCobranza: this.pagoModel.idVisitaCobranza,
        idPago: idPagoCredito,
        coordenadas: { // FIXME: Get location from the browser
          latitud: Constants.LATITUD_POINT.toString(),
          longitud: Constants.LONGITUD_POINT.toString()
        },
        motivo: motivo
      };
  
      this._cancelarPago(cancelarPago);
    });
  }

  realizarPago() {

    Swal.fire({
      title: 'Pagar cuota',
      html: `<input type="number" id="montoCobrado" min="1" class="swal2-input" style="max-width: 100%" placeholder="Monto cobrado">
      <input type="number" id="montoCobrado2" min="1" class="swal2-input" style="max-width: 100%" placeholder="Confirmar monto cobrado">
      <input type="text" id="numTransferencia" class="swal2-input" style="max-width: 100%" placeholder="Número de transferencia">`,
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      showLoaderOnConfirm: true,
      preConfirm: () => {

        const montoCobrado = Number((<HTMLInputElement> Swal.getPopup().querySelector('#montoCobrado')).value);
        const montoCobrado2 = Number((<HTMLInputElement> Swal.getPopup().querySelector('#montoCobrado2')).value);
        const numTransferencia = (<HTMLInputElement> Swal.getPopup().querySelector('#numTransferencia')).value;

        if (montoCobrado <= 0 || montoCobrado2 <= 0) Swal.showValidationMessage(`El monto no pueden ser negativo`)
        if (montoCobrado != montoCobrado2) Swal.showValidationMessage(`Los montos no son iguales`)
        if (isNullUndefined(numTransferencia) || numTransferencia === "") Swal.showValidationMessage(`Este campo es obligatorio`)

        return { montoCobrado: montoCobrado, numTransferencia }
      }
    }).then((result) => {
      const montoCobrado = Number(result.value.montoCobrado);
      const numTransferencia = result.value.numTransferencia;

      const complementoPago: ComplementoPagoModel = {
        idVisitaCobranza: this.pagoModel.idVisitaCobranza,
        idCuotaCredito: this.pagoModel.idCuotaCredito,
        idCobrador: this.idUsuario,
        montoCobrado: montoCobrado,
        formaPago: Constants.CLAVE_PAGO_TRANSFERENCIA, // Only transfer
        numTransferencia: numTransferencia,
        coordenadas: { // FIXME: Get location from the browser
          latitud: Constants.LATITUD_POINT.toString(),
          longitud: Constants.LONGITUD_POINT.toString()
        }
      };
      this._registrarComplementoPago(complementoPago);
    });
    
  }

  // VALIDACIONES
  validaCancelacion(pago: DetallePagosModel) {

    if(!this.permisoSuperAdmin) return false;
    if(this.historico) return false;
    if(!this.pagoModel.idNegocioCobranza) return false;
    if(pago.tipoPago.clave != 'CAN') return true;
    return false;
  }

  // SERVICIOS EXTERNOS
  private _registrarComplementoPago(complementoPago: ComplementoPagoModel) {

    UtilsSwal.enviarInformacion(
      "¿Estás seguro de generar un complemento de pago a esta cuota?",
      "Generando complemento de pago",
      "Guardar",
      this.cobranzaService.realizarComplementoPago(this.token, complementoPago),
      false
    ).then(() => {
      this._enviarInfoSocket();
    });
  }


  private _cancelarPago(cancelarPago: CancelarPagoModel) {

    UtilsSwal.enviarInformacion(
      "¿Estás seguro de cancelar este pago?",
      "Cancelando pago",
      "Cancelar",
      this.cobranzaService.realizarCancelacion(this.token, cancelarPago),
      false
    ).then(() => {
      this._enviarInfoSocket();
    });
  }

  private _enviarInfoSocket() {
    const sendSocket: SendSocket = {
      idSucursal: this.idSucursal,
    }
    this.client.publish({ 
      destination: '/send/ruta-cobranza', 
      body: JSON.stringify(sendSocket) 
    });
  }

}
