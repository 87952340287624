import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NegocioModel } from 'src/app/models/business/negocio.model';
import { AutocompleteCliente } from 'src/app/models/clients/autocomplete-cliente.model';
import { ClienteModel } from 'src/app/models/clients/cliente.model';
import { CreditoModel } from 'src/app/models/credits/credito.model';
import UtilsRest from 'src/utils/UtilsRest';
import { AuthService } from '../../../services/auth.service';
import { ClienteService } from '../../../services/cliente.service';
import { CreditoService } from '../../../services/credito.service';

@Component({
  selector: 'app-cli-neg-cred',
  templateUrl: './cli-neg-cred.component.html',
  styleUrls: ['./cli-neg-cred.component.scss']
})
export class CliNegCredComponent implements OnDestroy, OnChanges {

  token: string = sessionStorage.getItem('token');
  idSucursal: string = sessionStorage.getItem('idSucursal');

  // EVENTOS DE SALIDA
  @Output() enableButton = new EventEmitter<boolean>();

  // PARAMETROS RECIBIDOS
  @Input() creditosActivos: boolean;

  // PARAMETROS LOCALES
  keyword: string = 'nombre';

  autocompleteClientes: AutocompleteCliente[] = [];
  negocios: NegocioModel [] = [];
  creditos: CreditoModel [] = [];
  clientes: ClienteModel[] = [];

  clienteSelect: AutocompleteCliente;
  negocioSelect: NegocioModel;
  creditoSelect: CreditoModel;

  // FORMS
  form: UntypedFormGroup;

  constructor(
    private clienteService: ClienteService, 
    private authService: AuthService, 
    private creditoService: CreditoService, 
    private fb: UntypedFormBuilder
  ) {
    this._crearFormulario();
  }

  ngOnDestroy(): void {
    this.clientes = [];
    this.negocios = [];
    this.creditos = [];
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if(this.clienteSelect != null) {
      this._buscarNegocios(this.clienteSelect.numCliente)
    } else {
      this._buscarClientes('');
    }
  }
  // METODOS FORMS
  _crearFormulario() {
    this.form = this.fb.group({
      negocio: [null, [Validators.required]],
      credito: [null, [Validators.required]] 
    });
  }

  clearedEvent() {
    this.clienteSelect = null;
    this.enableButton.emit(false);
  }

  selectEvent(item) {
    this.clienteSelect = item;
    this._buscarNegocios(this.clienteSelect.numCliente);
  }

  // HANDLERS
  validarFormHandler(): UntypedFormGroup {
    return this.form;
  }

  // OBTENER INFORMACIÓN
  _buscarClientes(termino: string) {
    this.clienteService.obtenerClientesByTermino(this.token, termino, this.idSucursal)
    .subscribe(resp => {
      this.clientes = resp.resultados;
      this._crearDataAutocomplete(this.clientes);
    }, (err) => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._buscarClientes(termino);
      }
      if(status.codigo == 404) {
        this.clientes = [];
      }
    });
  }

  _buscarNegocios(numCliente: string) {
    this.clienteService.obtenerNegociosByCliente(this.token, numCliente)
    .subscribe(resp => {
      this.negocios = resp.resultados;
      this.negocioSelect = resp.resultados[0];

      this._buscarCreditos(this.negocioSelect.idNegocio, this.creditosActivos);
    }, (err) => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._buscarNegocios(numCliente);
      }
      if(status.codigo == 404) {
        this.negocios = [];
      }
    });
  }

  _buscarCreditos(idNegocio: number, activos: boolean) {
    this.creditoService.obtenerCreditoByNegocio(this.token, idNegocio, activos)
    .subscribe(resp => {
      this.creditos = resp.resultados;
      this.creditoSelect = resp.resultados[0];
      this.enableButton.emit(true);
    }, (err) => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._buscarCreditos(idNegocio, activos);
      }
      if(status.codigo == 404) {
        this.creditos = [];
      }
    });
  }

  // MÉTODOS PRIVADOS
  private _crearDataAutocomplete(clientes: ClienteModel[]) {
    for (let cliente of clientes) {
      let autocompleteCliente = new AutocompleteCliente();

      autocompleteCliente.nombre = this._eliminarDiacriticosEs(`${cliente.persona.nombre} ${cliente.persona.apellidoPaterno} ${cliente.persona.apellidoMaterno}`);
      autocompleteCliente.idCliente = cliente.idCliente;
      autocompleteCliente.numCliente = cliente.numCliente;

      this.autocompleteClientes.push(autocompleteCliente);
    }
  }

  private _eliminarDiacriticosEs(texto: string) {
    return texto.normalize('NFD')
      .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
      .normalize();
  }

  // VALIDACIONES (DATOS NO VALIDOS)
  get negocioInvalido() {
    return this.form.get('negocio').invalid && this.form.get('negocio').touched;
  }

  get creditoInvalido() {
    return this.form.get('credito').invalid && this.form.get('credito').touched;
  }
}
