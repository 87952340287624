import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CreditoService } from 'src/app/services/credito.service';
import { ReporteService } from 'src/app/services/reporte.service';
import Utils from 'src/utils/Utils';
import UtilsRest from 'src/utils/UtilsRest';
import UtilsSwal from 'src/utils/UtilsSwal';

@Component({
  selector: 'app-reporte-edo-cuenta',
  templateUrl: './reporte-edo-cuenta.component.html',
  styleUrls: ['./reporte-edo-cuenta.component.scss']
})
export class ReporteEdoCuentaComponent implements OnInit {

  token: string = sessionStorage.getItem('token');
  idSucursal: string = sessionStorage.getItem('idSucursal');

  // ELEMENTOS DEL HTML
  @ViewChild('appCliNegCred') appCliNegCred: any;

  // PARAMETROS LOCALES
  showButton: boolean = false;
  creditosActivos: boolean = true;
  tipoBusqueda: string = "credito";
  numContrato: string;
  todosNumContratos: string[] = [];

  // PARAMETROS AUTOCOMPLETE
  keyword: string = '';

  constructor(
    private authService: AuthService, 
    private creditoService: CreditoService, 
    private reporteService: ReporteService,
  ) {
    this._buscarCreditosAutocomplete(
      this.keyword, 
      this.creditosActivos
    );
  }

  ngOnInit(): void {}

  // METODOS AUTOCOMPLETE
  clearedEvent() {
    this.numContrato = null;
  }

  selectEvent(item) {
    this.numContrato = item;
    this.generarReporte(this.numContrato);
  }

  // METODOS RECIBIDIOS DEL OUTPUT
  updateButtonState(enable) {
    if(!enable) {
      this.clearedEvent();
    }
    this.showButton = enable;
  }

  //HANDLER DE RADIO BUTTON - CHECKBOX
  onItemChange(value){
    this.tipoBusqueda = value;
    this.numContrato = null;
    this.showButton = false;
  }

  onCheckboxSelect(event) {

    this.creditosActivos = event.target.checked;
    this._buscarCreditosAutocomplete(
      this.keyword, 
      this.creditosActivos
    );
  }

  // OBTENER INFORMACIÓN - SERVICIOS EXTERNOS
  _buscarCreditosAutocomplete(numContrato: string, activos: boolean) {
    this.creditoService.obtenerCreditosAutocomplete(
      this.token, 
      numContrato, 
      this.idSucursal,
      activos
    ).subscribe(resp => {
      this.todosNumContratos = resp.resultado;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._buscarCreditosAutocomplete(numContrato, activos);
      }
      if(status.codigo == 404) {
        this.todosNumContratos = [];
      }
    });
  }

  generarReporte(numContrato: string) {

    UtilsSwal.generarReporte(
      this.reporteService.generarReporteEdoCta(
        this.token, 
        numContrato, 
        this.idSucursal
      )
    );
  }

  // METODOS PRIVADOS
  buscarCredito() {
      // Se manda a llamar el método validarFormHandler del componente cli-neg-cred (autocomplete)
      // Para obtener el form con el num de contrato
      const formNegCred = this.appCliNegCred.validarFormHandler();
      if (formNegCred.invalid) {
        return Utils.validateForms(formNegCred)
      }
      this.generarReporte(formNegCred.get('credito').value.numContrato);
      this.numContrato = formNegCred.get('credito').value.numContrato;
  }
}
