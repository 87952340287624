import { PersonaCredito } from '../person/persona-request.model';

export class ReferenciaModel {
    idNegocio: number;
    numNegocio: string;
    idCliente: number;
    numCliente: string;
    numSolicitud: string;
    usuarioUltMod: string;
    referencias: PersonaCredito[];
}