import { Component,Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from '@stomp/stompjs';
import { SendSocket } from 'src/app/models/collections/cobranza.model';
import { CotizacionModel } from 'src/app/models/credits-application/cotizacion.model';
import { EstatusSolicitudRequest } from 'src/app/models/credits-application/estatus-solicitud.model';
import { SolicitudModel } from 'src/app/models/credits-application/solicitud.model';
import { AuthService } from 'src/app/services/auth.service';
import { SolicitudesService } from 'src/app/services/solicitudes.service';
import { WebsocketsService } from 'src/app/services/websockets.service';
import UtilsRest from 'src/utils/UtilsRest';
import UtilsSwal from '../../../../utils/UtilsSwal';
import * as SockJS from 'sockjs-client';
import Swal from 'sweetalert2';
import { Constants } from 'src/app/classes/constants.class';

@Component({
  selector: 'app-datos-generales-solicitud',
  templateUrl: './datos-generales-solicitud.component.html',
  styleUrls: ['./datos-generales-solicitud.component.scss']
})
export class DatosGeneralesSolicitudComponent implements OnChanges {

  private token: string = sessionStorage.getItem('token');
  private idUsuario: string = sessionStorage.getItem('idUsuario');
  private idSucursal: string = sessionStorage.getItem('idSucursal');

  // PARAMETROS RECIBIDOS
  @Input() solicitud: SolicitudModel;

  // PARAMETROS LOCALES
  cotizacionSolicitud: CotizacionModel;

  // SOCKETS
  private client: Client;

  constructor(
    private authService: AuthService,
    private solicitudesService: SolicitudesService,
    private websocketsService: WebsocketsService,
    private router: Router,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes.solicitud.currentValue) {
      this.solicitud = null;
    } else {

      // Se realiza conexión a sockets
      this.client = new Client();
      this.client.webSocketFactory = () => {
        return new SockJS(this.websocketsService.getPathSockSolicitudesJS());
      }

      this.client.activate();
      this.buscarCotizacionSolicitud();
    }
  }

  eliminarSolicitud() {
    Swal.fire({
      title: 'Confirmación de cancelación',
      html: `<textarea id="motivo" placeholder="Inserta un motivo de cancelación" cols="50"></textarea>`,
      confirmButtonText: 'Confirmar',
      focusConfirm: false,
      preConfirm: () => {
        const motivo = (<HTMLInputElement> Swal.getPopup().querySelector('#motivo')).value;

        if (!motivo) {
          Swal.showValidationMessage(`Por favor inserta un motivo para continuar`)
        }
        return { motivo: motivo }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        
        const estatusSolicitud: EstatusSolicitudRequest = {
          numSolicitud: this.solicitud.numSolicitud,
          usuarioUltMod: this.idUsuario,
          motivo: result.value.motivo.trim(),
          claveEstatusSolicitud: Constants.CLAVE_TIPO_ESTATUS_CANCELACION
        }

        this._cancelarSolicitud(estatusSolicitud);
      }
    });
  }

  // METODOS HTML - VALIDACIONES
  mostrarBotonEliminar() {
    if(!this.authService.hasRole(Constants.ROLE_ADMIN)) return false;
    return true;
  }
  
  // SERVICIOS EXTERNOS
  buscarCotizacionSolicitud() {
    this.solicitudesService.obtenerCotizacionSolicitud(
      this.token, 
      this.solicitud.numSolicitud, 
    ).subscribe(resp => {
      this.cotizacionSolicitud = resp.resultado;

    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarCotizacionSolicitud();
      }
    });
  }

  private _cancelarSolicitud(estatusSolicitud: EstatusSolicitudRequest) {

    UtilsSwal.enviarInformacion(
      "¿Estás seguro de cancelar esta solicitud?",
      "Cancelando solicitud",
      "Cancelar",
      this.solicitudesService.cancelarSolicitud(this.token, estatusSolicitud),
      false
    )
    .then(() => this._enviarInfoSocket())
    .then(() => this.router.navigate(['/solicitudes']));
  }

  private _enviarInfoSocket() {
    const sendSocket: SendSocket = {
      idSucursal: this.idSucursal,
    }
    this.client.publish({ 
      destination: '/send/solicitudes', 
      body: JSON.stringify(sendSocket) 
    });
  }

}
