import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CatalogosSolicitud } from 'src/app/models/catalogues/cat-response.model';
import { DatosTemporales } from 'src/app/models/clients/datos-temporales.model';
import { AuthService } from 'src/app/services/auth.service';
import { SolicitudesService } from 'src/app/services/solicitudes.service';
import UtilsRest from 'src/utils/UtilsRest';

@Component({
  selector: 'app-datos-temporales',
  templateUrl: './datos-temporales.component.html',
  styleUrls: ['./datos-temporales.component.scss']
})
export class DatosTemporalesComponent implements OnInit, OnChanges {

  private token: string = sessionStorage.getItem('token');

  // PARAMETROS RECIBIDOS
  @Input() numSolicitud: string;
  @Input() catalogosSolicitud: CatalogosSolicitud;

  // PARAMETROS LOCALES
  datosTemporales: DatosTemporales;

  constructor(
    private solicitudesService: SolicitudesService,
    private authService: AuthService, 
  ) { }

  ngOnInit(): void {
    this._getDatosTemporales();
  }

  ngOnChanges(): void {}

  // SERVICIOS EXTERNOS
  private _getDatosTemporales() {

    this.solicitudesService.obtenerDatosTemporales(
      this.token, 
      this.numSolicitud
    ).subscribe(resp => {
      this.datosTemporales = resp.resultado;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getDatosTemporales();
      }
    });
  }

  // METODOS PRIVADOS
  buscarMedioConocimiento(clave: string) {
    return this.catalogosSolicitud.catMediosConocimiento
      .find((catalogo) => catalogo.clave === clave).descripcion;
  }

  buscarNivelEscolar(clave: string) {
    return this.catalogosSolicitud.catNivelesEscolares
      .find((catalogo) => catalogo.clave === clave).descripcion;
  }

  buscarOcupacion(clave: string) {
    return this.catalogosSolicitud.catOcupaciones
      .find((catalogo) => catalogo.clave === clave).descripcion;
  }

  buscarFuenteIngreso(clave: string) {
    return this.catalogosSolicitud.catFuentesIngresos
      .find((catalogo) => catalogo.clave === clave).descripcion;
  }

  buscarEstadoCivil(clave: string) {
    return this.catalogosSolicitud.catEstadoCivil
      .find((catalogo) => catalogo.clave === clave).descripcion;
  }

  buscarTipoVivienda(clave: string) {
    return this.catalogosSolicitud.catTipoVivienda
      .find((catalogo) => catalogo.clave === clave).descripcion;
  }

}
