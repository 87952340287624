<app-sidebar></app-sidebar>
<div class="horizontal-menu horizontal-app-menu dashboard">
    <app-header></app-header>
    <div class="page-container pl-5" *ngIf="permisoUsuario">
        <div class="page-content-wrapper ">
            <div class="content sm-gutter">
                <div class="container sm-padding-10 p-t-20 p-l-0 p-r-0">
                    <div class="row">
                        <!-- DATOS TEMPORALES -->
                        <app-datos-temporales-form class="full-width" [numSolicitud]="numSolicitud"></app-datos-temporales-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>