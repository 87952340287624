import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Constants } from 'src/app/classes/constants.class';
import { CatResponse } from 'src/app/models/catalogues/cat-response.model';
import { CotizacionForm, BuscarCotizacionRenovacionModel } from 'src/app/models/credits-application/cotizacion.model';
import { AuthService } from 'src/app/services/auth.service';
import { CreditoService } from 'src/app/services/credito.service';
import { SolicitudesService } from 'src/app/services/solicitudes.service';
import Utils from 'src/utils/Utils';
import UtilsRest from 'src/utils/UtilsRest';
import UtilsSwal from 'src/utils/UtilsSwal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cotizador-renovacion-form',
  templateUrl: './cotizador-renovacion-form.component.html',
  styleUrls: ['./cotizador-renovacion-form.component.scss']
})
export class CotizadorRenovacionFormComponent implements OnInit {

  private token: string = sessionStorage.getItem('token');
  private idSucursal: string = sessionStorage.getItem('idSucursal');

  // EVENTOS DE SALIDA
  @Output() cotizacionEvent = new EventEmitter<CotizacionForm>();

  // PARAMETROS RECIBIDOS
  @Input() claveTipoSolicitud: string;
  @Input() nombreSolicitud: string;
  @Input() idNegocio: string;

  // PARAMETROS LOCALES
  catalogoEsquemasPago: CatResponse[];
  catalogoTiposCredito: CatResponse[];
  cotizacion: CotizacionForm;

  // FORMS
  formCotizacion: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private creditoService: CreditoService,
    private solicitudesService: SolicitudesService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.buscarCatalogoEsquemaPago();
    this.buscarCatalogoTiposCredito();
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.crearFormulario();
    this.claveTipoSolicitud = changes.claveTipoSolicitud.currentValue;

    const nombreSolicitud =  changes.nombreSolicitud.currentValue;
    this.formCotizacion.get('nombreSolicitud').setValue(nombreSolicitud);
  }

  // METODOS FORMS
  crearFormulario() {
    this.formCotizacion = this.fb.group({
      nombreSolicitud: ['', Validators.required],
      esquemaPago: ['', Validators.required],
      tipoCredito: ['', Validators.required],
      monto: ['', Validators.required],
    });
  }

  // METODOS HTNL
  limpiar(){
    this.formCotizacion.get('esquemaPago').setValue(null);
    this.formCotizacion.get('tipoCredito').setValue(null);
    this.formCotizacion.get('monto').setValue(null);

    this.cotizacion = null;
    this.cotizacionEvent.emit(null);
  }

  generarCotizacion() {
    if (this.formCotizacion.invalid) {
      return Utils.validateForms(this.formCotizacion)
    }

    const buscarCotizacion: BuscarCotizacionRenovacionModel = {
      claveEsquemaPago: this.formCotizacion.get('esquemaPago').value,
      claveTipoCredito: this.formCotizacion.get('tipoCredito').value,
      montoCredito: this.formCotizacion.get('monto').value,
      idNegocio: this.idNegocio,
      idSucursal: this.idSucursal
    }

    this.buscarCotizacion(buscarCotizacion);
  }

  // SERVICIOS EXTERNOS
  buscarCatalogoEsquemaPago() {
    this.creditoService.obtenerCatalogoEsquemaPago(
      this.token, 
    ).subscribe(resp => {
      
      var catalogo = resp.resultados;
      this.catalogoEsquemasPago = catalogo;

    }, (err) => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarCatalogoEsquemaPago();
      }
      if(status.codigo == 404) {
        this.catalogoEsquemasPago = [];
      }
    });
  }

  buscarCatalogoTiposCredito() {
    this.creditoService.obtenerCatalogoTiposCredito(
      this.token, 
    ).subscribe(resp => {
      
      var catalogo = resp.resultados;
      this.catalogoTiposCredito = catalogo;

      if(this.claveTipoSolicitud != Constants.CLAVE_TIPO_SOLICITUD_RENOVACION) {
        this.formCotizacion.get('tipoCredito').setValue(Constants.CLAVE_TIPO_CREDITO_GENERAL);
      }

    }, (err) => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarCatalogoTiposCredito();
      }
      if(status.codigo == 404) {
        this.catalogoTiposCredito = [];
      }
    });
  }

  buscarCotizacion(buscarCotizacion: BuscarCotizacionRenovacionModel) {
    UtilsSwal.buscandoInformacion();
    Swal.showLoading();

    this.solicitudesService.obtenerCotizacionRenovacion(
      this.token, 
      buscarCotizacion
    ).subscribe(resp => {

      Swal.close();

      const cotizacionForm: CotizacionForm = {
        cotizacion: resp.resultado,
        nombreSolicitud: this.formCotizacion.get('nombreSolicitud').value,
        tipoCredito: this.formCotizacion.get('tipoCredito').value
      }

      this.cotizacion = cotizacionForm;
      this.cotizacionEvent.emit(this.cotizacion);

    }, err => {

      Swal.close();
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarCotizacion(buscarCotizacion);
      }

    });
  }

  // VALIDACIONES (DATOS NO VALIDOS)
  get nombreSolicitudsNoValido() {
    return this.formCotizacion.get('nombreSolicitud').invalid && this.formCotizacion.get('nombreSolicitud').touched
  }

  get esquemaPagooNoValido() {
    return this.formCotizacion.get('esquemaPago').invalid && this.formCotizacion.get('esquemaPago').touched
  }

  get tipoCreditoNoValido() {
    return this.formCotizacion.get('tipoCredito').invalid && this.formCotizacion.get('tipoCredito').touched
  }

  get montoNoValido() {
    return this.formCotizacion.get('monto').invalid && this.formCotizacion.get('monto').touched
  }

}
