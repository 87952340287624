import { Component, NgZone, OnInit } from '@angular/core';
import { Constants } from 'src/app/classes/constants.class';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { WebsocketsService } from 'src/app/services/websockets.service';
import { LoadjsService } from 'src/app/services/loadjs.service';
import { Client } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { ResponseModel } from 'src/app/models/generic/responde.model';
import { SolicitudesResponseModel, SolicitudResumidasModel } from 'src/app/models/credits-application/solicitud.model';
import UtilsRest from 'src/utils/UtilsRest';
import { SolicitudesService } from '../../services/solicitudes.service';

@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.scss']
})
export class SolicitudesPageComponent implements OnInit {

  private token: string = sessionStorage.getItem('token');
  private idSucursal: string = sessionStorage.getItem('idSucursal');

  // VALIDACION ROL
  permisoUsuario: boolean;

  // VARIABLES LOCALES
  solicitudes: SolicitudesResponseModel;
  solicitudesCreadas: SolicitudResumidasModel[];
  solicitudesDatosTemporales: SolicitudResumidasModel[];
  solicitudesDocumentos: SolicitudResumidasModel[];
  solicitudesDatos: SolicitudResumidasModel[];
  solicitudesPreautorizadas: SolicitudResumidasModel[];
  solicitudesFirmaContrato: SolicitudResumidasModel[];
  solicitudesAutorizadas: SolicitudResumidasModel[];
  client: Client;

  constructor(
    private authService: AuthService, 
    private solicitudesService: SolicitudesService,
    private websocketsService: WebsocketsService,
    private loadjsService: LoadjsService,
    private router: Router, 
    private _ngZone: NgZone
  ) {}

  ngOnInit(): void {
    
    this.permisoUsuario = this.authService.permisosUsuario();
    this.loadjsService.loadScript();
    this.client = new Client();

    this.client.webSocketFactory = () => {
      return new SockJS(this.websocketsService.getPathSockSolicitudesJS());
    }

    this.client.activate();

    this.client.onConnect = () => {
      this.client.subscribe('/subs/solicitudes', resp => {

        const respuesta = JSON.parse(resp.body) as ResponseModel;
        const solicitudesRespuesta: SolicitudesResponseModel = respuesta.resultado;

        if (solicitudesRespuesta.idSucursal == this.idSucursal) {
          this.solicitudes = solicitudesRespuesta;
          this.filtrarSolicitudesEstatus(solicitudesRespuesta);
          this.reloadComponent();
        }

      });
    }
    this.obtenerSolicitudes();
  }

  // METODOS PRIVADOS
  reloadComponent() {
    this._ngZone.run(()=>{
      this.router.navigate([this.router.url])
    });
  }

  filtrarSolicitudesEstatus(nvaSolicitud: SolicitudesResponseModel) {
    this.solicitudesCreadas = nvaSolicitud.solicitudes
      .filter(solicitud => solicitud.estatus.clave === Constants.CLAVE_SOLICITUD_CREADA);

    this.solicitudesDatosTemporales = nvaSolicitud.solicitudes
      .filter(solicitud => solicitud.estatus.clave === Constants.CLAVE_CAPTURA_DATOS_TEMPORALES);
      
    this.solicitudesDocumentos = nvaSolicitud.solicitudes
      .filter(solicitud => solicitud.estatus.clave === Constants.CLAVE_RECEPCION_VALIDACION_DOCUMENTOS); 

    this.solicitudesDatos = nvaSolicitud.solicitudes
      .filter(solicitud => solicitud.estatus.clave === Constants.CLAVE_CAPTURA_DATOS);

    this.solicitudesPreautorizadas = nvaSolicitud.solicitudes
      .filter(solicitud => solicitud.estatus.clave === Constants.CLAVE_SOLICITUD_PREAUTORIZADA);

    this.solicitudesFirmaContrato = nvaSolicitud.solicitudes
      .filter(solicitud => solicitud.estatus.clave === Constants.CLAVE_FIRMA_CONTRATO_PAGARE);
      
    this.solicitudesAutorizadas = nvaSolicitud.solicitudes
      .filter(solicitud => solicitud.estatus.clave === Constants.CLAVE_SOLICITUD_AUTORIZADA_DISPERSADA); 
  }

  // SERVICIOS DE TERCEROS
  obtenerSolicitudes(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.solicitudesService.obtenerSolicitudes(this.token, this.idSucursal)
      .subscribe(resp => {
  
        this.solicitudes = resp.resultado;
        this.filtrarSolicitudesEstatus(resp.resultado);

        resolve(true);
  
      }, (err) => {
        const status = UtilsRest.validaApiError(err, false, this.authService)
        if(status.codigo == 401) {
          this.authService.guardarToken(status.accessToken, status.refreshToken)
          this.obtenerSolicitudes();
        }
        reject();
      });
    });
  }
}
