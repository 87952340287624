import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms'
import { NegocioModel } from 'src/app/models/business/negocio.model'
import { AutocompleteCliente } from 'src/app/models/clients/autocomplete-cliente.model'
import { ClienteModel } from 'src/app/models/clients/cliente.model'
import { AuthService } from 'src/app/services/auth.service'
import { ClienteService } from 'src/app/services/cliente.service'
import UtilsRest from 'src/utils/UtilsRest'

export type NegociosIds = {
  cliente: AutocompleteCliente
  negocio: NegocioModel
}

@Component({
  selector: 'app-buscar-cliente',
  templateUrl: './buscar-cliente.component.html',
  styleUrls: ['./buscar-cliente.component.scss']
})
export class BuscarClienteComponent implements OnInit {
  private token: string = sessionStorage.getItem('token')
  private idSucursal: string = sessionStorage.getItem('idSucursal')

  // EVENTOS DE SALIDA
  @Output() negocioEmit = new EventEmitter<NegociosIds>()

  // PARAMETROS LOCALES
  form: UntypedFormGroup

  // CLIENTES
  autocompleteClientes: AutocompleteCliente[] = []
  clientes: ClienteModel[] = []
  clienteSelect: AutocompleteCliente

  // NEGOCIOS
  negocios: NegocioModel[] = []
  negocioSelect: NegocioModel

  // CONSTANTES
  keyword: string = 'nombre'

  constructor (
    private clienteService: ClienteService,
    private authService: AuthService,
    private fb: UntypedFormBuilder
  ) {
    this._crearFormulario()
  }

  ngOnInit (): void {
    this._buscarClientes('')
  }

  // METODOS HTML
  selectEvent (item) {
    this.clienteSelect = item
    this._buscarNegocios(this.clienteSelect.numCliente)
  }

  clearedEvent () {
    this.clienteSelect = null
    this.negocioSelect = null

    this.negocioEmit.emit({
      cliente: this.clienteSelect,
      negocio: this.negocioSelect
    })
  }

  _crearFormulario () {
    this.form = this.fb.group({
      negocio: [null, [Validators.required]]
    })
  }

  // SERVICIOS EXTERNOS
  _buscarClientes (termino: string) {
    this.clienteService
      .obtenerClientesByTermino(this.token, termino, this.idSucursal)
      .subscribe(
        resp => {
          this.clientes = resp.resultados
          this._crearDataAutocomplete(this.clientes)
        },
        err => {
          const status = UtilsRest.validaApiError(err, false, this.authService)
          if (status.codigo == 401) {
            this.authService.guardarToken(
              status.accessToken,
              status.refreshToken
            )
            this._buscarClientes(termino)
          }
          if (status.codigo == 404) {
            this.clientes = []
          }
        }
      )
  }

  _buscarNegocios (numCliente: string) {
    this.clienteService
      .obtenerNegociosByCliente(this.token, numCliente)
      .subscribe(
        resp => {
          this.negocios = resp.resultados
          this.negocioSelect = resp.resultados[0]
        },
        err => {
          const status = UtilsRest.validaApiError(err, false, this.authService)
          if (status.codigo == 401) {
            this.authService.guardarToken(
              status.accessToken,
              status.refreshToken
            )
            this._buscarNegocios(numCliente)
          }
          if (status.codigo == 404) {
            this.negocios = []
          }
        }
      )
  }

  // METODOS PRIVADOS
  private _crearDataAutocomplete (clientes: ClienteModel[]) {
    for (let cliente of clientes) {
      let autocompleteCliente = new AutocompleteCliente()

      autocompleteCliente.nombre = this._eliminarDiacriticosEs(
        `${cliente.persona.nombre} ${cliente.persona.apellidoPaterno} ${cliente.persona.apellidoMaterno}`
      )
      autocompleteCliente.idCliente = cliente.idCliente
      autocompleteCliente.numCliente = cliente.numCliente

      this.autocompleteClientes.push(autocompleteCliente)
    }
  }

  private _eliminarDiacriticosEs (texto: string) {
    return texto
      .normalize('NFD')
      .replace(
        /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
        '$1'
      )
      .normalize()
  }

  enviarInfoCliente () {
    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach(control => {
        if (control instanceof UntypedFormGroup) {
          Object.values(control.controls).forEach(con => con.markAsTouched())
        } else {
          control.markAsTouched()
        }
      })
    }

    this.negocioEmit.emit({
      cliente: this.clienteSelect,
      negocio: this.negocioSelect
    })
  }

  // VALIDACIONES FORM
  get negocioInvalido () {
    return this.form.get('negocio').invalid && this.form.get('negocio').touched
  }
}
