<!-- START card -->
<div class="card card-default">
    <div class="card-body" *ngIf="datosTemporales && catalogosSolicitud">
        <h4 class="text-primary">Datos temporales</h4>
        <p>
            <span class="title-labels">Estado Civil: </span> {{ buscarEstadoCivil(datosTemporales.claveEstadoCivil) }}
        </p>
        <p>
            <span class="title-labels">Nivel Escolar: </span> {{ buscarNivelEscolar(datosTemporales.claveNivelEscolar) }}
        </p>
        <p>
            <span class="title-labels">Ocupación: </span> {{ buscarOcupacion(datosTemporales.claveOcupacion) }}
        </p>
        <p>
            <span class="title-labels">Fuente Ingreso: </span> {{ buscarFuenteIngreso(datosTemporales.claveFuenteIngreso) }}
        </p>
        <p>
            <span class="title-labels">Tipo Vivienda: </span> {{ buscarTipoVivienda(datosTemporales.claveTipoVivienda) }}
        </p>
        <p>
            <span class="title-labels">Escrituras: </span> {{ datosTemporales.escriturasVivienda == 'S' ? 'Con' : 'Sin' }} escrituras
        </p>
        <p>
            <span class="title-labels">Email:</span> {{datosTemporales.email}}
        </p>
        <p>
            <span class="title-labels">Número Dependiente Económicos: </span> {{datosTemporales.numDependientesEconomicos}}
        </p>
        <p>
            <span class="title-labels">Actividad Económica: </span> {{datosTemporales.actividadEconomicaNegocio}}
        </p>
        <p>
            <span class="title-labels">Antiguedad (Meses): </span> {{datosTemporales.antiguedadNegocio}}
        </p>
        <p>
            <span class="title-labels">Entidad Financiera Anterior: </span> {{datosTemporales.entidadFinancieraAnterior}}
        </p>
        <div *ngFor="let telefono of datosTemporales.telefonos">
            <p>
                <span class="title-labels">Teléfono {{telefono.claveTipoTelefono == 'CEL' ? 'Celular' : '' }}  de {{telefono.lugar}}: </span> {{telefono.telefono}}
            </p>
        </div>

        <h4 class="text-primary">Ingresos</h4>
        <p>
            <span class="title-labels">Ingreso Mensual Cliente: </span> {{datosTemporales.ingresos.ingresoCliente | currency}}
        </p>
        <p>
            <span class="title-labels">Ingreso Mensual Negocio: </span> {{datosTemporales.ingresos.ingresoNegocio | currency}}
        </p>
        <p>
            <span class="title-labels">Ingreso Apoyo Familiar: </span> {{datosTemporales.ingresos.ingresoApoyoFamiliar | currency}}
        </p>
        <p>
            <span class="title-labels">Otro Ingreso: </span> {{datosTemporales.ingresos.otroIngreso | currency}}
        </p>

        <h4 class="text-primary">Gastos</h4>
        <p>
            <span class="title-labels">Gasto Alimentos: </span> {{datosTemporales.gastos.gastoAlimentos | currency}}
        </p>
        <p>
            <span class="title-labels">Gasto Servicios Básicos: </span> {{datosTemporales.gastos.gastoServiciosBasicos | currency}}
        </p>
        <p>
            <span class="title-labels">Gasto Prestamos: </span> {{datosTemporales.gastos.gastoRenta | currency}}
        </p>
        <p>
            <span class="title-labels">Gasto Renta: </span> {{datosTemporales.gastos.gastoAlimentos | currency}}
        </p>
        <p>
            <span class="title-labels">Gasto Materia Prima:</span> {{datosTemporales.gastos.gastoMateriaPrima | currency}}
        </p>
        <p>
            <span class="title-labels">Otro Gasto: </span> {{datosTemporales.gastos.otroGasto | currency}}
        </p>

    </div>
</div>