import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from 'src/app/classes/constants.class';
import { Telefono } from 'src/app/models/person/telefono.model';

@Component({
  selector: 'app-telefonos',
  templateUrl: './telefonos.component.html',
  styleUrls: ['./telefonos.component.scss']
})
export class TelefonosComponent implements OnInit, OnChanges {

  // CONSTANTES
  claveTel = Constants.CLAVE_TELEFONO;
  claveCel = Constants.CLAVE_CELULAR;

  // PARAMETROS RECIBIDOS
  @Input() telefonos: Telefono[]

  // FORMS
  formTelefonos: UntypedFormGroup;
  arrayFormTelefonos: UntypedFormArray;

  constructor(
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this._crearFormularioTelefonos();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.telefonos) this._crearFormularioTelefonos();
  }

  // HANDLERS
  validarFormHandler() {
    return this.formTelefonos;
  }

  // METODOS FORMS
  _crearFormularioTelefonos() {

    this.arrayFormTelefonos = this.fb.array([]);

    this.formTelefonos = this.fb.group({ 
      telefonos: this.arrayFormTelefonos
    });

    this.telefonos.forEach(e => this.agregarTelefono(e.telefono, e.claveTipoTelefono));
  }

  agregarTelefono(telefono: string = null, tipoTelefono: string = null) {

    const telForm = this.fb.group({
      telefono: [
        telefono != null ? telefono : '', 
        [Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.required]],
      tipoTelefono: [
        tipoTelefono != null ? tipoTelefono : Constants.CLAVE_CELULAR
      ]
    });

    this.arrayFormTelefonos.push(telForm);
  }

  eliminarTelefono(index: number) {
    this.arrayFormTelefonos.removeAt(index);
  }
}
