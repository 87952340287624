import { UntypedFormGroup } from '@angular/forms';
import { ClienteCredito } from 'src/app/models/clients/cliente-request.model';
import Utils from 'src/utils/Utils';

export default class UtilsCliente { 
    static mapFormtoDto(
        form: UntypedFormGroup,
        idPersona: string,
        idUsuario: string,
        idCliente: string = null
    ):ClienteCredito {

        const cliente: ClienteCredito = {
            idCliente: idCliente,
            idPersona: idPersona,
            claveOcupacion: form.get('ocupacion').value,
            claveNivelEscolar: form.get('nivelEscolar').value,
            claveFuenteIngreso: form.get('fuenteIngresos').value,
            claveMedioConocimiento: form.get('medioConocimiento').value,
            numDependientesEconomicos: form.get('depEconomicos').value,
            entidadFinancieraAnterior: form.get('otraFinanciera').value,
            ingresoMensual: Utils.b64EncodeUnicode(form.get('cantidadFuenteIngresos').value),
            usuarioUltMod: idUsuario,
        }

        return cliente;
    }
}