export class Constants {

    //Tipos de telefono
    public static CLAVE_TELEFONO = 'TEL';
    public static CLAVE_CELULAR = 'CEL';

    //Tipos vivienda
    public static CLAVE_PROPIA = 'PR';

    //Tipos solicitud
    public static CLAVE_TIPO_SOLICITUD_NUEVA = 'CN';
    public static CLAVE_TIPO_SOLICITUD_NUEVA_PAG_WEB = 'CNPW';
    public static CLAVE_TIPO_SOLICITUD_RENOVACION = 'R';
    public static CLAVE_TIPO_SOLICITUD_PREMIUM = 'CNP';

    //Roles
    public static ROLE_ADMIN = 'ROLE_ADMIN';
    public static ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
    public static ROLE_COLLECTOR = 'ROLE_COLLECTOR';
    public static ROLE_USER = 'ROLE_USER';
    public static ROLE_CLIENT = 'ROLE_CLIENT';

    //Tipos expedientes
    public static CLAVE_TIPO_EXPEDIENTE_NUEVO = 'SCN';
    public static CLAVE_TIPO_EXPEDIENTE_CREDITO = 'CRE';
    public static CLAVE_TIPO_EXPEDIENTE_PREMIUM = 'SCP';

    //Tipos fuentes ingreso
    public static CLAVE_TIPO_FUENTE_INGRESO_NINGUNO = 'N';
    public static CLAVE_TIPO_FUENTE_INGRESO_SIN_DEFINIR = 'SD';

    //Tipos estatus solicitud
    public static CLAVE_TIPO_ESTATUS_CAPTURA_DATOS = 'CD';
    public static CLAVE_TIPO_ESTATUS_SUBIR_ARCHIVOS = 'FCP';
    public static CLAVE_TIPO_ESTATUS_CANCELACION = 'C';

    //Tipos estatus visitas cobranza
    public static CLAVE_NEGOCIO_VISITADO = 'NV';
    public static CLAVE_NEGOCIO_NO_VISITADO = 'SV';
    public static CLAVE_CUOTA_PAGADA = 'CP';
    public static CLAVE_PAGO_ADELANTADO = 'PA';
    public static CLAVE_PAGO_CANCELADO = 'CAN';

    //Tipos clasificaciones archivos
    public static CLAVE_TIPO_CLASIFICACION_ARCHIVO_CREDITO = 'CC';

    // Tipos creditos
    public static CLAVE_TIPO_CREDITO_GENERAL = 'G';

    // Estatus solicitudes de credito
    public static CLAVE_SOLICITUD_CREADA = 'SC';
    public static CLAVE_CAPTURA_DATOS_TEMPORALES = 'CDT';
    public static CLAVE_RECEPCION_VALIDACION_DOCUMENTOS = 'RVD';
    public static CLAVE_CAPTURA_DATOS = 'CD';
    public static CLAVE_SOLICITUD_PREAUTORIZADA = 'PRE';
    public static CLAVE_FIRMA_CONTRATO_PAGARE = 'FCP';
    public static CLAVE_SOLICITUD_AUTORIZADA_DISPERSADA = 'AD';
    
    //Estatus verificacion de datos
    public static CLAVE_DATOS_CAPTURADOS = 'DC';
    public static CLAVE_DATOS_VERIFICADOS = 'VER';

    // Estatus interesados pagina web
    public static CLAVE_POR_REVISAR = 'PR';
    public static CLAVE_VALIDACION_DATOS = 'VD';
    public static CLAVE_CLIENTE_POTENCIAL = 'PC';
    public static CLAVE_CREDITO_OTROGADO = 'CO';

    //Tipos archivos
    public static CLAVE_TIPO_ARCHIVO_CONTRATO = 'COC';

    //Extensiones archivos
    public static EXTENSION_PDF = 'pdf';
    public static TIPO_DIGITALIZACION_PDF = 'pdf';
    public static TIPO_DIGITALIZACION_IMAGEN = 'imagen';

    //Tipos generales
    public static CLAVE_TIPO_SIN_DEFINIR = 'Sin Definir';

    //Tipos contrato
    public static CONTRATO_ELEPHAS = 'ELEPHAS';
    public static CONTRATO_SMILES = 'SMILES';

    // Forma de pago
    public static CLAVE_PAGO_TRANSFERENCIA = 'T';

    // Coordenadas Point Constitución
    public static LATITUD_POINT = 20.1157008;
    public static LONGITUD_POINT = -98.7518382;

}