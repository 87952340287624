import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActualizarEstatusNegocio } from '../models/collections/cobranza.model';
import { CancelarPagoModel, ComplementoPagoModel, RegistrarPagoModel, RegistrarVisitaModel } from '../models/credits/pagos.model';
import { notNullUndefined } from '../../utils/nonNullUndefined';

@Injectable({
  providedIn: 'root'
})
export class CobranzaService {
  constructor(private http: HttpClient) { }

  obtenerRutaCobranza(accessToken: string, idSucursal: string, fechaRuta?: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    const url = notNullUndefined(fechaRuta)
      ?  `${environment.url}/cobranza/v1/ruta-cobranza?idSucursal=${idSucursal}&fecha=${fechaRuta}`
      : `${environment.url}/cobranza/v1/ruta-cobranza?idSucursal=${idSucursal}`

    return this.http.get(
      url, 
      {headers: headers}
    );
  }

  obtenerCatalogoImpago(accessToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/cobranza/v1/catalogo/negocios/impago`, 
      {headers: headers}
    );
  }

  // POST - CUOTAS

  realizarPago(accessToken: string, registrarPago: RegistrarPagoModel): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/cobranza/v1/pago`, JSON.stringify(registrarPago), {headers: headers});
  }

  realizarComplementoPago(accessToken: string, complementoPago: ComplementoPagoModel): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/cobranza/v1/complemento-pago`, JSON.stringify(complementoPago), {headers: headers});
  }

  realizarVisita(accessToken: string, registrarVisita: RegistrarVisitaModel): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/cobranza/v1/visita`, JSON.stringify(registrarVisita), {headers: headers});
  }

  realizarCancelacion(accessToken: string, cancelarPago: CancelarPagoModel): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/cobranza/v1/cancelacion-pago`, JSON.stringify(cancelarPago), {headers: headers});
  }

  actualizarEstatusNegocioCobranza(accessToken: string, actualizarEstatusNegocio: ActualizarEstatusNegocio): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.put(
      `${environment.url}/cobranza/v1/cobranza/estatus-negocio`,
      JSON.stringify(actualizarEstatusNegocio),
      {headers: headers}
    );
  }
}
