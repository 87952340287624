import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SolicitudResumidasModel } from 'src/app/models/credits-application/solicitud.model';
import { AuthService } from 'src/app/services/auth.service';
import { SolicitudesService } from 'src/app/services/solicitudes.service';
import UtilsRest from 'src/utils/UtilsRest';

@Component({
  selector: 'app-solicitud-autorizada-dispersada',
  templateUrl: './solicitud-autorizada-dispersada.component.html',
  styleUrls: ['./solicitud-autorizada-dispersada.component.scss']
})
export class SolicitudAutorizadaDispersadaComponent implements OnChanges {

  private token: string = sessionStorage.getItem('token');

  // PARAMETROS RECIBIDOS
  @Input() solicitudesAutorizadas: SolicitudResumidasModel[];

  constructor(
    private router: Router,
    private authService: AuthService,
    private solicitudesService: SolicitudesService,
  ) { }

  ngOnChanges(): void {}

  // METODOS HTML
  visualizarSolicitud(solicitud: SolicitudResumidasModel) {
    this.buscarSolicitud(solicitud.numSolicitud);
  }

  // SERVICIOS EXTERNOS
  buscarSolicitud(numSolicitud: string) {
    this.solicitudesService.obtenerSolicitud(
      this.token, 
      numSolicitud, 
      false
    ).subscribe(resp => {

      const solicitud = resp.resultado;
      const route = '/creditos';
      
      this.router.navigate([route], {
        queryParams: {
          numContrato: solicitud.credito.numContrato,
        }
      });
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarSolicitud(numSolicitud);
      }
    });
  }

}
