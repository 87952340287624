import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DetalleCuotaModel } from 'src/app/models/credits/cuota.model';
import { AuthService } from 'src/app/services/auth.service';
import { CreditoService } from 'src/app/services/credito.service';
import Utils from 'src/utils/Utils';
import UtilsRest from 'src/utils/UtilsRest';
import UtilsSwal from 'src/utils/UtilsSwal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-creditos-page',
  templateUrl: './creditos-page.component.html',
  styleUrls: ['./creditos-page.component.scss']
})
export class CreditosPageComponent implements OnInit {

  token: string = sessionStorage.getItem('token');
  idSucursal: string = sessionStorage.getItem('idSucursal');

  // ELEMENTOS DEL HTML
  @ViewChild('appCliNegCred') appCliNegCred: any;

  // VALIDACION ROL
  permisoUsuario: boolean;

  // PARAMETROS LOCALES
  showButton: boolean = false;
  creditosActivos: boolean = true;
  tipoBusqueda: string = "credito";
  numContrato: string;
  todosNumContratos: string[] = [];

  // PARAMETROS EXPORTAR
  cuotas: DetalleCuotaModel[];

  // PARAMETROS AUTOCOMPLETE
  keyword: string = '';

  constructor(
    private authService: AuthService, 
    private creditoService: CreditoService, 
    private activatedRoute: ActivatedRoute,
  ) {
    this._buscarCreditosAutocomplete(
      this.keyword, 
      this.creditosActivos
    );
  }

  ngOnInit(): void {

    this.permisoUsuario = this.authService.permisosUsuario();
    
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.numContrato = params['numContrato'];
      }
    );
  }

  // METODOS AUTOCOMPLETE
  clearedEvent() {
    this.numContrato = null;
    this.cuotas = null;
  }

  selectEvent(item) {
    if(item === this.numContrato) return; 
    this.numContrato = item;
    this._getInfoCuotas(this.numContrato);
  }

  // METODOS RECIBIDIOS DEL OUTPUT
  updateButtonState(enable) {
    if(!enable) {
      this.clearedEvent();
    }
    this.showButton = enable;
  }

  cuotaUpdated(updated) {
    if(updated) {
      this._getInfoCuotas(this.numContrato);
    }
  }

  //HANDLER DE RADIO BUTTON - CHECKBOX
  onItemChange(value){
    this.tipoBusqueda = value;
    this.numContrato = null;
    this.cuotas = null;
    this.showButton = false;
  }

  onCheckboxSelect(event) {

    this.creditosActivos = event.target.checked;
    this._buscarCreditosAutocomplete(
      this.keyword, 
      this.creditosActivos
    );
  }

  // OBTENER INFORMACIÓN
  _buscarCreditosAutocomplete(numContrato: string, activos: boolean) {
    this.creditoService.obtenerCreditosAutocomplete(
      this.token, 
      numContrato, 
      this.idSucursal,
      activos
    ).subscribe(resp => {
      this.todosNumContratos = resp.resultado;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._buscarCreditosAutocomplete(numContrato, activos);
      }
      if(status.codigo == 404) {
        this.todosNumContratos = [];
      }
    });
  }

  private _getInfoCuotas(numContrato: string) {

    UtilsSwal.buscandoInformacion();
    Swal.showLoading();

    // FIXME: Agregar filtros para order cuotas asc / desc en html
    this.creditoService.obtenerHistoricoCuotas(this.token, numContrato, true, "DESC")
    .subscribe(resp => {

      this.cuotas = resp.resultados;
      Swal.close();
      
    }, err => {

      Swal.close();
      const status = UtilsRest.validaApiError(err, true, this.authService);

      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getInfoCuotas(numContrato);
      }

    });
  }

  // METODOS PRIVADOS
  buscarCredito() {
      // Se manda a llamar el método validarFormHandler del componente cli-neg-cred (autocomplete)
      // Para obtener el form con el num de contrato
      const formNegCred = this.appCliNegCred.validarFormHandler();
      if (formNegCred.invalid) {
        return Utils.validateForms(formNegCred)
      }
      this._getInfoCuotas(formNegCred.get('credito').value.numContrato);
      this.numContrato = formNegCred.get('credito').value.numContrato;
  }

}
