<nav class="main-menu">
    <ul>
        <li routerLinkActive="active">
            <a [routerLink]="['/home']">
                <i class="fa fa-home fa-2x"></i>
                <span class="nav-text">
                    Dashboard
                </span>
            </a>
        </li>
        <li class="has-subnav" routerLinkActive="active" *ngIf="permisoUsuario">
            <a [routerLink]="['/clientes']">
                <i class="fa fa-address-card fa-2x"></i>
                <span class="nav-text">
                    Clientes
                </span>
            </a>
        </li>
        <li class="has-subnav" routerLinkActive="active">
            <a [routerLink]="['/cobranza']">
                <i class="fa fa-motorcycle fa-2x"></i>
                <span class="nav-text">
                    Cobranza
                </span>
            </a>
        </li>
        <li class="has-subnav" routerLinkActive="active" *ngIf="permisoUsuario">
            <a [routerLink]="['/creditos']">
                <i class="fa fa-credit-card fa-2x"></i>
                <span class="nav-text">
                   Créditos
                </span>
            </a>
        </li>
        <li class="has-subnav" routerLinkActive="active" *ngIf="permisoUsuario">
            <a [routerLink]="['/historico']">
                <i class="fa fa-history" aria-hidden="true"></i>
                <span class="nav-text">
                    Historico
                </span>
            </a>
        </li>
        <li class="has-subnav" routerLinkActive="active" *ngIf="permisoUsuario">
            <a [routerLink]="['/solicitudes']">
                <i class="fa fa-edit fa-2x"></i>
                <span class="nav-text">
                   Solicitudes
                </span>
            </a>
        </li>
        <li class="has-subnav" routerLinkActive="active" *ngIf="permisoUsuario">
            <a [routerLink]="['/solicitudes-pagina-web']">
                <i class="fa fa-globe fa-2x"></i>
                <span class="nav-text">
                   Página web
                </span>
            </a>
        </li>
        <li class="has-subnav" routerLinkActive="active">
            <a [routerLink]="['/reportes']">
                <i class="fa fa-file-pdf fa-2x"></i>
                <span class="nav-text">
                    Reportes
                </span>
            </a>
        </li>
    </ul>

    <ul class="logout">
        <li>
            <a [routerLink]="['/cambiar-password']">
                <i class="fa fa-key fa-2x"></i>
                <span class="nav-text">
                    Cambiar contraseña
                </span>
            </a>
        </li>
        <li>
            <a href="#" (click)="logout()">
                <i class="fa fa-power-off fa-2x"></i>
                <span class="nav-text">
                    Cerrar sesión
                </span>
            </a>
        </li>
    </ul>
</nav>