<div class="row">
    <div class="col-lg-7" *ngIf="cuotaSelect">
        <!-- START ROW PAGOS -->
        <app-pagos [pagoModel]="pagoModel"> </app-pagos>
    </div>
    <div class="col-lg-5" *ngIf="cuotaSelect">
        <!-- START INTERESES -->
        <app-intereses [intereses]="intereses"> </app-intereses>

        <!-- START ADEUDOS -->
        <app-adeudos [adeudos]="adeudos"> </app-adeudos>
    </div>
</div>

<!-- START ROW CUOTA DEL CREDITO-->
<div class="row">
    <div class="col-lg-12 d-flex" *ngIf="cuotas && cuotas.length > 0">
        <div class="card social-card share  full-width m-b-10 no-border" data-social="item">
            <div class="card-body">
                <div class="card-header clearfix">
                    <div class="row">
                        <div class="col-10-flex">
                            <h4 class="text-primary">Cuotas del crédito</h4>
                        </div>
                    </div>
                </div>
                <!-- START TABLE -->
                <div class="table-responsive vertical-scroll" *ngIf="cuotas.length > 0">
                    <div class="card card-borderless">
                        <table class="table table-hover table-condensed table-responsive" id="condensedTable" aria-describedby="Cuotas del crédito">
                            <thead>
                                <tr>
                                    <th class="v-align-middle bold text-center col-1">No.</th>
                                    <th class="v-align-middle bold text-center col-1">Fecha Inicio</th>
                                    <th class="v-align-middle bold text-center col-1">Fecha Fin</th>
                                    <th class="v-align-middle bold text-right col-2">Pago Requerido</th>
                                    <th class="v-align-middle bold text-right col-2">Capital</th>
                                    <th class="v-align-middle bold text-right col-2">Intereses</th>
                                    <th class="v-align-middle bold text-right col-2">Capital + Intereses</th>
                                    <th class="v-align-middle bold text-right col-2">Saldo liquidar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cuota of cuotas; let i = index" (click)="setCuotaSelect(cuota)" [class.selected]="cuota.numCuota === cuotaSelect.numCuota">
                                    <td class="v-align-middle normal text-center">{{cuota.numCuota}}</td>
                                    <td class="v-align-middle normal text-center">{{cuota.fechaInicioCuota}}</td>
                                    <td class="v-align-middle normal text-center">{{cuota.fechaFinCuota}}</td>
                                    <td class="v-align-middle normal text-right">{{cuota.pagoRequerido | currency }}</td>
                                    <td class="v-align-middle normal text-right">{{cuota.saldoCapital | currency }}</td>
                                    <td class="v-align-middle normal text-right">{{cuota.saldoIntereses | currency }}</td>
                                    <td class="v-align-middle normal text-right">{{cuota.saldoCapitalIntereses | currency }}</td>
                                    <td class="v-align-middle normal text-right">{{cuota.saldoLiquidar | currency }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>