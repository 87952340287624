import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import UtilsSwal from '../../../utils/UtilsSwal';
import { Constants } from '../../classes/constants.class';
import { RolModel } from '../../models/users/rol.model';
import { SucursalModel } from '../../models/branch-office/sucursal.model';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginPageComponent implements OnInit {

  anio: number = new Date().getFullYear();
  username: string;
  password: string;
  recordarUsuario = false;
  tipoCampo: boolean;

  constructor(private authService: AuthService, private router: Router, private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    if (this.authService.isLoggin()) {
      this.router.navigate(['/home']);
    }

    if (localStorage.getItem('username') ) {
      this.username = localStorage.getItem('username');
      this.recordarUsuario = true;
    }
  }

  cambiarTipoCampo() {
    this.tipoCampo = !this.tipoCampo;
  }

  login(form: NgForm) {

    if (form.invalid) {
      return;
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Autentificando...',
      heightAuto: false
    });

    Swal.showLoading();

    this.authService.login(this.username, this.password)
      .subscribe(respLogin => {
        let sucursales: SucursalModel[] = [];

        this.usuarioService.obtenerDatosUsuario(respLogin.access_token, this.username)
        .subscribe(resp => {
          sucursales = resp.resultado.sucursales;
          const roles: RolModel[] = resp.resultado.roles;

          // FIXME: Desde backend validar por credenciales del sistema que no puedan generar token
          // Se agrega doble validación para que usuarios con rol de cliente no puedan acceder al sistema
          if(roles.find(rol => rol.nombre === Constants.ROLE_CLIENT)) {
            UtilsSwal.lanzarError(
              'Acceso denegado',
              'No puedes acceder a este recurso'
            );
            throw new Error('Access denied');
          }

          //Se evalua si el usuario tiene asignado mas de una sucursal para mostrar pantalla y seleccionar sucursal
          if (sucursales.length > 1) {
            let sucursalesSelect = new Map<number, string>();
      
            sucursales.forEach(sucursal => {
              sucursalesSelect.set(sucursal.idSucursal, sucursal.nombre);
            });
            
            Swal.fire({
              title: 'Selecciona la sucursal',
              input: 'select',
              inputOptions: sucursalesSelect,
              inputPlaceholder: 'Seleccionar sucursal',
              showCancelButton: true,
              heightAuto: false,
              allowOutsideClick: false,
              confirmButtonText: 'Seleccionar',
              cancelButtonText: 'Cancelar',
              inputValidator: (value) => {
                return new Promise((resolve) => {
                  if (value != '') {
                    resolve(undefined)
                  } else {
                    resolve('Selecciona la sucursal')
                  }
                })
              }
            }).then(result => {
              if (result.isConfirmed) {
                let idSucursalSelect = result.value;
                
                this._setSessionVariables(respLogin.access_token, respLogin.refresh_token, idSucursalSelect, sucursalesSelect.get(+idSucursalSelect));
                this.router.navigate(['/home']);
              }
            });
          } else {
            this._setSessionVariables(respLogin.access_token, respLogin.refresh_token, sucursales[0].idSucursal.toString(), sucursales[0].nombre);
            this.router.navigate(['/home']);
          }
        }, (err) => {
          this._validarEstatusError(err);
        });
        Swal.close();
      }, (err) => {
        this._validarEstatusError(err);
      });
  }

  private _setSessionVariables(accessToken: string, refreshToken: string, idSucursal: string, nombreSucursal: string) {
    this.authService.guardarUsuario(accessToken);
    this.authService.guardarToken(accessToken, refreshToken);

    sessionStorage.setItem('username', this.username);
    sessionStorage.setItem('idSucursal', idSucursal);
    sessionStorage.setItem('nombreSucursal', nombreSucursal);

    if (this.recordarUsuario) {
      localStorage.setItem('username', this.username);
    } else {
      localStorage.removeItem('username');
    }
  }

  private _validarEstatusError(err) {
    if (err.status == 400 || err.status == 401) {
      this._lanzarError('Error', err.error.detalles[0].mensaje.split(".", 1));
    } else if (err.status == 403) {
      this._lanzarError('Acceso denegado', err.error.detalles[0].mensaje);
    } else if (err.status == 404) {
      this._lanzarError('Error', '¡Ups! No podemos autenticarte, revisa tus datos de usuario y contraseña');
    } else if (err.status == 500 || err.status == 503) {
      this._lanzarError('Error de comunicación', err.error.detalles[0].mensaje);
    } else {
      this._lanzarError('Error desconocido', 'Lo sentimos, ocurrió un error al intentar procesar la petición, si el problema persiste reportalo a soporte@grupofragy.com');
    }
  }

  private _lanzarError(titulo: string, texto: string) {
    Swal.fire({
      icon: 'error',
      title: titulo,
      text: texto,
      heightAuto: false
    });
  }

}
