import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CatResponse } from 'src/app/models/catalogues/cat-response.model';
import { ClienteInfoCompleta } from 'src/app/models/clients/cliente.model';
import { AuthService } from 'src/app/services/auth.service';
import { ClienteService } from 'src/app/services/cliente.service';
import UtilsRest from 'src/utils/UtilsRest';
import { personaEmpty } from '../../forms/persona-form/persona-form.component';
import { negocioEmpty } from '../../forms/negocios-form/negocios-form.component';
import { NegocioInfoCompleta } from '../../../models/business/negocio-request.model';
import { PersonaInfoCompleta } from 'src/app/models/person/persona.model';
import { PersonaService } from 'src/app/services/persona.service';

@Component({
  selector: 'app-modificar-cliente',
  templateUrl: './modificar-cliente.component.html',
  styleUrls: ['./modificar-cliente.component.scss'],
})
export class ModificarClienteComponent implements OnInit {

  private token: string = sessionStorage.getItem('token');

  // PARAMETROS RECIBIDOS
  idNegocio: string;

  // PARAMETROS LOCALES
  paso: number = 1;
  negocio: NegocioInfoCompleta;
  persona: PersonaInfoCompleta;
  cliente: ClienteInfoCompleta;

  // CATALOGOS
  catMediosConocimiento: CatResponse[] = [];
  catNivelesEscolares: CatResponse[] = [];
  catOcupaciones: CatResponse[] = [];
  catFuentesIngresos: CatResponse[] = [];
  catEstadoCivil: CatResponse[] = [];
  catTipoVivienda: CatResponse[] = [];

  // ELEMENTOS DEL HTML
  @ViewChild('pasoAdelante') pasoAdelante: any;
  @ViewChild('pasoAtras') pasoAtras: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService, 
    private clienteService: ClienteService,
    private personaService: PersonaService,
  ) {}

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(
      params => {
        this.idNegocio = params['idNegocio'];
      }
    );

    this._getMediosConocimiento();
    this._getNivelesEscolares();
    this._getOcupaciones();
    this._getFuentesIngresos();
    this._getTipoVivienda();
    this._getEstadoCivil();
    this._getInfoCliente(this.idNegocio);
  }

  // OBTENER INFORMACIÓN
  private _getInfoCliente(idNegocio: string) {
    const idsNegocios = [idNegocio];
    this.clienteService.obtenerClienteByIdNegocio(this.token, idsNegocios, true)
    .subscribe(resp => {

      this.negocio = resp.resultados[0];
      this.persona = this.negocio.cliente.persona;
      this.cliente = this.negocio.cliente;

    }, err => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getInfoCliente(idNegocio);
      }
      if(status.codigo == 404) {
        this.negocio = negocioEmpty;
        this.persona = personaEmpty;
      } 
    });
  }

  private _getMediosConocimiento() {
    this.clienteService.obtenerMedioConocimiento(this.token)
    .subscribe(resp => {
      this.catMediosConocimiento = resp.resultados;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getMediosConocimiento();
      }
    });
  }

  private _getNivelesEscolares() {
    this.clienteService.obtenerNivelEscolar(this.token)
    .subscribe(resp => {
      this.catNivelesEscolares = resp.resultados;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getNivelesEscolares();
      }
    });
  }

  private _getOcupaciones() {
    this.clienteService.obtenerOcupacion(this.token)
    .subscribe(resp => {
      this.catOcupaciones = resp.resultados;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getOcupaciones();
      }
    });
  }

  private _getFuentesIngresos() {
    this.clienteService.obtenerFuenteIngresos(this.token)
    .subscribe(resp => {
      this.catFuentesIngresos = resp.resultados;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getFuentesIngresos();
      }
    });
  }

  private _getTipoVivienda() {
    this.personaService.obtenerTipoVivienda(this.token)
    .subscribe(resp => {
      this.catTipoVivienda = resp.resultados;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getTipoVivienda();
      }
    });
  }

  private _getEstadoCivil() {
    this.personaService.obtenerEstadoCivil(this.token)
    .subscribe(resp => {
      this.catEstadoCivil = resp.resultados;
    }, (err) => {
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getEstadoCivil();
      }
    });
  }

  // NAVEGACIÓN
  // Instrucciones para cambiar de pantallas
  siguientePaso() {
    this.paso++
    if(this.paso >= 3) this.paso = 3;
  }

  anteriorPaso() {
    this.paso--;
    if(this.paso <= 1) this.paso = 1;
  }

}