import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ClienteService } from 'src/app/services/cliente.service';
import Utils from 'src/utils/Utils';
import UtilsRest from 'src/utils/UtilsRest';
import Swal from 'sweetalert2';
import UtilsSwal from '../../../../utils/UtilsSwal';
import { UserClientModel } from '../../../models/clients/cliente.model';
import { NegocioInfoCompleta } from '../../../models/business/negocio-request.model';
import { LockUserModel, ResetPasswordModel, UsuarioGeneralModel } from '../../../models/users/usuario.model';
import { UsuarioService } from '../../../services/usuario.service';

@Component({
  selector: 'app-detalle-cliente',
  templateUrl: './detalle-cliente.component.html',
  styleUrls: ['./detalle-cliente.component.scss']
})
export class DetalleClienteComponent implements OnInit, OnDestroy {

  @Input() idNegocio: string;
  private token: string = sessionStorage.getItem('token');
  private idUsuario: string = sessionStorage.getItem('idUsuario');

  negocio: NegocioInfoCompleta;
  capacidadPago: number;
  usuario: UsuarioGeneralModel;

  constructor(
    private authService: AuthService, 
    private clienteService: ClienteService,
    private usuarioService: UsuarioService,
  ) { }

  ngOnDestroy(): void {
    this.negocio = null
  }

  ngOnInit(): void {
    if(this.idNegocio) {
      this._getInfoCliente(this.idNegocio);
    } 
  }

  // HTML METHODS
  createUser() {
    const userClient: UserClientModel = {
      idCliente: this.negocio.cliente.idCliente,
      numCliente: this.negocio.cliente.numCliente,
      usuarioUltMod: this.idUsuario,
    }
    UtilsSwal.enviarInformacion(
      "¿Estás seguro de crear un usuario para este cliente?",
      "Generando usuario",
      "Generar",
      this.clienteService.createUser(this.token, userClient),
      false
    )
    .then( resp => 
      Swal.fire({
        icon: 'success',
        title: `Usuario creado: ${resp.resultado.codigoUsuario}`,
        text: `La contraseña de primer uso es: ${Utils.b64DecodeUnicode(resp.resultado.password)}`,
        showConfirmButton: true,
        heightAuto: true
      })
      .then(() => window.location.reload())
    );
  }

  lockUser() {
    const lock = this.usuario.bloqueo
      ? "Desbloquear"
      : "Bloquear"
    const lockUser: LockUserModel = {
      codigoUsuario: this.negocio.cliente.usuario,
      usuarioUltMod: this.idUsuario,
    }
    UtilsSwal.enviarInformacion(
      `¿Estás seguro de ${lock.toLocaleLowerCase()} este usuario?"`,
      `${lock} usuario`,
      lock,
      this.usuarioService.lockUser(this.token, lockUser),
      false
    )
    .then( () => {
      const lockResponse = this.usuario.bloqueo
        ? "desbloqueado"
        : "bloqueado"
      Swal.fire({
        icon: 'success',
        title: `Usuario ${lockResponse}`,
        text: `El usuario ha sido ${lockResponse} correctamente `,
        showConfirmButton: true,
        heightAuto: true
      })
      .then(() => window.location.reload())
    });
  }

  resetPassword() {
    Swal.fire({
      title: 'Ingresa la nueva contraseña de un uso',
      html: `<input type="text" id="password" class="swal2-input" placeholder="Contraseña">`,
      confirmButtonText: 'Confirmar',
      focusConfirm: false,
      preConfirm: () => {
        const password = (<HTMLInputElement> Swal.getPopup().querySelector('#password')).value;
        if (!password) Swal.showValidationMessage(`Por favor inserta una contraseña para continuar`)
        return { password: password }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const resetPassword: ResetPasswordModel = {
          codigoUsuario: this.negocio.cliente.usuario,
          usuarioUltMod: this.idUsuario,
          password: Utils.b64EncodeUnicode(result.value.password.trim()),
        }
        UtilsSwal.enviarInformacion(
          "¿Estás seguro de resetear la contraseña de este usuario?",
          "Reseteando contraseña",
          "Resetear",
          this.usuarioService.resetPassword(this.token, resetPassword),
          false
        )
        .then( () => 
          Swal.fire({
            icon: 'success',
            title: 'Contraseña restablecida correctamente',
            text: 'Con esta contraseña el usuario puede acceder nuevamente al sistema para actualizar sus datos',
            showConfirmButton: true,
            heightAuto: true
          })
          .then(() => window.location.reload())
        );
      }
    });
  }

  // APIS METHODS
  private _getInfoCliente(idNegocio: string) {
    UtilsSwal.buscandoInformacion();
    Swal.showLoading();
    let idsNegocios = [idNegocio];

    this.clienteService.obtenerClienteByIdNegocio(
      this.token, 
      idsNegocios, 
      true
    )
    .subscribe(resp => {
      Swal.close();
      this.negocio = resp.resultados[0];
      // Capacidad de Pago
      this.negocio.capacidadPago.gastoMensual = Utils.b64DecodeUnicode(this.negocio.capacidadPago.gastoMensual);
      this.negocio.capacidadPago.ingresoMensual = Utils.b64DecodeUnicode(this.negocio.capacidadPago.ingresoMensual);
      if( this.negocio.cliente.fuenteIngreso.ingresoMensual ) this.negocio.cliente.fuenteIngreso.ingresoMensual = Utils.b64DecodeUnicode(this.negocio.cliente.fuenteIngreso.ingresoMensual);
      if( this.negocio.capacidadPago.ingresoMensual && this.negocio.capacidadPago.gastoMensual ) this.capacidadPago = (+this.negocio.capacidadPago.ingresoMensual - +this.negocio.capacidadPago.gastoMensual) / 2;
      // Datos Bancarios
      this.negocio.datosBancarios.banco = Utils.b64DecodeUnicode(this.negocio.datosBancarios.banco);
      this.negocio.datosBancarios.beneficiario = Utils.b64DecodeUnicode(this.negocio.datosBancarios.beneficiario);
      if(this.negocio.datosBancarios.cuenta != null) this.negocio.datosBancarios.cuenta = Utils.b64DecodeUnicode(this.negocio.datosBancarios.cuenta);
      if(this.negocio.datosBancarios.cuentaClabe != null) this.negocio.datosBancarios.cuentaClabe = Utils.b64DecodeUnicode(this.negocio.datosBancarios.cuentaClabe);
      if(this.negocio.datosBancarios.numTarjeta != null) this.negocio.datosBancarios.numTarjeta = Utils.b64DecodeUnicode(this.negocio.datosBancarios.numTarjeta);
      // Datos usuario
      if(this.negocio.cliente.usuario) {
        this.usuarioService.obtenerDatosUsuario(this.token, this.negocio.cliente.usuario)
        .subscribe(userResp => {
          this.usuario = userResp.resultado;
        }, err => {
          // TODO: Arreglar reintentos y respuesta de error
          UtilsRest.validaApiError(err, true, this.authService)
        });
      }
    }, err => {
      Swal.close();
      const status = UtilsRest.validaApiError(err, true, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this._getInfoCliente(idNegocio)
      }
    });
  }

}
