<div class="card social-card share full-width m-b-10 no-border" data-social="item" *ngIf="credito">
    <div class="card-header clearfix">
        <h5 class="text-primary">Datos del crédito</h5>
    </div>
    <div class="grid-random">
        <div class="card-body">
            <label class="badge-estatus">
                <span class="badge-labels" [ngClass]="{'A' : 'badge-success', 'AME3' : 'badge-warning', 'A3' : 'badge-important', 'AMA3' : 'badge-danger', 'AMA10' : 'badge-danger', 'L' : 'badge-info'}[credito.estatus.clave]">
                    {{credito.estatus.descripcion}}
                </span>
            </label>
            <p>
                <span class="title-labels">No. Contrato: </span> {{credito.numContrato}}
            </p>
            <p>
                <span class="title-labels">Esquema de pago: </span> {{credito.esquemaPago.descripcion}}
            </p>
            <p>
                <span class="title-labels">Tipo de crédito: </span> {{credito.tipoCredito.descripcion}}
            </p>
            <p>
                <span class="title-labels">Monto solicitado: </span> {{credito.montoSolicitado | currency}}
            </p>
            <p>
                <span class="title-labels">Número de cuotas: </span> {{credito.numPagos}}
            </p>
            <p>
                <span class="title-labels">Comisión: </span> {{credito.comision | currency}}
            </p>
            <p>
                <span class="title-labels">Tasa de interes: </span> {{ credito.tasaInteres | percent:'1.2' }}
            </p>
            <p>
                <span class="title-labels">Tasa de interes anual: </span> {{ credito.tasaInteresAnual | percent:'1.2' }}
            </p>
            <p>
                <span class="title-labels">Cuota recurrente: </span> {{credito.cuotaRecurrente | currency }}
            </p>
            <p>
                <span class="title-labels">Fecha de apertura: </span> {{credito.fechaApertura}}
            </p>
            <p>
                <span class="title-labels">Fecha de autorización: </span> {{credito.fechaAutorizacion}}
            </p>
        </div>
    </div>
</div>