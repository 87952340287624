<div [formGroup]="formDireccion">
    <div class="row">
        <h5 class="text-primary">Dirección</h5>
    </div>

    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label>Calle</label>
                <input type="text" class="form-control" placeholder="Calle" formControlName="calle" [class.is-invalid]="calleNoValido">
                <small *ngIf="calleNoValido" class="text-danger">Campo requerido</small>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Num Ext</label>
                <input type="text" class="form-control" placeholder="Num Ext" formControlName="numExt" [class.is-invalid]="numExtNoValido">
                <small *ngIf="numExtNoValido" class="text-danger">Campo requerido</small>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Num Int</label>
                <input type="text" class="form-control" placeholder="Num Int" formControlName="numInt">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Colonia</label>
                <input type="text" class="form-control" placeholder="Colonia" formControlName="colonia" [class.is-invalid]="coloniaNoValido">
                <small *ngIf="coloniaNoValido" class="text-danger">Campo requerido</small>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label>Delegación o Municipio</label>
                <input type="text" class="form-control" placeholder="Delegación o Municipio" formControlName="municipio" [class.is-invalid]="municipioNoValido">
                <small *ngIf="municipioNoValido" class="text-danger">Campo requerido</small>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Estado</label>
                <input type="text" class="form-control" placeholder="Estado" formControlName="estado" [class.is-invalid]="estadoNoValido">
                <small *ngIf="estadoNoValido" class="text-danger">Campo requerido</small>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>País</label>
                <input type="text" class="form-control" placeholder="País" formControlName="pais" [class.is-invalid]="paisNoValido">
                <small *ngIf="paisNoValido" class="text-danger">Campo requerido</small>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label class="ellipsis">Código Postal</label>
                <input type="text" class="form-control" placeholder="Código Postal" formControlName="cp" [class.is-invalid]="cpNoValido">
                <small *ngIf="cpNoValido" class="text-danger">El campo no es válido</small>
            </div>
        </div>
    </div>
</div>