import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constants } from 'src/app/classes/constants.class';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-capturar-documentos',
  templateUrl: './capturar-documentos.component.html',
  styleUrls: ['./capturar-documentos.component.scss']
})
export class CapturarDocumentosComponent implements OnInit {

  // VALIDACION ROL
  permisoUsuario: boolean;

  // PARAMETROS LOCALES
  numSolicitud: string;
  claveEstatusSolicitud: string;
  claveTipoSolicitud: string;

  // CONSTANTES
  claveDatosTemporales = Constants.CLAVE_CAPTURA_DATOS_TEMPORALES;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {

    this.permisoUsuario = this.authService.permisosUsuario();
    
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.numSolicitud = params['numSolicitud'];
        this.claveEstatusSolicitud = params['claveEstatusSolicitud'];
        this.claveTipoSolicitud = params['claveTipoSolicitud'];
      }
    );
  }
}
