import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActualizaPersona, ClienteCredito } from '../models/clients/cliente-request.model';
import { ClienteVerificacionDatos, UserClientModel } from '../models/clients/cliente.model';
import { NegocioModel } from '../models/business/negocio-request.model';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private http: HttpClient) { }

  obtenerClientesByTermino(accessToken: string, termino: string, idSucursal: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/clientes/v1/clientes/autocomplete?nombre=${termino}&idSucursal=${idSucursal}`, 
      {headers: headers}
    );
  }

  obtenerNegociosByCliente(accessToken: string, numCliente: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/clientes/v1/negocios?numCliente=${numCliente}`, 
      {headers: headers}
    );
  }

  obtenerNivelEscolar(accessToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/clientes/v1/catalogo/niveles-escolares`, 
      {headers: headers}
    );
  }

  obtenerOcupacion(accessToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/clientes/v1/catalogo/ocupaciones`, 
      {headers: headers}
    );
  }

  obtenerFuenteIngresos(accessToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/clientes/v1/catalogo/fuentes-ingresos`, 
      {headers: headers}
    );
  }

  obtenerMedioConocimiento(accessToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/clientes/v1/catalogo/medios-conocimiento`, 
      {headers: headers}
    );
  }

  obtenerClienteByIdNegocio(accessToken: string, idsNegocios: string[], infoExtra: boolean): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/clientes/v1/clientes?idsNegocios=${idsNegocios}&infoExtra=${infoExtra}`, 
      {headers: headers}
    );
  }

  obtenerCliente(
    accessToken: string, 
    idCliente: number
  ): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/clientes/v1/cliente?idCliente=${idCliente}`, 
      {headers: headers}
    );
  }

  obtenerEstatusVerificacion(accessToken: string, idCliente: string, idNegocio: string, idSucursal: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/clientes/v1/estatus/verificacion?idCliente=${idCliente}&idNegocio=${idNegocio}&idSucursal=${idSucursal}`, 
      {headers: headers}
    );
  }

  realizarVerificacion(accessToken: string, request: ClienteVerificacionDatos): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/clientes/v1/verificacion-datos`,
      JSON.stringify(request),
      {headers: headers}
    );
  }

  actualizarInfoPersonas(accessToken: string, actualizarPersona: ActualizaPersona): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.put(
      `${environment.url}/clientes/v1/persona`, JSON.stringify(actualizarPersona), {headers: headers}
    );
  }

  guardarCliente(accessToken: string, cliente: ClienteCredito): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/clientes/v1/cliente`, JSON.stringify(cliente), {headers: headers}
    );
  }

  guardarNegocio(accessToken: string, negocio: NegocioModel): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/clientes/v1/negocio`, JSON.stringify(negocio), {headers: headers}
    );
  }

  createUser(accessToken: string, userClient: UserClientModel): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });
    return this.http.post(
      `${environment.url}/clientes/v1/usuario`, JSON.stringify(userClient), 
      {headers: headers}
    );
  }
}
