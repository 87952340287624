import { Component, Input, OnInit } from '@angular/core';
import { Constants } from 'src/app/classes/constants.class';
import { ArchivoDigitalizacion, ArchivoSolicitudModel, EliminarArchivoModel, VerArchivoModel } from 'src/app/models/files/archivo-solicitud.model';
import { AuthService } from 'src/app/services/auth.service';
import { ReporteService } from 'src/app/services/reporte.service';
import UtilsRest from 'src/utils/UtilsRest';
import UtilsSwal from 'src/utils/UtilsSwal';
import { DigitalizacionService } from '../../../services/digitalizacion.service';

@Component({
  selector: 'app-contrato-pagare',
  templateUrl: './contrato-pagare.component.html',
  styleUrls: ['./contrato-pagare.component.scss']
})
export class ContratoPagareComponent implements OnInit {

  private token: string = sessionStorage.getItem('token');
  private idUsuario: string = sessionStorage.getItem('idUsuario');

  // PARAMETROS RECIBIDOS
  @Input() numContrato: string;
  @Input() numSolicitud: string;

  // PARAMETROS LOCALES
  archivos: ArchivoDigitalizacion[] = [];
  verArchivos: VerArchivoModel[]; 

  // CONSTANTES
  tipo = Constants.TIPO_DIGITALIZACION_PDF;
  numArchivo = 1;

  // CONSTANTES - ACTUALIZAR ESTATUS
  claveFirmaContrato = Constants.CLAVE_FIRMA_CONTRATO_PAGARE;
  tituloActualizarEstatus = "Confirmar contrato y pagaré firmado";

  constructor(
    private authService: AuthService,
    private reporteService: ReporteService,
    private digitalizacionService: DigitalizacionService
  ) { }

  ngOnInit(): void {
    this.buscarExpediente();
  }

  // METODOS HTML
  contratoFirmado(): boolean {
    return this.verArchivos === null || this.verArchivos.length === 0
      ? false
      : true;
  }
  
  generarContrato() {
    UtilsSwal.generarReporte(
      this.reporteService.generarContratoPagare(
        this.token, 
        this.numContrato
      )
    );
  }

  guardarArchivos() {

    const archivos: ArchivoSolicitudModel = {
      idDinamico: this.numSolicitud,
      claveTipoExpediente:  Constants.CLAVE_TIPO_EXPEDIENTE_CREDITO,
      claveClasificacionArchivo: Constants.CLAVE_TIPO_CLASIFICACION_ARCHIVO_CREDITO,
      usuarioUltMod: this.idUsuario,
      tiposArchivos: [{
        clave: Constants.CLAVE_TIPO_ARCHIVO_CONTRATO,
        archivos: this.archivos
      }]
    };

    UtilsSwal.enviarInformacion(
      "¿Estás seguro que deseas subir este archivo al servidor?",
      "Guardando contrato y pagaré firmado",
      "Guardar",
      this.digitalizacionService.guardarArchivo(this.token, archivos),
      false
    )
    .then(() => this.reloadPage());
  }

  borrarArchivos() {

    const eliminarArchivo: EliminarArchivoModel = {
      idDinamico: this.numSolicitud,
      claveTipoArchivo: Constants.CLAVE_TIPO_ARCHIVO_CONTRATO,
      numArchivo: this.numArchivo,
      usuarioUltMod: this.idUsuario
    }

    UtilsSwal.enviarInformacion(
      "¿Estás seguro que deseas eliminar este archivo al servidor?",
      "Eliminando contrato y pagaré",
      "Eliminar",
      this.digitalizacionService.eliminarArchivo(this.token, eliminarArchivo),
      false
    )
    .then(() => this.reloadPage());
  }

  addFile(nuevoArchivo: ArchivoDigitalizacion) {
    if(nuevoArchivo.base64) {
      // Si el archivo contiene información, se guarda
      this.archivos.push(nuevoArchivo);
    } else {
      // Si el archivo esta vacio y existen la lista, se borra 
      this.archivos.splice(0, 1);
    }
  }

  // METODOS PRIVADOS
  reloadPage() {
    window.location.reload();
  }

  // SERVICIOS EXTERNOS
  buscarExpediente() {
    this.digitalizacionService.obtenerExpediente(
      this.token, 
      this.numSolicitud,
      Constants.CLAVE_TIPO_EXPEDIENTE_CREDITO, 
    ).subscribe(resp => {

      this.verArchivos = [{
        idDinamico: this.numSolicitud,
        numArchivo: this.numArchivo,
        tipoArchivo: Constants.CLAVE_TIPO_ARCHIVO_CONTRATO
      }];

    }, (err) => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarExpediente();
      }
      if(status.codigo === 404) {
        this.verArchivos = null;
      }
    });
  }
}
