import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NegociosCobranza, RutaCobranzaModel } from 'src/app/models/collections/cobranza.model';
import { CatResponse } from '../../../models/catalogues/cat-response.model';
import { Constants } from '../../../classes/constants.class';

@Component({
  selector: 'app-ruta',
  templateUrl: './ruta.component.html',
  styleUrls: ['./ruta.component.scss']
})
export class RutaComponent implements  OnChanges {

  token: string = sessionStorage.getItem('token');
  idUsuario: string = sessionStorage.getItem('idUsuario');

  // EVENTOS DE SALIDA
  @Output() negocioRutaEmit = new EventEmitter<NegociosCobranza>();

  // PARAMETROS RECIBIDOS
  @Input() ruta: RutaCobranzaModel;
  @Input() historico: boolean;
  
  // VARIABLES LOCALES
  cobranzaCobrados: NegociosCobranza[] = [];
  cobranzaNoCobrados: NegociosCobranza[] = [];
  catalogoImpago: CatResponse[] = [];

  negocioCobranzaSelect: NegociosCobranza;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {

    if (changes.ruta.currentValue
      && changes.ruta.previousValue != changes.ruta.currentValue
    ) {

      this.cobranzaCobrados = this.ruta.negociosCobranza
        .filter( nc => nc.cobranza.estatus.clave === Constants.CLAVE_NEGOCIO_VISITADO);

      this.cobranzaNoCobrados = this.ruta.negociosCobranza
        .filter( nc => nc.cobranza.estatus.clave === Constants.CLAVE_NEGOCIO_NO_VISITADO);

      // Se actualiza la información del negocio seleccionado
      if(this.negocioCobranzaSelect) {
        const negocioCobranzaActualizado =  this.ruta.negociosCobranza
          .find(nc => nc.cobranza.idNegocioCobranza === this.negocioCobranzaSelect.cobranza.idNegocioCobranza);
  
        this.setNegocioCobranzaSelect(negocioCobranzaActualizado);
      } 
    }

    if(changes.historico && changes.historico.currentValue) {
      this.historico = changes.historico.currentValue;
    }
  }

  // METODOS HTML
  setNegocioCobranzaSelect(negocioCobranza: NegociosCobranza) {
    this.negocioCobranzaSelect = negocioCobranza;
  }

  clearNegocio() {
    this.negocioCobranzaSelect = null;
  }
}
