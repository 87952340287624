import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";

export default class Utils {

    // File to base64
    static readFileBase64(file): Promise<string> {

        const reader = new FileReader();
        const future = new Promise((resolve, reject) => {

          reader.addEventListener('load', function () {
            resolve(reader.result);
          }, false);
          
          reader.addEventListener('error', function (event) {
            reject(event);
          }, false);
        
          reader.readAsDataURL(file);
        });

        return future.then((base64: string) => {
            const data = base64.split("base64,");
            return data[1].trim();
        });
    }

    // Base64 to blob
    static b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }

    // Encoding UTF8 ⇢ base64
    static b64EncodeUnicode(str: string):string {
        return str != null ? window.btoa(unescape(encodeURIComponent( str ))) : '';
    }

    // Decoding base64 ⇢ UTF8
    static b64DecodeUnicode(str: string):string {
        if(str != null) {
            str = str.replace(/\s/g, '');   
            return decodeURIComponent(escape(window.atob( str )));
        } else {
            return null
        }
    }

    // Capitalize Each Word
    static capitalizeEachWord(str) {
        return str.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    // Validate Forms
    static validateForms(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormArray) {
                for (const control1 of control.controls) {
                    if (control1 instanceof UntypedFormControl) {
                        control1.markAsTouched({
                            onlySelf: true
                        });
                    }
                    if (control1 instanceof UntypedFormGroup) {
                        this.validateForms(control1);
                    }
                }
            }
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({
                    onlySelf: true
                });
            } else if (control instanceof UntypedFormGroup) {
                this.validateForms(control);
            }
        });
    }
}