import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DireccionConCoordenadas } from '../../../../models/person/direccion.model';

@Component({
  selector: 'app-direcciones',
  templateUrl: './direcciones.component.html',
  styleUrls: ['./direcciones.component.scss']
})
export class DireccionesComponent implements OnInit, OnChanges {

  // PARAMETROS RECIBIDOS
  @Input() direccion: DireccionConCoordenadas;

  // FORMS
  formDireccion: UntypedFormGroup;
  
  constructor(
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this._crearFormularioDireccion();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.direccion) this._crearFormularioDireccion();
  }

  // HANDLERS
  validarFormHandler(): UntypedFormGroup {
    return this.formDireccion;
  }

  // METODOS FORMS
  _crearFormularioDireccion() {
    this.formDireccion = this.fb.group({
      calle: [this.direccion.calle, [Validators.required]],
      numExt: [this.direccion.numExt, [Validators.required]],
      numInt: [this.direccion.numInt],
      colonia: [this.direccion.colonia, [Validators.required]],
      municipio: [this.direccion.municipio, [Validators.required]],
      estado: [this.direccion.estado, [Validators.required]],
      pais: [this.direccion.pais, [Validators.required]],
      cp: [this.direccion.cp, [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    });
  }

  // VALIDACIONES (DATOS NO VALIDOS)
  get calleNoValido() {
    return this.formDireccion.get('calle').invalid && this.formDireccion.get('calle').touched
  }

  get numExtNoValido() {
    return this.formDireccion.get('numExt').invalid && this.formDireccion.get('numExt').touched
  }

  get coloniaNoValido() {
    return this.formDireccion.get('colonia').invalid && this.formDireccion.get('colonia').touched
  }

  get municipioNoValido() {
    return this.formDireccion.get('municipio').invalid && this.formDireccion.get('municipio').touched
  }

  get estadoNoValido() {
    return this.formDireccion.get('estado').invalid && this.formDireccion.get('estado').touched
  }

  get paisNoValido() {
    return this.formDireccion.get('pais').invalid && this.formDireccion.get('pais').touched
  }

  get cpNoValido() {
    return this.formDireccion.get('cp').invalid && this.formDireccion.get('cp').touched
  }

}

export const direccionEmpty:DireccionConCoordenadas = {
  idDireccion: '',
  calle: '',
  numExt: '',
  numInt: null,
  colonia: '',
  municipio: '',
  estado: '',
  pais: 'México',
  cp: '',
  coordenadas: {
    latitud: '',
    longitud: ''
  }
};
