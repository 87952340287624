import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LockUserModel, ResetPasswordModel } from '../models/users/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient) { }

  obtenerDatosUsuario(accessToken: string, username: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/usuarios/v1/usuario/info-general?codigoUsuario=${username}`, 
      {headers: headers}
    );
  }

  actualizarPassword(accessToken: string, codigoUsuario: string, passwordAnterior: string, passwordNuevo: string, usuarioUltMod: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    const body = {
      codigoUsuario,
      passwordAnterior,
      passwordNuevo,
      usuarioUltMod
    };

    return this.http.put(
        `${environment.url}/usuarios/v1/password`, body, {headers: headers});
  }

  resetPassword(accessToken: string, resetPassword: ResetPasswordModel): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });
    return this.http.put(
      `${environment.url}/usuarios/v1/reset/password`, 
      resetPassword, 
      {headers: headers});
  }

  lockUser(accessToken: string, lockUser: LockUserModel): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });
    return this.http.put(
      `${environment.url}/usuarios/v1/bloqueo`, 
      lockUser, 
      {headers: headers});
  }

  obtenerCobradores(accessToken: string, idSucursal: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/usuarios/v1/usuarios/cobradores?idSucursal=${idSucursal}`, 
      {headers: headers}
    );
  }

  obtenerColocadores(accessToken: string, idSucursal: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/usuarios/v1/usuarios/colocadores?idSucursal=${idSucursal}`, 
      {headers: headers}
    );
  }
}
