import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

const ENCRYPT_KEY = environment.encryptKey;

@Injectable({
  providedIn: 'root'
})

export class EncriptarService {

  constructor() { }

  public encriptarTexto(texto: string): string{
    var encrypted = CryptoJS.AES.encrypt(texto.trim(), ENCRYPT_KEY, {
      mode: CryptoJS.mode.ECB
    });
    return encrypted.toString();
  }

  public desencriptarTexto(texto: string): string{
    return CryptoJS.AES.decrypt(texto, ENCRYPT_KEY, {
      mode: CryptoJS.mode.ECB
    }).toString(CryptoJS.enc.Utf8);
  }
}
