import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { SendSocket, VisitaComponentModel, VisitaModel } from 'src/app/models/collections/cobranza.model';
import { PagoModel, RegistrarPagoModel, RegistrarVisitaModel } from 'src/app/models/credits/pagos.model';
import { CobranzaService } from 'src/app/services/cobranza.service';
import UtilsSwal from 'src/utils/UtilsSwal';
import Swal from 'sweetalert2';
import { CatResponse } from 'src/app/models/catalogues/cat-response.model';
import UtilsRest from 'src/utils/UtilsRest';
import { AuthService } from 'src/app/services/auth.service';
import { Constants } from '../../../classes/constants.class';
import { Client } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { WebsocketsService } from 'src/app/services/websockets.service';
import { isNullUndefined } from '../../../../utils/nonNullUndefined';

@Component({
  selector: 'app-visitas',
  templateUrl: './visitas.component.html',
  styleUrls: ['./visitas.component.scss']
})
export class VisitasComponent implements OnDestroy, OnChanges {

  private token: string = sessionStorage.getItem('token');
  private idUsuario: string = sessionStorage.getItem('idUsuario');
  private idSucursal: string = sessionStorage.getItem('idSucursal');

  // EVENTOS DE SALIDA
  @Output() visitaEmit = new EventEmitter<PagoModel>();

  // PARAMETROS RECIBIDOS
  @Input() visitaComponentModel: VisitaComponentModel;
  @Input() historico: boolean;

  // VARIABLES LOCALES
  visitaSelect: VisitaModel;
  visitas: VisitaModel[];
  catalogoImpago: CatResponse[] = [];

  // SOCKETS
  private client: Client;

  constructor(
    private authService: AuthService,
    private cobranzaService: CobranzaService,
    private websocketsService: WebsocketsService,
  ) { }

  // METODOS 
  ngOnDestroy(): void {
    this.visitas = []
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if(!changes.visitaComponentModel.currentValue) {
      this.visitaComponentModel = null;
      this.visitaSelect = null;
      this.visitas = [];
    } else {

      this.visitaComponentModel = changes.visitaComponentModel.currentValue;
      this.visitas = changes.visitaComponentModel.currentValue.visitas;

      // Actualizar componente de pagos (socket)
      if(this.visitaSelect) {

        // Se valida si la visita pertenece al mismo negocio cobranza
        const visitaSelectActualizado = this.visitas
          .find(v => v.idVisitaCobranza === this.visitaSelect.idVisitaCobranza);
        
        this.setVisitaSelect(visitaSelectActualizado);
      }

      // Se realiza conexión a sockets
      this.client = new Client();
      this.client.webSocketFactory = () => {
        return new SockJS(this.websocketsService.getPathSockCobranzaJS());
      }

      this.client.activate();
    }

    if(changes.historico && changes.historico.currentValue) {
      this.historico = changes.historico.currentValue;
    }
  }

  // METODOS HTML
  setVisitaSelect(visita: VisitaModel) {
    this.visitaSelect = visita;

    if(visita) {
      this.visitaEmit.emit({
        idNegocioCobranza: this.visitaComponentModel.idNegocioCobranza,
        idCuotaCredito: this.visitaComponentModel.idCuotaCredito,
        idVisitaCobranza: this.visitaSelect.idVisitaCobranza,
        estatusVisita: this.visitaSelect.estatus.clave,
        pagos: this.visitaSelect.pagos
      });
    } else {
      this.visitaEmit.emit(null);
    }
  }

  // MODALES
  realizarPago() {

    Swal.fire({
      title: 'Pagar cuota',
      html: `<input type="number" id="montoCobrado" min="1" class="swal2-input" style="max-width: 100%" placeholder="Monto cobrado">
      <input type="number" id="montoCobrado2" min="1" class="swal2-input" style="max-width: 100%" placeholder="Confirmar monto cobrado">
      <input type="text" id="numTransferencia" class="swal2-input" style="max-width: 100%" placeholder="Número de transferencia">`,
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      showLoaderOnConfirm: true,
      preConfirm: () => {

        const montoCobrado = Number((<HTMLInputElement> Swal.getPopup().querySelector('#montoCobrado')).value);
        const montoCobrado2 = Number((<HTMLInputElement> Swal.getPopup().querySelector('#montoCobrado2')).value);
        const numTransferencia = (<HTMLInputElement> Swal.getPopup().querySelector('#numTransferencia')).value;

        if (montoCobrado <= 0 || montoCobrado2 <= 0) Swal.showValidationMessage(`El monto no pueden ser negativo`)
        if (montoCobrado != montoCobrado2) Swal.showValidationMessage(`Los montos no son iguales`)
        if (isNullUndefined(numTransferencia) || numTransferencia === "") Swal.showValidationMessage(`Este campo es obligatorio`)

        return { montoCobrado: montoCobrado, numTransferencia }
      }
    }).then((result) => {

      const montoCobrado = Number(result.value.montoCobrado);
      const numTransferencia = result.value.numTransferencia;
      const registrarPago: RegistrarPagoModel = {
        idCobrador: this.idUsuario,
        idCuotaCredito: this.visitaComponentModel.idCuotaCredito,
        idNegocioCobranza: this.visitaComponentModel.idNegocioCobranza,
        idCredito: this.visitaComponentModel.idCredito,
        idNegocio: this.visitaComponentModel.idNegocio,
        montoCobrado: montoCobrado,
        formaPago: Constants.CLAVE_PAGO_TRANSFERENCIA, // Only transfer
        numTransferencia: numTransferencia,
        coordenadas: { // FIXME: Get location from the browser
          latitud: Constants.LATITUD_POINT.toString(),
          longitud: Constants.LONGITUD_POINT.toString()
        }
      };

      this._registrarPago(registrarPago);
    });
  }

  realizarVisita() {

    const options: Map<string, string> = new Map<string, string>();
    this.catalogoImpago.forEach((e) => {
      if(!(e.clave === Constants.CLAVE_PAGO_ADELANTADO && this.visitaComponentModel.pagoRequerido > 0)) {
        options.set(e.clave, e.descripcion);
      }
    });

    Swal.fire({
      title: 'Registrar visita',
      input: 'select',
      inputOptions: options,
      inputPlaceholder: 'Selecciona un estatus',
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      inputValidator: function (value) {
        return !value && 'Debes indicar un estatus';
      }
    }).then((result) => { 
      if(result.isConfirmed) {
        const visita:RegistrarVisitaModel = {
          idNegocioCobranza: this.visitaComponentModel.idNegocioCobranza,
          idCredito: this.visitaComponentModel.idCredito,
          idNegocio: this.visitaComponentModel.idNegocio,
          idCobrador: this.idUsuario,
          estatusVisita: result.value,
          coordenadas: { // FIXME: Get location from the browser
            latitud: Constants.LATITUD_POINT.toString(),
            longitud: Constants.LONGITUD_POINT.toString()
          }
        }
        this._registrarVisita(visita);
      }
    });
  }

  // SERVICIOS EXTERNOS
  buscarCatalogoImpago() {
    this.cobranzaService.obtenerCatalogoImpago(
      this.token, 
    ).subscribe(resp => {
      
      var catalogo = resp.resultados;
      this.catalogoImpago = catalogo;
      this.realizarVisita();

    }, (err) => {
      const status = UtilsRest.validaApiError(err, false, this.authService)
      if(status.codigo == 401) {
        this.authService.guardarToken(status.accessToken, status.refreshToken)
        this.buscarCatalogoImpago();
      }
      if(status.codigo == 404) {
        this.catalogoImpago = [];
      }
    });
  }

  private _registrarPago(registrarPago: RegistrarPagoModel) {
    UtilsSwal.enviarInformacion(
      "¿Estás seguro de registar el pago de esta cuota",
      "Guardando pago",
      "Guardar",
      this.cobranzaService.realizarPago(this.token, registrarPago),
      false
    ).then(() => {
      this._enviarInfoSocket();
    });
  }

  private _registrarVisita(registrarVisita: RegistrarVisitaModel) {
    UtilsSwal.enviarInformacion(
      "¿Estás seguro de registar esta visita?",
      "Guardando visita",
      "Guardar",
      this.cobranzaService.realizarVisita(this.token, registrarVisita),
      false
    ).then(() => {
      this._enviarInfoSocket();
    });
  }

  private _enviarInfoSocket() {
    const sendSocket: SendSocket = {
      idSucursal: this.idSucursal,
    }
    this.client.publish({ 
      destination: '/send/ruta-cobranza', 
      body: JSON.stringify(sendSocket) 
    });
  }
}
