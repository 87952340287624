import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { PersonaCredito } from '../models/person/persona-request.model';

@Injectable({
  providedIn: 'root'
})
export class PersonaService {

  constructor(private http: HttpClient) { }

  obtenerEstadoCivil(accessToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/personas/v1/catalogo/estado-civil`, 
      {headers: headers}
    );
  }

  obtenerTipoVivienda(accessToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/personas/v1/catalogo/tipo-vivienda`, 
      {headers: headers}
    );
  }

  obtenerInfoPersonas(accessToken: string, idsPersonas: number[]): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/personas/v1/personas?idsPersonas=${idsPersonas}`, 
      {headers: headers}
    );
  }

  // POST 
  actualizarPersona(
    accessToken: string, 
    persona: PersonaCredito
  ) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.post(
      `${environment.url}/personas/v1/persona`, JSON.stringify(persona), {headers: headers}
    );
  }
}
